import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cell from '../../Cell/Cell';

function Row(props) {
  const [checked, setChecked] = useState(false);

  const validateChecked = () => {
    let check = false;

    if (props.checkList.indexOf(props.row[props.idKey]) !== -1) {
      check = true;
    }

    setChecked(check);
  }

  const checkItemToggle = () => {
    props.checkItemToggle(props.row[props.idKey]);
  }

  useEffect(() => {
    validateChecked();
  }, [props.checkList, props.row]);

  return (
    <tr>
      {props.columns.map((column, i) => {
        return (
          <Cell
            key={i}
            checkAll={props.checkAll}
            checked={checked}
            checkItemToggle={checkItemToggle}
            column={column}
            row={props.row}
            invalid={props.invalid}
          />
        );
      })}
    </tr>
  );
};

Row.propTypes = {
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

Row.defaultProps = {
  invalid: '-'
};

export default Row;
