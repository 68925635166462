import React, {Component} from 'react';

import {
  Columns,
  Content,
  Button
} from 'react-bulma-components/full';

import { STATUS } from '../../../../../General/Constants/Item';

class ItemWaitingPhotos extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  changeStatusItem = () => {
    this.props.changeStatusItem(this.props.item._id, STATUS.WAITINGPHOTOS);
    this.props.closeModal();
  }

  render() {
    return(
      <div>
        <Columns>
          <Columns.Column>
            <Content size="large" className="has-text-centered">
              <h1>{this.props.item.pc9}</h1>
            </Content>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column className="has-text-right">
            <Button color="success" onClick={() => this.changeStatusItem()}>Aceptar</Button>
          </Columns.Column>
          <Columns.Column>
            <Button color="primary" onClick={() => this.props.closeModal()}>Cancelar</Button>
          </Columns.Column>
        </Columns>
      </div>
    );
  }
}

export default ItemWaitingPhotos;