import React from 'react';

function TFooter() {  
  return (
    <tfoot>
      <tr></tr>
    </tfoot>
  );
};

export default TFooter;