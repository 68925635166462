import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from '../../../../../General/Form/Dropzone/Dropzone';
import ItemService from '../../../../../../Services/ItemsService';

import {
  Button,
  Columns
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {toast} from 'react-toastify';

import { STATUS as ITEM_STATUS } from '../../../../../General/Constants/Item';

const itemService = new ItemService();

const UploadFolder = (props) => {

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [perFileProgress, setPerFileProgress] = useState(0)
  const [filesPerPC9, setFilesPerPC9]= useState(0)
  const [PC9, setPC9]= useState('')
  const [uploading, setUploading] = useState(false);
  const [totalPc9, setTotalPc9] = useState(0);

  

  const getListError = (errors) => {
    return (
      <div>
        Los siguientes archivos no cumplen con el formato en el nombre:
        {errors.map((error, key) => {
          return <li key={key}>{error}</li>;
        })}
      </div>
    );
  }

  const onChangeDropzone = (state) => {
    setFiles(state.files);
  }

  const preProcessData = () => {
    let allowMimeTYpes = ['image/jpeg', 'image/png', 'image/tiff'];
    let arrToUploadTmp = {};
    let formatError = [];
    let totalPc9 = 0;

    for (let i in files) {
      if (allowMimeTYpes.indexOf(files[i].type) === -1) {
        continue;
      }

      let split = files[i].name.split('-');
      let pc9Clean = '';
      
      if (split.length < 2) {
        formatError.push(files[i].name);
        continue;
      }
      let indexImage = parseInt(split.pop());

      pc9Clean = split.join().replace(/[^0-9a-zA-Z]/gi, '');
      let regex = new RegExp('(' + pc9Clean + ')', 'gi');
      let dataItem = props.items.find(item => regex.test(item.pc9));
      
      // Check item exist in list
      if (!dataItem) {
        continue;
      }

      // Check status
      let status = (props.type === 'eComm' ? dataItem.status.current : dataItem.status.product_guide)
      if ([ITEM_STATUS.TOREVIEW, ITEM_STATUS.APPROVED].indexOf(status) !== -1) {
        continue;
      }

      if (!arrToUploadTmp[pc9Clean]) {
        let channelName = (props.type === 'eComm' ? 'master' : 'mpguide')
        arrToUploadTmp[pc9Clean] = [];
        arrToUploadTmp[pc9Clean] = {
          channel: dataItem.channels.filter(ch => ch.channel === channelName)[0],
          item: dataItem,
          files: []
        };

        totalPc9++;
      }

      arrToUploadTmp[pc9Clean].files[indexImage] = files[i];
    }
    
    return {
      arrToUploadTmp: arrToUploadTmp,
      formatError: formatError,
      totalPc9: totalPc9
    };
  }


  const updateStatus = (item, status) => {
    let statusKey = (props.type === 'eComm' ? 'status.current' : 'status.product_guide')
    itemService.put(item._id, {
      "co": [{
        [statusKey]: ITEM_STATUS[status]
      }]
    })
    .then((response) => {
      props.onChange();
    });
  }

  function waitPlease(secs) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('Han pasado '+ secs+' segundos');
      }, secs);
    });
  }

  


  const uploadAllFiles = async () => {
    let {arrToUploadTmp, formatError, totalPc9} = preProcessData();
    let countProgress = 1;
    setProgress(1);
    if (formatError.length) {
      toast.error(getListError(formatError), {
        autoClose: false
      });
    }
    setTotalPc9(totalPc9);
    
    
    if (Object.keys(arrToUploadTmp).length) {
      for (let ipc9Key in arrToUploadTmp) {
        let countProgressPerFile = 0
        let item = arrToUploadTmp[ipc9Key].item;
        let channel = arrToUploadTmp[ipc9Key].channel;
        let pc9Files = arrToUploadTmp[ipc9Key].files.filter(function (el) {
          return el != null;
        });
        //console.log(pc9Files)
        setFilesPerPC9(pc9Files.length)
        setPC9(item.pc9)
        setPerFileProgress(++countProgressPerFile)
        for(let i=0; i<pc9Files.length; i++){
          
            await itemService.uploadImages([pc9Files[i]], channel, item)
            .then(response=>{
              //console.log(response)
              if(i===pc9Files.length-1){
                itemService.generateCuts(response._id, response.channels[0].id_channel)
              }
            })
            .catch(error=>console.log(error))

            if(i===pc9Files.length-1){
              await waitPlease(3500*pc9Files.length);              
              updateStatus(item, 'TOREVIEW')
              toast.success('El PC9: "' + ipc9Key + '" recibió ' + pc9Files.length + " fotos")
              setPerFileProgress(++countProgressPerFile)              
            } else{
              await waitPlease(1000);
              setPerFileProgress(++countProgressPerFile)
            }
            
        }
        setProgress(++countProgress)
      }
      
      props.onChange();
      props.closeModal();
    } else {
      toast.warn('No se encontraron PC9\'s que coincidan con la carga del folder.');
    }
    
    setUploading(false);
  }


  useEffect(() => {
    if (uploading) {
      uploadAllFiles();
    }
  }, [uploading]);
  

  return (
    <div className="has-text-centered">
      <Columns centered={true}>
        <Columns.Column>
          <Button
            color="success"
            disabled={(!files.length || uploading)}
            fullwidth={true}
            onClick={() => setUploading(true)}
          >
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              className={uploading ? '' : 'is-hidden'}
            />
            &nbsp; {uploading ? 'Subiendo '+perFileProgress +'/'+ filesPerPC9 +' fotos para '+ progress + '/' + totalPc9 + ' PC9\'s' : 'Subir todas las fotos'}
          </Button>
        </Columns.Column>
      </Columns>

      {!uploading? 
            <Dropzone
            directory={true}
            maxFiles={50000}
            onChange={onChangeDropzone}
            formats={[".png", ".jpg", "image/jpeg", "image/png"]}
          />
          :
          null
    }

    </div>
  );
}

UploadFolder.propTypes = {
  onChange: PropTypes.func
};

UploadFolder.defaultProps = {
  onChange: () => { }
};

export default UploadFolder;
