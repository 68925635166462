import React, { Component } from 'react';
import ItemsService from '../../../Services/ItemsService';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import {
  faCalendarCheck,
  faCrosshairs
} from '@fortawesome/free-solid-svg-icons';

import Datatable from '../../General/Datatable/Datatable';
import ModalCard from '../../General/Modal/ModalCard';
import CreateShootingForm from './components/CreateShootingForm';
import AddToShooting from './components/AddToShooting';
import Loader from '../../General/Loader/Loader';
import AvailableEmptyRows from './components/AvailableEmptyRow';

import {STATUS} from '../../General/Constants/Item';
import {PENDING_ITEM, ITEMS} from '../../General/Language/es';

class PendingShooting extends Component {
  constructor(props) {
    super(props);
    this.tabIndex = React.createRef();
    
    this.state = {
      checkList: [],
      data: [],
      loading: true,
      modal: {
        show: false,
      },
      table: {
        actions: [
          {
            title: "Asignar PC9's seleccionados a un shooting",
            label: 'Asignar a shooting',
            className: 'is-success',
            icon: faCalendarCheck,
            classSetName: 'is-success',
            onClick: () => {
              this.addTo();
            }
          }
        ],
        checkCounter: true,
        columns: [
          {
            type: 'checklist',
            field: '_id'
          }, {
            field: 'pc9',
            label: 'PC9',
            sortable: true,
          }, {
            field: 'brand',
            label: 'Marca',
            sortable: true
          }, {
            field: 'line',
            label: 'Línea',
            sortable: true
          }, {
            field: 'category',
            label: 'Categoría',
            sortable: true
          }, {
            dictionary: ITEMS.STATUS,
            field: 'status.product_guide',
            label: 'Estatus',
            sortable: true,
            type: 'status'
          },
          {
            label: 'Acciones',
            type: 'actions',
            actions: [
              {
                title: 'Solicitar búsqueda en almacén',
                icon: faCrosshairs,
                color: 'danger',
                onClick: (item) => {
                  this.searchAgain(item);
                }
              }
            ]
          },
        ],
        emptyLegend: <AvailableEmptyRows />,
        filtersConfig: {
            brand: {
            label: 'Marca'
          }, 
          gender: {
            label: 'Género'
          }, 
          category: {
            label: 'Categoría'
          }, 
          'status.product_guide': {
            label: 'Estatus',
            dictionary: ITEMS.STATUS
          },
          pc9: {
            type: 'textarea',
            label: 'Varios PC9'
          }
        },
        loadingLegend: <Loader />,
        searchFields: ['pc9', 'barcode', 'skus']
      },
      renderRows: [],
      queryString: {
        rf: ['_id', 'pc9', 'brand', 'line', 'category', 'status', 'barcode', 'skus'],
        qf: {
          'status.product_guide': STATUS.AVAILABLE
        }
      }
    };

    this.confirm = {
      title: <strong>Confirmar</strong>,
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onKeypressEscape: () => {}
    };

    this.service = new ItemsService();
  }

  searchAgain = (item) =>{
    let options = this.confirm;

    options.message = PENDING_ITEM.SET_DELETE;
    options.buttons = [
      {
        label: 'Sí, buscar',
        onClick: () => {
          this.service.put(item._id, {
            co: [{
              'status.product_guide': STATUS.PENDING
            }]
          })
          .then((response) => {
            toast.success('Se ha solicitado la búsqueda nuevamente del PC9: ' + item.pc9);
            this.fetch();
          }).catch((error) => {
            toast.error('Ocurrió un error al cambiar el estatus, vuelve a intentarlo.');
          });

        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  addTo = () => {
    let modal = this.state.modal;
    modal.title = 'Agregar a un shooting';
    modal.body = (
      <AddToShooting 
        checkList={this.state.checkList}
        close={this.closeModal}
        updateTable={this.fetch}
        type={'pGuide'}
      />
    );
    modal.show = true;
    this.setState({
      modal: modal
    });
  }

  closeModal = () => {
    let modal = {
      show: false
    };

    this.setState({
      modal: modal
    });
  }

  componentDidMount() {
    let queryString = this.state.queryString;
    
    this.setState({
      queryString: queryString
    }, () => {
      this.fetch();
    })
  }

  createShooting = () => {
    let modal = this.state.modal;

    modal.title = 'Crear Shooting';
    modal.body = (
      <CreateShootingForm 
        close={this.closeModal}
      />
    );
    modal.show = true;
    this.setState({
      modal: modal
    });
  }

  fetch = () => {
    this.service.get(this.state.queryString).then((response) => {
      this.setState({
        data: response.d,
        loading: false
      });
    });
  }

  onChangeDatatable = (state) => {
    let {renderRows, checkList} = state;
    
    this.setState({
      checkList: checkList,
      renderRows: renderRows
    });
  }

  render() {
    return (
      <div>
        <ModalCard 
          {...this.state.modal}
          closeModal={this.closeModal}
        />
        
        <Datatable 
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
          onChange={this.onChangeDatatable}
        /> 
      </div>
    );
  }
}

PendingShooting.defaultProps = {
  
};

PendingShooting.propTypes = {
  
};

export default PendingShooting;
