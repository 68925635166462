function filtering(data, filters) {
  let output = [];

  if (Object.keys(filters).length) {
    output = data.filter((item) => {
      let match = false;
      for (let keyFilter in filters) {
        let filterText = filters[keyFilter];


        let filterSplit = keyFilter.split('.');
        let property = item;

        for (let i in filterSplit) {
          property = property[filterSplit[i]];
        }

        // Array VS array
        if (Array.isArray(property) && Array.isArray(filterText)) {
          match = arrayVsArray(property, filterText);

        // String | number VS array
        } else if ((typeof property === 'string' || typeof property === 'number') && Array.isArray(filterText)) {
          match = stringVsArray(property, filterText);

        // Array VS string | number
        } else if (Array.isArray(property) && typeof filterText === 'string') {
          match = arrayVsString(property, filterText);

        // String | number VS String | number
        } else {
          match = stringVsString(property, filterText);
        }

        if (!match) {
          break;
        }
      }

      return match;
    });
  } else {
    output = data;
  }

  return output;
}

const arrayVsArray = (values, filterText) => {
  let match = false;
  let regex = new RegExp("^(" + filterText.join('|') + ")$", "gi");
  
  for (let v in values) {
    if (regex.test(values[v])) {
      match = true;
      break;
    }
  }

  return match;
};

const stringVsArray = (value, filterText) => {
  let output = false;
  let regex = new RegExp("^(" + value + ")$", 'gi');
  
  for (let i in filterText) {
    if (regex.test(filterText[i])) {
      output = true;
      break;
    }
  }
  
  return output;
};

const arrayVsString = (value, filterText) => {
  return value.indexOf(filterText) !== -1;
}

const stringVsString = (value, filterText) => {
  let regexp = new RegExp('^' + filterText + '$', 'gi');
  return regexp.test(value);
};

export default filtering;
