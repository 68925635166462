import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SelectMultiple from 'react-select';

import {capitalize} from '../../../../utils';

const SelectFilter = (props) => {

  const [options, setOptions] = useState([]);
  const [valData, setValData] = useState();

  const changeHandler = (data) => {
    let output = [];
    if (data) {
      output = data.map((element) => element.value);
    }

    props.onChange(output, props.filter);
  };

  const constructor = (data) => {
    return data.map((row) => {
      let text = row;
      let dictionary = props.filters.dictionary;
      if (dictionary) {
       // let keys = Object.keys(props.filters.dictionary).map((k) => k.toLowerCase());
        let keys = Object.keys(props.filters.dictionary).map((k) => k);

        let values = Object.values(props.filters.dictionary);
        let found = keys.indexOf(row);
        if (found !== -1) {
          text = values[found]; 
        }
      }
      return {
        label: capitalize(text),
        value: row
      };
    });
  };

  useEffect(() => {
    const constructOptions = () => {
      let output = constructor(props.fills);
      
      setOptions(output);
    };
    
    constructOptions();
  }, [props.fills]);


  useEffect(() => {
    const constructValues = () => {
      let output = [];
      if (props.value.length) {
        output = constructor(props.value);
      }
      setValData(output);
    };

    constructValues();
  }, [props.value]);


  return (
    <SelectMultiple
      isMulti 
      isSearchable
      noOptionsMessage={() => "Sin opciones"}
      onChange={changeHandler}
      options={options}
      placeholder="Todos"
      value={valData}
      styles={{
        valueContainer: (styles) => ({...styles, ...{padding: "0px 8px 3px 8px"}}),
        placeholder: (styles) => ({...styles, ...{fontSize: '13px'}}),
        menu: (styles) => ({...styles, ...{fontSize: '13px'}}) 
      }}
      menuPlacement={props.menuPlacement}
    />
  );
}

SelectFilter.propTypes = {
  fills: PropTypes.array,
  filter: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array
};

SelectFilter.defaultProps = {
  fills: [],
  filter: '',
  label: '',
  onChange: () => {}, 
  value: []
};

export default SelectFilter;
