import React, { useState, useEffect } from 'react';
import ChannelsService from '../../../Services/ChannelsService';
import ItemService from '../../../Services/ItemsService';


import {
    Button,
    Progress,
    Form,
    Tag
} from "react-bulma-components/full";
import {
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
const channelsService = new ChannelsService();
const itemService = new ItemService();


const AddChannelToPC9 = (props) => {
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedField, setSelectedField] = useState('')
    const [isDeleting, setIsDeleting] = useState(false)

    const [progress, setProgress] = useState(0)
    const [newValue, setNewValue] = useState('')

    const [fields, setFields] = useState([
        {
            field: 'brand',
            title: 'MARCA',
            isLarge: false
        },
        {
            field: 'gender',
            title: 'GÉNERO',
            isLarge: false
        },
        {
            field: 'clothes_collection',
            title: 'COLECCIÓN',
            isLarge: false
        },
        {
            field: 'line',
            title: 'MODA/BÁSICO',
            isLarge: false
        },
        {
            field: 'category',
            title: 'CATEGORÍA',
            isLarge: false
        },
        {
            field: 'name',
            title: 'NOMBRE',
            isLarge: false
        },
        {
            field: 'simple_description',
            title: 'DESCRIPCIÓN',
            isLarge: false
        },
        {
            field: 'marketing_description',
            title: 'DESCRIPCIÓN MERCADOLÓGICA',
            isLarge: false
        },
        {
            field: 'composition',
            title: 'COMPOSICIÓN',
            isLarge: false
        },
        {
            field: 'official_color',
            title: 'COLOR INGLÉS',
            isLarge: false
        },
    ])

    const catchError = () => {
        toast.warn('Intentalo nuevamente.', '¡Ocurrió un error!');
        props.close();
    };

    

    const handleSelect = (e) => {
//console.log(e.target.innerText)
        setSelectedField(e.target.value)
        setNewValue('')
       
    }

    const handleNewValue = (e) => {
        //console.log(e.target.value)
        setNewValue(e.target.value)
       
    }



    const defineNewValue = async (e) => {
        //console.log(newValue)
        //console.log(selectedField)
        setIsDeleting(true)
        for (let i = 0; i < props.checkList.length; i++) {
            await itemService.put(props.checkList[i], {
                co: [{
                    [selectedField]: newValue
                }]
            })

            setProgress(i)
        }

        toast.success('El campo: '+ selectedField + ' ha sido editado correctamente en: '+ props.checkList.length)
        props.close()
        props.fetch()

    }

    return (
        <div >
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-start' }}>
                <strong>PC9 Seleccionados</strong>&nbsp;&nbsp;
                <Tag.Group className="label">
                    <Tag color="info">{props.checkList.length}</Tag>
                    <Tag>PC9</Tag>
                </Tag.Group>

            </div><br />

            <div style={{ display: 'flex', flexDirection:'column',alignItems: 'baseline', justifyContent: 'flex-start' }}>
                <strong>Editar:</strong>
                <Form.Select onChange={e => handleSelect(e)} name="field" value={selectedField}>
                    <option value="default" >Selecciona un campo</option>
                    {fields.map((element, index) => {
                        return (
                            <option value={element.field} key={index} >{element.title}</option>
                        );
                    })}
                </Form.Select>&nbsp;

                {
                    selectedField !== '' ?
                    <div className="field" style={{ marginRight: '10px' }}>
                    <label className="label">Reemplazar por:</label>
                    <div className="control">
                      <input 
                        autoComplete="off"
                        className="input" 
                        type="textArea" 
                        placeholder={selectedField} 
                        name='sample_size' 
                        onChange={handleNewValue} 
                        value={newValue} 
                        readOnly={false} />
                    </div>
                  
                  </div>
                    :
                    null
                }

            </div><br />
            {
                isDeleting ?

                    <div>
                        <Progress
                            max={props.checkList.length}
                            value={progress}
                            color={props.checkList.length === progress ? 'success' : 'warning'}
                            size="large"
                            style={{ marginBottom: '0rem' }}
                        /><br />

                    </div>

                    :
                    null
            }

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <Button color='info' disabled={selectedField === '' || props.checkList.length === 0} onClick={e => defineNewValue(e)}>
                    {isDeleting ? <FontAwesomeIcon spin={true} icon={faSpinner} /> : 'Editar '+props.checkList.length +' PC9s'}
                </Button>

            </div>

        </div>
    )
}

export default AddChannelToPC9;

