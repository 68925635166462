import React from "react";

import "./spiner.scss";

const Spiner = () => {
  return (
    <div className="spiner">
      <span>Drones buscando en el almacen</span>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spiner;
