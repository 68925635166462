import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import colors from "../../../../Constants/colors";


function getColor(color) {
  if (typeof color === 'function') {
    color = color();
  }

  if (colors.indexOf(color) !== -1) {
    return 'has-text-' + color;
  }

  return '';
};

function IconCell(props) {
  return(
    <p className={'has-text-centered ' + getColor(props.color)} 
      title={props.dictionary[props.title] ? props.dictionary[props.title] : props.title}
    >
      {props.icon ? 
        <FontAwesomeIcon icon={props.icon} />
      :
        null
      }
    </p>
  );
}

IconCell.propTypes = {
  color: PropTypes.string,
  dictionary: PropTypes.object,
  icon: PropTypes.any,
  title: PropTypes.string
};

IconCell.defaulProps = {
  color: "",
  dictionary: {},
  icon: null,
  title: null
};

export default IconCell;
