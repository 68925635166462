import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ItemService from '../../../Services/ItemsService';

import {
  Button,
  Columns
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dropzone from '../../General/Form/Dropzone/Dropzone';

import {CSV} from '../../General/Language/es';

const service = new ItemService(); 

const UploadCSV = (props) => {

  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);


	const onChangeDropzone = (state) => {
    if (state.files && !(state.files.length === 0 && files.length === 0)) {
      setFiles(Array.from(state.files));
    }
  }
  
  const uploadFile = async (event) => {
    let response = await service.uploadCSV(files);

    for (let iErr in response.error) {
      if (response.error[iErr].code) {
        toast.error(response.error[iErr].message);
        setUploading(false);
        return;
      }
    }
      
    const {error, skip, success, update} = response.endp;

    await setUploading(false);
    
    if (success) {
      toast.success(success + CSV.UPLOAD.CREATE);
    }

    if (update) {
      toast.success(update + CSV.UPLOAD.UPDATE);
    }
    
    if (error) {
      toast.error(error + CSV.UPLOAD.ERROR);
    }

    if (skip) {
      toast.warn(skip + CSV.UPLOAD.SKIP);
    }

    if (success || update) {
      props.close();
      props.updateTable();
    }
  }


  useEffect(() => {
    if (uploading) {
      uploadFile();
    }
  }, [uploading]);

  
  return (
    <div className="has-text-centered">
      <Dropzone
        maxFiles={1}
        onChange={onChangeDropzone}
        formats={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx", ".xls", "application/vnd.ms-excel"]}
      />
      <Columns centered={true}>
        <Columns.Column>
          <Button 
            color="success" 
            disabled={(!files.length || uploading)} 
            fullwidth={true}
            onClick={(e) => {
              e.persist();
              setUploading(true);
            }}
          >
            <FontAwesomeIcon 
              icon={faSpinner}
              spin={true} 
              className={uploading ? '': 'is-hidden'}
            />
            &nbsp; Procesar CSV
          </Button>
        </Columns.Column>
      </Columns>
    </div>
  );
}

UploadCSV.propTypes = {
	onChange: PropTypes.func
};

UploadCSV.defaultProps = {
	onChange: () => {}
};

export default UploadCSV;
