import React, { Component } from 'react';

import BatchService from '../../Services/BatchService';
import ItemsService from '../../Services/ItemsService';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faCircle, faCrosshairs } from '@fortawesome/free-solid-svg-icons';

import Datatable from '../General/Datatable/Datatable';

import LookingEmptyRow from './components/LokingEmptyRow';
import Loader from '../General/Loader/Loader';
import ModalCard from '../General/Modal/ModalCard';
import WarehouseStatusInfo from './components/WarehouseStatusInfo';
import DetailsPC9 from '../Items/components/Details/DetailsPC9'

import { STATUS } from '../General/Constants/Item';
import { BATCH, ITEMS } from '../General/Language/es';
import {sortBy} from '../General/Datatable/utils';

class LookingFor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.type,
      batch: null,
      checkList: [],
      data: [],
      hasChange: false,
      loading: true,
      loggedInUser: this.props.loggedInUser,
      modal: {
        show: false,
        size: ''
      },
      renderRows: [],
      table: {
        checkCounter: true,
        columns: [
          {
            field: '_id',
            type: 'checklist'
          }, {
            field: 'pc9',
            sortable: true,
            type: 'link',
            label: 'PC9',
            onClick: (e, item) => {
              this.showDetailsPC9(item._id)
            },
          }, {
            field: 'brand',
            sortable: true,
            label: 'Marca'
          }, {
            field: 'gender',
            sortable: true,
            label: 'Género'
          }, {
            field: 'category',
            sortable: true,
            label: 'Categoría'
          }, {
            field: 'month',
            sortable: true,
            label: 'Mes'
          }, {
            dictionary: ITEMS.STATUS,
            field: (this.props.type === 'eComm' ? 'status.current' : 'status.product_guide'),
            label: 'Estatus',
            sortable: true,
            type: 'status'
          },
          {
            field: 'eta_photos.eta',
            type: 'ETA',
            label: 'Fecha entrega',
            extraData: (item)=>{
              return item.status.current
            },
            //format: ['MOMENT::DD/MM/YYYY'],
            sortable: true
          }
        ],
        filtersConfig: {
          season: {
            label: 'Temporada'
          },
          brand: {
            label: 'Marca',
          },
          line: {
            label: 'Línea',
          },
          'status.current': {
            label: 'eComm',
            dictionary: ITEMS.STATUS
          },
          'status.product_guide': {
            label: 'pGuide',
            dictionary: ITEMS.STATUS
          },
          pc9: {
            type: 'textarea',
            label: 'Varios PC9'
          }
        },
        actions: [
          {
            title: 'Picado',
            label: 'Encontrado',
            className: 'is-success',
            icon: faCrosshairs,
            onClick: () => {
              this.isFound(this.state.checkList);
            }
          },
          {
            title: 'Quitar de búsquedas',
            label: 'Requerido',
            className: 'is-warning',
            icon: faCircle,
            onClick: () => {
              this.returnToRequired(this.state.checkList);
            }
          }
        ],
        loadingLegend: <Loader />,
        searchFields: ['pc9', 'barcode', 'skus'],
      },
      queryString: {},
    };

    this.confirm = {
      title: <strong>Confirmar</strong>,
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onKeypressEscape: () => { }
    };

    this.service = new ItemsService();
    this.batchService = new BatchService();
  }

  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({ modal: modal });
  }

  onChangeDatatable = (state) => {
    this.setState({
      checkList: state.checkList,
      renderRows: state.renderRows
    });
  }

  closeModal = () => {
    let modal = {
      show: false
    };

    if (this.state.hasChange) {
      this.fetch();
    }

    this.setState({
      hasChange: false,
      modal: modal
    });
  }


  componentWillMount() {
    let statusKey = (this.props.type === 'eComm' ? 'current' : 'product_guide');

    this.setState({
      queryString: {
        rf: ['_id', 'pc9', 'brand', 'gender', 'category', 'month', 'status', 'line', 'barcode', 'skus', 'season', 'eta_photos'],
        qf: {
          ['status.' + statusKey]: {
            $in: [
              STATUS.PENDING,
              STATUS.UNAVAILABLE,
              STATUS.URGENT,
            ]
          }
        }
      }
    }, () => {
      this.fetch();
    });
  }

  componentWillReceiveProps(props) {
    if (this.props.type !== props.type) {
      let table = this.state.table
      table.columns = [
        {
          field: '_id',
          type: 'checklist'
        }, {
          field: 'pc9',
          sortable: true,
          type: 'link',
          label: 'PC9',
          onClick: (e, item) => {
            this.showDetailsPC9(item._id)
          },
        }, {
          field: 'brand',
          sortable: true,
          label: 'Marca'
        }, {
          field: 'gender',
          sortable: true,
          label: 'Género'
        }, {
          field: 'category',
          sortable: true,
          label: 'Categoría'
        }, {
          field: 'month',
          sortable: true,
          label: 'Mes'
        }, {
          dictionary: ITEMS.STATUS,
          field: (props.type === 'eComm' ? 'status.current' : 'status.product_guide'),
          label: 'Estatus',
          sortable: true,
          type: 'status'
        },
        {
          field: 'eta_photos.eta',
          type: 'ETA',
          label: 'Fecha entrega',
          extraData: (item)=>{
            return item.status.current
          },
          //format: ['MOMENT::DD/MM/YYYY'],
          sortable: true
        }
      ]


      let statusKey = (props.type === 'eComm' ? 'current' : 'product_guide');
      let queryString = {
        rf: ['_id', 'pc9', 'brand', 'gender', 'category', 'month', 'status', 'line', 'barcode', 'skus', 'season', 'eta_photos'],
        qf: {
          ['status.' + statusKey]: {
            $in: [
              STATUS.PENDING,
              STATUS.UNAVAILABLE,
              STATUS.URGENT,
            ]
          }
        }
      }
      this.setState({
        queryString: queryString,
        table
      }, () => {
        this.fetch();
      });
    }
  }

  fetch = () => {
    this.service.get(this.state.queryString)
      .then((response) => {

        let orderedData = sortBy(response.d,{
          field: 'updated_at',
          order: 'asc'
        })

        this.setState({
          data: orderedData,
          loading: false
        });
      });
  }

  isFound = (arrayIds) => {
    let options = this.confirm;
    let status = (this.props.type === 'eComm' ? 'status.current' : 'status.product_guide')

    if (arrayIds.constructor === Set) {
      arrayIds = Array.from(arrayIds);
    }

    options.message = arrayIds.length + BATCH.SET_ISFOUND;
    options.buttons = [
      {
        label: 'Aceptar',
        onClick: async () => {
          let response = await this.service.multiPut({
            ol: arrayIds,
            co: [
              {
                [status]: STATUS.AVAILABLE
              }
            ]
          });

          for (let iErr in response.error) {
            if (response.error[iErr].code === 0) {
              toast.success(BATCH.ISFOUND.SUCCESS);
              this.fetch();
            } else {
              toast.error(BATCH.ISFOUND.ERROR);
            }
          }
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  returnToRequired = (arrayIds) => {
    let options = this.confirm;
    let status = (this.props.type === 'eComm' ? 'status.current' : 'status.product_guide')

    if (arrayIds.constructor === Set) {
      arrayIds = Array.from(arrayIds);
    }

    options.message = arrayIds.length + BATCH.SET_REQUIRED;
    options.buttons = [
      {
        label: 'Aceptar',
        onClick: async () => {
          let response = await this.service.multiPut({
            ol: arrayIds,
            co: [
              {
                [status]: STATUS.REQUIRED
              }
            ]
          });

          for (let iErr in response.error) {
            if (response.error[iErr].code === 0) {
              toast.success('Cambio ejecutado correctamente');
              this.fetch();
            } else {
              toast.error('Error');
            }
          }
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  showDetailsPC9 = (itemId) => {
    this.service.getItemById(itemId)
      .then(item => {
        this.setState({
          showDetails: true,
          clickedItem: item,
        });
      })
  }

  updateDetailsPC9 = (item) => {
    this.setState({
      clickedItem: item
    });
  }

  closeDetailsPC9 = () => {
    this.setState({
      showDetails: false
    }, () => this.fetch());
  }


  render() {
    return (
      <div>
        <ModalCard
          title={this.state.modal.title}
          footer={this.state.modal.footer}
          show={this.state.modal.show}
          body={this.state.modal.body}
          closeModal={this.closeModal}
          size={this.state.modal.size}
        />
        {this.state.showDetails ?
          <DetailsPC9
            clickedItem={this.state.clickedItem}
            loggedInUser={this.props.loggedInUser}
            close={this.closeDetailsPC9}
            show={this.state.showDetails}
            renderRows={this.state.renderRows}
            updateAll={this.updateDetailsPC9}
            type={'eComm'}
          />
          : null}

        <WarehouseStatusInfo
          type={this.props.type}
          data={this.state.data}
        />

        <Datatable
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
          onChange={this.onChangeDatatable}
          emptyLegend={<LookingEmptyRow />}
        />
      </div>
    );
  }
}

export default LookingFor;
