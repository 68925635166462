// Services/ItemsService.js

import axios from 'axios';
import qs from 'qs';

class ItemsService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      },
    });
    this.service = service;
  }

   async downloadZip(channel){
    return this.service.post('/zip', channel)
     .then(response => response.data.d[0])
     .catch(error=>{
       console.log(error)
     })
   }

}


export default ItemsService;