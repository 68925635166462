// Services/LogsService.js

import axios from 'axios';
import qs from 'qs';

import {STATUS} from '../components/General/Constants/Log';

class LogsService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/log`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      },
    });
    
    this.service = service;
  }

  get = (query) => {
    return this.service.get('/', {params: query})
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  post = (data, id) => {
    let path = '/' + (id ? id : '');
    return this.service.post(path, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e)
      });
  }

  resolve = (id) => {
    return this.service.put('/' + id,  {
        co: [{status: STATUS.RESOLVED}]
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
        return e.response.data;
      });
  }
}

export default LogsService;