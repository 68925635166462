import React, {Component} from 'react';

import { toast } from 'react-toastify';

import {
  Button,
  Columns,
  Form,
  Heading
} from 'react-bulma-components/full';

import {PROFILE} from "../../General/Language/es";
import UserService from '../../../Services/UserService';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: ''
    };

    this.userService = new UserService()
  }

  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    let newValue = {
      [name]: value
    }
    this.setState(newValue);
  }

  toggleEditPassword = () => {
    this.setState({
      editPasswordVisible: !this.state.editPasswordVisible,
    }, () => {
      this.resetForm();
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.userService.changePassword(this.props.loggedInUser._id, this.state.newPassword)
      .then((response) => {
        for (let i in response.error) {
          if (response.error[i].code === 0) {
            toast.success(PROFILE.CHANGE_PASSWORD_SUCCESS);
          } else {
            toast.error(response.data[i].message);
          }
        }
      });
  }
  
  resetForm = () => {
    this.setState({
      newPassword: ''
    });
  }

  render () {
    return (
      <div>
        <Button 
          className={'is-fullwidth ' + (this.state.editPasswordVisible ? 'is-hidden' : '')}
          onClick={this.toggleEditPassword}
        >
          Cambiar contraseña
        </Button>

        <form 
          name="editProfile"
          onSubmit={this.handleSubmit}
          className={this.state.editPasswordVisible ? '' : 'is-hidden'}
        >
          <Heading size={5} className="has-text-centered">Cambiar contraseña</Heading>
          <Form.Field>
            <Form.Control>
              <Form.Input
                autoComplete="off"
                type="password"
                value={this.state.newPassword}
                name="newPassword" 
                placeholder="Contraseña nueva" 
                onChange={this.handleChange}
                >
              </Form.Input>
            </Form.Control>
          </Form.Field>

          <Columns>
            <Columns.Column>
              <Button 
                color="success" 
                className="is-block is-fullwidth"
                onClick={this.handleSubmit}
              >
                Actualizar
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button 
                type="button"
                className="is-block is-fullwidth"
                onClick={this.toggleEditPassword}
              >
                Cancelar
              </Button>
            </Columns.Column>
          </Columns>
        </form>
      </div>
    );
  }
}

export default ChangePassword;
