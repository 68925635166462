import React, {Component} from 'react';

import ShootingService from '../../../../Services/ShootingService';
import ItemsService from '../../../../Services/ItemsService';


import {
  Box,
  Button,
  Form
} from 'react-bulma-components/full';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

import {SHOOTING} from '../../../General/Language/es';

class UpdateShootingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
        name: '',
        scheduled_date: new Date(),
        delivery_date: new Date(),
        photoman: '',
        studio: '',
        description: '',
        origin:'',
        shootingItems:[]
    }
    this.service = new ShootingService();
    this.itemService = new ItemsService();

  }

  componentDidMount() {
    this.service.getById(this.props.shootingId).then((response) => {
      let shooting = response.d[0];
 
      
      shooting.scheduled_date = new Date(shooting.scheduled_date);
      shooting.delivery_date = new Date(shooting.delivery_date);
      this.setState({
        name: shooting.name,
        scheduled_date: shooting.scheduled_date,
        delivery_date: shooting.delivery_date,
        photoman: shooting.photoman,
        studio: shooting.studio,
        description: shooting.description,
        origin:shooting.origin, 
        shootingItems: shooting.items_stack
      });
    });
  }

  handleSubmit = async(event) => {
    event.preventDefault();
    let oldShooting = await this.service.getById(this.props.shootingId)

    if (oldShooting.origin !== this.state.origin){
      for (let i = 0; i< this.state.shootingItems.length;i++){
        this.itemService.put(this.state.shootingItems[i], {
          co: [{
            origin: this.state.origin
          }]
        })
      }

    }

this.service.put({
    co:[{
        name: this.state.name,
        scheduled_date: this.state.scheduled_date,
        delivery_date: this.state.delivery_date,
        photoman: this.state.photoman,
        studio: this.state.studio,
        description: this.state.description,
        origin:this.state.origin,
        items_stack:this.state.shootingItems
}]
    },this.props.shootingId)
    .then(resp=>{
      toast.success(SHOOTING.UPDATE.SUCCESS);
      this.props.close();
      this.props.fetch()
    })
    .catch(err=>{
      console.log(err)
    })



   
  }

  onChange = (field,event) => {

    this.setState({
      [field]: event.target.value
    });
  }

  onChangeDate = (field, date) => {

    this.setState({
      [field]: date
    });
  }



  render() {
    return (
      <Box className="is-marginless">
        <form onSubmit={this.handleSubmit}>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Nombre</label>
            </div>
            <div className="field-body">
              <div className="field">  
                <Form.Input 
                  autocomplete="off"
                  value={this.state.name} 
                  name="name" 
                  onChange={e=>this.onChange('name',e)}
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Origen</label>
                </div>
                <div className="field-body">
                  <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Form.Select onChange={e => {
                      e.preventDefault()
                      this.setState({ origin: e.target.value })
                    }} value={this.state.origin} placeholder="Selecciona un tipo de foto" >
                      <option key={1} value="default" disabled> Indica el origen de las fotos </option>
                      <option key={2} value="local" > Local </option>
                      <option key={3} value="global" > Global</option>

                    </Form.Select>
                  </div>
                </div>
              </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Fecha programada</label>
            </div>
            <div className="field-body">
              <div className="field">  
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  isClockOpen={false}
                  onChange={(date, e) => {this.onChangeDate('scheduled_date', date)}}
                  required={true}
                  selected={this.state.scheduled_date}
                  calendarClassName="field"
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Fecha de entrega</label>
            </div>
            <div className="field-body">
              <div className="field">  
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  isClockOpen={false}
                  onChange={(date, e) => {this.onChangeDate('delivery_date', date)}}
                  required={true}
                  selected={this.state.delivery_date}
                  calendarClassName="field"
                  minDate={this.state.scheduled_date}
                />
              </div>
            </div>
          </div>



          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Estudio</label>
            </div>
            <div className="field-body">
              <div className="field">  
                <Form.Input 
                  autocomplete="off"
                  value={this.state.studio} 
                  name="studio" 
                  onChange={e=>this.onChange('studio',e)}
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Fotógrafo</label>
            </div>
            <div className="field-body">
              <div className="field">  
                <Form.Input 
                  autocomplete="off"
                  value={this.state.photoman} 
                  name="photoman" 
                  onChange={e=>this.onChange('photoman',e)}
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Description</label>
            </div>
            <div className="field-body">
              <div className="field">  
                <Form.Textarea 
                  autocomplete="off"
                  value={this.state.description} 
                  name="description" 
                  onChange={e=>this.onChange('descrption',e)}
                />
              </div>
            </div>
          </div>
          <div className="field is-grouped is-grouped-right">
            <Button color="success" type="submit">Guardar</Button>
          </div>
        </form>
      </Box>
    );
  }
}

UpdateShootingForm.propTypes = {

};

UpdateShootingForm.defaultProps = {
  
};

export default UpdateShootingForm;
