// Services/SeasonsService.js

import axios from 'axios';
import qs from 'qs';

class SeasonsService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/season`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      }
    });
    this.service = service;
  }

  get = (queryString = {}) => {
    return this.service.get('/', {
      params: queryString
    })
      .then(response => response.data)
      .catch((response) => {
        console.log(response);
      });
  }

  post = (data) => {
    return this.service.post('/', data)
      .then(response => response.data)
      .catch((response) => {
        console.log(response);
      });
  }
}

export default SeasonsService;