import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TicketService from '../../Services/TicketService';
import LogsService from '../../Services/LogsService';

import {
  Button,
  Columns,
  Modal,
  Form
} from 'react-bulma-components/full';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPaperPlane, faCommentDots, faHeadset} from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';

import Dropzone from '../General/Form/Dropzone';

import './style.scss';

const ticketService = new TicketService();
const logService = new LogsService()
const SupportEmailForm = (props) => {
  
  const [type, setType] = useState("");
  const [title, setTitle] = useState('PC9: '+props.pc9);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [sending, setSending] = useState(false);
  const [visible, setVisible] = useState(false);

  const addComment = () => {

    let data = {
      id_item: props.idItem,
      data: {
        comment: title+ ' '+description
      }
    }
    
    
    logService.post({
      co: [
        data
      ]})
      .then((response) => {
        //console.log(response)
        if (props.newStatus){
          props.changeStatusItem(props.idItem, props.newStatus)
        }
      
        
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const submitHandler = async() => {

    console.log({type},{
      'data.title' : title,
      'data.body'  : description
    }, files )

    ticketService.post(type, {
      'data.title' : title,
      'data.body'  : description
    }, files)
      .then((response) => {
        //console.log(response)
        for (let e in response.error) {
          if (response.error[e].code === 0) {
            toast.success("Atenderemos tu solicitud lo antes posible y nos pondremos en contacto.");
            setVisible(false);
            setType('');
            setTitle('');
            setDescription('');
            setFiles([]);
          } else {  
            toast.error("Ocurrió un problema vuelve a intentarlo.");
          }
          if(props.idItem){
            addComment()
          }
          
          props.closeModal()
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error("Ocurrió un problema vuelve a intentarlo.")
      })
      .finally(() => setSending(false))
  };

  useEffect(() => {
    if (sending) {
      submitHandler();
    }
  }, [sending])

  return(
    <div className="support">

            <Form.Field>
              <Form.Label>
                Tipo de solicitud
              </Form.Label>
              <Form.Control>
                <Form.Select onChange={(e) => setType(e.target.value)} value={type}>
                  <option value="" disabled>Selecciona una opción</option>
                  <option value="error_photos" className="has-background-link">Fotos incorrectas</option>
                  <option value="error_photos" className="has-background-link">Fotos faltantes</option>
                  <option value="error_adapt" className="has-background-link">Error en recorte de fondo</option>
                  <option value="error_copy" className="has-background-link">Error en copy</option>
                  <option value="bug" className="has-background-danger">Phototool tiene un error</option>
                </Form.Select>
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Form.Label>
                Título de ticket
              </Form.Label>
              <Form.Control>
                <Form.Input onChange={(e) => setTitle(e.target.value)} value={title} />
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Form.Label>
                Comentario o descripción del problema
              </Form.Label>
              <Form.Control>
                <Form.Textarea onChange={(e) => setDescription(e.target.value)} value={description}>

                </Form.Textarea>
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Form.Label>
                Archivos (jpg/png/csv)
              </Form.Label>
              <Form.Control>
                <Dropzone
                  maxFiles={5}
                  onChange={({files}) => setFiles(files)}
                />
              </Form.Control>
            </Form.Field>

            <Columns>
              <Columns.Column>
                <Button
                  className="is-pulled-right"
                  color="success"
                  disabled={(!type.length || !title.length || !description.length) || sending}
                  onClick={() => setSending(true)}
                >
                  <FontAwesomeIcon icon={faPaperPlane}/>
                  &nbsp; Enviar
                </Button>
              </Columns.Column>
            </Columns>

    </div>
  );
};

SupportEmailForm.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

SupportEmailForm.defaultProps = {
  title: ""
};

export default SupportEmailForm;
