// Services/ShootingsService.js

import axios from 'axios';
import qs from 'qs';

class ShootingsService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/shooting`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      }
    });
    this.service = service;
  }

  get = (query = {}) => {
    return this.service.get('/', { params: query })
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  getById = (id) => {
    return this.service.get('/' + id)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  post = (data) => {
    return this.service.post('/', data)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  put = (data, id = '') =>{
    return this.service.put('/' + id, data)
    .then(response => response.data)
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
  }
  multiPut = (data) => {
    return this.service.put('/', data)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  deleteItemFromShooting = (shootingId, data)=>{
    console.log(data)
    return this.service.put('/'+shootingId+'/items',data)
    .then(response => response.data)
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
  }

  deleteFromShooting = (shootingId, item, status, shootingType) =>{
    return this.service.post('/deleteFromShooting', {
        shootingId,
        shootingType,
        itemId: item._id,
        newStatus: status
      })
      .then(response => response.data)
      .catch(error => {
        console.log(error)
      });
  }
}

export default ShootingsService;