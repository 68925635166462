// Services/UserService.js

import axios from 'axios';
import qs from 'qs';

class UserService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/User`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      }
    });

    this.service = service;
  }

  get = (query) => {
    return this.service.get('/', {params: query})
      .then(response => response.data)
      .catch((error) => error.response.data);
  }

  put = (id, data) => {
    return this.service.put('/' + id, data)
      .then(response => response.data)
      .catch((error) => error.response.data);
  }

  changePassword = (id, password) => {
    return this.service.put('/changePassword', {
        ol: [id],
        co:[
          {
            password: password
          }
        ]
      })
      .then((response) => response.data)
      .catch((error) => error.response.data);
  }
}

export default UserService;
