import axios from 'axios';
import qs from 'qs';


class PackageService {

  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/package`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      }
    });
    this.service = service;
  }

  get = (query = {}) => {
    return this.service.get('/', { params: query })
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  post = (data) => {
    console.log(data)
    return this.service.post('/', data)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  put = (data, id = '') =>{
    return this.service.put('/' + id, data)
    //return this.service.put('/', data)
    .then(response => response.data)
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
  }

  //No sirve el delete, solo se cambia el status a eliminated
  delete = (data)=>{
    return this.service.delete('/', data)
    .then(response => response.data)
    .catch(error =>{
      console.log(error)
      return error.response.data
    })
  }

  addShare = (id, array) => {
    return this.service.put('/' + id + '/shared', {
      co: [{
        share: array
      }]
    })
    .then((response) => response.data)
    .catch((error) => error.response);
  }

  removeShare = (id, array) => {
    return this.service.delete('/' + id + '/shared', {
        data: {
          co: [{
            share: array
          }]
        }
      })
      .then((response) => response.data)
      .catch((error) => error.response);
  };
}

export default PackageService;
