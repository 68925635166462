import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Columns,
  Form,
  Panel
} from "react-bulma-components/full";

import {
  faArrowDown,
  faArrowUp,
  faEraser
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TextareaFilter from './TextAreaFilter';
import SelectFilter from './SelectFilter';

import './style.scss';

function FilterList(props) {
  const [visible, setVisible] = useState(false);

  const handleChange = (value, key) => {  
    let filters = props.filters;
    if (!value || !value.length) {
      delete filters[key];
    } else {
      filters[key] = value;
    }
    
    props.onChange(filters);
  }

  return (
    <Panel className="filter-list">
      <Button onClick={() => setVisible(!visible)}>
        <span>Filtros</span>
        <span className={Object.keys(props.filters).length ? '': 'is-hidden'}>
          &nbsp; ({Object.keys(props.filters).length})
        </span>
        <span className="icon is-small is-pulled-right">
          <FontAwesomeIcon size="xs" icon={faArrowDown} className={`${visible ? ' is-hidden' : ''}`} />
          <FontAwesomeIcon size="xs" icon={faArrowUp} className={`${visible ? '' : ' is-hidden'}`} />  
        </span>
      </Button>
      <Panel.Block className={'float-panel panel-block-bg-white' + (visible ? '': ' is-hidden')}>
        <div>
          <form>
            {Object.keys(props.filtersConfig).map((filter, key) => {  
              return (
                <Form.Field horizontal={true}
                  key={filter} 
                >
                  <div className="field-label is-small has-text-left">
                    <Form.Label>
                      {props.filtersConfig[filter].label}
                    </Form.Label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        
                        {props.filtersConfig[filter].type === 'textarea' ?
                          <TextareaFilter
                            filter={filter}
                            filters={props.filters}
                            onChange={handleChange}
                            renderRowsPC9={props.renderRowsPC9}
                          />
                        :
                          <SelectFilter 
                            className="is-fullwidth"
                            filters={props.filtersConfig[filter]}
                            fills={props.filterFills[filter]}
                            filter={filter}
                            value={props.filters[filter]}
                            onChange={handleChange}
                            menuPlacement={key > 5 ? "top" : "bottom"}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </Form.Field>
              );
            })}
          </form>
          
          &nbsp;
          <Columns>
            <Columns.Column >
              <Button size="small" onClick={() => props.onChange({})}>
                <FontAwesomeIcon icon={faEraser} />
                &nbsp;
                Limpiar filtros
              </Button>  


            </Columns.Column>
          </Columns>
        </div>
      </Panel.Block>
    </Panel>
  );
}

FilterList.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
  filterFills: PropTypes.object,
  onChange: PropTypes.func
};

FilterList.defaultProps = {
  data: [],
  filters: {},
  filterFills: {},
  onChange: () => {}
};

export default FilterList;
