import React from "react";
import { Columns } from "react-bulma-components";
import VerticalProgressBar from "../General/VerticalProgresBar";

const PdpMonth = ({ timelinePhoto }) => {
  return (
    <div className="d-pdp-month">
      <Columns className="header">
        <Columns.Column size={12}>
          <div className="is-size-4 has-text-weight-bold">Entrega de contenido PDP por mes de venta</div>
        </Columns.Column>
      </Columns>

      <Columns>
        <Columns.Column size={10} className="d-charts">
          <VerticalProgressBar
            label="Ene"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[0] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[0] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[0] : 0}
          />
          <VerticalProgressBar
          label="Feb"
          max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[1] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[1] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[1] : 0}
          />
          <VerticalProgressBar
            label="Mar"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[2] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[2] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[2] : 0}
          />
          <VerticalProgressBar
            label="Abr"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[3] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[3] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[3] : 0}
          />
          <VerticalProgressBar
            label="May"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[4] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[4] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[4] : 0}
          />
          <VerticalProgressBar
            label="Jun"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[5] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[5] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[5] : 0}
          />
          <VerticalProgressBar 
            label="Jul"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[6] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[6] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[6] : 0}
          />
          <VerticalProgressBar 
            label="Ago"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[7] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[7] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[7] : 0}
          />
          <VerticalProgressBar
            label="Sep"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[8] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[8] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[8] : 0}
          />
          <VerticalProgressBar 
            label="Oct"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[9] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[9] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[9] : 0}
          />
          <VerticalProgressBar 
            label="Nov"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[10] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[10] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[10] : 0}
          />
          <VerticalProgressBar
            label="Dic"
            max={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthTotal ? timelinePhoto.count.monthTotal[11] : 0}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthDelivered ? timelinePhoto.count.monthDelivered[11] : 0}
            shadow={timelinePhoto && timelinePhoto.count && timelinePhoto.count.monthEstimated ? timelinePhoto.count.monthEstimated[11] : 0}
          />

        </Columns.Column>
        <Columns.Column className="d-charts">
        <VerticalProgressBar
            label="C.O."
            max={timelinePhoto.itemsLength}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.overcarried ? timelinePhoto.count.overcarried : 0}
          />
          <VerticalProgressBar
            label="Drop"
            max={timelinePhoto.itemsLength}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.pending ? timelinePhoto.count.drop : 0}
          />
          <VerticalProgressBar
            label="P.Inf"
            max={timelinePhoto.itemsLength}
            value={timelinePhoto && timelinePhoto.count && timelinePhoto.count.pending ? timelinePhoto.count.pending : 0}
          />
        </Columns.Column>
      </Columns>
    </div>
  );
}

export default PdpMonth;
