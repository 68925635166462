import React, { Component } from 'react';
import {
  Card,
  Columns,
  Image
} from 'react-bulma-components';
import ChangePassword from './components/ChangePassword';
import EditProfile from './components/EditProfile';

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = () => {

  }

  render() {
    return (
      <Columns>
        <Columns.Column size={5}>
          <Card>
            <Card.Content>
              <div className="media">
                <div className="media-left">
                  <Image 
                    size={64}
                    rounded="true"
                    src="../images/unknow.png" alt="Placeholder image" />
                </div>
                <div className="media-content">
                  <p className="title is-4">
                    {this.props.loggedInUser.account.first_name}
                    <br/>
                    {this.props.loggedInUser.account.last_name}
                  </p>
                  <p className="subtitle is-6">{this.props.loggedInUser.email}</p>
                </div>
              </div>

              <Card.Content>
                En ésta sección podrás consultar y editar los datos de tu perfil.
              </Card.Content>
            </Card.Content>
          </Card>
        </Columns.Column>

        <Columns.Column size={7}>
          <Card>
            <Card.Content>
              <EditProfile {...this.props} />
              <hr />
              <ChangePassword {...this.props} />
            </Card.Content>
          </Card>
        </Columns.Column>
      </Columns>
    );
  }
};

export default Account;