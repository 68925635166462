import React, { Component } from 'react';
import ItemService from '../../../../../Services/ItemsService';
import ShootingsService from '../../../../../Services/ShootingService';
import ChannelsService from '../../../../../Services/ChannelsService';
import toCSV from '../../../../General/Datatable/utils/downloadCSV'
import SeasonService from '../../../../../Services/SeasonsService'
import AddToPackage from '../../../../Items/components/AddToPackage';
import PackageShareWith from '../../../../Items/components/PackageShareWith';

import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-regular-svg-icons';

import moment from 'moment';

import {
  faCamera,
  faSpinner,
  faUpload,
  faTrash,
  faEdit,
  faEye,
  faUndo,
  faCheck, faCircle, faBinoculars, faCalendar, faCalendarCheck, faClock, faSearch, faAdjust, faDownload, faFileExcel, faPlus, faBoxOpen, faPlusCircle, faEraser, faDatabase, faPlay
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button
} from 'react-bulma-components/full';

import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Datatable from '../../../../General/Datatable/Datatable';

import UpdateShootingForm from '../UpdateShootingForm';
import Loader from '../../../../General/Loader/Loader';
import ItemWaitingPhotos from './components/ItemWaitingPhotos';
import UploadImages from '../../../../Items/components/Details/components/UploadImages';
import UploadFolder from './components/UploadFolder';
import ModalCard from '../../../../General/Modal/ModalCard'

import { STATUS as ITEM_STATUS } from '../../../../General/Constants/Item';

import { SHOOTING, SHOOTING_ITEM, ITEMS } from '../../../../General/Language/es';
import ShootingStatusInfo from './components/ShootingStatusInfo';
import EditForm from '../../../../Items/components/Details/components/EditForm'
import DetailsPC9 from '../../../../Items/components/Details/DetailsPC9'
import SetETA from '../../../../Items/components/setETA'

class ShootingItems extends Component {
  constructor(props) {
    super(props);
    this.tabIndex = React.createRef();

    this.state = {
      loggedInUser: this.props.loggedInUser,
      adaptations: false,
      showSetETA: false,
      checkList: [],
      data: [],
      renderRows: [],
      hasChange: false,
      modal: {
        show: false,
      },
      showDetails: false,
      waitMichelada: false,
      makingCSV: false,
      showInitShooting: (this.props.shooting.status === 'new' ? true : false)
  
    };
    let status = (this.props.type === 'eComm' ? 'status.current' : 'status.product_guide')
    this.state.table = {
      filtersConfig: {
        brand: {
          label: 'Marca',
        },
        gender: {
          label: 'Género',
        },
        category: {
          label: 'Categoría',
        },
        [status]: {
          label: 'Estatus',
          dictionary: ITEMS.STATUS
        },
        pc9: {
          type: 'textarea',
          label: 'Varios PC9'
        }
      },
      actions: [
        {
          title: 'Acciones',
          label: 'Acciones',
          //visible:()=>this.state.checkList.length > 0,
          color: 'success',
          size: 'medium',
          disabled: () => this.state.pGuideWait,
          className: 'is-info',
          options: [

            {
              icon: faBoxOpen,
              label: 'Agregar a paquete',
              onClick: () => this.addToPackage()
            },
            {
              title: 'Definir ETA',
              label: 'Definir ETA',
              icon: faClock,
              color: 'info',
              onClick: () => this.setETA()
            },
            {
              title: 'Aprobar todo lo que tiene foto',
              label: 'Aprobar fotos',
              icon: faCheckCircle,
              color: 'success',
              onClick: () => this.approveAll(),
              waiting: () => this.state.approving
            },
            {
              title: 'Aprobar copy',
              label: 'Aprobar copy',
              icon: faCheckCircle,
              color: 'success',
              onClick: () => this.approveCopy(),
              waiting: () => this.state.approving
            },
            // {
            //   icon: faPlusCircle,
            //   label: 'Agregar canal',
            //   onClick: this.addChannelToSelectedPC9
            // }, {
            //   icon: faEraser,
            //   label: 'Eliminar adapt.',
            //   onClick: this.deleteAdaptationsFromChannel
            // }, {
            //   icon: faEdit,
            //   label: 'Editar campo',
            //   onClick: this.setMultipleField
            // }, 
            {
              title: 'Descargar Excel de shooting',
              label: 'Descargar Excel',
              icon: faFileExcel,
              color: 'warning',
              onClick: () => this.downloadExcel(this.props.shooting),
              waiting: () => this.state.makingCSV
            },
          ]
        },
        {
          title: 'Cargar fotos',
          label: 'Subir carpeta',
          icon: faUpload,
          color: 'info',
          onClick: () => this.showUploadFolder(),
          visible: () => {
            if (this.state.showInitShooting) {
              return false;
            } else {
              return true;
            }
          }
        },
        {
          icon: faPlay,
          onClick: () => {
            this.initShooting(props.shooting)
            
          },
          title: 'Iniciar shooting',
          label: 'Iniciar shooting',
          visible: () => {
            if (this.state.showInitShooting) {
              return true;
            } else {
              return false;
            }
          },
          color:'success'
        }

        //  {
        //   title: 'Generar adaptaciones PDP',
        //   label: 'PDP',
        //   icon: faAdjust,
        //   color: 'warning',
        //   onClick: () => this.generateContentMichelada(),
        //   visible: () => this.props.shooting.type === SHOOTING_TYPE.PGUIDE,
        //   waiting: () => this.state.waitMichelada
        // }

      ],
      checkCounter: true,
      columns: [
        {
          field: '_id',
          type: 'checklist'
        }, {
          field: 'pc9',
          label: 'PC9',
          onClick: (e, item) => {
            this.showDetailsPC9(item._id);
          },
          sortable: true,
          type: 'link'
        }, {
          label: 'Marca',
          field: 'brand',
          sortable: true
        }, {
          label: 'Género',
          field: 'gender',
          sortable: true
        }, {
          label: 'Categoría',
          field: 'category',
          sortable: true
        },
        {
          dictionary: ITEMS.STATUS,
          sortable: true,
          field: (this.props.type === 'eComm' ? 'status.current' : 'status.product_guide'),
          label: (this.props.type === 'eComm' ? 'eComm' : 'pGuide'),
          color: (item) => {
            let colors = {
              required: 'danger',
              pending: 'grey-light',
              available: 'primary',
              assigned: 'grey-light',
              toShoot: 'grey-light',
              waitingPhotos: 'grey-light',
              toReview: 'primary',
              approved: 'success',
              urgent: 'danger'
            };

            return colors[item.status[(this.props.type === 'eComm' ? 'current' : 'product_guide')]] ? colors[item.status[[(this.props.type === 'eComm' ? 'current' : 'product_guide')]]] : '';
          },
          icon: (item) => {
            let icons = {
              required: faCircle,
              pending: faBinoculars,
              available: faCalendar,
              assigned: faCalendarCheck,
              toShoot: faClock,
              waitingPhotos: faClock,
              toReview: faSearch,
              approved: faCheckCircle,
              urgent: faBinoculars
            };

            return icons[item.status[[(this.props.type === 'eComm' ? 'current' : 'product_guide')]]] ? icons[item.status[[(this.props.type === 'eComm' ? 'current' : 'product_guide')]]] : null;
          },
          title: (item) => item.status.current,
          type: 'icon'
        }, {
          field: 'eta_photos.eta',
          type: 'ETA',
          label: 'ETA Fotos',
          extraData: (item) => {
            //console.log(item.eta_photos)
            return item.status.current
          },
          //format: ['MOMENT::DD/MM/YYYY'],
          sortable: true
        },
        {
          label: 'Acciones',
          type: 'actions',
          actions: [
            {
              icon: faCheckCircle,
              color: 'info',
              onClick: (item) => {
                this.changeStatusItem(item._id, ITEM_STATUS.TOSHOOT);
              },
              title: 'El PC9 está en el estudio',
              visible: (item) => {
                let type = this.props.type
                let itemStatus = item.status
                let shootingStatus = this.props.shooting.status
                if (shootingStatus === 'inProgress' || !this.state.showInitShooting) {
                  switch (type) {
                    case 'eComm':
                      switch (itemStatus.current) {
                        case 'required':
                        case 'pending':
                        case 'urgent':
                        case 'available':
                        case 'assigned':
                        case 'unavailable':
                        case 'rejected':
                          return true;
                        default:
                          return false;
                      }
                    case 'pGuide':
                      switch (itemStatus.product_guide) {
                        case 'required':
                        case 'pending':
                        case 'urgent':
                        case 'available':
                        case 'assigned':
                        case 'unavailable':
                        case 'rejected':
                          return true;
                        default:
                          return false;
                      }
                    default:
                      return false;
                  }
                } else {
                  return false;
                }

              }
            }, {
              icon: faBinoculars,
              color: 'warning',
              title: 'El PC9 no está en el estudio',
              onClick: (item) => {
                this.markItemAsUrgent(item);
              },
              visible: (item) => {
                let type = this.props.type
                let itemStatus = item.status
                let shootingStatus = this.props.shooting.status
                if (shootingStatus === 'inProgress'|| !this.state.showInitShooting ) {
                  switch (type) {
                    case 'eComm':
                      switch (itemStatus.current) {
                        case 'required':
                        case 'available':
                        case 'assigned':
                        case 'unavailable':
                        case 'rejected':
                          return true;
                        default:
                          return false;
                      }
                    case 'pGuide':
                      switch (itemStatus.product_guide) {
                        case 'required':
                        case 'pending':
                        case 'urgent':
                        case 'available':
                        case 'assigned':
                        case 'unavailable':
                        case 'rejected':
                          return true;
                        default:
                          return false;
                      }
                    default:
                      return false;
                  }
                } else {
                  return false;
                }

              }
            }, {
              onClick: (item) => {
                this.openPC9inShoot(item);
              },
              icon: faCamera,
              title: 'Shootear PC9',
              color: 'success',
              visible: (item) => {
                let type = this.props.type
                let itemStatus = item.status
                let shootingStatus = this.props.shooting.status
                if (shootingStatus === 'inProgress' || !this.state.showInitShooting) {
                  switch (type) {
                    case 'eComm':
                      switch (itemStatus.current) {
                        case 'toShoot':
                          return true;
                        default:
                          return false;
                      }
                    case 'pGuide':
                      switch (itemStatus.product_guide) {
                        case 'toShoot':
                          return true;
                        default:
                          return false;
                      }
                    default:
                      return false;
                  }
                } else {
                  return false;
                }

              }
            }, {
              onClick: (item) => {
                this.openUploadImages(item);
              },
              icon: faUpload,
              title: 'Cargar fotografías',
              color: 'info',
              visible: (item) => {
                let type = this.props.type
                let itemStatus = item.status
                let shootingStatus = this.props.shooting.status
                if (shootingStatus === 'inProgress'|| !this.state.showInitShooting) {
                  switch (type) {
                    case 'eComm':
                      switch (itemStatus.current) {
                        case 'waitingPhotos':
                          return true;
                        default:
                          return false;
                      }
                    case 'pGuide':
                      switch (itemStatus.product_guide) {
                        case 'waitingPhotos':
                          return true;
                        default:
                          return false;
                      }
                    default:
                      return false;
                  }
                } else {
                  return false;
                }

              }
            },
            {
              visible: (item) => {
                let type = this.props.type
                let itemStatus = item.status
                let shootingStatus = this.props.shooting.status
                if (shootingStatus === 'inProgress' || !this.state.showInitShooting) {
                  switch (type) {
                    case 'eComm':
                      switch (itemStatus.current) {
                        case 'waitingPhotos':
                        case 'toShoot':
                        case 'toReview':
                          return true;
                        default:
                          return false;
                      }
                    case 'pGuide':
                      switch (itemStatus.product_guide) {
                        case 'waitingPhotos':
                        case 'toShoot':
                          return true;
                        default:
                          return false;
                      }
                    default:
                      return false;
                  }
                } else {
                  return false;
                }

              },
              onClick: (item) => {
                this.ctrlzStatus(item);
              },
              icon: faUndo,
              color: '',
              title: 'Ctrl+z'
            },
            {
              visible: (item) => {
                let type = this.props.type
                let itemStatus = item.status
                if (this.props.loggedInUser.code !== 'brand_manager') {
                  return false
                } else {
                  switch (type) {
                    case 'eComm':
                      switch (itemStatus.current) {
                        case 'required':
                        case 'pending':
                        case 'urgent':
                        case 'available':
                        case 'assigned':
                        case 'unavailable':
                        case 'rejected':
                        case 'toShoot':
                        case 'waitingPhotos':
                          
                          return true;
                        default:
                          return false;
                      }
                    case 'pGuide':
                      switch (itemStatus.product_guide) {
                        case 'required':
                        case 'pending':
                        case 'urgent':
                        case 'available':
                        case 'assigned':
                        case 'unavailable':
                        case 'rejected':
                          return true;
                        default:
                          return false;
                      }
                    default:
                      return false;
                  }
                }
              },
              onClick: (item) => {
                this.deleteItem(item);
              },
              icon: faTrash,
              color: '',
              title: 'Eliminar del shooting'
            },

            
          ]
        },
      ],
      emptyLegend: "Aún no existen items asignados al shooting.",
      loadingLegend: <Loader />,
      searchFields: ['pc9', 'barcode']
    };

    this.confirm = {
      title: <strong>Confirmar</strong>,
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onKeypressEscape: () => { }
    };

    this.itemService = new ItemService();
    this.channelsService = new ChannelsService();
    this.shootingService = new ShootingsService();
    this.seasonService = new SeasonService();

  }

  initShooting = (shooting) => {

    let options = this.confirm;

    options.message = 'Iniciar shooting: ' + shooting.name;
    options.buttons = [
      {
        label: 'Aceptar',
        onClick: async () => {
          let response = await this.shootingService.put({
            ol: [shooting._id],
            co: [{
              'status': 'inProgress'
            }]
           });

          for (let iErr in response.error) {
            if (response.error[iErr].code === 0) {
              this.fetch()
              toast.success(SHOOTING.INIT.SUCCESS);
              this.setState({showInitShooting:!this.state.showInitShooting})
         
            } else {
              toast.error(SHOOTING.INIT.ERROR);
            }
          }
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  approveAll = async () => {
    let statusType = (this.props.type === 'eComm' ? 'current' : 'product_guide')
    this.setState({
      approving: true
    })

    let options = this.confirm;

    options.message = "Se aprobarán las fotos de los PC9 seleccionados";
    options.buttons = [
      {
        label: 'Aceptar',
        onClick: async () => {
          for (let i = 0; i < this.state.checkList.length; i++) {
            let response = await this.itemService.get({
              rf: ['_id', 'status', 'channels'],
              qf: {
                _id: this.state.checkList[i]
              }
            })

            let statusToCompare = response.d[0].status[statusType]
            let masterImages = response.d[0].channels.filter((ch) => {
              return ch.channel === 'master';
            }).pop().images.length

          

            if (statusToCompare === 'toReview' && masterImages > 0) {
              this.itemService.put(this.state.checkList[i], {
                co: [{
                  'status.current': 'approved'
                }]
              })
            }

          }
          toast.success("Se han aprobado todos los PC9 con fotos")
          this.fetch();
          this.setState({
            approving: false
          })
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  approveCopy = async (pc9toapprove) => {
    this.setState({
      approving: true
    })

    let options = this.confirm;

    options.message = "Aprobar el copy para los elementos seleccionados?";
    options.buttons = [
      {
        label: 'Aceptar',
        onClick: async () => {
          for (let i = 0; i < this.state.checkList.length; i++) {
            let response = await this.itemService.get({
              rf: ['_id', 'status'],
              qf: {
                _id: this.state.checkList[i]
              }
            })

            let statusToCompare = response.d[0].status.mkt

            if (statusToCompare === 'toReview') {
              this.itemService.put(this.state.checkList[i], {
                co: [{
                  'status.mkt': 'approved'
                }]
              })
            }

          }
          toast.success("Se aprobó el copy")
          this.fetch();
          this.setState({
            approving: false
          })
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  setETA = () => {
    let modal = this.state.modal;
    modal.title = 'Definir ETA';
    modal.size = 'is-medium';
    modal.body = (
      <SetETA
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
        seasonId={process.env.APP_DEFAULT_SEASON}
        close={this.closeModal}
        updateTable={this.fetch}
      />
    )

    this.setState({
      modal: modal
    }, this.openModal());
  }

  exportCSV = async () => {
    //e.preventDefault();
    //setLoading(true);
    this.setState({
      makingCSV: true
    })

    let arrToExport = [];
    let PT = 0;
    let objSeasons = {};

    let responseSeason = await this.seasonService.get({
      rf: ['_id', 'name'],
      qf: {
        status: 'active'
      }
    });

    for (let j = 0; j < responseSeason.d.length; j++) {
      objSeasons[responseSeason.d[j]._id] = responseSeason.d[j].name;
    }

    for (let i = 0; i < this.state.data.length; i++) {
      let response = await this.itemService.get({
        rf: ['_id', 'status', 'id_season', 'brand', 'gender', 'category'],
        qf: {
          pc9: this.state.data[i].pc9
        }
      });

      if (response.d.length > 0) {
        let seasonName = objSeasons[response.d[0].id_season];
        PT++;
        arrToExport.push({
          PC9: (this.state.data[i].pc9).slice(0, 5) + "-" + (this.state.data[i].pc9).slice(5),
          MARCA: response.d[0].brand,
          GENERO: response.d[0].gender,
          CATEGORIA: response.d[0].category,
          TEMPORADA: seasonName,
          ESTATUS_FOTO: response.d[0].status.current,
          ESTATUS_GUIA_PRODUCTO: response.d[0].status.product_guide,
        });
      } else {
        arrToExport.push({
          PC9: (this.state.data[i].pc9).slice(0, 5) + "-" + (this.state.data[i].pc9).slice(5),
          MARCA: '#NA',
          GENERO: '#NA',
          CATEGORIA: '#NA',
          TEMPORADA: '#NA',
          ESTATUS_FOTO: '#NA',
          ESTATUS_GUIA_PRODUCTO: '#NA'
        });
      }
    }

    const headers = {
      PC9: 'PC9',
      MARCA: 'MARCA',
      GENERO: 'GÉNERO',
      CATEGORIA: 'CATEGORÍA',
      TEMPORADA: 'TEMPORADA',
      ESTATUS_FOTO: 'F_ECOMM',
      ESTATUS_GUIA_PRODUCTO: 'F_PGUIDE'
    };

    toCSV(headers, arrToExport, '[' + PT + '] ' + this.props.shooting.name, 'ISO-8859-1');

    this.setState({
      makingCSV: false
    });
  };


  changeStatusItem = (itemId, newStatus,toastText='Cambio de estatus exitoso') => {
    let status = (this.props.type === 'eComm' ? 'status.current' : 'status.product_guide')

    this.itemService.put(itemId, {
      co: [{
        [status]: newStatus
      }]
    })
      .then((response) => {
        toast.success(toastText);
        this.fetch();
      }).catch(() => {
        toast.error('Ocurrió un error al cambiar el estatus, vuelve a intentarlo.');
      });
  }

  closeModal = () => {
    let modal = {
      show: false
    };

    if (this.state.hasChange) {
      this.fetch();
    }

    this.setState({
      hasChange: false,
      modal: modal
    });
  }

  componentDidMount() {
    this.fetch();
  }
  
  componentWillReceiveProps(){
    this.fetch();
  }

  generateContentMichelada = async () => {
    await this.setState({ waitMichelada: true });

    let itemToSend = [];
    let success = 0;
    let errors = [];
    let reqChannels = await this.channelsService.get({
      rf: ['_id'],
      qf: { name: "pdp" }
    }, false, true);
    let items = this.state.data.filter(item => item.status.product_guide === ITEM_STATUS.APPROVED);
    let allowedChannels = reqChannels.d.map((channel) => channel._id);

    items.forEach(async (item) => {
      let channelsArray = [];
      for (let c in item.channels) {
        if (item.channels[c].sku !== null) {

          if (allowedChannels.indexOf(item.channels[c].id_channel) !== -1) {
            channelsArray.push({
              channel: item.channels[c].channel,
              channelId: item.channels[c].id_channel
            });
          }
        }
      }

      if (channelsArray.length) {
        itemToSend.push({
          item: {
            _id: item._id,
            pc9: item.pc9
          },
          channels: channelsArray
        });
      }
    });

    if (!itemToSend.length) {
      toast.info('No hay elementos para generar contenido.');
      await this.setState({ waitMichelada: false });
      return;
    }

    let toastIdProgress = toast(
      <div>
        <FontAwesomeIcon icon={faSpinner} spin />
        &nbsp;
        Generando contenido para Michelada: {success} de {itemToSend.length}
      </div>
      , {
        autoClose: false,
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        type: toast.TYPE.INFO
      });

    for (let i in itemToSend) {
      let response = await this.itemService.adaptations(itemToSend[i].channels, itemToSend[i].item, "mpguide");

      for (let e in response.error) {
        if (response.error[e].code) {
          errors.push(itemToSend[e].item.pc9);
        } else {
          success++;
        }
      }

      toast.update(toastIdProgress, {
        render: <div>
          <FontAwesomeIcon icon={faSpinner} spin />
          &nbsp;
          Generando contenido para Michelada: {success} de {itemToSend.length}
        </div>
      });
    }

    toast.update(toastIdProgress, {
      autoClose: 3000,
      render: <div>
        <FontAwesomeIcon icon={faCheck} />
        &nbsp;
        Creación de contenido realizado.
      </div>,
      type: toast.TYPE.SUCCESS
    });

    if (itemToSend.length) {
      if (errors.length) {
        toast('Existieron errores al procesar el contenido para Michelada en los siguientes pc9: ' + errors.join(', '), {
          autoClose: false,
          type: toast.TYPE.WARNING
        });
      }
    }

    this.setState({ waitMichelada: false });
  }

  generateAdaptations = async () => {
    await this.setState({
      adaptations: true
    });

    let reqChannels = await this.channelsService.get({ rf: ['_id'] });
    let itemToSend = [];
    let success = 0;
    let errors = [];

    let items = this.state.data.filter(item => item.status.current === ITEM_STATUS.APPROVED);

    let allowedChannels = reqChannels.d.map((channel) => channel._id);

    items.forEach(async (item) => {
      let channelsArray = [];
      for (let c in item.channels) {
        if (item.channels[c].sku !== null && item.channels[c].channel !== 'master') {

          if (allowedChannels.indexOf(item.channels[c].id_channel) !== -1) {
            channelsArray.push({
              channel: item.channels[c].channel,
              channelId: item.channels[c].id_channel
            });
          }
        }
      }

      if (channelsArray.length) {
        itemToSend.push({
          item: {
            _id: item._id,
            pc9: item.pc9
          },
          channels: channelsArray
        });
      }
    });

    if (!itemToSend.length) {
      toast.info('No hay elementos para generar adaptaciones.');
      await this.setState({
        adaptations: false
      });
      return;
    }

    let toastIdProgress = toast(
      <div>
        <FontAwesomeIcon icon={faSpinner} spin />
        &nbsp;
        Generando adaptaciones: {success} de {itemToSend.length}
      </div>
      , {
        autoClose: false,
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        type: toast.TYPE.INFO
      });

    for (let i in itemToSend) {
      let response = await this.itemService.adaptations(itemToSend[i].channels, itemToSend[i].item);

      for (let e in response.error) {
        if (response.error[e].code) {
          errors.push(itemToSend[e].item.pc9);
        } else {
          success++;
        }
      }

      toast.update(toastIdProgress, {
        render: <div>
          <FontAwesomeIcon icon={faSpinner} spin />
          &nbsp;
          Generando adaptaciones: {success} de {itemToSend.length}
        </div>
      });
    }

    toast.update(toastIdProgress, {
      autoClose: 3000,
      render: <div>
        <FontAwesomeIcon icon={faCheck} />
        &nbsp;
        Adaptaciones realizadas.
      </div>,
      type: toast.TYPE.SUCCESS
    });

    if (itemToSend.length) {
      if (errors.length) {
        toast('Existieron errores al procesar adaptaciones de los siguientes pc9: ' + errors.join(', '), {
          autoClose: false,
          type: toast.TYPE.WARNING
        });
      }
    }

    this.setState({ adaptations: false });
  }

  markItemAsUrgent = (item) => {
    let options = this.confirm;

    options.message = SHOOTING_ITEM.SEARCH_AGAIN;
    options.buttons = [
      {
        label: 'Continuar',
        onClick: () => {
          this.changeStatusItem(item._id, 'urgent')

        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  onChangeDatatable = (state) => {
    let showSetEta = false
    if (state.checkList > 0) {
      showSetEta = true
    }
    this.setState({
      checkList: state.checkList,
      renderRows: state.renderRows,
      showSetEta: showSetEta
    });
  }

  toggleItemsDetail = (itemId, direction) => {

    let actualItemIndex = this.state.actualIndex;

    if (direction === 'prev' && actualItemIndex !== 0) {
      this.showDetailsItem(this.state.renderRows[actualItemIndex - 1]._id);

    } else if (direction === 'next' && actualItemIndex !== this.state.renderRows.length - 1) {
      this.showDetailsItem(this.state.renderRows[actualItemIndex + 1]._id);
    }
  }

  ctrlzStatus = (item) => {

    if (this.props.type === 'eComm') {
      if (item.status.current === ITEM_STATUS.WAITINGPHOTOS) {
        this.changeStatusItem(item._id, ITEM_STATUS.TOSHOOT);
      } else if (item.status.current === ITEM_STATUS.TOSHOOT) {
        this.changeStatusItem(item._id, ITEM_STATUS.ASSIGNED);
      } else if (item.status.current === ITEM_STATUS.TOREVIEW) {
        this.changeStatusItem(item._id, ITEM_STATUS.WAITINGPHOTOS);
      }else if (item.status.current === ITEM_STATUS.APPROVED) {
        this.changeStatusItem(item._id, ITEM_STATUS.TOREVIEW);
      } 
    } else if (this.props.type === 'pGuide') {
      if (item.status.product_guide === ITEM_STATUS.WAITINGPHOTOS) {
        this.changeStatusItem(item._id, ITEM_STATUS.TOSHOOT);
      } else if (item.status.product_guide === ITEM_STATUS.TOSHOOT) {
        this.changeStatusItem(item._id, ITEM_STATUS.ASSIGNED);
      }
    }

  }

  openSharedWith=(pkg)=>{
    let modal = this.state.modal;
    modal.title = "Compartir "+pkg.name;
    modal.show = true;
    modal.body = (<PackageShareWith 
      ownerId={this.props.loggedInUser._id}
      packageId={pkg._id} 
    />);
    this.setState({
      modal
    }, this.openModal());
  }

  addToPackage = () => {
    let modal = this.state.modal;
    modal.title = 'Agregar a paquete';
    modal.size = 'is-medium';
    modal.body = (
      <AddToPackage
        checkList={this.state.checkList}
        close={this.closeModal}
        callSharedWith={this.openSharedWith}
        loggedInUser={this.props.loggedInUser}
      />
    );

    this.setState({
      modal
    }, this.openModal());
  }

  deleteItem = (item) => {
    let options = this.confirm;

    options.message = SHOOTING_ITEM.SET_DELETE;
    options.buttons = [
      {
        label: 'Aceptar',
        onClick: () => {

          this.shootingService.deleteItemFromShooting(this.props.shooting._id, {
            co: [
              {
                "$action": "delete",
                items_stack: [item._id],
              }
            ]
          })
            .then(resp => {
              if(item.status.current === 'urgent' || item.status.current === 'pending' ){
                toast.success('Se ha eliminado del shooting')
              } else {
                this.changeStatusItem(item._id,'available', 'El PC9 ha regresado a la pestaña de por asignar')
              }              
              this.fetch()
            })
            .catch(error => {
              toast.error(SHOOTING.DELETE_ERROR);
            })
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  fetch = () => {
    let status = ''
    if (this.props.type === 'eComm') {
      status = 'status.current'
    } else if (this.props.type === 'pGuide') {
      status = 'status.product_guide'
    }

    let queryString = {
      rf: ['_id', 'pc9', 'brand', 'gender', 'category', 'name', 'status', 'channels', 'barcode', 'eta_photos'],
      qf: {
        shooting: this.props.shooting._id,
        [status]: ['urgent', 'pending', 'available', 'assigned', 'toShoot', 'waitingPhotos', 'toReview', 'approved']
      }
    };

    this.itemService.get(queryString).then((response) => {
      this.setState({
        data: response.d,
        loading: false
      });
    });
  }

  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({
      modal: modal
    });
  }

  showUploadFolder = () => {
    let modal = {
      title: 'Cargar carpeta de shooting',
      size: '',
      show: true,
      body: (
        <UploadFolder
          items={this.state.data.map(item => {
            return {
              _id: item._id,
              status: item.status,
              pc9: item.pc9,
              channels: item.channels
            };
          })}
          onChange={this.fetch}
          closeModal={this.closeModal}
          type={this.props.type}

        />
      )
    };

    this.setState({ modal: modal });
  }

  openUploadImages = (item) => {

    let channel = [];
    if (this.props.type === 'eComm') {
      channel = item.channels.filter(ch => ch.channel === 'master');
    } else if (this.props.type === 'pGuide') {
      channel = item.channels.filter(ch => ch.channel === 'mpguide');
    }

    let modal = this.state.modal;
    modal.title = 'Cargar imágenes';
    modal.size = '';
    modal.body = (
      <UploadImages
        channel={channel[0]}
        item={item}
        closeModal={() => this.closeModal()}
        onChange={() => this.fetch()}
        shooting={true}
        type={this.props.type}
      />
    );
    this.setState({
      modal: modal
    }, () => {
      this.openModal();
    });
  }

  openPC9inShoot = (item) => {
    let modal = this.state.modal;
    modal.title = 'Estás shooteando';
    modal.size = '';
    modal.body = <ItemWaitingPhotos
      item={item}
      changeStatusItem={this.changeStatusItem}
      closeModal={this.closeModal}
    />
    this.setState({
      modal: modal
    }, () => {
      this.openModal();
    });
  }

  shootingEdit = () => {
    let modal = this.state.modal;

    modal.title = 'Editar shooting';
    modal.size = '';
    modal.body = (
      <UpdateShootingForm
        shootingId={this.props.shootingId}
        close={this.closeModal} />
    );
    this.setState({
      modal: modal
    }, () => {
      this.openModal();
    });
  }
  downloadExcel = async (shooting) => {
    let arrIds = []
    for (let i = 0; i < this.state.data.length; i++) {
      arrIds.push(this.state.data[i].pc9)
    }

    this.itemService.downloadXLSConfig(arrIds, shooting.name)
      .then(respXLS => {
        window.location.href = respXLS.data.d[0].url_csv
      })
      .catch(err => {
        console.log(err)
      })

  }



  returnToDetail = (modal) => {
    this.setState({
      modal: modal
    })
  }

  openEditForm = (item) => {
    let modal = this.state.modal
    let actualItemIndex = this.state.renderRows.map(it => { return it._id }).indexOf(item._id);

    modal.title = (<div><b>Detalles PC9: </b>  {item.pc9} </div>)
    modal.body = (
      <EditForm
        item={item}
        loggedInUser={this.state.loggedInUser}
        getModal={this.returnToDetail}
        openEditForm={this.openEditForm}
        renderRows={this.state.renderRows}
        toggleItemsDetail={this.toggleItemsDetail}
      />
    )

    modal.footer = (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button disabled={actualItemIndex === 0 ? true : false} onClick={() => this.toggleItemsDetail(item._id, 'prev')}>PREV</Button>
        <p>{actualItemIndex + 1 + '/' + this.state.renderRows.length}</p>&nbsp;&nbsp;&nbsp;
        <Button disabled={actualItemIndex === this.state.renderRows.length - 1 ? true : false} onClick={() => this.toggleItemsDetail(item._id, 'next')}>NEXT</Button>
      </div>
    )
    modal.size = 'is-large';

    this.setState({
      modal: modal
    });
  }

  showDetailsPC9 = (itemId) => {

    this.itemService.getItemById(itemId)
      .then(item => {
        this.setState({
          showDetails: true,
          clickedItem: item,
        })
      })


  }

  close = () => {
    this.setState({
      showDetails: false
    }, () => this.fetch())
  }

  updateAll = (item) => {
    this.setState({
      clickedItem: item
    })
  }

  render() {
    return (
      <div>
        <ModalCard
          {...this.state.modal}
          closeModal={this.closeModal}
        />
        {this.state.showDetails ?
          <DetailsPC9
            clickedItem={this.state.clickedItem}
            loggedInUser={this.props.loggedInUser}
            close={this.close}
            show={this.state.showDetails}
            renderRows={this.state.renderRows}
            updateAll={this.updateAll}
            type={this.props.type}
          />
          : null}
        <ShootingStatusInfo
          data={this.state.data}
          shooting={this.props.shooting}
          type={this.props.type}
        />
        <Datatable
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
          onChange={this.onChangeDatatable}
          height={"calc(100vh - 380px)"}
        />
      </div>

    );
  }
}

export default ShootingItems;
