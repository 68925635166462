import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';


import {
    Button,
    Tag
} from "react-bulma-components/full";

import {
    faClock,
    
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemsService from '../../../Services/ItemsService';

class SetETA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkList: [],
            ETA: new Date()
            
        };

        this.itemsService = new ItemsService();
    }

    componentWillMount() {
        let checkList = this.props.checkList
        let nowDate = new Date()
        let daysToETA = Math.round((this.state.ETA.getTime()-nowDate.getTime())/(1000 * 3600 * 24))

        this.setState({
            checkList: checkList,
            daysToETA: daysToETA
        });
    }

    componentWillReceiveProps(props) {
        if (this.state.checkList !== props.checkList) {
            this.setState({
                checkList: props.checkList
            });
        }
    }



    setNewETA = async (e) => {
        e.preventDefault();
        let itemsToSetEta = 0
        let notAllowed = 0
        for (let i = 0; i < this.props.checkList.length; i++) {
            let item = await this.itemsService.getItemById(this.props.checkList[i])
            let allowedToSetETA = false

            switch (item.status.current) {
                case 'required':
                case 'pending':
                case 'urgent':
                case 'available':
                case 'unavailable':
                    notAllowed++
                break;
                case 'assigned':
                case 'toShoot':
                case 'waitingPhotos':
                case 'toReview':
                    itemsToSetEta++
                    allowedToSetETA = true
                  break;
                case 'approved':
                case 'rejected':
                    notAllowed++
                  break;
                default:
                  console.log('Status not considered')
              }

              if (allowedToSetETA){
                 this.itemsService.put(item._id, {
                    co: [{
                        'eta_photos.eta': this.state.ETA
                    }]
                })
              } else {
                this.itemsService.put(item._id, {
                    co: [{
                        'eta_photos.eta': item.status.current
                    }]
                })
              }
            

            
        }
        toast.warning( notAllowed + ' PC9 no han sido asignados a shooting')
        toast.success(itemsToSetEta + ' PC9 fueron actualizados')
        this.props.updateTable()
        this.props.close()
    }





    render() {
        return (
            <div className="has-text-centered">
                <Tag.Group className="label">
                        <Tag color="info">{this.props.checkList.length}</Tag>
                        <Tag>PC9</Tag>
                    </Tag.Group>

                <div className="field" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}>
                    <label className="label" style={{
                    fontSize:'20px'
                }}>Fecha</label>&nbsp;&nbsp;
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        isClockOpen={false}
                        minDate={new Date()}
                        onChange={(date, e) => {
                            e.preventDefault()
                            let nowDate = new Date()
                            let daysToETA = Math.round((date.getTime()-nowDate.getTime())/(1000 * 3600 * 24))
                    
                            this.setState({ 'ETA': date,
                                            daysToETA: daysToETA
                                            })
                        }}
                        required
                        selected={this.state.ETA}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>

                    

                    &nbsp;
                    <div>

                        <Button color="warning" onClick={this.setNewETA}>
                            <FontAwesomeIcon icon={faClock} />&nbsp;
                            Confirmar ETA
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}

SetETA.defaultProps = {
    checkList: []
};

export default SetETA;
