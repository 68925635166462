import React, { useState } from 'react';

import ChannelsService from '../../../Services/ChannelsService';
import {
  Columns,
  Button,
  Form
} from 'react-bulma-components/full';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { CHANNELS } from '../../General/Language/es';

const service = new ChannelsService();
const CreateChannelForm = ({close}) => {
  const [channel, setChannel] = useState({
    background: {
      start: "#ffffff",
      end: "#ffffff"
    },
    name: '',
    width: '0',
    height: '0',
    maxSize: '0',
    density: '72',
    nameExtention: '',
    swatch: false
  });

  const handleSubmit = async(event) => {
    event.preventDefault();
    
    let response = await service.post({
      co: [channel]
    });
    
    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.success(CHANNELS.CREATE.SUCCESS);
      } else {
        toast.error(CHANNELS.CREATE.ERROR);
      }
    }

    close();
  };

  const onChange = (event, property = null, nested = null) => {
    let newChannel = {...channel};
    if (property !== null && nested !== null) {
      newChannel[property][nested] = event.target.value;
    } else {
      newChannel[event.target.name] = event.target.value;
    }
    setChannel(newChannel);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="field is-horizontal" style={{display:'flex', justifyContent:'center'}}>
        <div className="field-label is-normal">
          <label className="label">Nuevo canal</label>
        </div>
        <div className="field-body">
          <div className="field" >  
            <Form.Input 
              autoComplete="off"
              style={{width:'100px'}}
              value={channel.name} 
              name="name" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Ancho (px)</label>
        </div>
        <div className="field-body">
          <div className="field">  
          <Form.Input 
              autoComplete="off"
              value={channel.width} 
              name="width" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Largo (px)</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.height} 
              name="height" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Peso máximo (Kb)</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.maxSize} 
              name="maxSize" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Densidad (dpi)</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.density} 
              name="density" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Extensión de nombre</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.nameExtension} 
              name="nameExtension" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Fondo de</label>
        </div>
        <div className="field-body">
          <Columns>
            <Columns.Column>
              <div className="field">  
                <Form.Input 
                  autoComplete="off"
                  value={channel.background.start} 
                  name="background.start" 
                  onChange={(e) => onChange(e, 'background', 'start')}
                />
              </div>
            </Columns.Column>
            
            <Columns.Column className="is-narrow"><b>a</b></Columns.Column>
            
            <Columns.Column>
              <div className="field">  
                <Form.Input 
                  autoComplete="off"
                  value={channel.background.end} 
                  name="background.end" 
                  onChange={(e) => onChange(e, 'background', 'end')}
                />
              </div>
            </Columns.Column>
          </Columns>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Swatch</label>
        </div>
        <div className="field-body">
          <div className="field">
            <Form.Radio 
              name="swatch"
              checked={channel.swatch === true}
              onChange={() => setChannel({...channel, swatch: true})}
              value="Sí"
            > Sí</Form.Radio>
            <Form.Radio 
              name="swatch"
              checked={channel.swatch !== true}
              onChange={() => setChannel({...channel, swatch: false})}
              value="No"
            > No</Form.Radio>
          </div>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <Button color="success" type="submit">Crear</Button>
      </div>
    </form>
  );
};

CreateChannelForm.propTypes = {

};

CreateChannelForm.defaultProps = {
  
};

export default CreateChannelForm;
