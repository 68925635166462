export const STATUS = {
  ASSIGNED: 'assigned',
  APPROVED: 'approved',
  AVAILABLE: 'available',
  PENDING: 'pending',
  REQUIRED: 'required',
  TOREVIEW: 'toReview',
  TOSHOOT: 'toShoot',
  UNAVAILABLE: 'unavailable',
  URGENT: 'urgent',
  WAITINGPHOTOS: 'waitingPhotos',
  REJECTED: 'rejected'
};