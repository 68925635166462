import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Modal
} from "react-bulma-components/full";

import './styleDetails.scss';
import moment from 'moment';
import Details from '../../../Items/components/Details/Details';
import EditForm from '../../../Items/components/Details/components/EditForm'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button
} from 'react-bulma-components/full';

import {
  faEdit,
  faEye
} from '@fortawesome/free-solid-svg-icons';

import { STATUS as ITEM_STATUS } from '../../../General/Constants/Item';

import { ITEMS } from '../../../General/Language/es';
import ItemService from '../../../../Services/ItemsService';
import { faImages, faListAlt, faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';

class DetailsPC9 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: this.props.clickedItem,
      closeOnBlur: true,
      show: this.props.show,
      activity: 'general',

    };

    this.itemService = new ItemService();
  }

  close = () => {
    this.props.close()
  }

  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
      item: props.clickedItem
    });
  }


  show = () => {
    this.setState({
      show: true
    });
  }

  changeActivity = (e) => {
    e.preventDefault()
    let newAct = "general"
    if (this.state.activity === 'general') {
      newAct = 'viewDetails'
    } else if (this.state.activity === 'viewDetails') {
      newAct = 'general'
    }
    this.setState({
      activity: newAct
    })
  }

  renderHeader = () => {
    let item = this.state.item
    let loggedInUser = this.props.loggedInUser
    let activity = this.state.activity
    let modalHeader = null
    if (activity === 'general') {

      modalHeader = (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ fontSize: 18 }}>
            <b>PC9: </b>{item.pc9}
          </div>


          <div style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 13
          }}
          >
            <b><FontAwesomeIcon icon={faImages} color={'gray'} /> &nbsp;Fotos eComm: </b>
            &nbsp;
            {ITEMS.STATUS[item.status.current]} {item.status.current === ITEM_STATUS.APPROVED ? <img style={{ width: '15px', marginLeft: '5px' }} src='/success_circle.png' alt='success' /> : <div></div>}
          </div>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 13
            }}
              onClick={(e) => this.changeActivity(e)}
            >           
                {loggedInUser.code === "commercial" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEdit} />}
                &nbsp;
              Copy:
              &nbsp;
              <a href="" onClick={(e) => this.changeActivity(e)} >{ITEMS.STATUSCOPY[item.status.mkt]}</a> {item.status.mkt === ITEM_STATUS.APPROVED ? <img style={{ width: '15px', marginLeft: '5px' }} src='/success_circle.png' alt='success' /> : <div></div>}
            </div>
          

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              fontSize: 14
            }}>
              <div>
                {moment(item.updated_at).format('DD/MM/YY h:mm A')} <b> Última actividad</b>
              </div>

            </div>

          </div>
        </div>
      )

    } else if (activity === 'viewDetails') {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
            <div style={{ fontSize: 18 }}>
              <b>PC9: </b>{item.pc9}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            {this.props.loggedInUser.code !== 'brand_manager' ?
              null :
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: 15
              }}
              >
                <b><FontAwesomeIcon icon={faListAlt} /> &nbsp;Copy </b>
                &nbsp;
                  {ITEMS.STATUS[item.status.mkt]} {item.status.mkt === ITEM_STATUS.APPROVED ? <img style={{ width: '15px', marginLeft: '5px' }} src='/success_circle.png' alt='success' /> : <div></div>}
              </div>
            }
          </div>
          <div>
            <Button color='default' onClick={(e) => this.changeActivity(e)}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} /> &nbsp; Regresar
            </Button>&nbsp;&nbsp;&nbsp;
          </div>
        </div>
      );
    }

    return modalHeader;
  }

  renderBody = (item, loggedInUser, activity) => {
    let body = null
    if (activity === 'general') {
      body = (
        <Details
          photoMode={this.props.photoMode}
          //getTitleUpdated={(pc9, status) => this.updateModalTitle(pc9, status)}
          item={item}
          //fetch={() => this.setState({hasChange: true})}
          loggedInUser={loggedInUser}
          type={this.props.type}
          updateAll={this.props.updateAll}
          toggleGeneralLoading={this.props.toggleGeneralLoading}
        />
      )

    } else if (activity === 'viewDetails') {
      body = (
        <EditForm
          item={item}
          loggedInUser={loggedInUser}
          changeActivity={() => this.changeActivity}
          updateFromEdit={() => this.updateFromEdit}
          updateAll={this.props.updateAll}
        />
      );
    }
    return body;
  }

  renderFooter = () => {
    let actualItemIndex = this.props.renderRows.map(it => { return it._id }).indexOf(this.state.item._id)

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button disabled={actualItemIndex === 0 ? true : false} onClick={() => this.toggleItemsDetail(this.state.item._id, 'prev')}>PREV</Button>
        <p>{actualItemIndex + 1 + '/' + this.props.renderRows.length}</p>
        &nbsp;&nbsp;&nbsp;
        <Button disabled={actualItemIndex === this.props.renderRows.length - 1 ? true : false} onClick={() => this.toggleItemsDetail(this.state.item._id, 'next')}>NEXT</Button>
      </div>
    )
  }



  toggleItemsDetail = (itemId, direction) => {
    let actualItemIndex = this.props.renderRows.map(it => { return it._id }).indexOf(this.state.item._id)

    if (direction === 'prev' && actualItemIndex !== 0) {

      this.itemService.getItemById(this.props.renderRows[actualItemIndex - 1]._id)
        .then(item => {
          this.setState({
            item
          });
        })

    } else if (direction === 'next' && actualItemIndex !== this.props.renderRows.length - 1) {
      this.itemService.getItemById(this.props.renderRows[actualItemIndex + 1]._id)
        .then(item => {
          this.setState({
            item
          });
        })
    }
  }


  render() {
    return (
      <Modal show={this.state.show} onClose={this.close} showClose={false}>
        <Modal.Card className={'modal-is-large'}>
          <Modal.Card.Head onClose={this.close}>
            <Modal.Card.Title renderAs="div">
              {this.renderHeader()}
            </Modal.Card.Title>
          </Modal.Card.Head>
          <Modal.Card.Body>
            {this.renderBody(this.state.item, this.props.loggedInUser, this.state.activity)}
          </Modal.Card.Body>
          <Modal.Card.Foot>
            {this.renderFooter()}
          </Modal.Card.Foot>
        </Modal.Card>
      </Modal>
    );
  }
};

DetailsPC9.propTypes = {
  header: PropTypes.any,
  title: PropTypes.any,
  footer: PropTypes.any,
};

DetailsPC9.defaultProps = {
  body: null,
  closeOnBlur: true,
  header: true,
  footer: false,
  size: '',
  title: ''
};

export default DetailsPC9;
