import React, {Component} from 'react';
import {
  Columns,
  Box,
  Progress,
  Image
} from "react-bulma-components/full";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro} from '@fortawesome/free-solid-svg-icons';

import {STATUS} from '../../General/Constants/Item';

class ItemStatusInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      levis: [0,0],
      dockers: [0,0],
      denizen: [0,0],
      signature: [0,0],
      global: [0,0]
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.data !== undefined) {
      this.setState({
        data: newProps.data
      }, () => {
        this.getResumeInfo();
      });
    }
  }

  getResumeInfo = () => {
    let levis_max = 0;
    let levis_approved = 0;
    let denizen_max = 0;
    let denizen_approved = 0;
    let dockers_max = 0;
    let dockers_approved = 0;
    let signature_max = 0;
    let signature_approved = 0;
    let global_approved = 0;

    for (let i = 0; i < this.state.data.length; i++) {
      let brand = this.state.data[i].brand
      let status = this.state.data[i].status.current
      
      switch (brand) {
        case "Levi's®":
        case "Levi's":
        case "Levis":
        case "Levi's ®":
        case "Levis ®":
        case "Levis®":        
          levis_max++
          if (status === STATUS.APPROVED) {
            levis_approved++
            global_approved++
          }
          break;
        case "Denizen":
        case "Denizen®":
        case "Denizen ®":
          denizen_max++
          if (status === STATUS.APPROVED) {
            denizen_approved++
            global_approved++
          }
          break;
        case "Dockers":
        case "Dockers®":
          case "Dockers ®":
          dockers_max++;
          if (status === STATUS.APPROVED) {
            dockers_approved++;
            global_approved++;
          }
          break;
        case "Levi's Signature":
        case "Signature®":
        case "Signature ®":   
          signature_max++;
          if (status === STATUS.APPROVED) {
            signature_approved++;
            global_approved++;
          }
          break;
        default:
          break;
      }
    }
    
    this.setState({
      levis: [levis_approved, levis_max],
      denizen: [denizen_approved,denizen_max],
      dockers: [dockers_approved,dockers_max],
      signature: [signature_approved,signature_max],
      global: [global_approved,this.state.data.length]
    });
  }

  render() {
    return (
      <Box className="is-top-marginless">
        <Columns style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around'
        }}>
          <Columns.Column className="has-text-centered" size={1}>
            <img src='./images/brand_logos/levis_logo_status.png' alt='levis_logo'/>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <FontAwesomeIcon icon={faCameraRetro} />&nbsp;
              {this.state.levis[0] + '/' + this.state.levis[1]}
            </div>
            <Progress max={this.state.levis[1]} value={this.state.levis[0]} color="primary" size="small"/>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={1}>
            <Image src='./images/brand_logos/denizen_logo_status.png'/>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <FontAwesomeIcon icon={faCameraRetro} />&nbsp;
              {this.state.denizen[0] + '/' + this.state.denizen[1]}
            </div>
            <Progress max={this.state.denizen[1]} value={this.state.denizen[0]} color="primary" size="small"/>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={1}>
            <Image src='./images/brand_logos/dockers_logo_status.png'/>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <FontAwesomeIcon icon={faCameraRetro} />&nbsp;
              {this.state.dockers[0] + '/' + this.state.dockers[1]}
            </div>
            <Progress max={this.state.dockers[1]} value={this.state.dockers[0]} color="primary" size="small"/>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={1}>
            <Image src='./images/brand_logos/signature_logo_status.png'/>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <FontAwesomeIcon icon={faCameraRetro} />&nbsp;
              {this.state.signature[0] + '/' + this.state.signature[1]}
            </div>
            <Progress max={this.state.signature[1]} value={this.state.signature[0]} color="primary" size="small"/>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={1}>
            <Image src='./images/brand_logos/hanger_logo_status.png'/>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <FontAwesomeIcon icon={faCameraRetro} />&nbsp;
              {this.state.global[0] + '/' + this.state.global[1]}
            </div>
            <Progress max={this.state.global[1]} value={this.state.global[0]} color="primary" size="small"/>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default ItemStatusInfo;