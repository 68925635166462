import React, { useState, useEffect } from 'react';

import ChannelsService from '../../../Services/ChannelsService';
import ItemService from '../../../Services/ItemsService';
import LinksService from '../../../Services/LinkService'
import PackageService from '../../../Services/PackageService';
import QuotationService from '../../../Services/QuotationService'

import {
  Box,
  Columns,
  Button,
  Form,
  Progress
} from "react-bulma-components/full";

import {
  faSpinner,
  faImages,
  faCheckCircle,
  faChevronCircleRight,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { STATUS } from '../../General/Constants/Item';
import { toast } from 'react-toastify';

import './generate-channel-link.scss';

const channelsService = new ChannelsService();
const itemService = new ItemService();
const linksService = new LinksService();
const packageService = new PackageService()
const quotationService = new QuotationService();

const GenerateChannelLink = (props) => {
  const [channels, setChannels] = useState();
  const [items, setItems] = useState();
  const [dataChannels, setDataChannel] = useState();
  const [generatingLink, setGeneratingLink] = useState(false);
  const [itemsStack, setItemStack] = useState();
  const [linkName, setLinkName] = useState('');
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('');


  const catchError = () => {
    toast.warn('Intentalo nuevamente.', '¡Ocurrió un error!');
    props.close();
  };

  const getItemsStack = async () => {
    if(props.type === 'package'){
    let packageResponse = await packageService.get({
        rf: ['name', 'items_count','created_at', 'id_season', 'items_stack'],
        qf: {
          _id: props.quotation._id
        }
      }).catch((error) => {
        catchError();
      });

    if (packageResponse.d[0]) {
      setItemStack(packageResponse.d[0].items_stack);
    } else {
      catchError();
    }

  } else if (props.type === 'quotation'){
    let packageResponse = await quotationService.get({
      rf: ['name', 'items_count','created_at', 'id_season', 'items_stack'],
      qf: {
        _id: props.quotation._id
      }
    }).catch((error) => {
      catchError();
    });
  
  if (packageResponse.d[0]) {
    setItemStack(packageResponse.d[0].items_stack);
  } else {
    catchError();
  }
  }
  };

  const getChannelsInfo = () => {
    let objChannels = {};

    for (let c in channels) {
      objChannels[channels[c].name] = {
        logo_url: '',
        max_items: 0,
        approved_items: 0,
        items: [],
        itemsIds: [],
        channelsId: [channels[c]._id]
      };
    }

    let tmpItems = [...items];

    for (let i in tmpItems) {

      let actualItem = tmpItems[i];
      let user = props.loggedInUser;
      let filteredChannels = [];

      if (user.code !== 'latam') {
        //todos menos latam
        for (let c in actualItem.channels) {
          if (actualItem.channels[c].channel !== 'latam' && actualItem.channels[c].channel !== 'pdp') {
            filteredChannels.push(actualItem.channels[c]);
          }
        }

      } else if (user.code === 'latam') {
        //master y latam
        for (let c in actualItem.channels) {
          if (actualItem.channels[c].channel === 'latam' || actualItem.channels[c].channel === 'master' || actualItem.channels[c].channel === 'levi.cl' 
          || actualItem.channels[c].channel === 'falabella' || actualItem.channels[c].channel === 'ripley' || actualItem.channels[c].channel === 'paris'
          || actualItem.channels[c].channel === 'dockers.cl' || actualItem.channels[c].channel === 'hites' || actualItem.channels[c].channel === 'dafiti'
          || actualItem.channels[c].channel === 'linio.cl' || actualItem.channels[c].channel === 'oeschle' ) {
            filteredChannels.push(actualItem.channels[c]);
          }
        }
      }

      actualItem.channels = filteredChannels;

      for (let c in actualItem.channels) {
        let actualChannel = actualItem.channels[c].channel;
        let actualSku = actualItem.channels[c].sku;
        let arrImages = actualItem.channels[c].images;
        if (actualSku !== null) {
          objChannels[actualChannel].max_items++;
          if (arrImages.length > 0 && (actualItem.status.current === STATUS.APPROVED || 
            (actualChannel === 'mpguide' && actualItem.status.product_guide === STATUS.APPROVED))
          ) {
            objChannels[actualChannel].approved_items++;
            objChannels[actualChannel].itemsIds.push(actualItem._id);
            objChannels[actualChannel].items.push({
              id: actualItem._id,
              pc9: actualItem.pc9,
              channel: actualChannel,
              sku: actualSku,
              images: arrImages
            });
          }
        }
      }
    }

    setDataChannel(objChannels);
  };


  const filterChannelsByRole = (channels) => {
    let user = props.loggedInUser;
    let filteredChannels = [];

    if (user.code !== 'latam') {
      //todos menos latam
      for (let i = 0; i < channels.length; i++) {
        if (channels[i].name !== 'latam' && channels[i].name !== 'pdp' && channels[i].name !== 'mpguide') {
          filteredChannels.push(channels[i]);
        }
      }

    } else if (user.code === 'latam') {
      //master y latam
      for (let i = 0; i < channels.length; i++) {
        if (channels[i].name === 'latam' || channels[i].name === 'master') {
          filteredChannels.push(channels[i]);
        }
      }
    }

    return filteredChannels;
  }

  const getChannels = async () => {
    let channelResp = await channelsService.get()
      .catch(() => catchError());

    if (channelResp.d) {
      setChannels(channelResp.d);
    } else {
      catchError();
    }

  };


  const getItems = async() => {
    let itemsResp = await itemService.get({
      rf: ['_id', 'pc9', 'brand', 'gender', 'category', 'name', 'status', 'departament', 'month', 'last_update', 'channels'],
      qf: {
        [props.type]: props.quotation._id
      }
    })
    .catch(() => catchError);

    if (itemsResp.d) {
      setItems(itemsResp.d);
    } else {
      catchError();
    }
  };


  const onChangeDropdown = (e) => {
    e.preventDefault()
    let name = e.target.value
    
    let linkNameAux = props.quotation.name + ' ' + name + ' (' + dataChannels[name].approved_items + '/' + dataChannels[name].max_items + ')'

    if (name === 'default') {
      setSelectedChannel('default');
      setLinkName('');
    } else {
      setSelectedChannel(name);
      setLinkName(linkNameAux);
    }
  };


  const renderAdaptationsButton = () => {

    let button = (
      <Button size='small' color='warning'  onClick={e => generateAdaptationsByChannel()}>
        {processing ? <FontAwesomeIcon spin={true} icon={faSpinner} /> : <div> <FontAwesomeIcon icon={faChevronCircleRight}></FontAwesomeIcon>&nbsp;Generar adaptaciones</div>}
      </Button>
    );

    if (selectedChannel === 'master' || selectedChannel === 'mpguide' ) {
      if (dataChannels[selectedChannel].max_items === dataChannels[selectedChannel].approved_items) {
        return <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>;
      }
      return null;
    } else if (selectedChannel !== 'master') {
      if (dataChannels[selectedChannel].max_items === dataChannels[selectedChannel].approved_items) {
        return <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>;
      } else if (dataChannels[selectedChannel].approved_items < dataChannels.master.approved_items) {
        return button;
      }
    }
  }

  const generateLink = () => {
    setGeneratingLink(true);

    let chId = channels.filter(ch => (ch.name === selectedChannel)).pop()._id;

    linksService.post({
        co: [{
          name: linkName,
          type: props.type,
          snap: props.quotation._id,
          owner: props.loggedInUser._id,
          channel: chId,
        }]
      })
      .then(resp => {
        toast.success('El link ' + linkName + ' está siendo generado. Te avisaremos por correo cuando esté listo');
      });

    setTimeout(() => {
      props.close();
    }, 2000);
  }


  const generateAdaptationsByChannel = async () => {
    setProcessing(true);

    let qf = {};
    let selectedChannelAux = channels.filter((ch) => (ch.name === selectedChannel))[0];
    let dataChannelsAux = {...dataChannels};

    qf[props.type] = props.quotation._id;

    let responseItems = await itemService.get({
      rf: ['_id', 'pc9', 'status', 'channels'],
      qf: qf
    });

    let items = responseItems.d;

    for (let i = 0; i < items.length; i++) {
      for (let j = 0; j < items[i].channels.length; j++) {
        if (items[i].channels[j].channel === selectedChannel && 
          items[i].channels[j].images.length === 0 && 
          items[i].status.current === 'approved' && 
          items[i].channels[j].sku !== null
        ) {

          let chToSend = [{
            channel: selectedChannel,
            channelId: selectedChannelAux._id
          }];
          let channelBase = 'master_cut';
          
          await itemService.adaptations(chToSend, items[i], channelBase)

          dataChannelsAux[selectedChannel].approved_items++;
          let linkNameAux = props.quotation.name + ' ' + selectedChannelAux.name + ' (' + dataChannels[selectedChannelAux.name].approved_items + '/' + dataChannels[selectedChannelAux.name].max_items + ')'

          setLinkName(linkNameAux);

        }
      }
    }
     setDataChannel(dataChannels);

    toast.warning('Se han generado todas las adaptaciones posibles');
    setProcessing(false);
  };

  const calculateAproxSize = () => {
    let aproxSize = 0;
    if (selectedChannel === 'master') {
      aproxSize = dataChannels.master.approved_items * 8.14159;
      aproxSize = aproxSize.toFixed(1) + ' MB';
    } else {
      aproxSize = dataChannels[selectedChannel].approved_items * 2.14159;
      aproxSize = aproxSize.toFixed(1) + ' MB';
    }

    return aproxSize;
  };


  useEffect(() => {
    if (loading) {
      getItemsStack();
    }
  }, [loading]);

  useEffect(() => {
    if (loading && itemsStack) {
      getItems();
    }
  }, [itemsStack]);


  useEffect(() => {
    if (loading && items) {
      getChannels();
    }
  }, [items]);


  useEffect(() => {
    if (loading && channels) {
      getChannelsInfo();
    }
  }, [channels]);

  useEffect(() => {
    if (loading && dataChannels) {
      setTimeout(() => setLoading(false), 1000);
    }
  }, [dataChannels]);


  return (
    <div>
      {loading ?
        <p className="has-text-centered">
          <FontAwesomeIcon icon={faSpinner} spin/> Cargando
        </p>
      :
        <div className="generate-channel-link">
          <Box>
            <Columns style={{width: '100%'}}>
              <Columns.Column>
                {props.quotation.name}&nbsp;&nbsp;
                (<strong>{itemsStack ? itemsStack.length : ''} </strong>&nbsp;PC9's)
              </Columns.Column>

              <Columns.Column>
                <p><FontAwesomeIcon icon={faImages} />&nbsp;
                  MASTER&nbsp;
                  <span>{dataChannels.master.approved_items + ' de ' + dataChannels.master.max_items + " PC9's"} &nbsp;</span>
                </p>
                {/* <p><FontAwesomeIcon icon={faImages} />&nbsp;
                  MPGUIDE&nbsp;
                  <span>{dataChannels.mpguide.approved_items + ' de ' + dataChannels.mpguide.max_items + " PC9's"} &nbsp;</span>
                </p> */}
              </Columns.Column>
            </Columns>
          </Box>
          <Box>
            <Columns>
              <Columns.Column size={6}>
                <strong>Descarga por canal: </strong>
              </Columns.Column>
              <Columns.Column size={6}>
                <Form.Select value={selectedChannel} onChange={onChangeDropdown} color='warning'>

                <option value="" disabled>Escoge un canal</option>
                {channels.map((ch, index) => {
                    let key = ch.name;
                    
                    if (dataChannels[key] && dataChannels[key].max_items > 0) {
                      return (
                        <option key={key} value={key}>
                          {(function() {
                            switch (key) {
                              case 'master':
                                return key + ' (Originales)';
                                case 'master_cut':
                                  return key + ' (PNG)';
                              case 'mpguide':
                                return key + ' (PDP MWEEK)';
                              default:
                                return key + ' (' + channels[index].width + 'x' + channels[index].height + ')'
                            }
                          })()}
                        </option>
                      );
                    }

                    return null;
                  })}

                </Form.Select>

                {/* <Dropdown value={selectedChannel} onChange={onChangeDropdown} color='warning'>
                  <Dropdown.Item value='default' style={{display: 'none'}}>
                    Departamental &nbsp;
                    <FontAwesomeIcon icon={faStore} />
                  </Dropdown.Item>

                  {channels.map((ch, index) => {
                    let key = ch.name;
                    //console.log(dataChannels)
                    if (dataChannels[key] && dataChannels[key].max_items > 0) {
                      return (
                        <Dropdown.Item key={key} value={key}>
                          {key === 'master' ?
                            key + ' (Originales)'
                          :
                            key + ' (' + channels[index].height + 'x' + channels[index].width + ')'
                          }
                        </Dropdown.Item>
                      );
                    }

                    return <span style={{display: 'none'}} key={index} />;
                  })}
                </Dropdown> */}
              </Columns.Column>
            </Columns>
            {selectedChannel ?
              <Columns>
                <Columns.Column size={12}>
                  <h1>Tamaño aproximado de la descarga:
                    <strong>
                      <small>{calculateAproxSize()}</small>
                    </strong>
                  </h1>

                </Columns.Column>

                <Columns.Column size={12}>
                  <label>PC9's con fotos: </label>
                  <strong>{dataChannels[selectedChannel].approved_items} de {dataChannels[selectedChannel].max_items}</strong>
                </Columns.Column><br />

                <Columns.Column size={12}>
                  <Columns>
                    <Columns.Column size={8}>
                      <Progress
                        max={dataChannels[selectedChannel].max_items}
                        value={dataChannels[selectedChannel].approved_items}
                        color={dataChannels[selectedChannel].max_items === dataChannels[selectedChannel].approved_items ? 'success' : 'warning'}
                        size="large"
                        style={{ marginBottom: '0rem' }}
                      />
                    </Columns.Column>
                    <Columns.Column size={4}>{renderAdaptationsButton()}</Columns.Column>
                  </Columns>
                </Columns.Column>

                <Columns.Column>

                  <Form.Field horizontal={true}>
                    <Form.Field.Label>Nombre:</Form.Field.Label>
                    <Form.Input
                      autoComplete="off"
                      disabled={processing}
                      onChange={(e) => setLinkName(e.target.value)}
                      value={linkName}
                    />
                  </Form.Field>
                  <Button fullwidth
                    color={dataChannels[selectedChannel].approved_items === dataChannels[selectedChannel].max_items ? 'success' : 'light'}
                    disabled={generatingLink}
                    onClick={generateLink}>
                    {generatingLink ?
                      <FontAwesomeIcon spin={true} icon={faSpinner} />
                    :
                      <div> <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>&nbsp;Generar link</div>
                    }
                  </Button>

                </Columns.Column>

              </Columns>
            :
              null
            }
          </Box>
        </div>
      }
    </div>
  );
}

export default GenerateChannelLink;
