import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Menu } from "react-bulma-components/full";
import './sidebar.scss';

const Sidebar = ({ loggedInUser, ...props}) => {
  const history = useHistory();

  const [menuItemVisible, setMenuItemVisible] = useState({
    masterInfo: false,
    lookingFor: {
      visible: false,
      label: ''
    },
    shootings: {
      visible: false,
      label: ''
    },
    recyclebin: {
      visible: false,
      label: ''
    },
    quotations: {
      visible: false,
      label: ''
    },
    channels: false,
    profile: true,
    activityLog: false,
    curbside: false
  });

  const [lookingForIsSelected, setLookingForIsSelected] = useState(false);

  const drawSideBarOptions = (user) => {
    let code = user.code;
    let _menuItemVisible = {...menuItemVisible};

    switch (code) {
      case "brand_manager":
        _menuItemVisible.masterInfo = true;
        _menuItemVisible.lookingFor = {
          label: 'Búsquedas',
          visible: true
        }
        _menuItemVisible.shootings = {
          label: 'Shootings',
          visible: true
        }
        _menuItemVisible.recyclebin = {
          label: 'PC9 DROP',
          visible: true
        }
        // _menuItemVisible.quotations = {
        //   label: 'Cotizaciones LATAM',
        //   visible: true
        // }
        _menuItemVisible.channels = true;
        _menuItemVisible.activityLog = {
          visible: true,
          label: 'Log de actividad'
        }
        _menuItemVisible.curbside = {
          visible: true,
          label: 'Curbside'
        }
        break;

      case "photo_team":
        _menuItemVisible.shootings = {
          label: 'Photo team',
          visible: true
        };
        _menuItemVisible.lookingFor = {
          label: 'Almacén',
          visible: true
        };
        break;

      case "warehouse_picker":
        _menuItemVisible.lookingFor = {
          label: 'Almacén',
          visible: true
        }
        break;

      case "marketing":
      case "commercial":
        _menuItemVisible.masterInfo = true;
        break;

      case "latam":
        _menuItemVisible.masterInfo = {
          label: 'Home',
          visible: true
        }
        break;
      case "curbside":
        _menuItemVisible.curbside = {
          label: 'Buscador',
          visible: true
        }
        break;
      default:
        break;
    }

    setMenuItemVisible(_menuItemVisible);
  };

  useEffect(() => {
    drawSideBarOptions(loggedInUser);
  }, []);

  return (
    <Menu
      className="column stuck is-narrow-mobile is-fuollheight section sidebar-menu"
    >
      <Menu.List>

        {menuItemVisible.masterInfo ?
          <NavLink
            to="/master"
            activeClassName="is-active"
            onClick={() => history.push("/master")}
          >  Home </NavLink>
          :
          null
        }

{menuItemVisible.masterInfo ?
          <NavLink
            to="/dashboard"
            activeClassName="is-active"
            onClick={() => history.push("/master")}
          >  Reportes PDP </NavLink>
          :
          null
        }

        {menuItemVisible.lookingFor.visible ?
          <Menu.List.Item
            onClick={() => setLookingForIsSelected(!lookingForIsSelected)}
          >
            <Menu.List title={menuItemVisible.lookingFor.label}>
              {lookingForIsSelected ?
                <div>
                  <Menu.List.Item
                    renderAs={NavLink}
                    to="/looking-for/ecomm"
                    activeClassName="is-active"
                  >
                    eComm
                  </Menu.List.Item>
                </div>
              : null}
            </Menu.List>
          </Menu.List.Item>
          :
          null
        }

        {menuItemVisible.shootings.visible ?
          <Menu.List.Item
            renderAs={NavLink}
            to="/shootings"
            activeClassName="is-active"
          >
            Shootings
          </Menu.List.Item>
          :
          null
        }
           {menuItemVisible.recyclebin.visible ?
          <Menu.List.Item
            renderAs={NavLink}
            to="/recyclebin"
            activeClassName="is-active"
          >
            PC9 DROP
          </Menu.List.Item>
          :
          null
        }

        {menuItemVisible.quotations.visible ?
          <Menu.List.Item
            renderAs={NavLink}
            to="/quotations"
            activeClassName="is-active"
          >
            {menuItemVisible.quotations.label}
          </Menu.List.Item>
          :
          null
        }

        {menuItemVisible.activityLog.visible ?
          <Menu.List.Item
            renderAs={NavLink}
            to="/activity"
            activeClassName="is-active"
          >{menuItemVisible.activityLog.label}</Menu.List.Item>
          :
          null
        }

        {menuItemVisible.curbside.visible ?
          <Menu.List.Item
            renderAs={NavLink}
            to="/curbside"
            activeClassName="is-active"
          >{menuItemVisible.curbside.label}</Menu.List.Item>
          :
          null
        }
      </Menu.List>

      {menuItemVisible.channels ?
        <Menu.List title="Configuración">
          <Menu.List.Item
            renderAs={NavLink}
            to="/config/channels"
            activeClassName="is-active"
          >Canales</Menu.List.Item>
        </Menu.List>
        :
        null
      }

      <Menu.List title="Usuario">
        {menuItemVisible.profile ?
          <Menu.List.Item
            renderAs={NavLink}
            to="/account"
            activeClassName="is-active"
          >Mi perfil</Menu.List.Item>
          :
          null
        }

        <Menu.List.Item
          to="/logout"
          onClick={() => props.logout()}
        >Cerrar sesión</Menu.List.Item>
      </Menu.List>

      <Menu.List title={'Phototool v' + props.version}></Menu.List>
    </Menu>
  );
};

Sidebar.propTypes = {
};

Sidebar.defaultProps = {
};

export default Sidebar;
