import React, { Component } from 'react';

import ShootingService from '../../../Services/ShootingService';
import ItemsService from '../../../Services/ItemsService';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { toast } from 'react-toastify';

import moment from 'moment';

import {
  faEdit,
  faPlay,
  faStop,
  faTrash,
  faCalendarPlus
} from '@fortawesome/free-solid-svg-icons';

import { faCamera } from '@fortawesome/free-solid-svg-icons';

import ModalCard from '../../General/Modal/ModalCard';
import Datatable from '../../General/Datatable/Datatable';
import UpdateShootingForm from './components/UpdateShootingForm';
import ShootingItems from './components/ShootingItems/ShootingItems';
import CreateShootingForm from './components/CreateShootingForm';
import Loader from '../../General/Loader/Loader';

import { STATUS } from '../../General/Constants/Shooting';
import { SHOOTING } from '../../General/Language/es';
import ShootingEmptyRows from './components/ShootingEmptyRow';
import "react-big-calendar/lib/css/react-big-calendar.css";


class ShootingArchive extends Component {
  constructor(props) {
    super(props);
    this.tabIndex = React.createRef();

    this.state = {
      loggedInUser:this.props.loggedInUser,
      data: [],
      events: [
        {
          start: moment().toDate(),
          end: moment()
            .add(0, "days")
            .toDate(),
          title: "Some title"
        }
      ],
      loading: true,
      modal: {
        show: false
      },
      table: {
        columns: [
          {
            label: 'Nombre',
            field: 'name',
            type: 'link',
            onClick: (e, shooting) => {
              this.detailShooting(shooting._id);
            },
            sortable: true
          },{
            label: 'Tipo',
            field: 'type',
            sortable: true
          },{
            label: 'Fecha',
            field: 'scheduled_date',
            format: ['MOMENT::DD/MM/YYYY'],
            sortable: true
          }, {
            label: 'Fotógrafo',
            field: 'photoman',
            sortable: true
          }, {
            field: 'itemsCount',
            label: '# Items',
            sortable: true
          }, {
            field: 'status',
            label: 'Estatus',
            sortable: true,
            type: 'status',
            dictionary: SHOOTING.STATUS
          }, {
            type: 'actions',
            label: 'Acciones',
            actions: [
              {
                title: 'Editar',
                icon: faEdit,
                onClick: (shooting) => {
                  this.editShooting(shooting._id);
                }
              }, {
                disabled: (shooting) => {

                  return false;
                },
                icon: faPlay,
                onClick: (shooting) => {
                  this.initShooting(shooting);
                },
                title: 'Iniciar shooting',

                visible: (shooting) => {

                  if (shooting.status === STATUS.INPROGRESS || shooting.status === STATUS.TERMINATED) {
                    return false;
                  } else {
                    return true;
                  }

                },
                color:'success'
              }, {
                title: 'Terminar',
                color:'warning',
                icon: faStop,
                onClick: (shooting) => this.stopShooting(shooting),
                visible: (shooting) => (shooting.status === STATUS.INPROGRESS)
              },  {
                title: 'Eliminar shooting',
                color:'danger',
                icon: faTrash,
                onClick: (shooting) => this.deleteShooting(shooting),
                visible: (shooting) => (!shooting.itemsCount)
              }
            ]
          },
        ],
        emptyLegend: <ShootingEmptyRows />,
        filtersConfig: {
          status: {
            label: 'Estatus',
            dictionary: SHOOTING.STATUS
          },
          photoman: {
            label: 'Fotógrafo'
          },
          scheduled_date: {
            label: 'Fecha'
          }
        },
        searchFields: ['name'],
        loadingLegend: <Loader />
      },
      renderRows: [],
      queryString: {
        rf: ['_id', 'name', 'type','scheduled_date', 'photoman', 'studio', 'status', '_items'],
        qf: {
          status: [STATUS.TERMINATED]
        }
      }
    };
    this.confirm = {
      title: <strong>Confirmar</strong>,
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onKeypressEscape: () => {}
    };

    this.service = new ShootingService();
    this.itemsService = new ItemsService();
  }

  closeModal = () => {
    let modal = {
      show: false
    };

    this.setState({
      modal: modal
    });
  }

  componentDidMount() {
    let queryString = this.state.queryString;

    this.setState({
      queryString: queryString
    }, () => {
      this.fetch();
    });
  }

  countItems = (shootings) => {
    return new Promise((resolveAll) => {
      let promises = [];
      for (let i in shootings) {
        promises.push(new Promise ((resolve, reject) => {
          this.itemsService.get({
            rf: ['_id'],
            qf: {
              shooting: [shootings[i]._id]
            },
            fd: {
              type: 'count'
            }
          }).then((response) => {
            //console.log(response)
            shootings[i].itemsCount = response.d[0].count;
            resolve();
          });
        }));
      }

      Promise.all(promises).then(() => {
        resolveAll(shootings);
      })
    });
  }



  detailShooting = (shootingId) => {
    this.service.getById(shootingId).then((response) => {
      let data = response.d[0];
      if (data) {
        this.props.addTab({
          label: 'Shooting: ' + data.name,
          icon: faCamera,
          content: ShootingItems,
          props: {
            type:data.type,
            closeTab: true,
            shooting: data,
            loggedInUser:this.state.loggedInUser
          }
        });
      }
    });
  }

  editShooting = (shootingId) => {
    let modal = this.state.modal;
    modal.size = '';
    modal.show = true;
    modal.body = (
      <UpdateShootingForm
        shootingId={shootingId}
        close={this.closeModal}
        fetch={this.fetch}
      />
    );

    this.setState({
      moda: modal
    });
  }

  fetch = async() => {
    let response = await this.service.get(this.state.queryString);

    this.countItems(response.d)
      .then((shootings) => {
        this.setState({
          data: shootings,
          loading: false
        });
      });
  }



  onChangeDatatable = (state) => {
    let {renderRows, checkList} = state;

    this.setState({
      checkList: checkList,
      renderRows: renderRows
    });
  }




  render() {
    return (
      <div >
        <ModalCard
          {...this.state.modal}
          closeModal={this.closeModal}
        />

        <Datatable
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
          onChange={this.onChangeDatatable}
        />
      </div>
    );
  }
}

export default ShootingArchive;
