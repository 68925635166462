import React, { Component } from 'react';
import ItemService from '../../../../../Services/ItemsService';
import ZipService from '../../../../../Services/zipService';
import ChannelsService from '../../../../../Services/ChannelsService';

import {
  Button,
  Table,
  Columns,
  Form
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagic,
  faUpload,
  faDownload,
  faSpinner,
  faPlusCircle,
  faMinusCircle,
  faTag,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

import UploadImages from './UploadImages';
import ModalCard from '../../../../General/Modal/ModalCard';

import { STATUS } from '../../../../General/Constants/Item';
import { toast } from 'react-toastify';

class DetailsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: this.props.item,
      channels: this.props.item.channels.filter(ch => {
        return ch.sku != null
      }),
      modal: {
        show: false,
        size: ''
      },
      downloading: false,
      catalogueChannels: [],
      missingChannels: [],
      selected: 'default',
      selectedChannel: {
        name: 'Elige un canal'
      },
      newSku: '',
      generateAdaptations: false,
      loggedInUser:this.props.loggedInUser,
    };

    this.service = new ItemService();
    this.zipService = new ZipService();
    this.channelsService = new ChannelsService();
  }

  updateItem = (item, index) => {
    this.props.getUpdateChannel(item, index);
    this.setState({
      item: item,
      channels: item.channels.filter(ch => {
        return ch.sku !== null;
      })
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
      channels: newProps.item.channels.filter(ch => {
        return ch.sku != null
      }),
    }, () => this.fetch());
  }

  closeModal = () => {
    let modal = {
      show: false
    };

    this.setState({ modal: modal });
  }

  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({ modal: modal });
  }

  onChangeUploadImages = (state) => {
    this.openModal();
  }

  onUploadImages=(item)=>{
    this.props.updateAll(item)
    this.closeModal();
  }

  showUploadImages = (e, index) => {
    let modal = this.state.modal;
    modal.title = "Agrega las fotos para " + this.state.channels[index].channel;
    modal.size = '';
    modal.body = (
      <UploadImages
        channel={this.state.channels[index]}
        item={this.state.item}
        onChange={this.onChangeUploadImages}
        getItem={item => this.updateItem(item, index)}
        closeModal={() => { this.closeModal() }}
        statusUpdate={(item, status) => this.props.statusUpdate(item, status)}
        onUploadImages={this.onUploadImages}
      />
    );

    this.setState({
      modal: modal
    }, this.openModal());
  }
  

  deleteAllImages = async (index) => {
    let channelToReject = this.state.item.channels.filter((ch) => {
      return ch.sku != null;
    })[index];
    let channels = this.state.item.channels.filter((ch) => {
      return ch.sku != null;
    });
    let channelsToClean = [];

    if (channelToReject.channel === "master") {
      for (let i = 0; i < channels.length; i++) {
        if (
          channels[i].channel !== "mpguide" &&
          channels[i].channel !== "pdp"
        ) {
          channelsToClean.push(channels[i]);
        }
      }
    } 

    for (let i = 0; i < channelsToClean.length; i++) {
      await this.service.deleteImages(this.state.item._id, [
        {
          channel: channelsToClean[i].channel,
          keys: channelsToClean[i].images.map((element) =>
            element.key ? element.key : null
          ),
        },
      ]);
    }

  };

  deleteImages = async(e, index) => {
    let channels = this.state.item.channels.filter(ch => ch.sku != null);
    let channelToReject = this.state.item.channels.filter((ch) => {
      return ch.sku != null;
    })[index]

    if(channelToReject.channel ==='master'){
      await this.deleteAllImages(index)
    }

    let resp = await this.service.deleteImages(this.state.item._id, [{
      channel: channels[index].channel,
      keys: channels[index].images.map((element) => (element.key ? element.key : null))
    }]);
    


    if (resp && resp.d && resp.d[0]) {
      let item = resp.d[0];
      this.setState({
        channels: item.channels.filter(ch => (ch.sku !== null)),
        item: item
      });
      this.props.getUpdateChannel(item, index)
      this.closeModal();
    }

    if (channels[index].channel === 'master') {
      this.service.put(this.state.item._id, {
          co: [{
          'status.current': 'waitingPhotos'
          }]
        })
        .then(response => this.onUploadImages(response.d[0].ref));
    }
  }

  openDeleteImagesModal = (e, index) => {
    let modal = this.state.modal;
    modal.title = "Borrar fotos";
    modal.size = 'is-small';
    modal.body = (
      <div>
        <p className="has-text-centered"> <b>Esta acción borrará las fotos del canal, podrás generalas después, deseas continuar?</b></p>
        <br />
        <Columns centered={true}>
          <Columns.Column>
            <Button
              color="info"
              fullwidth={true}
              onClick={this.closeModal}
            >
              No, cancelar
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="warning"
              fullwidth={true}
              onClick={e => { this.deleteImages(e, index) }}
            >
              Sí, eliminar
            </Button>
          </Columns.Column>
        </Columns>

      </div>
    )

    this.setState({
      modal: modal
    }, this.openModal());
  }

  openSetToNullModal = (e, index) => {
    let modal = this.state.modal;
    modal.title = "Eliminar canal";
    modal.size = 'is-small';
    modal.body = (
      <div>
        <p className="has-text-centered"> <b>Esta acción eliminará el canal, deseas continuar?</b></p>
        <br />
        <Columns centered={true}>
          <Columns.Column>
            <Button
              color="info"
              fullwidth={true}
              onClick={this.closeModal}
            >
              No, cancelar
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="warning"
              fullwidth={true}
              onClick={e => { this.setChannelToNull(e, index) }}
            >
              Sí, eliminar
            </Button>
          </Columns.Column>
        </Columns>

      </div>
    )

    this.setState({
      modal: modal
    }, this.openModal());
  }

  generateAdaptations = async (e, index) => {
    toast.info('Las adaptaciones están siendo generadas')
    await this.setState({
      generateAdaptations: true
    });

    let channel = {
      channelId: this.state.channels[index].id_channel,
      channel: this.state.channels[index].channel
    };

    let newState = {
      generateAdaptations: false
    };

    let response = await this.service.adaptations(
      channel, 
      this.state.item,
      'master_cut'
    );
    

    if (response.d) {
      let updatedItem = response.d[0];
      this.props.getUpdateChannel(updatedItem, index);

      newState.channels = updatedItem.channels.filter(ch => { return ch.sku !== null });
      newState.item = updatedItem;
      toast.success('Adaptaciones generadas')
    }

    this.setState(newState);
  }

  downloadPhotos = async (e, index) => {
    var channel = this.state.channels[index];
    var itemsChannels = {
      channel: [channel.id_channel],
      item: [this.state.item._id]
    };

    this.setState({ downloading: true })

    this.zipService.downloadZip(itemsChannels)
      .then(response => {
        window.location.href = response.url;
        this.setState({ downloading: false });
      });
  }

  selectedChannelDropdown = (e) => {
    let index = e.target.value
    this.setState({
      selected: index,
      selectedChannel: this.state.missingChannels[index],
      newSku:this.state.item.pc9

    });
  }

  compareChannels = (catalogueChannels, itemChannels) => {
    let missingChannels = [];

    for (var i = 0; i < catalogueChannels.length; i++) {

      if (itemChannels.map(ch => { return ch.channel }).indexOf(catalogueChannels[i].name) !== -1) {
        for (var j = 0; j < itemChannels.length; j++) {
          if (catalogueChannels[i].name === itemChannels[j].channel && itemChannels[j].sku === null) {
            missingChannels.push(catalogueChannels[i]);
          }
        }
      } else {
        missingChannels.push(catalogueChannels[i])
      }
    }

    this.setState({
      missingChannels: missingChannels
    });
  }

  componentWillMount() {
    this.fetch();
  }

  fetch = () => {
    this.channelsService.get()
      .then((response) => {
        this.compareChannels(response.d, this.state.item.channels);
      });
  }

  setChannelToNull = (e,index)=>{
    this.service.setChannelToNull(this.state.item,this.state.channels[index])
    .then(item => {
      this.updateItem(item, 0)
      this.setState({
        item: item,
      });
      this.fetch();
      this.closeModal();
    })

  }

  addNewChannel = (event) => {
    event.preventDefault();
    this.service.addNewChannel(this.state.item, this.state.selectedChannel, this.state.newSku.trim())
      .then(item => {
        this.updateItem(item, 0)
        this.setState({
          item: item,
          selected: 'default',
          newSku: ''
        });
        toast.info("El canal "+ this.state.selectedChannel.name+" ha sido agregado" )
        this.fetch();
      });
  }

  onChange = (event) => {
    let sku = event.target.value;
    this.setState({
      newSku: sku
    });
  }



  drawDeleteChannelButton = (channel, index)=>{
    if (['master', 'mpguide', 'pdp'].indexOf(channel.channel) !== -1) {
      return (<div>-</div>);
    } else {
      return(
        <Button
          disabled={this.state.generateAdaptations || this.props.bussy}
          size="small"
          onClick={e => { this.openSetToNullModal(e, index) }}>
          <FontAwesomeIcon color={'red'} icon={faMinusCircle} />
        </Button>
      );
    }

  }

  drawActionButtons2 = (channel, index) => {

    let master = this.state.channels[this.state.channels.map(ch => { return ch.channel }).indexOf('master')]
    let masterHasPhotos = (master.images.length > 0 ? true : false)

    if (this.state.loggedInUser.code === 'warehouse_picker' || this.state.loggedInUser.code === 'latam') {
      return (<div>-</div>);
    } else {
    // master sin fotos
    if (channel.channel === 'master' && channel.images.length === 0 && this.state.item.status.current !== STATUS.APPROVED && this.state.loggedInUser.code !== 'commercial') {
      return (
        <Button
          color='warning'
          size="small"
          onClick={e => { this.showUploadImages(e, index) }}>
          <FontAwesomeIcon icon={faUpload} />
        </Button>
      )
      //master con fotos
    } else if (channel.channel === 'master' && channel.images.length > 0 && this.state.item.status.current !== STATUS.APPROVED && this.state.loggedInUser.code !== 'commercial') {
      return (
        <div>
          <Button
            color='warning'
            disabled={this.state.generateAdaptations || this.props.bussy}
            size="small"
            onClick={e => { this.showUploadImages(e, index) }}>
            <FontAwesomeIcon icon={faUpload} />
          </Button>

          
          <Button
            color='success'
            size="small"
            disabled={this.state.downloading || this.props.bussy}
            onClick={(e) => this.downloadPhotos(e, index)}>
            {this.state.downloading
              ?
              <FontAwesomeIcon icon={faSpinner} spin={true} />
              :
              <FontAwesomeIcon icon={faDownload} />
            }
          </Button>
        
          <Button
            disabled={this.state.generateAdaptations || this.props.bussy}
            size="small"
            onClick={(e) => { this.openDeleteImagesModal(e, index) }}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      )
      //Fotos aprobadas
    } else if ('master' === channel.channel && this.state.item.status.current === STATUS.APPROVED && this.state.loggedInUser.code !== 'commercial') {
      return (
        <div>
          <Button
            color='success'
            size="small"
            disabled={this.state.downloading || this.props.bussy}
            onClick={(e) => this.downloadPhotos(e, index)}>
            {this.state.downloading
              ?
              <FontAwesomeIcon icon={faSpinner} spin={true} />
              :
              <FontAwesomeIcon icon={faDownload} />
            }
          </Button>
        </div>
      )
      // cualquier canal sin fotos, master tampoco tiene fotos
    } else if (channel.channel === 'mpguide' && channel.images.length && this.state.item.status.product_guide === STATUS.APPROVED) {
      return (
        <div>
          <Button
            color='success'
            size="small"
            disabled={this.state.downloading || this.props.bussy}
            onClick={(e) => this.downloadPhotos(e, index)}>
            {this.state.downloading
              ?
              <FontAwesomeIcon icon={faSpinner} spin={true} />
              :
              <FontAwesomeIcon icon={faDownload} />
            }
          </Button>
        </div>
      );
    } else if(channel.channel === 'pdp') {
      return(
        <div>-</div>
      )
    } else if (channel.channel !== 'master' && channel.images.length === 0 && masterHasPhotos === false && this.state.loggedInUser.code !== 'commercial') {
      return (
        <div>-</div>
      )
      //cualquier canal con fotos, master sin fotos
    } else if (channel.channel !== 'master' && channel.images.length > 0 && masterHasPhotos === false && this.state.loggedInUser.code !== 'commercial') {
      return (
        <div>
          <Button
            color='warning'
            disabled={this.state.generateAdaptations || this.props.bussy}
            size="small"
            onClick={e => { this.showUploadImages(e, index) }}>
            <FontAwesomeIcon icon={faUpload} />
          </Button>
          <Button
            disabled={this.state.generateAdaptations || this.props.bussy}
            size="small"
            onClick={(e) => { this.openDeleteImagesModal(e, index) }}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      )
      //cualquier canal sin fotos, master sí tiene fotos
    } else if (channel.channel !== 'mpguide' && channel.images.length === 0 && masterHasPhotos === true && this.state.loggedInUser.code !== 'commercial') {
      return (
        <div>
          <Button
            color='warning'
            disabled={this.state.generateAdaptations || this.props.bussy}
            size="small"
            onClick={e => { this.showUploadImages(e, index) }}>
            <FontAwesomeIcon icon={faUpload} />
          </Button>
          {channel.channel === 'master_cut' ?
            null
          :
            <Button
              color='info'
              disabled={this.state.generateAdaptations || this.props.bussy}
              size="small"
              onClick={(e) => { this.generateAdaptations(e, index) }}>
                {this.state.generateAdaptations ? <FontAwesomeIcon icon={faSpinner} />: <FontAwesomeIcon icon={faMagic} />}
            </Button>
          }
        </div>

      )
      //cualquier canal con fotos, master también tiene fotos
    } else if ((channel.channel !== 'master') && channel.images.length > 0 && masterHasPhotos === true && this.props.loggedInUser.code !== 'commercial') {
      return (
        <div>
          <Button
            disabled={this.state.downloading || this.state.generateAdaptations || this.props.bussy}
            spin={'true'}
            color='success'
            size="small"
            onClick={e => { this.downloadPhotos(e, index) }}>
            {this.state.downloading
              ?
              <FontAwesomeIcon icon={faSpinner} spin={true} />
              :
              <FontAwesomeIcon icon={faDownload} />
            }
          </Button>
          <Button
            disabled={this.state.generateAdaptations || this.props.bussy}
            size="small"
            onClick={(e) => { this.openDeleteImagesModal(e, index) }}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      )
      //si el usuario loggeado es commercial, las fotos están aprobadas puede descargarlas
    } else if (this.state.item.status.current === STATUS.APPROVED && channel.images.length > 0 && masterHasPhotos === true && this.state.loggedInUser.code === 'commercial'){
        return (
          <Button
          disabled={this.state.downloading || this.state.generateAdaptations || this.props.bussy}
          spin={'true'}
          color='success'
          size="small"
          onClick={e => { this.downloadPhotos(e, index) }}>
          {this.state.downloading
            ?
            <FontAwesomeIcon icon={faSpinner} spin={true} />
            :
            <FontAwesomeIcon icon={faDownload} />
          }
        </Button>
        )
        //si el usuario es commercial y master ya tiene fotos el usuario puede generar las adaptaciones
    } else if(this.state.item.status.current === STATUS.APPROVED && channel.channel !== 'master'  && channel.images.length === 0 && masterHasPhotos === true && this.props.loggedInUser.code === 'commercial'){
      return(
        <Button
        color='info'
        disabled={this.state.generateAdaptations || this.props.bussy}
        size="small"
        onClick={(e) => { this.generateAdaptations(e, index) }}>
        <FontAwesomeIcon icon={faMagic} />
      </Button>
      )
    } else {
      return(
        <div>-</div>
      )
    }
  }
  }

  render() {
    return (
      <div>
        <ModalCard
          title={this.state.modal.title}
          show={this.state.modal.show}
          body={this.state.modal.body}
          closeModal={this.closeModal}
          size={this.state.modal.size}
        />
        <Table size="narrow">
          <thead >
            <tr >

              <th><b>Canal</b></th>
              <th><b>SKU</b></th>
              <th className="has-text-centered"><b>#</b></th>
              <th className="has-text-centered"><b>Fotos</b></th>
              <th className="has-text-centered"><b><FontAwesomeIcon  icon={faTag} /></b></th>
            </tr>
          </thead>
          <tbody>
            {this.state.channels.map((channel, index) => {
              return (
                <tr key={index}>
                  <td>{channel.channel}</td>
                  <td>{channel.sku}</td>
                  <td className="has-text-centered">
                    ({channel.images.length})
                  </td>
                  <td className="has-text-centered">
                    {this.drawActionButtons2(channel, index)}
                  </td>
                  <td className="has-text-centered">
                    {this.drawDeleteChannelButton(channel,index)}
                  </td>
                </tr>
              )
            })}

            {this.props.photoMode === true ? <tr></tr> :
            <tr key={20}>
              <td className="has-text-rigth">

              <Form.Select value={this.state.selected} onChange={this.selectedChannelDropdown} color='info'>
              <option value="default" disabled>Agregar</option>
              {this.state.missingChannels.map((channel, index) => {
                    return (
                      <option key={index} value={index}>{channel.name}</option>
                    )
                  })}
              </Form.Select>

                {/* <Dropdown
                  value={this.state.selected}
                  onChange={this.selectedChannelDropdown}
                >
                  <Dropdown.Item key={100} value={"default"} >Agregar</Dropdown.Item>
                  {this.state.missingChannels.map((channel, index) => {
                    return (
                      <Dropdown.Item key={index} value={index}>{channel.name}</Dropdown.Item>
                    )
                  })}
                </Dropdown> */}
              </td>
              <td>
                <Form.Input
                  placeholder='SKU Canal'
                  style={{ width: '100px' }}
                  disabled={this.state.selected === 'default'}
                  autoComplete="off"
                  value={this.state.newSku}
                  name="name"
                  onChange={this.onChange}
                />
              </td>
              <td className="has-text-centered">-</td>
              <td className="has-text-centered">-</td>
              <td className="has-text-centered">

                <Button
                  size="small"
                  disabled={this.state.newSku === '' || this.state.selected === 'default'}
                  onClick={(event) => this.addNewChannel(event)}
                >
                  <FontAwesomeIcon color={'blue'} icon={faPlusCircle} />
                </Button>
              </td>
            </tr>}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default DetailsTable;
