import React, {Component} from 'react';
import PropTypes from 'prop-types';

import LogsService from '../../../../Services/LogsService';

import {
  Panel
} from 'react-bulma-components/full';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faComments
} from '@fortawesome/free-solid-svg-icons';

import CreateComment from './components/Comment/CreateComment';
import CommentList from './components/Comment/CommentList';

class Activity extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.collapsed,
      commentActive:false,
      numComments:2,
      loading: true,
      logs: [],
      filter: "all",
      queryString: {}
    };

    this.logService = new LogsService();
  }

  componentDidMount() {
    this.onItemChange(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.itemId !== this.props.itemId) {
      this.onItemChange(nextProps);
    }
  }

  constructFilters = (props, filter = null) => {
    var query = {
      rf: [
        'user',
        'email',
        'item',
        'data',
        'action',
        'section',
        'type',
        'status',
        'created_at'
      ],
      qf: {
        id_item: props.itemId
      }
    };

    if (!filter) {
      filter = this.state.filter;
    }

    switch (filter) {
      case 'actions':
        query.qf['type'] = 'system';
        break;
      case 'comments':
        query.qf['type'] = 'comment';
        break;
      default:
        query.qf['type'] = ['comment', 'system'];
        break;
    }
    
    return query;
  }

  fetch = () => {
    this.setState({
      loading:true
    }, () => {
      this.logService.get(this.state.queryString).then((response) => {
        this.setState({
          loading: false,
          logs: response.d,
          commentActive:false,
        });
      });
    });
  }

  filterHandler = (e, filter) => {
    e.preventDefault();

    this.setState({
      filter: filter,
      queryString: this.constructFilters(this.props, filter)
    }, () => {
      this.fetch();  
    });
  }

  onCommentActive = (active = true) => {
    this.setState({
      commentActive: active
    });
  }

  onItemChange = (props) => {
    this.setState({
      queryString: this.constructFilters(props)
    }, () => {
      this.fetch();  
    });
  }

  render() {
    return (
      <Panel>
        <Panel.Header>
        {this.state.logs.length}&nbsp;&nbsp;
        <FontAwesomeIcon icon={faComments}/>&nbsp;
                  
          <span
            className="is-pulled-right"
            onClick={this.props.onToggleCollapse}
          >
            <FontAwesomeIcon 
              className="has-text-grey-light"
              icon={this.props.collapsed ? faArrowAltCircleDown: faArrowAltCircleUp}
            />
          </span>
        </Panel.Header>
        {
          this.props.collapsed ? 
            null
          :
          <div>
            <Panel.Tabs>
              <Panel.Tabs.Tab 
                active={this.state.filter === 'all'} 
                onClick={(e) => this.filterHandler(e, 'all')}>All
              </Panel.Tabs.Tab>
              
              <Panel.Tabs.Tab 
                active={this.state.filter === 'comments'} 
                onClick={(e) => this.filterHandler(e, 'comments')}>Comentarios
              </Panel.Tabs.Tab>
              
              <Panel.Tabs.Tab 
                active={this.state.filter === 'actions'} 
                onClick={(e) => this.filterHandler(e, 'actions')}>Cambios
              </Panel.Tabs.Tab>
            </Panel.Tabs>

            {this.state.commentActive ?
              null
            :
              <Panel.Block>
                <CreateComment 
                  itemId={this.props.itemId}
                  onChange={this.fetch}
                />
              </Panel.Block>
            }

            <CommentList 
              logs={this.state.logs} 
              onCommentActive={this.onCommentActive}
              onChange={this.fetch}
            />
          </div>
        }
      </Panel>
    );
  }
}

Activity.propTypes = {
  collapsed: PropTypes.bool,
  filter: PropTypes.string
};

Activity.defaultProps = {
  collapsed: false,
  filter: "all"
};

export default Activity;