const searching = (data = [], textSearch = '', fields = [], escape = true) => {
  let output = data;
  
  if (escape) textSearch = textSearch.replace(/[\W]+/, "");

  let regexp = new RegExp('(' + textSearch.trim() + ')', 'gi');

  if (textSearch.length) {
    output = data.filter((element) => {
      let found = false;
      for (let f in fields) {
        if (element[fields[f]] && regexp.test(element[fields[f]])) {
          found = true;
          break;
        }
      }

      return found;
    });
  }
  
  return output;
};

export default searching;
