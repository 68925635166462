import React, { useState, useEffect } from 'react';
import Datatable from '../../General/Datatable/Datatable';
import ActivityLogService from '../../../Services/ActivityLogService'


const activityLogService = new ActivityLogService();
const queryString = {
  qf: {
    'date.utc': '2023-01-01 00:00:00'
  }
};

function ActivityLog() {
  
  const [data, setData] = useState([]);
  const [loading] = useState(false);
  const [table] = useState({
    columns: [
      {
        field:'timestamps',
        label: 'Hora',
        format: ['MOMENT::DD/MM/YYYY hh:mm'],
        sortable: true
      },
      {
        field:'class',
        label:'Tipo'
      },
      {
        field: 'log_toString',
        label: 'Actividad',
      },
    ],
    searchFields: ['log_toString']
  });

  const fetch = () => {
    activityLogService.get(queryString)
      .then(resp => {
        setData(resp.d);
      });
  };

  useEffect(() => {
    if (!data.length) {
      fetch();
    }
  }, [data.length]);
  
  return(
    <div>
      <Datatable
        {...table}
        data={data}
        loading={loading}
      />
    </div>
  );
}

export default ActivityLog;
