import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Modal
} from "react-bulma-components/full";

import './style.scss';

class ModalCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closeOnBlur: true,
      show: false
    };
  }

  close = () => {
    this.props.closeModal();
  }

  componentWillReceiveProps(props) {
    if (this.props.show !== props.show) {
      this.setState({
        show: props.show,
      });
    }
  }

  show = () => {
    this.setState({
      show: true
    });
  }

  render() {
    return (
      <Modal show={this.state.show} onClose={this.close} showClose={false}>
        <Modal.Card className={`modal-${this.props.size}`}>
          <Modal.Card.Head onClose={this.close}>
            <Modal.Card.Title renderAs="div">
              {this.props.title}
            </Modal.Card.Title>
          </Modal.Card.Head>
          <Modal.Card.Body>
            {this.props.body}
          </Modal.Card.Body>
          <Modal.Card.Foot>
          {this.props.footer}
          </Modal.Card.Foot>
        </Modal.Card>
      </Modal>
    );
  }
};

ModalCard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closeOnBlur: PropTypes.bool,
  header: PropTypes.any,
  title: PropTypes.any,
  footer: PropTypes.any,
};

ModalCard.defaultProps = {
  body: null,
  closeOnBlur: true,
  header: true,
  footer: false,
  size: '',
  title: ''
};

export default ModalCard;
