import React from 'react';
import PropTypes from 'prop-types';

import {
  Dropdown
} from 'react-bulma-components/full';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ActionsDropdown(props) {

  const onChange = (value) => {
    if (props.options[value]) {
      props.options[value].onClick();
    }
  };

  return(
    <Dropdown onChange={onChange} disabled={props.disabled}>
      <Dropdown.Item value='' key={0} style={{display: "none"}}>
        {props.icon ? <FontAwesomeIcon icon={props.icon} /> : null}
        {props.icon && props.label ? <span>&nbsp;</span> : null}
        {props.label || ''}
      </Dropdown.Item>
      {props.options.map((action, key) => {
        return (
          <Dropdown.Item value={key} key={key} title={action.label}>
            {action.icon ? <FontAwesomeIcon icon={action.icon} /> : null}
            {action.icon && action.label ? <span>&nbsp;</span> : null}
            {action.label || ''}
          </Dropdown.Item>
        );
      })}
    </Dropdown>
  );
}

ActionsDropdown.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array
};

ActionsDropdown.defaultProps = {
  disabled: false,
  options: []
};

export default ActionsDropdown;