import React, {Component} from 'react';

import LogsService from '../../../../../../Services/LogsService';

import ResponseItem from './ResponseItem';
import CreateResponse from './CreateResponse';

import {
  Panel
} from 'react-bulma-components/full';

class ResponseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responses: []
    };

    this.logsService = new LogsService();
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    this.logsService.get({
      rf: [
        'user', 
        'email',
        'item',
        'data', 
        'action', 
        'section', 
        'type', 
        'status',
        'created_at'
      ],
      qf: {
        id_log_link: this.props.log._id,
        type: 'response'
      }
    })
    .then((response) => {
      let responses = response.d.sort((a, b) => {
        return a.created_at > b.created_at ? -1 : 1;
      });

      this.setState({
        responses: responses
      });
    });
  }

  render() {
    return(
      <Panel>
        <CreateResponse 
          commentId={this.props.log._id}
          onChange={this.fetch}
        />
        {this.state.responses.map((response) => {
          return(
            <ResponseItem log={response} key={response._id} />
          );
        })}
      </Panel>
    );
  }
}

ResponseList.propTypes = {

};

ResponseList.defaultProps = {

};

export default ResponseList;