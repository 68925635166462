import React, { useEffect, useState } from "react";
import AnalyticService from "../Services/AnalyticService";
import ChannelService from "../Services/ChannelsService";
import ItemsService from "../Services/ItemsService";

const analyticService = new AnalyticService();
const channelService = new ChannelService();
const itemsService = new ItemsService();

const useDashboard = () => {
  const [filterOptions, setFilterOptions] = useState({});
  const [filters, setFilters] = useState({});
  const [tags, setTags] = useState([]);

  const downloadPDF = async (html) => {
    let { d } = await analyticService.generatePDF(html);

    
    if (d && d[0] && d[0].url) {
      window.open(d[0].url);
    }
  };


  const downloadXLS = async (filters) => {
    let _filters = {};

    for (let f in filters) {
      if (!filters[f] || !filters[f].length) continue;

      _filters[f] = filters[f].map((r) => r.value);
    }

    let { d } = await analyticService.generateXLS(_filters);

    if (d && d[0] && d[0].url) {
      window.open(d[0].url);
    }
  };

  const fetchAllData = async (filters) => {
    let output = {};
    let promises = [];

    promises.push(new Promise(
      async (resolve) => {
        let { d } = await analyticService.fetchPhotoStatusEcom(filters);
        resolve({
          photoStatusEcomm: d && d[0] ? d[0] : {}
        });
      }
    ));

    promises.push(new Promise(
      async (resolve) => {
        let { d } = await analyticService.fetchPhotoStatusProgress(filters);
        resolve({
          photoStatusProgress: d && d[0] ? d[0] : {}
        });
      }
    ));

    promises.push(new Promise(
      async (resolve) => {
        let { d } = await analyticService.fetchCopyStatus(filters);
        resolve({
          copyStatus: d && d[0] ? d[0] : {}
        });
      }
    ));

    promises.push(new Promise(
      async (resolve) => {
        let { d } = await analyticService.fetchPhotoOrigin(filters);
        resolve({
          photoOrigin: d && d[0] ? d[0] : {}
        });
      }
    ));

    promises.push(new Promise(
      async (resolve) => {
        let { d } = await analyticService.fetchTimelinePhoto(filters);
        resolve({
          timelinePhoto: d && d[0] ? d[0] : {}
        });
      }
    ));

    promises.push(new Promise(
      async (resolve) => {
        let { d } = await analyticService.fetchShootings(filters);
        resolve({
          shootings: d  ? d : {}
        });
      }
    ));

    await Promise.all(promises).then((array) => {
      array.forEach((data) => {
        output = { ...output, ...data };
      });
    });

    return output;
  };

  const fetchFilterOptions = async (fields) => {
    let output = {};
    let data = await itemsService.fieldsUnique(fields).then((r) => r.d && r.d[0] ? r.d[0] : {});
    let channels = await channelService.get({
      rf: ["_id", "name"],
    }).then((r) => r.d);

    for (let field in data) {
      output[field] = data[field].filter((r) => !!r).map((r) => ({
        label: r,
        value: r
      }));
    }

    if (Array.isArray(channels)) {
      output.saleChannel = channels.map((r) => ({
        label: r.name,
        value: r.name
      }));
    }

    setFilterOptions(output);
    return output;
  }

  const getTags = (filters) => {
    let output = [];
    let keys = Object.keys(filters);

    if (keys.length) {
      for (let k of keys) {
        if (Array.isArray(filters[k])) {
          output = [...output, ...filters[k].filter((a) => !!a).map((r) => r.value)];
        } else {
          output.push(filters[k]);
        }
      }
    }
    output = output.filter((a) => a && a.length);
    setTags(output);
    return output;
  };

  return {
    applyFilters: setFilters,
    downloadPDF,
    downloadXLS,
    fetchAllData,
    fetchFilterOptions,
    filterOptions,
    filters,
    getTags,
    tags
  }
};

export default useDashboard;
