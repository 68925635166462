import React, {Component} from 'react';
import UserService from '../../../Services/UserService';

import { toast } from 'react-toastify';

import {
  Button,
  Columns,
  Form,
  Heading
} from 'react-bulma-components/full';

import {PROFILE} from '../../General/Language/es';

class EditProfile extends Component {
  constructor(props) {
    super(props);
    
    let {account, email} = this.props.loggedInUser;

    this.state = {
      user: {
        account: account,
        email: email
      },
      editVisible: false
    };

    this.userService = new UserService();
  }

  handleChange = (e) => {
    const value = e.target.value;
    const properties = e.target.name.split('.');
    let user = this.state.user;
    
    if (properties.length > 1) {
      user.account[properties[1]] = value;
    } else {
      user[properties[0]] = value;
    }

    this.setState({
      user: user
    });
  }

  toggleEdit = () => {
    this.setState({
      editVisible: !this.state.editVisible,
    }, () => {
      this.resetForm();
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let {account} = this.state.user;

    this.userService.put(this.props.loggedInUser._id, {
        co: [{
          account: account
        }]
      })
      .then((response) => {
        for (let i in response.error) {
          if (response.error[i].code === 0) {
            toast.success(PROFILE.CHANGE_PROFILE_SUCCESS);
          } else {
            toast.error(response.error[i].message);
          }
        }
      });
  }

  resetForm = () => {
    this.setState({
      user: this.props.loggedInUser
    });
  }

  render() {
    return (
      <div>
        <Button 
          className={'is-fullwidth ' + (this.state.editVisible ? 'is-hidden' : '')}
          onClick={this.toggleEdit}
        >
          Editar perfil
        </Button>
        <form 
          name="editProfile"
          onSubmit={this.handleSubmit}
          className={this.state.editVisible ? '' : 'is-hidden'}
        >
          <Heading size={5} className="has-text-centered">Editar perfil</Heading>
          <Form.Field>
            <Form.Control>
              <Form.Input
                autoComplete="off"
                value={this.state.user.account.first_name}
                name="account.first_name" 
                placeholder="Nombre" 
                onChange={this.handleChange}
                required
              />
              </Form.Control>
          </Form.Field>

          <Form.Field>
            <Form.Control>
              <Form.Input
                autoComplete="off"
                value={this.state.user.account.last_name}
                name="account.last_name" 
                placeholder="Apellido" 
                onChange={this.handleChange}
                required
              />
            </Form.Control>
          </Form.Field>

          <Form.Field>
            <Form.Control>
              <Form.Input
                autoComplete="off"
                value={this.state.user.email}
                name="email" 
                placeholder="Email" 
                onChange={this.handleChange}
                required
                disabled
              />
            </Form.Control>
          </Form.Field>

          <Columns>
            <Columns.Column>
              <Button 
                color="success" 
                className="is-block is-fullwidth"
              >
                Actualizar
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button 
                type="button"
                className="is-block is-fullwidth"
                onClick={this.toggleEdit}
              >
                Cancelar
              </Button>
            </Columns.Column>
          </Columns> 
        </form>
      </div>
    );
  }
}

export default EditProfile;