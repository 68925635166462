import React from "react";
import PropTypes from "prop-types";
import { Columns, Table } from "react-bulma-components";
import moment from "moment";

const status = {
  "inProgress": "En proceso",
  "new": "Nuevo",
  "terminated": "Terminado"
};

const PhotoSchedule = ({ shootings }) => {
  return (
    <div className="d-photo-schedule">
      <Columns className="header">
        <Columns.Column size={12}>
          <div className="is-size-4 has-text-weight-bold">Sesiones de fotografía programadas</div>
        </Columns.Column>
      </Columns>

      <Columns>
        <Columns.Column size={12}>
          <Table striped={false}>
            <tbody>
              <tr>
                <th>Nombre</th>
                <th>Origen</th>
                <th>Avance</th>
                <th>F. Inicio</th>
                <th>F. Entrega</th>
                <th>Estatus</th>
              </tr>
              
              {shootings.length ? 
                shootings.map((row, i) => (
                  <tr key={i}>
                    <td>{row.name}</td>
                    <td>{row.origin}</td>
                    <td>{`${Math.round(row.progress.porcent * 100)}% (${row.progress.completed}/${row.progress.total})`}</td>
                    <td>{moment(row.scheduled_date).format("DD MMM YYYY")}</td>
                    <td>{row.delivery_date ? moment(row.delivery_date).format("DD MMM YYYY") : ""}</td>
                    <td>{status[row.status]}</td>
                  </tr>
                ))
              : null}
            </tbody>
          </Table>
        </Columns.Column>
      </Columns>
    </div>
  );
};

PhotoSchedule.propTypes = {
  shootings: PropTypes.array
};

PhotoSchedule.defaultProps = {
  shootings: []
};

export default PhotoSchedule;
