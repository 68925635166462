import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemService from '../../../../../Services/ItemsService';

import {
  Button,
  Columns
} from "react-bulma-components/full";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import Dropzone from '../../../../General/Form/Dropzone/Dropzone';

import {STATUS} from '../../../../General/Constants/Item';

class UploadImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      uploading: false,
      channel: this.props.channel,
      item: this.props.item,
      uploadProgress: 0
    };

    this.service = new ItemService();
  }

  onChangeDropzone = (state) => {
    this.setState({
      files: state.files
    });
  }

  updateStatus = (item, status) => {
    let statusKey = (this.props.type === 'eComm' ? 'status.current' : 'status.product_guide')
    this.service.put(item._id, {
      co: [{
        [statusKey]: STATUS[status]
      }]
    })
    .then(response => {
      this.props.onChange();
    })
    .catch(error => {
      console.log(error)
    });
  }

  uploadFile = async (event) => {
    this.setState({ uploading: true });
    
   // let item = await this.service.uploadImages(this.state.files, this.props.channel, this.props.item);

    for(let i=0; i<this.state.files.length; i++){
          
      await this.service.uploadImages([this.state.files[i]], this.props.channel, this.props.item)
      .then(response=>{
        console.log(response)


      })
      .catch(error=>console.log(error))

      this.setState({uploadProgress: this.state.uploadProgress + 1})
  }

    if (this.props.item) {
      this.setState({ uploading: false });

       if( this.props.shooting === true) {
        this.updateStatus(this.props.item, 'TOREVIEW')

       } else {
        let response = await this.service.put(this.props.item._id, {
           co: [{
            'status.current': 'toReview'
           }]
         })
        
        if (response.d[0]) {
          this.props.onUploadImages(response.d[0].ref);
        }
       }

      this.props.closeModal();
      toast.success('El PC9: "' + this.props.item.pc9 + '" recibió ' + this.state.files.length + " fotos")

      return this.props.item;
    }
  }

  
  render() {
    return (
      <div className="has-text-centered">
        <Columns centered={true}>
          <Columns.Column>
            <Button
              color="success"
              disabled={(!this.state.files.length || this.state.uploading)}
              fullwidth={true}
              onClick={this.uploadFile}
            >
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                className={this.state.uploading ? '' : 'is-hidden'}
              />
              &nbsp;{this.state.uploading ? 'Subiendo fotos '+this.state.uploadProgress + '/'+this.state.files.length : 'Subir fotos'}
            </Button>
          </Columns.Column>
        </Columns>
        
        {!this.state.uploading ?
                <Dropzone
                maxFiles={20}
                onChange={this.onChangeDropzone}
                formats={[".jpg", ".png", ".tiff", "image/jpeg", "image/png", "image/tiff"]}
                disabled={this.state.uploading}
              />
              :
              null
      }


      </div>
    );
  }
}

UploadImages.propTypes = {
  onChange: PropTypes.func
};

UploadImages.defaultProps = {
  onChange: () => { }
};

export default UploadImages;
