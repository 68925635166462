import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Columns
} from "react-bulma-components/full";

import THeader from './components/THead/THeader';
import TFooter from './components/TFooter/TFooter';
import TBody from './components/TBody/TBody';
import Header from './components/Header/Header';

import {sortBy, searching, extractUniq, filtering} from './utils';

import './style.scss';

const SIZE_PER_PAGE = 35;

function Datatable(props) {
  const [checkList, setCheckList] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterFills, setFilterFills] = useState({});
  const [textSearch, setTextSearch] = useState('');
  const [renderRows, setRenderRows] = useState([]);


  const [page, setPage] = useState(1);
  const [renderData, setRenderData] = useState([]);
  const ref = useRef(null);

  const [sort, setSort] = useState(null);
  const [order, setOrder] = useState(null);

  const checkAllToggle = (column) => {
    let array = [];
    if (renderRows.length !== checkList.length) {
      for (let i in renderRows) {        
        if (column.checkable) {
          if (!column.checkable(renderRows[i])) {
            continue;
          }
        }
        array.push(renderRows[i][column.field]);
      }
    }

    setCheckList(array);
  };

  const checkItemToggle = (value) => {
    let found = checkList.indexOf(value);
    let chkList = checkList;
    
    if (found === -1) {
      chkList.push(value);
    } else {
      chkList.splice(found, 1);
    }

    setCheckList([].concat(chkList));
  }

  const onChangeSort = (config) => {
    setSort(config.sort);
    setOrder(config.order);
  };

  const onChangeFilter = (filters) => {
    setFilters({...{}, ...filters});
  }

  const next = () => {
    if (props.loading === true) return;
    if (!Array.isArray(renderRows)) return;
    let initial = (page - 1) * SIZE_PER_PAGE;
    let end = page * SIZE_PER_PAGE;

    if (end > renderRows.length) {
      end = renderRows.length;
    }

    let aux;
    if (page === 1) {
      aux = [...(renderRows.slice(initial, end))];
    } else {
      aux = [...renderData, ...(renderRows.slice(initial, end))];
    }
    
    setRenderData(aux);
  };
 
  const scrollHandler = (e) => {
    if (ref.current === undefined) return;
    if (page * SIZE_PER_PAGE >= props.data.length) return;

    let {
      clientHeight,
      scrollHeight,
      scrollTop
    } = ref.current;
    
    if (((clientHeight + Math.round(scrollTop, 0)) - scrollHeight) >= 0) {
      setPage(page + 1);
    } 
  };
  
  useEffect(() => {
    setRenderRows(props.data);
    setFilters({});
  }, [props.data]);


  useEffect(() => {
    props.onChange({
      checkList: checkList,
      renderRows: renderRows
    });
  }, [checkList, renderRows])
  

  //onChange Filters
   useEffect(() => {
    setRenderRows(filtering(props.data, filters));
    setCheckList([]);
  }, [filters]);

  //onChange Text Search
  useEffect(() => {
    setRenderRows(searching(props.data, textSearch, props.searchFields));
    setCheckList([]);
  }, [textSearch])
  

  //onChange Data
  useEffect(() => {
    if (!props.filterFills) {
      const filterKeys = [];
      
      Object.keys(props.filtersConfig).forEach((key) => {
        if (props.filtersConfig[key].type !== 'textarea') {
          filterKeys.push(key);
        } 
      });

      if (props.filterfills) {
      }

      const update = new Promise((resolve, reject) => {
        resolve(extractUniq(props.data, filterKeys));
      });

        
      update.then((data) => {
        setFilterFills(data);
        
      });    


    }
    
  }, [props.data]);

  useEffect(() => {
    if (props.filterFills) {
      setFilterFills(props.filterFills ? props.filterFills : {});
    }
  }, [props.filterFills]);


  // OnChange sort
  useEffect(() => {
    if (sort !== null && order !== null) {
      let conditional = null;

      for (let column of props.columns) {
        if (column.field === sort && typeof column.sortable === 'function') {
          conditional = column.sortable;
          break;
        }
      }

      let config = {
        field: sort,
        order: order
      };
      
      let newRender = sortBy(renderRows, config, conditional);

      if (newRender === undefined)  throw "Not return value to sort, is required.";

      setRenderRows([...[], ...newRender]);
    }
  }, [sort, order]);


  useEffect(() => {
    if (page === 1) {
      setRenderData([]);
    }
    next();
  }, [page]);

  useEffect(() => {
    setRenderData([]);
    
    if (page === 1) next();
    
    setPage(1);

  }, [renderRows]);

  return (
    <Columns className="datatable">
      <Columns.Column>
        <Header 
          actions={props.actions ? props.actions : []}
          checkCounter={props.checkCounter}
          checkList={checkList}
          filters={filters}
          filterFills={filterFills}
          filtersConfig={props.filtersConfig}
          loading={props.loading}
          onChangeFilter={onChangeFilter}
          onChangeSearch={setTextSearch}
          renderRowsPC9={renderRows.map((row) => row.pc9)}
          searcheable={props.searchFields.length}
          textSearch={textSearch}
          phSearchBar={props.phSearchBar}
        />
        <div className="table-container"   
          onScroll={scrollHandler}
          style={{
            height: props.height ? props.height: null,
            maxHeight: props.height ? props.height: null
          }}
          ref={ref}
        >
          <Table className="table is-striped is-hoverable is-fullwidth is-responsive" >
            {props.tHeader ?
              <THeader
                sort={sort}
                order={order}
                columns={props.columns} 
                checkAll={checkList.length === renderRows.length}
                checkAllToggle={checkAllToggle}
                onChangeSort={onChangeSort}
                
              />
              :
              null
            }
            <TBody 
              idKey={props.idKey}
              columns={props.columns}
              checkAll={checkList.length === renderRows.length}
              checkToggle={checkAllToggle}
              checkList={checkList}
              checkItemToggle={checkItemToggle}
              data={renderData}
              emptyLegend={props.emptyLegend}
              filtered={props.data.length !== renderRows.length}
              loading={props.loading}
              loadingLegend={props.loadingLegend}
            />
            {props.TFooter ?
              <TFooter
                {...props}
                columns={props.columns} 
                checkAll={checkList.length === renderRows.length}
                checkAllToggle={this.checkAllToggle}
              />
              :
              null
            }
          </Table>
        </div>
        {props.footer}
      </Columns.Column>
    </Columns>
  );
}

Datatable.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  checkCounter: PropTypes.bool,
  data: PropTypes.array,
  emptyLegend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  filtersConfig: PropTypes.any,
  footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  height: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  searchFields: PropTypes.array,
  queryString: PropTypes.object,
  tHeader: PropTypes.bool,
  tFooter: PropTypes.bool
};

Datatable.defaultProps = {
  actions: false,
  checkCounter: false,
  data: [],
  emptyLegend: <b>Empty</b>,
  filtersConfig: false,
  filterFills: false,
  footer: false,
  header: false,
  height: null,
  idKey: '_id',
  loading: true,
  loadingLegend: <b>Loading...</b>,
  onChange: () => {},
  searchFields: [],
  queryString: {},
  tHeader: true,
  tFooter: false
};

export default Datatable;
