import {uniq as _uniq} from 'underscore';

const extractUnique = (array, properties = [], caseSensitive = false) => {
  let output = {};

  array.forEach(element => {
  
    properties.forEach((prop) => {
      let propSplit = prop.split('.');
      let property = element;
      
      for (let i in propSplit) {
        if (property[propSplit[i]]) {
          property = property[propSplit[i]];
        } else {
          property = undefined;
          break;
        }
      }

      if (!output[prop]) {
        output[prop] = [];
      }
      
      if (property && property.length) {
        if (Array.isArray(property)) {
          for (let p in property) {
            let aux = caseSensitive ? property[p] : property[p].toLowerCase();
            output[prop].push(aux);
            output[prop] = _uniq(output[prop]);
          }
        } else if (typeof property === 'string') {
          let aux = caseSensitive ? property : property.toLowerCase();
          output[prop].push(aux);
          output[prop] = _uniq(output[prop]);
        }
      }

      // Sorting
      output[prop].sort();
    });
  });
  
  return output;
}

export default extractUnique;
