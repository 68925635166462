import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BatchService from '../../../Services/BatchService';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faMapSigns
} from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Notification
} from 'react-bulma-components/full';

import { toast } from 'react-toastify';

import {
  STATUS as BATCH_STATUS, 
  TYPES as BATCH_TYPE
} from '../../General/Constants/Batch';

import {MASTER as MASTER_MSG} from '../../General/Language/es';

const batchService = new BatchService();

function OnProductGuide(props) {
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    let batch = {
      status: BATCH_STATUS.PENDING,
      type: BATCH_TYPE.PGUIDE
    };

    batch._items = props.itemList;

    let batchResp = await batchService.post({
      co: [batch]
    });

    if (!(batchResp.endp && batchResp.endp.success)) {
      toast.warning(MASTER_MSG.ERROR);
      return;
    }

    toast.success(MASTER_MSG.SUCCESS);
    props.onSubmit();
  };


  return(
    <div className="has-text-centered">
      {!props.itemList.length ?
        <Notification color="warning">
          Ningún elemento seleccionado.
        </Notification>
      :
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent:'space-between'
        }}>
          <div>
            <label className="label">¿Enviarás {props.itemList.length} item(s) a estado pendiente en Product guide?</label>
          </div>
          &nbsp;
          <div>
            <Button color="warning" disabled={loading} onClick={onSubmit}>
            
            <FontAwesomeIcon icon={faMapSigns}/>
              &nbsp;Enviar
            </Button>
          </div>
        </div>
      }
    </div>
  );
}

OnProductGuide.propTypes = {
  itemList: PropTypes.array,
  onSubmit: PropTypes.func
};

OnProductGuide.defaultProps = {
  itemList: [],
  onSubmit: () => {}
};

export default OnProductGuide;