import React, { Component } from 'react';

import ShootingService from '../../../../Services/ShootingService';
import {
  Button,
  Form,
  Dropdown
} from 'react-bulma-components/full';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

import { SHOOTING } from '../../../General/Language/es';

class CreateShootingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shooting: {
        name: '',
        type:'eComm',
        scheduled_date: props.fromCalendar ? props.start : new Date(),
        photoman: '',
        studio: '',
        description: ''
      }
    }
    this.service = new ShootingService();
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    let response = await this.service.post({
      co: [this.state.shooting]
    });

    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.success(SHOOTING.CREATE.SUCCESS);
        if (this.props.fetch) {
          this.props.fetch();
        }
      } else {
        toast.error(SHOOTING.CREATE.ERROR);
      }
    }

    this.props.close();
  }

  onChange = (event) => {
    let { shooting } = this.state;
    shooting[event.target.name] = event.target.value;
    this.setState({
      shooting: shooting
    });
  }

  changeType =(type)=>{

    let { shooting } = this.state;
    shooting.type = type
    this.setState({
      shooting: shooting
    });
  }

  onChangeDate = (field, date) => {
    let { shooting } = this.state;
    shooting[field] = date;
    this.setState({
      shooting: shooting
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Nombre</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Form.Input
                autocomplet="off"
                value={this.state.shooting.name}
                name="name"
                onChange={this.onChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Tipo</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Dropdown 
                  value={this.state.shooting.type}
                  onChange={this.changeType}
              >
                <Dropdown.Item value="eComm" >
                  eComm
                </Dropdown.Item>
                <Dropdown.Item value="pGuide">
                  pGuide
              </Dropdown.Item>

              </Dropdown>
            </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Fecha programada</label>
            </div>
            <div className="field-body">
              <div className="field">
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  isClockOpen={false}
                  minDate={new Date()}
                  onChange={(date, e) => { this.onChangeDate('scheduled_date', date) }}
                  required={true}
                  selected={this.state.shooting.scheduled_date}
                  calendarClassName="field"
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Fotógrafo</label>
            </div>
            <div className="field-body">
              <div className="field">
                <Form.Input
                  autocomplet="off"
                  value={this.state.shooting.photoman}
                  name="photoman"
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Estudio</label>
            </div>
            <div className="field-body">
              <div className="field">
                <Form.Input
                  autocomplet="off"
                  value={this.state.shooting.studio}
                  name="studio"
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Descripción</label>
            </div>
            <div className="field-body">
              <div className="field">
                <Form.Textarea
                  autocomplet="off"
                  value={this.state.shooting.description}
                  name="description"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="field is-grouped is-grouped-right">
            <Button color="success" type="submit">Crear</Button>
          </div>
      </form>
    );
  }
}

CreateShootingForm.propTypes = {

};

CreateShootingForm.defaultProps = {
  fetch: () => {}
};

export default CreateShootingForm;
