import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TicketService from '../../Services/TicketService';

import {
  Button,
  Columns,
  Modal,
  Form
} from 'react-bulma-components/full';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPaperPlane, faCommentDots, faHeadset} from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';

import Dropzone from '../General/Form/Dropzone';

import './style.scss';

const ticketService = new TicketService();

const SupportEmail = (props) => {
  
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [sending, setSending] = useState(false);
  const [visible, setVisible] = useState(false);

  const submitHandler = () => {
    ticketService.post(type, {
      'data.title' : title,
      'data.body'  : description
    }, files)
      .then((response) => {
        for (let e in response.error) {
          if (response.error[e].code === 0) {
            toast.success("Atenderemos tu solicitud lo antes posible y nos pondremos en contacto.");
            setVisible(false);
            setType('');
            setTitle('');
            setDescription('');
            setFiles([]);
          } else {  
            toast.error("Ocurrió un problema vuelve a intentarlo.");
          }
        }
      })
      .catch((error) => {
        toast.error("Ocurrió un problema vuelve a intentarlo.")
      })
      .finally(() => setSending(false))
  };

  useEffect(() => {
    if (sending) {
      submitHandler();
    }
  }, [sending])

  return(
    <div className="support">
      <Button className="btn-support" 
        color="link" 
        onClick={() => setVisible(true)}
        size="medium"
      > 
      {props.loggedInUser.code === 'brand_manager' ? '' : '¿Necesitas ayuda?'}
      &nbsp;&nbsp;&nbsp;
        <FontAwesomeIcon icon={faHeadset} />
      </Button>


      <Modal show={visible} onClose={() => setVisible(false)} showClose={false}>
        <Modal.Card>
          <Modal.Card.Head onClose={() => setVisible(false)}>
            <Modal.Card.Title renderAs="div">
              {props.title}
            </Modal.Card.Title>
          </Modal.Card.Head>
          
          <Modal.Card.Body>
            <Form.Field>
              <Form.Label>
                Tipo de solicitud
              </Form.Label>
              <Form.Control>
                <Form.Select onChange={(e) => setType(e.target.value)} value={type}>
                  <option value="" disabled>Selecciona una opción</option>
                  <option value="alta" className="has-background-link">Alta de PC9-SKU Canal</option>
                  <option value="error_link" className="has-background-link">Error al generar link descarga</option>
                  <option value="error_paquete" className="has-background-link">Error con un paquete</option>
                  <option value="error_pt" className="has-background-danger">Phototool tiene un error</option>
                  <option value="otro" className="has-background-warning">Necesito ayuda con algo más</option>
                </Form.Select>
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Form.Label>
                Título de ticket
              </Form.Label>
              <Form.Control>
                <Form.Input onChange={(e) => setTitle(e.target.value)} value={title} />
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Form.Label>
                Comentario o descripción del problema
              </Form.Label>
              <Form.Control>
                <Form.Textarea onChange={(e) => setDescription(e.target.value)} value={description}>

                </Form.Textarea>
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Form.Label>
                Archivos (jpg/png/csv)
              </Form.Label>
              <Form.Control>
                <Dropzone
                  maxFiles={5}
                  onChange={({files}) => setFiles(files)}
                />
              </Form.Control>
            </Form.Field>

            <Columns>
              <Columns.Column>
                <Button
                  className="is-pulled-right"
                  color="success"
                  disabled={(!type.length || !title.length || !description.length) || sending}
                  onClick={() => setSending(true)}
                >
                  <FontAwesomeIcon icon={faPaperPlane}/>
                  &nbsp; Enviar
                </Button>
              </Columns.Column>
            </Columns>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
    </div>
  );
};

SupportEmail.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

SupportEmail.defaultProps = {
  title: ""
};

export default SupportEmail;
