import React, { Component } from 'react';

import {
  Columns
} from "react-bulma-components/full";

class LookingEmptyRow extends Component {
  constructor(props) {
    super(props);
    
    this.state = {}
  }

  render() {
    return(
      <Columns>
        <Columns.Column>
          <p><b>Aún no tienes tareas de búsquedas asignadas.</b></p>
          <span>
            En cuanto te las asignen te apareceran aquí junto con la fecha en la que debería estar en el el estudio para ser shooteada.
          </span>
        </Columns.Column>
      </Columns>
    );
  }
}

export default LookingEmptyRow;