import React, { Component } from 'react';

import {
  Icon,
  Tabs
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCameraRetro,
  faCalendar,
  faCrosshairs,
  faArchive
} from '@fortawesome/free-solid-svg-icons';

import PendingShooting from './components/PendingShooting';
import PendingpGuide from './components/PendingpGuide';
import ShootingTable from './components/ShootingTable';
import ShootingArchive from './components/ShootingArchive';
import CalendarShootings from './components/CalendarShootings';

import BatchService from '../../Services/BatchService';

class Shootings extends Component {
  constructor(props) {
    super(props);
    this.tabIndex = React.createRef();
    
    this.state = {
      loggedInUser:this.props.loggedInUser,
      activeTab: 0,
      batchs: [],
      tabs: [{
        label:"Calendario",
        icon:faCalendar,
        content: CalendarShootings,
        props:{
          loggedInUser:this.props.loggedInUser
        }
        },
        {
          label:'PC9s por asignar',
          icon: faCrosshairs,
          content: PendingShooting,
          props:{
            loggedInUser:this.props.loggedInUser
          }
        },
        //{
        //   label:'Encontrados PGUIDE',
        //   icon: faCrosshairs,
        //   content: PendingpGuide
        // },
        {
          label: 'Listado shootings',
          icon: faCameraRetro,
          content: ShootingTable
        },
        {
          label: 'Archivo',
          icon: faArchive,
          content: ShootingArchive
        }
      ]
    };

    this.batchService = new BatchService();
  }


  addTab = (tab) => {
    let tabs = this.state.tabs;
    tabs[4] = tab;
    this.setState({
      tabs: tabs,
      activeTab: 4
    });
  }

  componentDidMount() {
    this.batchService.get({
      rf: ['_id', 'name']
    }).then((response) => {
      let batchIds = response.d.map((batch) => {
        return batch._id;
      });
            
      this.setState({
        batchs: batchIds
      });
    });
  }

  onChangeDatatable = (state) => {
    let {renderRows, checkList} = state;
    
    this.setState({
      checkList: checkList,
      renderRows: renderRows
    });
  }

  setActiveTab = (index) => {
    this.setState({
      activeTab: index
    });
  }

  closeTab = (e = null) => {
    if (e) {
      e.stopPropagation();
    }
    let tabs = this.state.tabs;
    tabs.pop()

    this.setState({
      activeTab: 0,
        tabs: tabs
    });
  }

  render() {
    let MyTab = this.state.tabs[this.state.activeTab].content;

    return (
      <div>
        <Tabs>
          {this.state.tabs.map((tab, index) => {
            return (
              <Tabs.Tab 
                active={this.state.activeTab === index} 
                onClick={(e) => this.setActiveTab(index)}
                key={index}
              >
                <Icon size="small" >
                  <FontAwesomeIcon icon={tab.icon}/>
                </Icon>
                {tab.label} &nbsp;
                {tab.props && tab.props.closeTab ? 
                  <span className="delete is-small" onClick={this.closeTab}></span> :
                  ''
                }
              </Tabs.Tab>  
            );
          })}
        </Tabs>
      
        <div className="tab-content"> 
          <MyTab 
            {...this.state.tabs[this.state.activeTab].props}
            addTab={this.addTab}
            batchs={this.state.batchs}
            closeTab={true}
            season={process.env.APP_DEFAULT_SEASON} 
            loggedInUser={this.state.loggedInUser}
          />
        </div>
      </div>
    );
  }
};

export default Shootings;
