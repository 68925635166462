// Services/TicketService.js

import axios from 'axios';

class TicketService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/ticket`,
      withCredentials: true
    });
    this.service = service;
  }

  post = async(type, data, files = []) => {

    console.log()
    data['type'] = type;
    let json = {
      co: [
        data
      ]
    };

    let formData = new FormData();
    formData.append("data", JSON.stringify(json));

    for (let f in files) {
      formData.append("attachments", files[f]);
    }

    return this.service.post('/', formData, {
      headers: {
        'Accept' : 'application/json',
        'Content-Type' : 'multipart/form-data'
        }
      })
      .then(response => response.data)
      .catch(error => {
        if (error.response && error.response.data) {
          return error.response.data;
        }
        return error;
      });
  }
}

export default TicketService;
