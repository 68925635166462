import React from 'react';
import PropTypes from 'prop-types';
import {
  Tag
} from "react-bulma-components/full";

function Counter(props) {
  
  return (
    <Tag.Group gapless>
      <Tag color="info">{props.size}</Tag>
      <Tag>Seleccionados</Tag>
    </Tag.Group>
  );
}

Counter.propTypes = {
  size: PropTypes.number
}

Counter.defaultProps = {
  size: 0
};

export default Counter
  