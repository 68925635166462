import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Columns, Form } from "react-bulma-components";
import { useHistory } from "react-router-dom";
import SelectMultiple from 'react-select';
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import useDashboard from "../../_hooks/useDashboard";
import html2canvas from "html2canvas";

const DashboardFilters = ({ filters, filterOptions, loading, toggleLoader, onChange }) => {
  const [selected, setSelected] = useState({
    season: [],
    channels: [],
    brand: [],
    gender: [],
    category: []
  });

  const { downloadPDF, downloadXLS } = useDashboard();
  const history = useHistory();

  const onChangeHandler = (name, value) => {
    let _selected = {...selected};
    _selected[name] = value;
    setSelected(_selected);
  };

  useEffect(() => {
    onChange(selected);
  }, [selected])

  return (
    <Columns centered multiline>
      <Columns.Column size={11}>
        <p className="title">Filtros de consulta</p>
        <Form.Control>
          <SelectMultiple
            isDisabled={loading}
            isMulti 
            isSearchable
            noOptionsMessage={() => "Sin opciones"}
            onChange={(v) => onChangeHandler("season", v)}
            options={filterOptions.season || []}
            placeholder="Temporada"
            value={selected.season}
            styles={{
              valueContainer: (styles) => ({...styles, ...{padding: "0px 8px 3px 8px"}}),
              placeholder: (styles) => ({...styles, ...{fontSize: '17px'}}),
              menu: (styles) => ({...styles, ...{fontSize: '17px'}}) 
            }}
            menuPlacement={"bottom"}
          />
          
        </Form.Control>

        <Form.Control>
          <SelectMultiple
            isDisabled={loading}
            isMulti 
            isSearchable
            noOptionsMessage={() => "Sin opciones"}
            onChange={(v) => onChangeHandler("channels", v)}
            options={filterOptions.saleChannel || []}
            placeholder="Canal de venta"
            value={selected.saleChannel}
            styles={{
              valueContainer: (styles) => ({...styles, ...{padding: "0px 8px 3px 8px"}}),
              placeholder: (styles) => ({...styles, ...{fontSize: '17px'}}),
              menu: (styles) => ({...styles, ...{fontSize: '17px'}}) 
            }}
            menuPlacement={"bottom"}
          />
        </Form.Control>
        
        <Form.Control>
          <SelectMultiple
            isDisabled={loading}
            isMulti 
            isSearchable
            noOptionsMessage={() => "Sin opciones"}
            onChange={(v) => onChangeHandler("brand", v)}
            options={filterOptions.brand || []}
            placeholder="Marca"
            value={selected.brand}
            styles={{
              valueContainer: (styles) => ({...styles, ...{padding: "0px 8px 3px 8px"}}),
              placeholder: (styles) => ({...styles, ...{fontSize: '17px'}}),
              menu: (styles) => ({...styles, ...{fontSize: '17px'}}) 
            }}
            menuPlacement={"bottom"}
          />
        </Form.Control>
        
        <Form.Control>
          <SelectMultiple
            isDisabled={loading}
            isMulti 
            isSearchable
            noOptionsMessage={() => "Sin opciones"}
            onChange={(v) => onChangeHandler("gender", v)}
            options={filterOptions.gender || []}
            placeholder="Género"
            value={selected.gender}
            styles={{
              valueContainer: (styles) => ({...styles, ...{padding: "0px 8px 3px 8px"}}),
              placeholder: (styles) => ({...styles, ...{fontSize: '17px'}}),
              menu: (styles) => ({...styles, ...{fontSize: '17px'}}) 
            }}
            menuPlacement={"bottom"}
          />
        </Form.Control>
        
        <Form.Control>
          <SelectMultiple
            isDisabled={loading}
            isMulti 
            isSearchable
            noOptionsMessage={() => "Sin opciones"}
            onChange={(v) => onChangeHandler("category", v)}
            options={filterOptions.category}
            placeholder="Categoría"
            value={selected.category}
            styles={{
              valueContainer: (styles) => ({...styles, ...{padding: "0px 8px 3px 8px"}}),
              placeholder: (styles) => ({...styles, ...{fontSize: '17px'}}),
              menu: (styles) => ({...styles, ...{fontSize: '17px'}}) 
            }}
            menuPlacement={"bottom"}
          />
        </Form.Control>
        
        <Form.Control className="action-btn">
          <Button
            color="primary"
            disabled={
              loading || 
              (!selected.season || !selected.season.length) && 
              (!selected.brand || !selected.brand.length) && 
              (!selected.channels || !selected.channels.length) && 
              (!selected.gender || !selected.gender.length) && 
              (!selected.category || !selected.category.length)
            }
            fullwidth
            size="medium"
            style={{
              backgroundColor: "#002e7b"
            }}
            onClick={() => {
              toggleLoader(true);
              html2canvas(document.getElementById("charts")).then(canvas => {
                let fullQuality = canvas.toDataURL('image/jpg', 0.6);
                downloadPDF(
                  `<div style="width:100%; text-align:center"><img src="${fullQuality}" style="max-width:85%;"/></div>`
                );
                
              })
              .finally(() => setTimeout(() => toggleLoader(false), 1000));
            }}
          >
            Descargar PDF
          </Button>
        </Form.Control>

        <Form.Control className="action-btn">
          <Button
            className="underline"
            disabled={
              loading ||
              (!selected.season || !selected.season.length) && 
              (!selected.brand || !selected.brand.length) && 
              (!selected.channels || !selected.channels.length) && 
              (!selected.gender || !selected.gender.length) && 
              (!selected.category || !selected.category.length)
            }
            fullwidth
            onClick={async () => {
              toggleLoader(true);
              await downloadXLS(filters);
              setTimeout(() => toggleLoader(false), 1000);
            }}
            size="medium"
          >
            <FontAwesomeIcon icon={faDownload} /> Descargar XLS
          </Button>
        </Form.Control>
      </Columns.Column>
    </Columns>
  );
};

export default DashboardFilters;

