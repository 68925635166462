import React, { Component, createRef } from "react";
import Magnifier from "react-magnifier";
import ItemsService from "../../../../../Services/ItemsService";

import {
  Button,
  Card,
  Columns,
  Dropdown,
  Image,
} from "react-bulma-components/full";
import ModalCard from "../../../../General/Modal/ModalCard";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash, faCut, faExclamationTriangle, faMagic, faSpinner, faPencilAlt, faCross, faChevronCircleLeft, faSuperscript, faHeadset, faCertificate, faEye } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from 'react-confirm-alert';
import SupportEmailForm from "../../../../SupportEmail/SupportEmailForm";

class DetailsImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        show: false,
        size: "",
      },
      item: props.item,
      channels: props.channels.filter((ch) => ch.sku != null),
      cutLoading: false,
      selected: this.props.index,
      selectedChannel: props.item.channels.filter((ch) => ch.sku != null)[
        this.props.index
      ],
      selectedImage:
        (props.item.channels.filter((ch) => ch.sku != null)[this.props.index]
          .images.length === 0
          ? {
              url: "./images/misc/no_image.png",
              height: 0,
              width: 0,
              size: 0,
            }
          : props.item.channels.filter((ch) => ch.sku != null)[this.props.index]
              .images[0]),
      clickX: 0,
      clickY: 0,
      swatchView: false,
      indexSelected: null,
      zoomFactor: 1.5,
      cutLoading: false,
      adaptInProgress:false
    };

    this.displayInitialImage = (channels) => {
      if (channels[0].images.length === 0) {
        return {
          url: "./images/misc/no_image.png",
          height: 0,
          width: 0,
          size: 0,
        };
      } else {
        return channels[0].images[0];
      }
    };

    this.imageRef = createRef();

    this.itemService = new ItemsService();
  }

  closeModal = () => {
    let modal = {
      show: false,
    };

    this.setState({ modal: modal });
  };

  componentWillMount() {
    this.renderReviewButtons(
      this.props.item,
      this.props.item.channels.filter((ch) => ch.sku != null)[this.props.index]
    );
  }

  componentWillReceiveProps(newProps) {
    this.renderReviewButtons(
      newProps.item,
      newProps.item.channels.filter((ch) => ch.sku != null)[newProps.index]
    );

    this.setState({
      item: newProps.item,
      channels: newProps.item.channels.filter((ch) => ch.sku != null),
      selected: parseInt(newProps.index),
      selectedChannel: newProps.item.channels.filter((ch) => ch.sku != null)[
        newProps.index
      ],
      selectedImage:
        newProps.item.channels.filter((ch) => ch.sku != null)[newProps.index]
          .images.length === 0
          ? {
              url: "./images/misc/no_image.png",
              height: 0,
              width: 0,
              size: 0,
            }
          : newProps.item.channels.filter((ch) => ch.sku != null)[
              newProps.index
            ].images[0],
    });

    if (this.props.item.pc9 !== newProps.item.pc9) {
      this.setState({
        indexSelected: 0
      });
    }
  }

  selectedChannelDropdown = (index) => {
    let { channels, item } = this.state;
    this.renderReviewButtons(item, channels[index]);

    if (channels[index].images.length === 0) {
      this.setState({
        selected: index,
        selectedChannel: channels[index],
        selectedImage: {
          url: "./images/misc/no_image.png",
          height: 0,
          width: 0,
          size: 0,
        }
      });
    } else {
      this.setState({
        selected: index,
        selectedChannel: channels[index],
        selectedImage: channels[index].images[0]
      });
    }
  };

  selectedImage = async (index) => {
    await this.setState({
      indexSelected: index,
      selectedImage: this.state.selectedChannel.images[index],
      swatchView: false,
    });
  };

  renderImageMagnifier = (selectedChannel, selectedImage) => {
    let imageSrc = selectedImage.url;

    if (selectedImage.src !== undefined) {
      imageSrc = selectedImage.src;
    } else if (
      selectedChannel.channel === "master" &&
      selectedChannel.images.length > 0
    ) {
      imageSrc = selectedImage.thumbnail;
    }

    return (
      <Magnifier
        className={this.state.selectedChannel.channel === 'master_cut' ? 'bg-png' : ''}
        ref={this.imageRef}
        style={{
          border: "1px solid #D2D8D8",
        }}
        src={imageSrc}
        onMouseDown={this.updateClickPosition}
        zoomFactor={this.state.zoomFactor}
      />
    );
  };

  updateClickPosition = (e) => {
    this.setState({
      clickX: e.nativeEvent.offsetX,
      clickY: e.nativeEvent.offsetY,
    });
  };

  renderImages = (selectedChannel) => {
    let thumbnails = selectedChannel.images.map((img, index) => {
      return (
        <Columns.Column key={index} size={2}>
          <Image
            className="bg-png"
            style={{
              border: "1px solid #D2D8D8",
            }}
            src={img.thumbnail}
            onClick={() => this.selectedImage(index)}
          />
        </Columns.Column>
      );
    });

    return thumbnails;
  };

  approveImages = (channel) => {
    let status = "";

    if (channel === "master") {
      status = "status.current";
    } else if (channel === "mpguide") {
      status = "status.product_guide";
    }
   
      this.itemService
      .put(this.state.item._id, {
        co: [
          {
            [status]: "approved",
          },
        ],
      })
      .then((response) => {
        toast.success("Las fotos para " + channel + " han sido aprobadas");
        let item = response.d[0].ref;

        let master_cut = item.channels.filter(ch => {
          return ch.channel === 'master_cut';
        }).pop()

        if(!master_cut || master_cut.images.length === 0){
          this.itemService.generateCuts(this.state.item._id, this.state.item.channels[0].id_channel)
        }
        this.props.updateAll(item);
      });
    

    
    
  };

  openRejectImages = (e, index) => {
    let modal = this.state.modal;
    modal.title = "Eliminar las fotos";
    modal.size = "is-small";
    modal.body = (
      <div>
        <p style={{ textAlign: "center" }}>
          {" "}
          <b>Escoge una opción</b>
        </p>
        <br />

        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="info"
            fullwidth={false}
            onClick={(e) => {
              this.rejectChannelImages("waitingPhotos", index);
            }}
          >
            Corregir masters
          </Button>
        </div>
      </div>
    );

    this.setState(
      {
        modal: modal,
      },
      this.openModal()
    );
  };

  setPinCuts = (id) => {
    confirmAlert({
      message: 'Se enviará un correo a produccion@boicot.com.mx solicitando la corrección de los recortes, ¿deseas continuar?',
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            this.itemService.pinCuts(id).then((response) => {
              if (response && response.d !== undefined) {
                toast.success("Se ha notificado que los cortes requieren ajustes.");
              }
            })
            .catch(() => {
              toast.warn("Ocurrió un error, inténtalo nuevamente.");
            }); 
          }
        },
        {
          label: 'Cancelar'
        }
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  }

  rejectChannelImages = async (newStatus, index) => {
    let channelToReject = this.state.item.channels.filter((ch) => {
      return ch.sku != null;
    })[index];
    let channels = this.state.item.channels.filter((ch) => {
      return ch.sku != null;
    });
    let channelsToClean = [];
    let status = null;

    if (channelToReject.channel === "master") {
      for (let i = 0; i < channels.length; i++) {
        if (
          channels[i].channel !== "mpguide" &&
          channels[i].channel !== "pdp"
        ) {
          channelsToClean.push(channels[i]);
        }
      }
    } else if (channelToReject.channel === "mpguide") {
      for (let i = 0; i < channels.length; i++) {
        if (
          channels[i].channel === "mpguide" ||
          channels[i].channel === "pdp"
        ) {
          channelsToClean.push(channels[i]);
        }
      }
    }

    for (let i = 0; i < channelsToClean.length; i++) {
      await this.itemService.deleteImages(this.state.item._id, [
        {
          channel: channelsToClean[i].channel,
          keys: channelsToClean[i].images.map((element) =>
            element.key ? element.key : null
          ),
        },
      ]);
    }

    if (channelToReject.channel === "master") {
      status = "status.current";
    } else if (channelToReject.channel === "mpguide") {
      status = "status.product_guide";
    }

    await this.itemService
      .put(this.state.item._id, {
        co: [
          {
            swatch: null,
            [status]: newStatus,
          },
        ],
      })
      .then((response) => {
        let item = response.d[0].ref;
        this.props.updateAll(item);
        this.closeModal();
      })
      .catch(() => {
        toast.error(
          "Ocurrió un error al cambiar el estatus, vuelve a intentarlo."
        );
        this.closeModal();
      });
  };

  renderReviewButtons = (item, selectedChannel) => {
    let index = this.state.channels
      .filter((ch) => ch.sku != null)
      .map((ch) => {
        return ch.channel;
      })
      .indexOf(selectedChannel.channel);
    let channel = selectedChannel.channel;
    let buttons = (
      <div>
        {(this.props.loggedInUser.code === 'brand_manager' && item.channels[0].images.length && !this.isCuttable(item)) && selectedChannel.channel ==="master_cut"?
          <Button 
            onClick={() => this.setPinCuts(item._id)}
            title="Notificar problema con el corte"
            color="warning"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Button>
        : null }
        {(this.props.loggedInUser.code === 'brand_manager' && this.isCuttable(item)) && selectedChannel.channel ==="master" ?
          <Button 
            disabled={this.state.cutLoading}
            onClick={(e) => this.generateCuts()}
            title="Recortar masters"
            loading={this.state.cutLoading}
          >
            <FontAwesomeIcon icon={faCut} />
          </Button>
        : null }
        {(this.props.loggedInUser.code === 'brand_manager' && item.status.current === 'waitingPhotos') && selectedChannel.images.length>0 ?
          <Button 
            disabled={this.state.cutLoading}
            onClick={(e) => this.changeStatusItem(this.state.item._id, 'toReview')}
            title="Solicitar revisión"
            //loading={this.state.cutLoading}
            color="warning"
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
        : null }

      {(item.status.current === 'required' || item.status.current === 'pending' || item.status.current === 'urgent' || item.status.current === 'available' || item.status.current === 'assigned' || item.status.current === 'toShoot' ) && selectedChannel.channel ==="master" ?
          <Button 
            disabled={this.state.cutLoading}
            onClick={(e) => this.notifyError()} //sin nexStatus
            title="Notificar error"
            color="info"
          >
            Ayuda&nbsp;&nbsp;
            <FontAwesomeIcon icon={faHeadset} />
          </Button>
        : null }

        {(item.status.current === 'approved' ) && selectedChannel.channel ==="master" ?
          <Button 
            disabled={this.state.cutLoading}
            onClick={(e) => this.notifyError('toReview')}
            title="Notificar error"
            color="info"
          >
            Ayuda&nbsp;&nbsp;
            <FontAwesomeIcon icon={faHeadset} />
          </Button>
        : null }

        {((channel === "master" &&
          item.status.current === "toReview" &&
          selectedChannel.images.length > 0 &&
          this.props.loggedInUser.code === "brand_manager")
        ||
          (channel === "mpguide" &&
          item.status.product_guide === "toReview" &&
          selectedChannel.images.length > 0 &&
          this.props.loggedInUser.code === "brand_manager")
        ) ?
          <>
            <Button 
            disabled={this.state.cutLoading}
            onClick={(e) => this.notifyError('waitingPhotos')}
            title="Regresar imágenes"
            //loading={this.state.cutLoading}
            color="danger"
          >
            X
          </Button>
        
            <Button 
              color="success" 
              onClick={() => this.approveImages(channel)}
            >
              Aprobar
            </Button>
          </>
        : null }

          { selectedChannel.channel !== "master" && selectedChannel.channel !== "master_cut" && selectedChannel.images.length===0 ?
            <Button
            color="info"
            disabled={this.state.adaptInProgress}
            onClick={(e) => this.generateAdaptations(e,index,selectedChannel)}
          >
            {this.state.adaptInProgress ? <FontAwesomeIcon icon={faSpinner}/>: <FontAwesomeIcon icon={faMagic}/>}
          
            
          </Button>
        : null }

      
        {(
          ["master", "master_cut"].indexOf(channel) !== -1 &&
          item.status.current === "approved" &&
          selectedChannel.images.length > 0 &&
          this.props.loggedInUser.code === "brand_manager" &&
          selectedChannel.channel ==="master"
        ) ?
          <Button 
            onClick={(e) => this.openRejectImages(e, index)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        : null }
      </div>
    );

    this.setState({
      reviewButtons: buttons,
    });
  };

  closeModal = () => {
    let modal = {
      show: false,
    };
    this.setState({ modal: modal });
  };

  notifyError = (afterStatus)=>{
   
      let modal = this.state.modal;
      modal.title = 'Ayuda - Notificar error en PC9';
      modal.size = '';
      modal.body = 
      <SupportEmailForm
        changeStatusItem={this.changeStatusItem}
        closeModal={this.closeModal}
        idItem={this.state.item._id}
        newStatus={(afterStatus ? afterStatus : null)}
        pc9={this.state.item.pc9}
      />
      this.setState({
        modal: modal
      }, () => {
        this.openModal();
      });
    
  }


  changeStatusItem = (itemId, newStatus) => {
    this.itemService.put(itemId, {
      co: [{
        'status.current': newStatus
      }]
    })
      .then((response) => {
        toast.success('Cambio de estatus exitoso.');

        this.props.updateAll(response.d[0].ref);

      }).catch(() => {
        toast.error('Ocurrió un error al cambiar el estatus, vuelve a intentarlo.');
      });
  }

  generateAdaptations = async (e, index,selectedChannel) => {

    this.setState({adaptInProgress: true}, () => {
      this.renderReviewButtons(this.state.item, this.state.selectedChannel);
    });

    toast.info('Las adaptaciones para el canal '+ selectedChannel.channel+ ' han sido solicitadas, espera un momento')
    this.itemService.adaptations(
      {
        channel:selectedChannel.channel,
        channelId:selectedChannel.id_channel
      }, 
      this.state.item,
      'master_cut'
    )
    .then(resp=>{
      this.setState({
        adaptInProgress:false
      })
      this.props.getUpdateChannel(resp.d[0],index)
      toast.success('Adaptaciones generadas correctamente')
    })
    .catch(err=>{
      console.log(err)
    })

  }

  generateCuts = async () => {
      toast.info("El recorte está en proceso espera aquí para ver los resultados.");
      this.setState({cutLoading: true}, () => {
        this.renderReviewButtons(this.state.item, this.state.selectedChannel);
      });

      this.itemService.generateCuts(this.state.item._id, this.state.item.channels[0].id_channel)
      .then(async (response) => {
        if (response && response.d && response.d[0]) {
          toast.success("Recortes realizados con éxito.");
          let item = await this.itemService.getItemById(this.state.item._id);
          
          let chAllowed = item.channels.filter((ch => ch.sku != null));
          let masterCutIndex = 0;
          let masterCut;
          for (let i in chAllowed) {
            if (chAllowed[i].channel === 'master_cut') {
              masterCutIndex = i;
              masterCut = chAllowed[i];
              break;
            }
          }
          
          setTimeout(() => {
            this.setState({
              selected: masterCutIndex !== undefined ? masterCutIndex : this.state.selected,
              selectedChannel: masterCut !== undefined ? masterCut : this.state.selectedChannel,
              cutLoading: false
            }, () => {
              this.props.getUpdateChannel(item, (masterCutIndex !== null ? masterCutIndex : this.state.selected ));
            });
          }, 500);
            
        } else {
          toast.error("Ocurrió un error al generar los recortes.");
          this.setState({cutLoading: false}, () => {
            this.renderReviewButtons(this.state.item, this.state.selectedChannel);
          });
        }
      })
      .catch(() => {
        toast.error("Ocurrió un error al generar los recortes.");
        this.setState({cutLoading: false}, () => {
          this.renderReviewButtons(this.state.item, this.state.selectedChannel);
        })
      });
  }

  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({ modal: modal });
  };

  setSwatch = async () => {
    let { height, width } = this.imageRef.current.img;
    let { clickX, clickY } = this.state;

    let xPct = ((clickX / width) * 100).toFixed(2);
    let yPct = ((clickY / height) * 100).toFixed(2);
    
    let response = await this.itemService.swatch(this.props.item._id, {
      index: this.state.indexSelected === null ? 0 : this.state.indexSelected,
      xPct,
      yPct,
      zoomFactor: parseFloat(this.state.zoomFactor).toFixed(1),
      height,
      width
    });

    if (response && response.d && response.d[0] !== undefined) {
      this.props.updateAll(response.d[0]);
      this.setState({
        indexSelected: 0
      });
      toast.success("Swatch generado correctamente.");
    } else {
      toast.warn("Ocurrió un problema al generar el swatch, intenta nuevamente.");
    }
  };

  isCuttable = (item) => {
    let hasMasters = false;
    let hasCuts = false;
    
    for (let channel of item.channels) {
      if (channel.channel === 'master' && channel.images.length) hasMasters = true;
      if (channel.channel === 'master_cut' && channel.images.length) hasCuts = true;
    }
    
    return hasMasters && !hasCuts;
  };
    

  render() {
    return (
      <div className="details-images">
        <ModalCard
          title={this.state.modal.title}
          show={this.state.modal.show}
          body={this.state.modal.body}
          closeModal={this.closeModal}
          size={this.state.modal.size}
        />
        <Columns>
          <Columns.Column size={4}>
            <p>
              <b>Nombre de la imagen</b>
            </p>
            <p>{this.state.selectedImage.name}</p>
            <p style={{ fontSize: "12px" }}>
              {this.state.selectedImage.width || "-"} x
              {this.state.selectedImage.height || "-"} px |
              {this.state.selectedImage.size !== undefined
                ? this.state.selectedImage.size.toFixed(2)
                : "-"}{" "}
              MB
            </p>
          </Columns.Column>
          <Columns.Column
            className="header-images"
            size={8}
          >
            {this.props.loggedInUser.code === "brand_manager"
              ? this.state.reviewButtons
              : null}
            <Dropdown
              value={this.state.selected}
              onChange={this.selectedChannelDropdown}
            >
              {this.state.channels.map((channel, index) => {
                return (
                  <Dropdown.Item key={index} value={index}>
                    {channel.channel + " (" + channel.images.length + ")"}
                  </Dropdown.Item>
                );
              })}
            </Dropdown>
          </Columns.Column>

          <Columns.Column size={12}>
            <Card shadowless>
              <Card.Footer>
                <Card.Footer.Item renderAs={Columns}>
                  {this.renderImages(this.state.selectedChannel)}
                </Card.Footer.Item>
              </Card.Footer>
              <Card.Content>
                <ContextMenuTrigger id="swatchContextMenu">
                  {this.renderImageMagnifier(
                    this.state.selectedChannel,
                    this.state.selectedImage
                  )}
                </ContextMenuTrigger>
                <ContextMenu id="swatchContextMenu" hideOnLeave={true}>
                  <div
                    className={`total-zoom${
                      this.state.zoomFactor >= 2.5 || this.state.zoomFactor <= 1
                        ? " total-zoom-disable"
                        : ""
                    }`}
                  >
                    {this.state.zoomFactor >= 2.5 ? (
                      "Zoom al máximo"
                    ) : this.state.zoomFactor <= 1 ? (
                      "Zoom al minimo"
                    ) : (
                      <>
                        <p>Zoom Aplicado</p>
                        <span>
                          {Math.round(this.state.zoomFactor * 100)}%
                        </span>
                      </>
                    )}
                  </div>
                  <div>
                    {" "}
                    {/* Si no se envuelve detecta mal el hover del primer elemento*/}
                    <MenuItem
                      disabled={this.state.zoomFactor >= 2.5}
                      onClick={() =>
                        this.setState({
                          zoomFactor: (this.state.zoomFactor + 0.1),
                        })
                      }
                      preventClose={true}
                    >
                      <button className="context-button">
                        <span className="zoom-in"></span> Zoom{" "}
                      </button>
                    </MenuItem>
                  </div>
                  <MenuItem
                    disabled={this.state.zoomFactor <= 1}
                    onClick={() =>
                      this.setState({ zoomFactor: (this.state.zoomFactor - 0.1) })
                    }
                    preventClose={true}
                  >
                    <button className="context-button">
                      <span className="zoom-out"></span> Zoom
                    </button>
                  </MenuItem>
                  <MenuItem
                    disabled={this.state.swatchView || this.state.selectedChannel.channel === 'master_cut'}
                    onClick={this.setSwatch}
                  >
                    <span className="muestra-context">Generar swatch con la selección</span>
                  </MenuItem>
                </ContextMenu>
              </Card.Content>
            </Card>
          </Columns.Column>
        </Columns>
      </div>
    );
  }
}

export default DetailsImages;
