import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./carrusel.scss";

const Carrusel = ({ images }) => {
  const [index, setIndex] = useState(0);
  const [image, setImage] = useState("");
  const [touch, setTouch] = useState(0);
  const refScroller = useRef(null);

  useEffect(() => {
    if (images.length !== 0) setImage(images[index].thumbnail);
  }, [index, images]);

  const handleUp = () => {
    const { scrollTop } = refScroller.current;
    refScroller.current.scroll({ top: scrollTop + 90 });
    setIndex(index + 1);
  };
  const handleDown = () => {
    const { scrollTop } = refScroller.current;
    refScroller.current.scroll({ top: scrollTop - 90 });
    setIndex(index - 1);
  };

  const handleStart = (e) => {
    const { clientX } = e;
    e.persist();
    setTouch(clientX);
  };
  const handleEnd = (e) => {
    const { clientX } = e;
    e.persist();
    if (touch - clientX < -60) return setIndex(index !== 0 ? index - 1 : 0);
    if (touch - clientX > 60)
      return setIndex(index !== images.length - 1 ? index + 1 : index);
  };
  return (
    <>
      <div
        className="carrusel"
        onMouseDown={(e) => handleStart(e)}
        onMouseUp={(e) => handleEnd(e)}
      >
        {index !== images.length - 1 && (
          <FontAwesomeIcon
            icon={faChevronRight}
            className="carrusel__right"
            onClick={(e) => handleUp(e)}
          />
        )}
        <img src={image} alt="Product" className={`carrusel-img`} />
        {index !== 0 && (
          <FontAwesomeIcon
            icon={faChevronRight}
            className="carrusel__left"
            onClick={(e) => handleDown(e)}
          />
        )}
      </div>
      <div className="carrusel__dots">
        {images.map((props, indexC) => (
          <span
            key={indexC}
            className={`carrusel__dots-circle ${
              indexC === index ? "carrusel__dots-circle-active" : ""
            }`}
          />
        ))}
      </div>

      <div className="carrusel-mini" ref={refScroller}>
        {images.map(({ thumbnail }, indexC) => (
          <div
            key={indexC}
            className={`carrusel-mini__img ${
              indexC === index ? "carrusel-mini__img-active" : ""
            }`}
            onClick={() => {
              const { scrollTop } = refScroller.current;
              const diferencia = scrollTop + 90 * (indexC - index);
              refScroller.current.scroll({ top: diferencia });
              setIndex(indexC);
            }}
          >
            <img src={thumbnail} alt="Miniatura" />
          </div>
        ))}
      </div>
    </>
  );
};

export default Carrusel;
