import React, { Component } from 'react';

import ShootingService from '../../../Services/ShootingService';
import ItemsService from '../../../Services/ItemsService';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { toast } from 'react-toastify';

import moment from 'moment';

import {
  faEdit,
  faPlay,
  faStop,
  faTrash,
  faCalendarPlus,
  faArchive
} from '@fortawesome/free-solid-svg-icons';

import { faCamera } from '@fortawesome/free-solid-svg-icons';

import ModalCard from '../../General/Modal/ModalCard';
import Datatable from '../../General/Datatable/Datatable';
import UpdateShootingForm from './components/UpdateShootingForm';
import ShootingItems from './components/ShootingItems/ShootingItems';
import Loader from '../../General/Loader/Loader';

import { STATUS } from '../../General/Constants/Shooting';
import { SHOOTING } from '../../General/Language/es';
import ShootingEmptyRows from './components/ShootingEmptyRow';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { sortBy } from '../../General/Datatable/utils';
import AddToShootingV2 from './components/AddToShootingV2';


class ShootingTable extends Component {
  constructor(props) {
    super(props);
    this.tabIndex = React.createRef();

    this.state = {
      loggedInUser:this.props.loggedInUser,
      data: [],
      events: [
        {
          start: moment().toDate(),
          end: moment()
            .add(0, "days")
            .toDate(),
          title: "Some title"
        }
      ],
      loading: true,
      modal: {
        show: false
      },
      table: {
        actions: [{
          title:"Crear un nuevo shooting",
          icon: faCalendarPlus,
          label: 'Nuevo',
          className: 'is-info',
          onClick: () => this.createShooting()
        }],
        columns: [
          {
            label: 'Nombre',
            field: 'name',
            type: 'link',
            onClick: (e, shooting) => {
              this.detailShooting(shooting._id);
            },
            sortable: true
          },{
            label: 'Productora',
            field: 'producer',
            sortable: true
          },
          {
            label: 'Origen',
            field: 'origin',
            sortable: true
          },
          {
            label: 'Fecha',
            field: 'scheduled_date',
            format: ['MOMENT::DD/MM/YY'],
            sortable: true
          }, 
          {
            label: 'Entrega',
            field: 'delivery_date',
            format: ['MOMENT::DD/MM/YY'],
            sortable: true
          },
          // {
          //   label: 'Fotógrafo',
          //   field: 'photoman',
          //   sortable: true
          // }, 
          {
            field: 'itemsCount',
            label: '# Items',
            sortable: true
          }, {
            field: 'status',
            label: 'Estatus',
            sortable: true,
            type: 'status',
            dictionary: SHOOTING.STATUS
          }, {
            type: 'actions',
            label: 'Acciones',
            actions: [
              {
                title: 'Editar',
                icon: faEdit,
                onClick: (shooting) => {
                  this.editShooting(shooting._id);
                }
              }, {
                disabled: (shooting) => {

                  return false;
                },
                icon: faPlay,
                onClick: (shooting) => {
                  this.initShooting(shooting);
                },
                title: 'Iniciar shooting',

                visible: (shooting) => {

                  if (shooting.status === STATUS.INPROGRESS || shooting.status === STATUS.TERMINATED) {
                    return false;
                  } else {
                    return true;
                  }

                },
                color:'success'
              }, {
                title: 'Archivar',
                color:'default',
                icon: faArchive,
                onClick: (shooting) => this.stopShooting(shooting),
                visible: (shooting) => (shooting.status === STATUS.INPROGRESS)
              },  {
                title: 'Eliminar shooting',
                color:'danger',
                icon: faTrash,
                onClick: (shooting) => this.deleteShooting(shooting),
          
              }
            ]
          },
        ],
        emptyLegend: <ShootingEmptyRows />,
        filtersConfig: {
          status: {
            label: 'Estatus',
            dictionary: SHOOTING.STATUS
          },
          photoman: {
            label: 'Fotógrafo'
          },
          scheduled_date: {
            label: 'Fecha'
          }
        },
        searchFields: ['name'],
        loadingLegend: <Loader />
      },
      renderRows: [],
      queryString: {
        rf: ['_id', 'name', 'producer', 'origin','scheduled_date', 'delivery_date', 'status', '_items', "createdAt"],
        qf: {
          status: [STATUS.NEW, STATUS.INPROGRESS],
        }
      }
    };
    this.confirm = {
      title: <strong>Confirmar</strong>,
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onKeypressEscape: () => {}
    };

    this.service = new ShootingService();
    this.itemsService = new ItemsService();
  }

  closeModal = () => {
    let modal = {
      show: false
    };

    this.setState({
      modal: modal
    });
  }

  componentDidMount() {
    let queryString = this.state.queryString;

    this.setState({
      queryString: queryString
    }, () => {
      this.fetch();
    });
  }

  countItems = (shootings) => {
    return new Promise((resolveAll) => {
      let promises = [];
      for (let i in shootings) {
        promises.push(new Promise ((resolve, reject) => {
          this.itemsService.get({
            rf: ['_id'],
            qf: {
              shooting: [shootings[i]._id]
            },
            fd: {
              type: 'count'
            }
          }).then((response) => {
            shootings[i].itemsCount = response.d[0].count;
            resolve();
          });
        }));
      }

      Promise.all(promises).then(() => {
        resolveAll(shootings);
      })
    });
  }

  createShooting = () => {
    let modal = this.state.modal;

    modal.title = 'Crear Shooting';
    modal.body = (
      <AddToShootingV2
      closeModal={this.closeModal}
      loggedInUser={this.state.loggedInUser}
      checkList={[]}
      createNewShooting={true}
   
      
      seasonId={process.env.APP_DEFAULT_SEASON}
      getShootings={this.fetch}
    />

    );
    modal.show = true;
    this.setState({
      modal: modal
    });
  }

  deleteShooting = (shooting) => {
    let options = this.confirm;

    options.message = 'Eliminar shooting: ' + shooting.name;
    options.buttons = [
      {
        label: 'Aceptar',
        onClick: async () => {
          let response = await this.service.put({
            ol:[shooting._id],
            co: [{
              'status': STATUS.DELETED
            }]
          });

          for (let iErr in response.error) {
            if (response.error[iErr].code === 0) {
              toast.success(SHOOTING.DELETE_SUCCESS);
              this.fetch();
            } else {
              toast.error(response.error[iErr].message);
            }
          }
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  detailShooting = (shootingId) => {
    this.service.getById(shootingId).then((response) => {
      let data = response.d[0];
      if (data) {
        this.props.addTab({
          label: 'Shooting: ' + data.name,
          icon: faCamera,
          content: ShootingItems,
          props: {
            type:data.type,
            closeTab: true,
            shooting: data,
            initShooting: this.initShooting,
            detailShooting: this.detailShooting,
            loggedInUser:this.state.loggedInUser
          }
        });
      }
    });
  }

  editShooting = (shootingId) => {
    let modal = this.state.modal;
    modal.size = '';
    modal.title = 'Editar info de shooting'
    modal.show = true;
    modal.body = (
      <UpdateShootingForm
        shootingId={shootingId}
        close={this.closeModal}
        fetch={this.fetch}
      />
    );

    this.setState({
      moda: modal
    });
  }

  fetch = async() => {
    let response = await this.service.get(this.state.queryString);

    this.countItems(response.d)
      .then((shootings) => {
        //console.log(this.state.loggedInUser)
        let filteredShootings = shootings
        let producerUser = this.state.loggedInUser.producer
        if (producerUser !== null){
          if(producerUser === 'boicot'){
            filteredShootings = shootings.filter((shooting) => shooting.producer === 'boicot');
          } else if(producerUser === 'curiosity'){
            filteredShootings = shootings.filter((shooting) => shooting.producer === 'curiosity');
          }
        }
        this.setState({
          data: sortBy(filteredShootings, {
            field: "createdAt",
            order: "desc"
          }),
          loading: false
        });
      });
  }

  initShooting = (shooting) => {
    let options = this.confirm;

    options.message = 'Iniciar shooting: ' + shooting.name;
    options.buttons = [
      {
        label: 'Aceptar',
        onClick: async () => {
          let response = await this.service.put({
            ol: [shooting._id],
            co: [{
              'status': STATUS.INPROGRESS
            }]
           });

          for (let iErr in response.error) {
            if (response.error[iErr].code === 0) {
              toast.success(SHOOTING.INIT.SUCCESS);
              this.detailShooting(shooting._id);
            } else {
              toast.error(SHOOTING.INIT.ERROR);
            }
          }
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }

  onChangeDatatable = (state) => {
    let {renderRows, checkList} = state;

    this.setState({
      checkList: checkList,
      renderRows: renderRows
    });
  }

  stopShooting = (shooting) => {
    let options = this.confirm;

    options.message = 'Archivar shooting: ' + shooting.name;
    options.buttons = [
      {
        label: 'Archivar',
        onClick: async () => {
          let response = await this.service.put({
            ol:[shooting._id],
            co: [{
              'status': STATUS.TERMINATED
            }]
          });

          for (let iErr in response.error) {
            if (response.error[iErr].code === 0) {
              toast.info('Archivado');
              this.fetch();
            } else {
              toast.error(response.error[iErr].message);
            }
          }
        }
      }, {
        label: 'Cancelar'
      }
    ];
    confirmAlert(options);
  }


  render() {
    return (
      <div >
        <ModalCard
          {...this.state.modal}
          closeModal={this.closeModal}
        />

        <Datatable
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
          onChange={this.onChangeDatatable}
        />
      </div>
    );
  }
}

export default ShootingTable;
