import React, { useState, useEffect } from 'react';
import ChannelsService from '../../../Services/ChannelsService';
import ItemService from '../../../Services/ItemsService';


import {
    Button,
    Progress,
    Form,
    Tag
  } from "react-bulma-components/full";
  import {
    faSpinner,
  } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { toast } from 'react-toastify';
  const channelsService = new ChannelsService();
  const itemService = new ItemService();


  const DeleteAdaptations=(props)=>{
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedChannel, setSelectedChannel] = useState('default')
    const [isDeleting, setIsDeleting] = useState(false)
    const [totalSeason, setTotalSeason] =useState(0)
    const [progress, setProgress] = useState(0)
    const channelsToAvoid =['master', 'mpguide', 'master_cut', 'pdp']

    const catchError = () => {
        toast.warn('Intentalo nuevamente.', '¡Ocurrió un error!');
        props.close();
      };


      useEffect(() => {
        if (loading) {
          getChannels();
        }
      }, []);

    const getChannels = async () => {
        let channelResp = await channelsService.get()
          .catch(() => catchError());
    
        if (channelResp.d) {
            //console.log(channelResp.d)
            let auxChannels =[]
            for (let i = 0;i<channelResp.d.length;i++){
                if(channelsToAvoid.indexOf(channelResp.d[i].name)===-1){
                    auxChannels.push(channelResp.d[i])
                }
            }
          setChannels(auxChannels);
          setLoading(false)
        } else {
          catchError();
        }
    
      };

const handleSelect=(e)=>{
    //console.log(e.target.value)
    setSelectedChannel(e.target.value)
}

const deleteFrom=async(e)=>{
    setIsDeleting(true)
    //console.log(selectedChannel)


    for (let i= 0; i < props.checkList.length; i++) {
      let item = await itemService.getItemById(props.checkList[i])
      
      let chann = item.channels.filter(channel=>  channel.channel === selectedChannel)
      if(chann.length > 0 && chann[0].sku !== null && chann[0].images.length>0){
           await itemService.deleteImages(item._id, [{
              channel: selectedChannel,
              keys: chann[0].images.map((element) => (element.key ? element.key : null))
            }]);
      }
      setProgress(i)
    }
    
      toast.success('Se han eliminado las adaptaciones de: '+selectedChannel)
      props.close()
    
}

    return(
        <div >
            <div style={{display:'flex',alignItems:'baseline', justifyContent:'space-around' }}>
            <strong>PC9 Seleccionados</strong>&nbsp;&nbsp;
            <Tag.Group className="label">
            <Tag color="info">{props.checkList.length}</Tag>
            <Tag>PC9</Tag>
            </Tag.Group>
                
            </div><br/>
           
            <div style={{display:'flex',alignItems:'baseline', justifyContent:'space-around' }}>
            <strong>Adaptaciones a borrar</strong>&nbsp;&nbsp;
            <Form.Select onChange={e=>handleSelect(e)} name="channel" value={selectedChannel}>
                    <option value="default" disabled>Canal</option>

                    {channels.map((element, index) => {
                      return (
                        <option value={element.name} key={index} >{element.name}</option>
                      );
                    })}
                  </Form.Select>
                
            </div><br/>
            {
                 isDeleting ?

            <div>
            <Progress
                        max={props.checkList.length}
                        value={progress}
                        color={props.checkList.length === progress ? 'success':'warning'}
                        size="large"
                        style={{ marginBottom: '0rem' }}
                      /><br/>

            </div>

                :
                null
             }
            
            <div style={{display:'flex',alignItems:'center', justifyContent:'space-around' }}>
            <Button color='danger' disabled={selectedChannel==='default' || isDeleting ? true : false || props.checkList.length === 0} onClick={e=>deleteFrom(e)}>
                {isDeleting ? <FontAwesomeIcon spin={true} icon={faSpinner} /> :'Eliminar'}
            </Button>

            </div>

        </div>
    )
  }

  export default DeleteAdaptations;

