import React, { Component } from 'react';
import './style.scss'
import AuthService from '../../Services/AuthService';
import {
  Link
} from "react-router-dom";
import {
  Container,
  Columns,
  Heading,
  Form,
  Button
} from "react-bulma-components/full";

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        password: "",
        code:"",
        first_name:"",
        last_name:"",
        job_title:"",
      }
    };

    this.authService = new AuthService();
  }

  handleChange = (event) => {
    const {user} = {...this.state};
    let current = user;
    const {name, value} = event.target;
    
    current[name] = value;
    
    this.setState({user: current});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.authService.signup({
      co: [{
        email:this.state.user.email,
        password:this.state.user.password,
        status:'active',
        code:'commercial',
        account:{
          first_name:this.state.user.first_name,
          last_name:this.state.user.last_name,
          job_title:'Comercial'
        }
      }]
    }).then(response => {
      console.log(response)
      for (let i in response.errors) {
        if (response.errors[i].code !== 0) {
          return;
        }
      }
      this.setState({
        user: {
          email: "",
          password: "",
          code:"",
          first_name:"",
          last_name:"",
          job_title:"",
        }
      })
      //this.props.setUser(response.data);
    });
  }

  render() {
    return (
      <Container className="has-text-centered">
        <Columns>
          <Columns.Column size={4} offset={4}>
            <Heading size={3} className="title has-text-grey">Sign Up</Heading>
            <div className="box">
              <form onSubmit={this.handleSubmit}>

              <Form.Field>
                  <Form.Control>
                    <Form.Input
                      autoComplete="off"
                      value={this.state.user.first_name}
                      name="first_name"
                      placeholder="First name"
                      onChange={this.handleChange}
                      required
                    >
                    </Form.Input>
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Control>
                    <Form.Input
                      value={this.state.user.last_name}
                      name="last_name"
                      placeholder="Last name"
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                    >
                    </Form.Input>
                  </Form.Control>
                </Form.Field>


                <Form.Field>
                  <Form.Control>
                    <Form.Input
                      value={this.state.user.email}
                      name="email"
                      placeholder="Email"
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                    >
                    </Form.Input>
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Control>
                    <Form.Input
                      type="password"
                      value={this.state.user.password}
                      name="password"
                      placeholder="Password"
                      autoComplete="off"
                      onChange={this.handleChange}
                    >
                    </Form.Input>
                  </Form.Control>
                </Form.Field>

                <Button color="success" className="is-block is-fullwidth">Sign Up</Button>
              </form>

            </div>
            <p className="has-text-grey">
              <Link to="/login">Login</Link> &nbsp;·&nbsp;
              <Link to="/forgot">Forgot password</Link>
            </p>
            <br />
            <p>Phototool v{this.props.version}</p>
          </Columns.Column>
        </Columns>
      </Container>
    );
  }
};

export default Signup;
