import React, { Component } from 'react';
import './style.scss'
import AuthService from '../../Services/AuthService';
import {
  Link
} from "react-router-dom";

import ModalCard from '../General/Modal/ModalCard';

import {
  Container,
  Columns,
  Heading,
  Form,
  Button
} from "react-bulma-components/full";


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        password: "",
      },
      validCredentials: true,
      modal:{},
      accepted:false
    };

    this.authService = new AuthService();
  }
  closeModal = () => {
    let modal = {
      show: false
    };
    
    this.setState({ modal: modal });
  }

  openModal = () => {
    let modal = this.state.modal;
    modal = {
      title:'Condiciones de uso Phototool',
      body:<div>Las imágenes que se encuentran en Phototool son de uso exclusivo para eCommerce, 
        cualquier uso distinto a este tiene que ser solicitado por escrito al equipo de eCommerce de LS&Co
        </div>,
      footer:'Equipo eCommerce LS&CO',
      size: 'is-small'
    }
    modal.show = true;
    this.setState({ modal: modal });
  }

  handleChange = (event) => {
    const { user } = { ...this.state };
    let current = user;
    const { name, value } = event.target;

    current[name] = value;
    this.setState({ user: current, validCredentials: true });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.authService.login(this.state.user)
      .then(response => {
        //Envía el objeto user al parent
        if (response) {
          this.props.setUser(response.d[0])
        } else if (response === undefined) {
          this.setState({
            validCredentials: false
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleCheckbox =(e)=>{
    this.setState({
      accepted: !this.state.accepted
    })
    
  }
  render() {
    return (
      <Container className="has-text-centered">
        <ModalCard
          title={this.state.modal.title}
          footer={this.state.modal.footer}
          show={this.state.modal.show}
          body={this.state.modal.body}
          closeModal={this.closeModal}
          size={this.state.modal.size}
        />
        <Columns>
          <Columns.Column size={4} offset={4}>

            <div className="box">
              <div className="avatar">
                <figure className="avatar">
                  <img style={{ width: '150px' }} src="/images/camera_icon.png" alt="Phototool Levi's" className="avatar-circle" />
                </figure>
              </div>
              <Heading size={3} className="title has-text-grey">Inicia sesión</Heading>
              <form onSubmit={this.handleSubmit}>
                <Form.Field>
                  <Form.Control>
                    <Form.Input
                      autoComplete="off"
                      value={this.state.user.email}
                      name="email"
                      placeholder="E-mail o usuario"
                      onChange={this.handleChange}
                      required
                    >
                    </Form.Input>
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Control>
                    <Form.Input
                      type="password"
                      value={this.state.user.password}
                      name="password"
                      placeholder="Contraseña"
                      autoComplete="off"
                      required
                      onChange={this.handleChange}
                    >
                    </Form.Input>
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Control>
                    <Form.Checkbox 
                    checked={this.state.accepted}
                    onChange={this.handleCheckbox}
                    >
                      &nbsp;&nbsp; Acepto las <a onClick={this.openModal}>condiciones de uso </a>de la plataforma
                    </Form.Checkbox>
                  </Form.Control>
                </Form.Field>

                {this.state.validCredentials === true ? <div></div> : <div><p className="help is-danger">E-mail o contraseña inválidos</p><br /></div>}
                <Button color="info" className="is-block is-fullwidth" disabled={!this.state.accepted}>Entrar</Button>
              </form>

            </div>
            <p className="has-text-grey">
              <Link to="/forgot">Olvidé mi contraseña</Link>
              <br />
              <span>Phototool v{this.props.version}</span>
            </p>
          </Columns.Column>
        </Columns>
      </Container>
    );
  }
};

export default Login;
