
import React from 'react';
import { 
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import {
  Section,
  Hero
} from "react-bulma-components/full";

import Login from '../../Account/Login';
import Signup from '../../Account/Signup';
import Forgot from '../../Account/Forgot';
import Restore from '../../Account/Restore';
import CurbsideData from '../Curbside/CurbsideData/CurbsideData'

const Logoff = (props) => {

  return (
    <Section className="hero success fullheigth">
      <Hero.Body>
        <Router>
          <Switch>
            <Route 
              exact 
              path="/login" 
              render={() => (<Login {...props} />)}/>
            <Route
              exact
              path="/X8HjY7H" 
              render={() => (<Signup {...props} />)}
            />
            <Route 
              exact 
              path="/forgot" 
              render={() => (<Forgot {...props} />)}
            />
            <Route 
              exact
              path="/restore/:token" 
              render={(localProps) => (<Restore {...localProps} {...props} />)}
            />
            <Route 
               
              path="/info/:pc9" 
              render={() => (<CurbsideData />)}
            />
            <Route  
              path="/" 
              render={() => (<Login {...props} />)}/>
          </Switch>
        </Router>
      </Hero.Body>
    </Section>
  );
};

export default Logoff;
