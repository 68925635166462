import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Form
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';

function THeader(props) {
  const [className, setClassName] = useState([]);

  const setSort = (column) => {
    let order = props.order === 'desc' || props.order === null? 'asc' : 'desc';
    props.onChangeSort({
      sort: column.field,
      order: order
    });
  }

  const getSortableIcon = (index) => {
    let column = props.columns[index];

    if (column.sortable !== false && column.sortable !== undefined) {
      let icon = faSort;

      if (column.field === props.sort) {
        switch (props.order) {
          case 'asc':
            icon = faSortUp;
            break;
          case 'desc':
            icon = faSortDown;
            break;
          default:
            icon = faSort;
            break;
        }
      }
      
      return (
        <Button 
          size="small" 
          color="white" 
          className="is-marginless is-paddingless"
          onClick={() => setSort(column)}
        >
          <FontAwesomeIcon icon={icon}/>
          &nbsp;
        </Button>
      );
    }
  }
  
  useEffect(() => {
    let classN = [];
    props.columns.forEach((column, key) => {
      let tmp = '';
      if (column.type === 'icon') {
        tmp += ' iconType';
      }

      if (column.type === 'checklist') {
        tmp += ' checkType';
      }

      classN[key] = tmp;
    });
    
    setClassName(classN);
  }, []);

  return (
    <thead>
      <tr>
      {props.columns.map((column, index) => {
        let label = '';
        if (typeof column === 'string') {
          label = column;
        } else if (typeof column === 'object') {
          switch (column.type) {
            case 'checklist':
              label = (
                <Form.Checkbox 
                  onChange={() => props.checkAllToggle(column)} 
                  checked={props.checkAll}
                />
              );
              break;
            case 'cell':
            default:
              label = column.label;
              break;
          }
        }
    
        return (
          <th 
            className={className[index]}
            key={index}
          >
            {getSortableIcon(index)}
            {label}
          </th>
        );
      })}
      </tr>
    </thead>
  );
};

THeader.propTypes = {
  columns: PropTypes.array,
  checkAll: PropTypes.bool,
  checkAllToggle: PropTypes.func,
  onChangeSort: PropTypes.func,
  order: PropTypes.string,
  sort: PropTypes.string
};

THeader.defaultProps = {
  columns: [],
  checkAll: false,
  checkAllToggle: () => {},
  onChangeSort: () => {},
  order: null,
  sort: null
};

export default THeader;
