export const MASTER = {
  UPDATE_SUCCESS: 'Los datos se han actualizado con éxito',
  SUCCESS: 'Item(s) enviados a búsqueda correctamente.',
  ERROR: 'Ocurrió un error al enviar el(los) item(s) a búsqueda.'
};

export const BATCH = {
  SET_ISFOUND: ' item(s) será(n) asignado(s) como ENCONTRADO y será removido de la tabla de pendientes.',
  SET_REQUIRED: ' item(s) será(n) asignado(s) como REQUERIDOS y será removido de la tabla de pendientes.',
  SET_PENDING:' item(s) será(n) marcados(s) como PENDIENTES y será removido de la papelera',
  SET_DROP:' item(s) será(n) marcados(s) como DROP y serán enviados a la papelera',
  ISFOUND: {
    SUCCESS: 'Los items han sido marcados como ENCONTRADOS.',
    ERROR: 'Ocurrió un error al marcar los items como ENCONTRADOS.'
  },
  NOTFOUND: {
    SUCCESS: 'Los items han sido marcados como NO DISPONIBLES.',
    ERROR: 'Ocurrió un error al marcar los items como NO DISPONIBLES.'
  }
};

export const CHANNELS = {
  CREATE: {
    SUCCESS: 'El canal ha sido creado con éxito.',
    ERROR: 'Ocurrió un error al crear el canal.'
  },
  UPDATE: {
    SUCCESS: 'El canal ha sido actualizado con éxito.',
    ERROR: 'Ocurrió un error al actualizar el canal.'
  }
};

export const SEASON = {
  CREATE: {
    SUCCESS: 'La temporada ha sido creada con éxito.',
    ERROR: 'Ocurrió un error al crear la temporada.'
  },
  UPDATE: {
    SUCCESS: 'La temporada ha sido actualizada con éxito.',
    ERROR: 'Ocurrió un error al actualizar la temporada.'
  }
};

export const SHOOTING = {
  STATUS: {
    'deleted': 'Eliminado',
    'inProgress': 'Activo',
    'new': 'Nuevo',
    'terminated': 'Terminado',
  },
  CREATE: {
    SUCCESS: 'El shooting ha sido creado con éxito.',
    ERROR: 'Ocurrió un problema al crear el shooting.'
  },
  DELETE: {
    SUCCESS: 'El shooting ha sido eliminado con éxito.',
    ERROR: 'Ocurrió un problema al eliminar el shooting.'
  },
  INIT: {
    SUCCESS: 'El shooting se ha iniciado.',
    ERROR: 'Ocurrió un problema al iniciar el shooting.'
  },
  UPDATE: {
    SUCCESS: 'El shooting ha sido editado con éxito.',
    ERROR: 'Ocurrió un problema al actualizar el shooting.'
  },
  ADDTO: {
    SUCCESS: 'Se han agregado los items al shooting con éxito.',
    ERROR: 'Ocurrió un problema al agregar los items al shooting.'
  }
};

export const PROFILE = {
  CHANGE_PROFILE_SUCCESS: 'Los cambios de tu perfil han sido realizados con éxito.',
  CHANGE_PASSWORD_SUCCESS: 'El cambio de contraseña ha sido realizado con éxito.'
};

export const PENDING_ITEM = {
  SET_DELETE: '¿Deseas solicitar nuevamente la búsqueda del item?',
  DELETED_ITEM_SUCCESS: 'El item será localizado en almacén',
  DELETED_ITEM_ERROR: 'Ha ocurrido un error, ponte en contacto con soporte'
};
  
export const SHOOTING_ITEM = {
  SET_DELETE: '¿Estás seguro de eliminar el item del shooting?',
  DELETED_ITEM_ERROR: 'Ha ocurrido un error, ponte en contacto con soporte',
  SEARCH_AGAIN: 'Este PC9 será marcado como URGENTE y volverá a ser solicitada su búsqueda en almacén',
  SEARCH_AGAIN_SUCCESS: 'El PC9 ha sido removido de este shooting y se ha solicitado su búsqueda en almacén',
  PREV_STATUS: 'Este PC9 regresará al status anterior, deseas continuar?',
  PREV_STATUS_SUCCESS: 'El PC9 ha regresado al estatus anterior',
  DELETED_ITEM_SUCCESS: 'El Item ha sido desasociado al shooting con éxito.'
};

export const CSV = {
  UPLOAD: {
    CREATE: ' elementos creados con éxito.',
    ERROR: ' elementos tuvieron problemas.',
    SKIP: ' elementos no alterados.',
    UPDATE: ' elementos actualizados con éxito.'
  }
};

export const LOGS = {
  COMMENT: {
    CREATE_SUCCESS: 'Tu comentario ha sido enviado.',
    CREATE_ERROR: 'Ocurrió un problema al enviar tu comentario.'
  },
  RESPONSE: {
    CREATE_SUCCESS: 'Tu respuesta ha sido enviada',
    CREATE_ERROR: 'Ocurrió un problema al enviar tu respuesta.'
  }
};

export const ITEMS = {
  STATUS: {
    'assigned': 'Asignado a shooting',
    'approved': 'Fotos aprobadas',
    'available': 'Por asignar a shooting',
    'pending': 'Buscando muestra',
    'required': 'DROP',
    'toReview': 'Por revisar',
    'toShoot': 'Por shootear',
    'unavailable': 'No disponible',
    'urgent': 'Buscando muestra urgente',
    'waitingPhotos': 'Esperando fotografía',
    'rejected': 'No aprobado'
  },
  STATUSCOPY: {
    'approved': 'Copy aprobado',
    'toReview': 'En revisión',
  }
}

export const ACTIVITY = {
  EMPTY: 'Sin registro de actividad.'
};

export const QUOTATIONS ={
  STATUS_BM:{
    'pending': '-',
    'sent': 'Nueva solicitud',  
    'answered': 'En aprobación', 
    'accepted': 'Aceptada',
    'rejected': 'Rechazada', 
    'expired': 'Caducada', 
    'eliminated': 'Eliminada'
  },
  STATUS_LATAM:{
    'pending': 'Nueva',
    'sent': 'Procesando',  
    'answered': 'Por aceptar', 
    'accepted': 'Autorizada',
    'rejected': 'Rechazada', 
    'expired': 'Caducada', 
    'eliminated': 'Eliminada'
  }
}