import React, {Component} from 'react';
import PropTypes from 'prop-types';

import CommentItem from './CommentItem';
import CommentItemEmpty from './CommentItemEmpty';
import {ACTIVITY} from '../../../../../General/Language/es';

class CommentList extends Component{
  constructor(props) {
    super(props);

    this.state = {
      logs: props.logs
    };
  }

  componentDidMount() {
    this.orderDatetime(this.props.logs);
  }

  componentWillReceiveProps(props) {
    this.orderDatetime(props.logs);
  }

  orderDatetime = (logs) => {
    logs.sort(function (a, b) {     
      return a.created_at > b.created_at ? -1 : 1;
    });

    this.setState({
      logs: logs
    });
  }

  render() {
    return(
      <div>
        {this.state.logs.length ?
          this.state.logs.map((log) => {
            return (
              <CommentItem 
                log={log} key={log._id} 
                onCommentActive={this.props.onCommentActive}
                onChange={this.props.onChange}
              />
            );
          })
        :
          <CommentItemEmpty leyend={ACTIVITY.EMPTY} />
        }
      </div>
    );
  }
}

CommentList.propTypes = {
  collapsed: PropTypes.bool
};

CommentList.defaultProps = {
  collapsed: true
};


export default CommentList;