import React from "react";

import { ReactComponent as Lost } from "../assets/404.svg";
import "./notFound.scss";

const NotFound = () => {
  return (
    <div className="notFound">
      <p className="notFound__ops mobile">
        <span>Oops...!</span> No encontramos lo que estabas buscando
      </p>
      <div className="notFound__error">
        <h1>404</h1>
        <Lost className="notFound__svg" />
      </div>
      <div className="notFound__info">
        <p className="notFound__ops tablet">
          <span>Oops...!</span> No encontramos lo que estabas buscando
        </p>
        <p className="notFound__buscar">
          Pero puedes visitar otros productos en nuestra pagina
        </p>
        <a href="https://www.levi.com.mx/" className="notFound__Ir">
          Ir al sitio
        </a>
      </div>
    </div>
  );
};

export default NotFound;
