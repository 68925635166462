import React, { useState, useEffect } from 'react';
import moment from 'moment';

import ShootingsService from '../../../Services/ShootingService';

import CreateShootingForm from './components/CreateShootingForm';
import ModalCard from '../../General/Modal/ModalCard';
import ShootingItems from './components/ShootingItems/ShootingItems';

import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';


import { STATUS } from '../../General/Constants/Shooting';
import './styles.scss';
import AddToShootingV2 from './components/AddToShootingV2';
import { toast } from 'react-toastify';

require('moment/locale/es.js');

const localizer = momentLocalizer(moment);
const shootingService = new ShootingsService();

const CalendarShootings = (props) => {

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [shootingList, setShootingList] = useState([]);
  const [modal, setModal] = useState({ show: false });

  const getShootings = async () => {
    let response = await shootingService.get({
      rf: ['_id', 'name', 'type', 'scheduled_date', 'delivery_date', 'producer'],
      qf: {
        season: process.env.APP_DEFAULT_SEASON,
        status: [STATUS.NEW, STATUS.INPROGRESS]
      }
    });
   // console.log(response.d)
    //console.log(props.loggedInUser)
    let filteredShootings = response.d
    let producerUser = props.loggedInUser.producer
    if (producerUser !== null){
      if(producerUser === 'boicot'){
        filteredShootings = response.d.filter((shooting) => shooting.producer === 'boicot');
      } else if(producerUser === 'curiosity'){
        filteredShootings = response.d.filter((shooting) => shooting.producer === 'curiosity');
      }
    }
    //console.log(filteredShootings)

    setShootingList(filteredShootings);
    shootingsToCalendarEvents(filteredShootings);
  };


  const shootingsToCalendarEvents = (shootings) => {
    let auxEvents = [];

    for (let i = 0; i < shootings.length; i++) {
      auxEvents.push({
        start: shootings[i].scheduled_date,
        end: shootings[i].scheduled_date,
        title: shootings[i].name,
        idShooting: shootings[i]._id,

      });
    }

    setCalendarEvents(auxEvents);
  };

  const openModalCreateShooting = (e) => {
    let today = new Date(new Date()-86400000) 
    
    if (today <= e.start){

      let auxModal = {};
      auxModal.title = 'Crear shooting';
      auxModal.body = (
        <AddToShootingV2
          closeModal={() => setModal({ show: false })}
          start={e.start}
          checkList={[]}
          createNewShooting={true}
          end={e.end}
          fromCalendar={true}
          seasonId={process.env.APP_DEFAULT_SEASON}
          getShootings={getShootings}
          loggedInUser={props.loggedInUser}
        />
      );
      auxModal.show = true;
      setModal(auxModal);
    }
     else{

      toast.warning('Selecciona una fecha a partir de hoy para crear un shooting')
    }

  };

  const detailShooting = (shooting) => {
    shootingService.getById(shooting.idShooting).then((response) => {
      let data = response.d[0];
      if (data) {
        props.addTab({
          label: 'Shooting: ' + data.name,
          icon: faCamera,
          content: ShootingItems,
          props: {
            type: data.type,
            closeTab: true,
            shooting: data,
            loggedInUser: props.loggedInUser
          }
        });
      }
    });
  };


  useEffect(() => {
    if (shootingList.length === 0) {
      getShootings()
    }
  });


  return (
    <div>
      <ModalCard
        {...modal}
        closeModal={() => setModal({ show: false })}
      />
      <div style={{ height: "500px" }}>
        <Calendar
          selectable
          localizer={localizer}
          events={calendarEvents}
          defaultView={Views.MONTH}
          scrollToTime={new Date(2015, 1, 1, 6)}
          defaultDate={new Date()}
          views={false}
          minDate={new Date()}
          onSelectEvent={e => detailShooting(e)}
          onSelectSlot={e => openModalCreateShooting(e)}
 
          messages={{
            next: "NEXT",
            previous: "PREV",
            today: "HOY",
            month: "Mes",
            week: "Semana",
            day: "Día"
          }}
        />
      </div>
    </div>
  )
}

export default CalendarShootings;
