import React, { Component } from 'react';

class AvailableEmptyRows extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return(
      <p>Aún no existen items encontrados para asignar a un shooting.</p>
    );
  }
}

export default AvailableEmptyRows;
