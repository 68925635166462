import React from 'react';

import ButtonCell from './ButtonCell';

function ActionsCell(props) {
  
  const checkVisible = (element) => {
    let visible = true;
    if (element.visible) {
      if (typeof element.visible === 'function') {
        visible = element.visible(props.row);
      } else {
        visible = element.visible;
      }
    }

    return visible;
  }

  const checkDisabled = (element) => {
    let disabled = false;
    if (element.disabled) {
      if (typeof element.disabled === 'function') {
        disabled = element.disabled(props.row);
      } else {
        disabled = element.disabled;
      }
    }

    return disabled;
  }

  
  return (
    <div  className="buttons">
      {props.column.actions.map((element, index) => {
        if (checkVisible(element)) {
          return(
            <ButtonCell
              color={element.color}
              disabled={checkDisabled(element)}
              title={element.title}
              icon={element.icon}
              key={index}
              onClick={(e) => element.onClick(props.row, e)} 
              size="small"
            />
          );
        }
        return null;
      })}
    </div>
  );
}

export default ActionsCell;