import axios from 'axios';
import qs from 'qs';

class LogsService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/activityLog`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      },
    });
    
    this.service = service;
  }

  get = (query) => {
    return this.service.get('/', {params: query})
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
}

export default LogsService;