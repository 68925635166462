import React from "react";
import moment from "moment";
import { Columns } from "react-bulma-components";

const InfoHeader = ({ loggedInUser }) => {
  return (
    <Columns className="d-header">
      <Columns.Column size={5}>
        <div className="is-size-3 has-text-weight-bold">LEVI STRAUSS & CO.</div>
      </Columns.Column>
      <Columns.Column centered="true" size={7} className="is-size-5">
      {loggedInUser.email} consultó el {moment().format("DD/MMM/YYYY HH:mm").toUpperCase()}
      </Columns.Column>
    </Columns>
  );
};

export default InfoHeader;
