import React from 'react';

function SubCell(props) {
  
	
  const RenderAs = props.renderAs;

  return (
    <RenderAs 
      {...props}
      className={props.className}
    >
      {props.label}
    </RenderAs>
  );
}

SubCell.defaultProps = {
  renderAs: 'span'
}


export default SubCell;