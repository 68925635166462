export const STATUS = {
  NEW: 'new',
  DELETED: 'deleted',
  INPROGRESS: 'inProgress',
  TERMINATED: 'terminated'
};


export const TYPE = {
  ECOMM: "eComm",
  PGUIDE: "pGuide"
}