import React, { useState, useEffect } from 'react';

import {
  Box,
  Dropdown,
  Button
} from "react-bulma-components/full";

import ShootingsService from '../../../Services/ShootingService';
import ItemsService from '../../../Services/ItemsService';

import { STATUS } from '../../General/Constants/Shooting';
import { toast } from 'react-toastify';


function MoveBetweenShootings(props) {
  const shootingService = new ShootingsService();
  const itemsService = new ItemsService();

  const [type, setType] = useState('eComm');
  const [shootingList, setShootingsList] = useState([]);
  const [selectedShooting, setSelectedShooting] = useState(null);
  const [items, setItems] = useState([]);
  const [resumeStatus, setResumeStatus] = useState({
    required: [],
    pending: [],
    available: [],
    assigned: [],
    toShoot: [],
    waitingPhotos: [],
    toReview: [],
    approved: []
  });
  const [ableToMove, setAbleToMove] = useState(0);

  let statusToCheck = (type === 'eComm' ? 'current' : 'product_guide');

  const getItems = async () => {

    if (props.checkList.length > 0) {
      let response = await itemsService.get({
        rf: ['_id', 'status'],
        qf: {
          _id: {
            $in: props.checkList
          }
        }
      });

      setItems(response.d);
      checkStatus(response.d);
    }
  }

  const checkStatus = (arrItems) => {
    let objByStatus = {
      required: [],
      pending: [],
      available: [],
      assigned: [],
      toShoot: [],
      waitingPhotos: [],
      toReview: [],
      approved: []
    };

    for (let i = 0; i < arrItems.length; i++) {
      objByStatus[arrItems[i].status[statusToCheck]].push(arrItems[i]._id);
    }

    setResumeStatus(objByStatus);
    setAbleToMove(objByStatus.available.length + objByStatus.assigned.length + objByStatus.toShoot.length + objByStatus.waitingPhotos.length + objByStatus.toReview.length);
  }

  const moveItems = async () => {

    await shootingService.multiPut({
      ol: [selectedShooting._id],
      co: [
        {
          _items: resumeStatus.assigned.concat(resumeStatus.available).concat(resumeStatus.toShoot)
        }
      ]
    });
    props.close();
    toast.success('Los PC9 han sido movidos de shooting correctamente');
  }

  useEffect(() => {

    const fetch = async (shootingType) => {
      let response = await shootingService.get({
        rf: ['_id', 'name', 'type'],
        qf: {
          season: props.seasonId,
          status: [STATUS.NEW, STATUS.INPROGRESS, STATUS.TERMINATED],
          type: shootingType
        }
      });
      setShootingsList(response.d);

      setSelectedShooting(null);
    };

    fetch(type);
    checkStatus(items);
  }, [type]);


  useEffect(() => {
    if (items.length === 0) {
      getItems();
    }
  }, [items])

  return (
    <div style={{ height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div >
          <Dropdown
            value={type}
            onChange={(value) => setType(value)}
          >
            <Dropdown.Item key={0} value={'eComm'}>eComm</Dropdown.Item>
            <Dropdown.Item key={1} value={'pGuide'}>pGuide</Dropdown.Item>
          </Dropdown>&nbsp;

            <Dropdown
            value={selectedShooting}
            onChange={shooting => {
              console.log(shooting)
              setSelectedShooting(shooting)
            }}
          >
            <Dropdown.Item key={-1} value={null}>Selecciona un shooting destino</Dropdown.Item>
            {
              shootingList.map((shooting, index) => {
                return (
                  <Dropdown.Item key={index} value={shooting}>{shooting.name}</Dropdown.Item>
                )
              })
            }
          </Dropdown>
        </div>

        <Button color='primary' disabled={ableToMove === 0 || selectedShooting === null} onClick={() => moveItems()}>
          Mover
        </Button>
      </div>

      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', marginTop: '1rem', padding: '0.3rem' }}>
        <div>PC9's seleccionados: {props.checkList.length}</div>
        <div>
          Puedes mover: <strong>{ableToMove}</strong> PC9's
        </div>
      </Box>

    </div>
  );
}

export default MoveBetweenShootings;
