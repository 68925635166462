import axios from 'axios';
import qs from 'qs';


class QuotationService {

  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/quotation`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      }
    });
    this.service = service;
  }

  get = (query = {}) => {
    return this.service.get('/', { params: query })
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  post = (data) => {
    return this.service.post('/', data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  put = (data) =>{
    return this.service.put('/', data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  changeStatus=(quotationId,data)=>{
    return this.service.put('/' + quotationId, data)
      .then(response =>response.data)
      .catch(error => {
        return error.response.data
      });
  }

  uploadPDF = (quotation, files) => {
    let data= new FormData();
    let co = [{status:"answered",estimate_result:{notes:quotation.estimate_result.notes}}];

    data.append('files', files[0]);
    data.append('co',JSON.stringify(co));

    return this.service.put('/'+quotation._id, data, {
        headers: {
          'content-type': 'multipart/form-data',
        }
      })
      .then(response => response.data)
      .catch((error) => {
        return error;
      });
  }

  //No sirve el delete, solo se cambia el status a eliminated
  delete = (data) => {
    return this.service.delete('/', data)
      .then(response => response.data)
      .catch(error => {
        return error.response.data
      })
  }
}

export default QuotationService;
