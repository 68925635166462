import React, { Component } from 'react';

import {
  Box,
  Columns
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

class WarehouseStatusInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      looking:0,
      urgent:0,
      total: 0
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.data !== undefined) {
      this.setState({
        data: newProps.data
      }, () => {
        this.getResumeInfo();
      });
    }
  }

  getResumeInfo = () => {
    let looking = 0;
    let urgent = 0;
    

    for (let i = 0; i < this.state.data.length; i++) {
      let status = (this.props.type === 'eComm' ? this.state.data[i].status.current : this.state.data[i].status.product_guide)

      switch (status) {
        case "pending":
          looking++
          break;
        case "urgent":
          urgent++
          break;
        default:
          break;
      }
    }
  
    this.setState({
      looking,
      urgent,
      total: this.state.data.length
    });
  }

  render() {
    return (
      <Box className="is-top-marginless">
        <Columns style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around'
        }}>
          <Columns.Column className="has-text-centered" size={2}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 30 }}>
              <strong>{this.props.type}</strong>
            </div>
            
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={2}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 30 }}>
              {this.state.total}
            </div>
            <div><b>Total</b></div>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={2}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25 }}>
              <FontAwesomeIcon icon={faSearchLocation} />&nbsp;
              {this.state.looking}
            </div>
            <div>Buscando</div>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={2}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25 }}>
              <FontAwesomeIcon icon={faExclamationCircle} />&nbsp;
              {this.state.urgent}
            </div>
            <div>Urgentes</div>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default WarehouseStatusInfo;