import React, { useRef } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const MultiProgressBar = ({ height, max, maxLegend, title, values }) => {
  const ref = useRef(null);
  
  return (
    <div className="multi-progress-bar">
      {!!title && 
        <div className="mpb-title">
          <div>{title}</div>
          <div>({max}) {maxLegend}</div>
        </div>
      }

      <div className="mpb-wrapper" ref={ref} style={{ height }}>
        {values.map((v, i) => {
          let { value, color, fontColor } = v;
          let calculated = value ? ((value / max) * 100).toFixed(2) : 0;
          let localColor = color ? { backgroundColor: color } : {};
          fontColor = { color: fontColor ? fontColor : "" };

          return (
            <div 
              key={i}
              title={`${calculated}%`}
              style={{
                width: `${calculated}%`,
                ...fontColor,
                ...localColor
              }}
            >
              {calculated >= 9 ? `${calculated < 1 ? calculated : Math.round(calculated)}%` : ""}
            </div>
          );
        })}

      </div>
      
      <div className="mpb-labels">
        {values.map((v, i) => {
          let { color, label, value } = v;

          if (!label) return null;
          
          return (
            <div key={i}>
              <span style={{ color }}>▀</span>
              {label} ({value})
            </div>
          );
        })}
      </div>
    </div>
  );
};

MultiProgressBar.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  height: PropTypes.string,
  max: PropTypes.number.isRequired,
  maxLegend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  values: PropTypes.array.isRequired
};

MultiProgressBar.defaultProps = {
  max: 100,
  maxLegend: "",
  values: []
};

export default MultiProgressBar;
