import React from 'react';

function TextCell(props) {
  
	
  const RenderAs = props.renderAs;

  return (
    <RenderAs 
      className={props.className}
    >
      {props.content}
    </RenderAs>
  );
}

TextCell.defaultProps = {
  className: '',
  renderAs: 'span'
}


export default TextCell;