import React, { Component } from 'react';

import {
  Columns
} from "react-bulma-components/full";

class DropEmptyRow extends Component {
  constructor(props) {
    super(props);
    
    this.state = {}
  }

  render() {
    return(
      <Columns>
        <Columns.Column>
          <p><b>Aún no se han enviado PC9s a la papelera</b></p>
          <span>
            Utiliza la acción por lotes en Home: "Mover a DROP"
          </span>
        </Columns.Column>
      </Columns>
    );
  }
}

export default DropEmptyRow;