import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as ImageEmptyFilters } from "./utils/business-analytics-icon.svg";

const prefix = "d-empty-filters";

const EmptyFilters = () => {
  const history = useHistory();

  return (
    <div className={prefix}>
      <div>
        <ImageEmptyFilters />
        <h2 className="title">LEVI STRAUSS & CO.</h2>
        <h5 className="subtitle"><b>Reportes de entrega de contenido pdp</b></h5>
        <h5 className="subtitle">&laquo; Selecciona algún filtro para continuar</h5>
      </div>

      <div>
        <a href="#" onClick={(e) => {
          e.preventDefault();
          history.push("/master");
        }}>
          Ó ingresa al catálogo completo aquí
        </a>
      </div>
    </div>
  );
};

export default EmptyFilters;
