import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ReactComponent as Share } from "../assets/share.svg";

const CopyToClipboard = ({ isButton }) => {
  const refCopy = useRef(null);
  const [location, setLocation] = useState("");
  useEffect(() => {
    setLocation(window.location.href.replace("curbside", "info"));
  }, []);
  const handleClipboard = () => {
    refCopy.current.select();
    document.execCommand("copy");
    toast.info("Se ha copiado al portapapeles");
  };
  return (
    <>
      {isButton ? (
        <button className="curbside__compartelo" onClick={handleClipboard}>
          <Share />
          Compartir Ahora
        </button>
      ) : (
        <Share onClick={handleClipboard} className="curbside__share" />
      )}
      <textarea
        defaultValue={location}
        className="curbside__link-copy"
        ref={refCopy}
      />
    </>
  );
};

export default CopyToClipboard;
