import React, { useEffect, useState } from 'react';
import moment from 'moment';

import ActionsCell from './components/ActionsCell';
import ButtonCell from './components/ButtonCell';
import CheckboxCell from './components/CheckboxCell';
import IconCell from './components/IconCell';
import LinkCell from './components/LinkCell';
import StatusCell from './components/StatusCell';
import TextCell from './components/TextCell';
import ETACell from './components/ETACell'


function Cell(props) {
  const [className, setClassName] = useState('');

  const getContent = () => {
    let row = props.row;
    let objectKey = props.column.field;
    let format = props.column.format;

    let content = [];
    if (!objectKey) {
      return '';
    }
    
    if (!(typeof objectKey === 'object' && objectKey.constructor === Array)) {
      objectKey = [objectKey];
    }
    
    for (let i in objectKey) {
      let keys = objectKey[i].split('.');
      let property = row;

      for (let i = 0; i < keys.length; i++) {
        if (keys[i] && !property[keys[i]] && typeof property[keys[i]] !== 'number') {
          property = '';
          break;
        }
        property = property[keys[i]];
      }
      if (format && format[i]) {
        property = formating(property, format[i]);        
      }
      content += property;
    }
    return content;
  }


  const getCell = () => {
    let column = props.column;
    let element;

    switch (column.type) {
      case 'actions':
        element = (<ActionsCell {...props}/>);
        break
      case 'button':
        element = (<ButtonCell
          {...column}
          row={props.row}
        />);
        break;
      case 'checklist':
        element = (
          <CheckboxCell 
            {...column}
            {...props}
          />
        );
        break;
      case 'icon': 
        element = (
          <IconCell
            color={typeof column.color === 'function' ? column.color(props.row) : column.color}
            icon={typeof column.icon === 'function' ? column.icon(props.row) : column.icon}
            title={getContent()}
            dictionary={column.dictionary ? column.dictionary : {}}
          />
        );
        break;
      case 'link':
        element = (
          <LinkCell 
            column={column}
            row={props.row}
            onClick={column.onClick}
            content={getContent()}
          />
        );
        break;
      case 'status':
        element = (
          <StatusCell 
            column={props.column}
            content={getContent()}
            row={props.row}
            list={props.column.dictionary}
          />
        );
        break;
        case 'ETA':
          element = (
            <ETACell 
            
            {...props.column}
            extraData={()=>column.extraData(props.row)}
            onSearch={column.onSearch}
            content={getContent()}
            row={props.row}
            />
          );
          break;
      default:
        element = (
          <TextCell 
            {...props.column}
            content={getContent()}
            row={props.row}
          />
        );
        break;
    }
    
    return element;
  }
  
  const formating = (value, format) => {
    format = format.split('::');
    let output;
    switch (format[0]) {
      case 'MOMENT':
        output = moment(value).isValid() ? moment(value).format(format[1]) : props.invalid;
        break;
      case 'RANGE':
        output = ' ' + format[1] + ' ';
        break;
      default:
        output = value;
        break;
    }
    return output;
  }

  useEffect(() => {
    let classN = '';
    if (props.column.type === 'icon') {
      classN += ' iconType';
    }

    if (props.column.type === 'checklist') {
      classN += ' checkType';
    }

    setClassName(classN);
  }, []);

  return (
    <td className={className}>
      {getCell()}
    </td>
  );
};

export default Cell;