import React, { useEffect, useState } from 'react';
import SeasonService from '../../../Services/SeasonsService';
import ItemService from '../../../Services/ItemsService';
import { Button, Columns, Form, Tag } from 'react-bulma-components/full';
import { toast } from 'react-toastify';

const seasonService = new SeasonService();
const itemService = new ItemService();

const MoveToSeason = ({ checkList, onMove }) => {
  const [inited, setInited] = useState();
  const [seasons, setSeasons] = useState();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);


  useEffect(() => {
    const fetchSeasons = async () => {
      let seasons = await seasonService.get();

      setSeasons(seasons.d ? seasons.d : []);
    };

    fetchSeasons();
  }, []);

  useEffect(() => setLoading(false), [seasons]);
  
  useEffect(() => {
    if (!loading && !inited) setInited(true);
  }, [loading]);


  const clickHandler = async () => {
    let response = await itemService.multiPut({
      ol: checkList,
      co: [{
        id_season: selected
      }]
    });
    
    let { endp } = response;

    if (endp.error > 0 ) toast.error(endp.error + " items no pudieron actualizarse");
    if (endp.error === 0 && endp.success > 0) toast.success("Cambio de temporada exitoso");

    onMove();
  };

  return (
    <Columns>
      <Columns.Column size={12}>
        <Tag.Group gapless>
          <Tag color="info">{checkList.length}</Tag>
          <Tag>PC9's</Tag>
        </Tag.Group>
      </Columns.Column>
      <Columns.Column size={12}>
        Elige a la temporada que deseas cambiar los items seleccionados.
      </Columns.Column>
      <Columns.Column mobile={{size: 12}} tablet={{size:9}}>
        <Form.Select
          className="move-season"
          onChange={(e) => setSelected(e.target.value)}
          value={selected === null ? "" : selected}
          disabled={checkList.length < 1}
          fullwidth
        >
          <option value="" disabled >
            Selecciona una temporada
          </option>
          {inited && seasons && seasons.map((row, key) => (
            <option value={row._id} key={key}>
              {row.name.length > 15 ? row.name.slice(0,15) + '...' : row.name}
            </option>
          ))}
        </Form.Select>
        
      </Columns.Column>
      <Columns.Column className="has-text-right" mobile={{size: 12}} tablet={{size:3}}>
        <Button 
          color="success"
          disabled={!inited || selected === null}
          onClick={clickHandler}
          fullwidth   
        >
          Mover
        </Button>
      </Columns.Column>
    </Columns>
  );
};

export default MoveToSeason;
