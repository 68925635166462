import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ItemsService from '../../../../../../../Services/ItemsService';
import { uniq as _uniq } from 'underscore';
import toCSV from '../../../../utils/downloadCSV';
import {
  Button,
  Columns,
  Form,
  Tag
} from 'react-bulma-components/full';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faDownload,
  faEraser, faFileExcel, faSpinner
} from '@fortawesome/free-solid-svg-icons';
import ChannelService from '../../../../../../../Services/ChannelsService';
import { toast } from 'react-toastify';

const lengthPC9 = 9;

const TextareaFilter = (props) => {
  const itemsService = new ItemsService();
  const channelService = new ChannelService();
  const [myTextArea, setMyTextArea] = useState('');
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false)

  const process = (val) => {

    /** Escape when is not complete pc9 size */
    if (val.length < lengthPC9) return;

    let valueArray = val.split(/,|\n/); // Split array values
    let arrPc9 = Array.from(tags);  // Copy array
    let change = false;

    for (let i in valueArray) {
      let cleaned = valueArray[i].trim().replace(/[^0-9a-z]/gi, '');

      if (valueArray[i].length >= lengthPC9) {
        change = true;
        arrPc9.push(cleaned);
      }
    }

    if (change) {
      // arrPc9 = Array.from(new Set(arrPc9));
      arrPc9 = _uniq(arrPc9);
      setTags(arrPc9);
      setMyTextArea('');
    }
  };


  const removeItem = (e) => {
    if (e.target && e.target.className.search('tag') !== -1) {
      let tagsAux = Array.from(tags);
      let index = tagsAux.indexOf(e.target.text);
      tagsAux.splice(index, 1);

      setTags(tagsAux);
    }
  };

  const exportCSV = async (e) => {
    e.preventDefault();
    setLoading(true);

    let arrPC9 = []
    let arrIds = []
    let PT = 0;
    let NA = 0;


    for (let i = 0; i < tags.length; i++) {
      if (props.renderRowsPC9.indexOf(tags[i]) !== -1) {
        arrPC9.push(tags[i])
        PT++
      } else {
        NA++
      }
    }
    if (PT > 0) {
      itemsService.downloadXLSConfig(arrPC9,'PT:' + PT)
        .then(respXLS => {
          window.location.href = respXLS.data.d[0].url_csv
          setLoading(false);
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      toast.error('No se han encontrado coincidencias')
      setLoading(false);
    }

  }

  const exportCSVNA = async (e) => {
    let arrToExport = []
    let NA = 0
    setLoading(true);

    for (let i = 0; i < tags.length; i++) {
      if (props.renderRowsPC9.indexOf(tags[i]) === -1) {
        arrToExport.push({
          PC9:(tags[i]).slice(0, 5) + "-" + (tags[i]).slice(5),
          MARCA:'-',
          GENERO:'-',
          CATEGORIA:'-',
          TEMPORADA:'-',
          FOTOS:'-',
          COPY:'-'
        });
        NA++
      } 
    }

    const headers = {
      PC9:'PC9',
      MARCA:'MARCA',
      GENERO:'GÉNERO',
      CATEGORIA:'CATEGORÍA',
      TEMPORADA:'TEMPORADA',
      FOTOS:'FOTOS',
      COPY: 'COPY'
    };
    if(NA > 0){
      toCSV(headers, arrToExport, '_NA:' + NA, 'iso-8859-1');
      
    } else{
      toast.error("No se han encontrado coincidencias")

    }
  setLoading(false);

  }

  

  useEffect(() => {
    if (props.filters[props.filter]) {
      setTags(props.filters[props.filter]);
    } else {
      setTags([]);
      setMyTextArea('');
    }

  }, [props.filters[props.filter]]);


  useEffect(() => {
    process(myTextArea);
  }, [myTextArea]);


  useEffect(() => {
    props.onChange(tags, props.filter);
  }, [tags]);

  return (
    <Columns className="textarea-filter">
      <Columns.Column size={12} className="tags-filter">
        <Form.Textarea
          className="is-size-7"
          value={myTextArea}
          onChange={(e) => setMyTextArea(e.target.value)}
          rows={1}
          placeholder='Ctrl+V PC9s'
        ></Form.Textarea>

        {tags.length ?
          <div className="content-tags" onClick={removeItem}>
            <Form.Field multiline kind="group">
              {tags.map((tag, index) => {
                let color = props.renderRowsPC9.indexOf(tag) !== -1 ? 'primary' : null;
                return (
                  <Form.Control key={index}>
                    <Tag.Group >
                      <Tag color={color} renderAs="a">
                        {tag}
                      </Tag>
                      {/* <Tag 
                        color={color}
                        onClick={() => removeItem(index)} 
                        remove 
                        
                      /> */}
                    </Tag.Group>
                  </Form.Control>
                )
              })}
            </Form.Field>
          </div>
          : null}


      </Columns.Column>

      {tags.length ?
        <Columns.Column size={12}>
          <label style={{fontSize:'12px'}}> {tags.length + ' PC9 pegados'}</label>&nbsp;
        {!loading ?
        <div style={{display:'flex', flexDirection:'row'}}>
                  <Tag color={"primary"} renderAs="a" onClick={(e) => exportCSV(e)}>
                  <FontAwesomeIcon icon={faDownload} /> &nbsp;
                    {' PT'}
                  </Tag>&nbsp;
                  <Tag color={"default"} renderAs="a" onClick={(e) => exportCSVNA(e)}>
                  <FontAwesomeIcon icon={faDownload} /> &nbsp;
                    {' NA'}
                  </Tag>
        </div>
        :
        <strong>{" Por favor, espere..."}</strong>
        }

          <Button
            className="is-pulled-right"
            size="small"
            onClick={() => setTags([])}
            type="button"
            title="Quitar PC9s"
          >
            <FontAwesomeIcon icon={faEraser} />
            &nbsp;
          </Button>
        </Columns.Column>
        :
        null
      }

    </Columns>
  );
}

TextareaFilter.propTypes = {
  filter: PropTypes.string,
  filters: PropTypes.object,
  onChange: PropTypes.func,
  renderRowsPC9: PropTypes.array
};

TextareaFilter.defaultProps = {
  filter: '',
  filters: {},
  onChange: () => { },
  renderRowsPC9: []
};

export default TextareaFilter;
