// Services/BatchesService.js

import axios from 'axios';
import qs from 'qs';

class BatchService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/batch`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      }
    });
    this.service = service;
  }

  get = (queryString = {}) => {
    return this.service.get('/', {
      params: queryString
    })
      .then(response => response.data)
      .catch((response) => {
        return response.data;
      });
  }

  post = (data) => {
    return this.service.post('/', data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  ctrlzStatus = (item) =>{
    return this.service.post('/ctrlzStatus',{
        itemId:item._id,
        status:item.status.current
      })
      .then(response => response.data)
      .catch(error => {
        console.log(error);
        return error.response.data;
      });
  }
  

  searchAgain = (batchId, item) =>{

    console.log("Cambiar estatus a open de: "+item.pc9 + " del shooting: " +batchId)

    // return this.service.post('/searchAgain', {
    //   shootingId: shootingId,
    //   itemId: item._id
    // })
    // .then(response => response.data)
    // .catch(error=>{
    //   console.log(error)
    // })
  }
}

export default BatchService;