import React from 'react';
import PropTypes from 'prop-types';

function StatusCell(props) {
  
  const getContent = () => {
    let value = props.content;
    if (props.list[value]) {
      return props.list[value];
    }

    return value;
  }
  
  
  return (
    <span 
      {...props}
    >
      {getContent()}
    </span>
  );

}

StatusCell.propTypes = {
  className: PropTypes.string,
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

StatusCell.defaultProps = {
  list: {}
}



export default StatusCell;