import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LogsService from '../../../../../../Services/LogsService';

import {toast} from 'react-toastify';

import  {
  Button,
  Columns,
  Form
} from 'react-bulma-components/full';

import {
  faComment
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {LOGS} from '../../../../../General/Language/es';

class CreateComment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      comment: '',
      loading: false
    };

    this.logService = new LogsService();
  }

  changeHandler = (e) => {
    this.setState({
      comment: e.target.value
    });
  }

  setLoandig = (loading) => {
    this.setState({
      loading: loading
    });
  }

  submitHandler = (e) => {
    e.preventDefault();

    this.setLoandig(true);

    let data = {
      id_item: this.props.itemId,
      data: {
        comment: this.state.comment
      }
    }
    
    this.logService.post({
      co: [
        data
      ]})
      .then((response) => {
        for (let err in response.error) {
          if (response.error[err].code === 0) {
            toast.success(LOGS.COMMENT.CREATE_SUCCESS);
            this.props.onChange();
          } else {
            toast.err(LOGS.COMMENT.CREATE_ERROR);
          }
        }
        this.setLoandig(false);
      });
  }

  resetForm = () => {
    this.setState({
      comment: ''
    });
  }
  

  render() {
    return(
      <form onSubmit={this.submitHandler} className="commentFullWidth">
        <Columns>
          <Columns.Column size={12}>
            <Form.Textarea 
              onChange={this.changeHandler}
              rows={2}
              value={this.state.comment} 
              disabled={this.state.loading}
              />
          </Columns.Column>
          <Columns.Column size={12}>
            <Button 
              className="is-pulled-right"
              color="success"
              disabled={this.state.comment.length < 5 || this.state.loading}
              type="submit"
              size="small"
            >
              <FontAwesomeIcon icon={faComment}/>
              &nbsp;
              Comentar
            </Button>
          </Columns.Column>
        </Columns>
      </form>
    );
  }
}

CreateComment.propTypes = {
  itemId: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

CreateComment.defaulProps = {
  onChange: () => {}
};

export default CreateComment;