import React, { useEffect, useState } from 'react';

import ChannelsService from '../../../Services/ChannelsService';

import {
  Box,
  Button,
  Columns,
  Form
} from 'react-bulma-components/full';
import { toast } from 'react-toastify';
import { CHANNELS } from '../../General/Language/es';

const channelService = new ChannelsService();

const UpdateChannelForm = ({ channelId, close }) => {
  
  const [channel, setChannel]= useState({});
  
  useEffect(() => {
    channelService.getById(channelId).then((response) => {
      let currentChannel = response.d[0];
      setChannel(currentChannel);
    });
  }, []);

  const handleSubmit = async(event) => {
    event.preventDefault();
    
    let response = await channelService.put(channelId, {
      co: [channel]
    });
    
    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.success(CHANNELS.UPDATE.SUCCESS);
      } else {
        toast.success(CHANNELS.UPDATE.ERROR);
      }
    }

    close();
  }

  const onChange = (event, property = null, nested = null) => {
    let newChannel = {...channel};
    if (property !== null && nested !== null) {
      newChannel[property][nested] = event.target.value;
    } else {
      newChannel[event.target.name] = event.target.value;
    }
    setChannel(newChannel);
  };

  return (
    <Box className="is-marginless">
    <form onSubmit={handleSubmit}>
      <div className="field is-horizontal" style={{display:'flex', justifyContent:'center'}}>
        <div className="field-label is-normal">
          <label className="label">Canal</label>
        </div>
        <div className="field-body">
          <div className="field" >  
            <Form.Input 
              style={{width:'100px'}}
              autoComplete="off"
              value={channel.name} 
              name="name" 
              readonly
              disabled={true}
              onChange={() => {}}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Ancho (px)</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.width ? channel.width.toString(): ''} 
              name="width" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Largo (px)</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.height ? channel.height.toString(): ''} 
              name="height" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Peso máximo (Kb)</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.maxSize ? channel.maxSize.toString(): ''} 
              name="maxSize" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Densidad (dpi)</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.density ? channel.density : ''} 
              name="density" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Extensión de nombre</label>
        </div>
        <div className="field-body">
          <div className="field">  
            <Form.Input 
              autoComplete="off"
              value={channel.nameExtension} 
              name="nameExtension" 
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Fondo de</label>
        </div>
        <div className="field-body">
          <Columns>
            <Columns.Column>
              <div className="field">  
                <Form.Input 
                  autoComplete="off"
                  value={channel.background && channel.background.start ? channel.background.start: "#ffffff"} 
                  name="background.start" 
                  onChange={(e) => onChange(e, 'background', 'start')}
                />
              </div>
            </Columns.Column>
            
            <Columns.Column className="is-narrow"><b>a</b></Columns.Column>
            
            <Columns.Column>
              <div className="field">  
                <Form.Input 
                  autoComplete="off"
                  value={channel.background && channel.background.end ? channel.background.end: "#ffffff"} 
                  name="background.end" 
                  onChange={(e) => onChange(e, 'background', 'end')}
                />
              </div>
            </Columns.Column>
          </Columns>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Swatch</label>
        </div>
        <div className="field-body">
          <div className="field">
            <Form.Radio 
              name="swatch"
              checked={channel.swatch === true}
              onChange={() => setChannel({...channel, swatch: true})}
              value="Sí"
            > Sí</Form.Radio>
            <Form.Radio 
              name="swatch"
              checked={channel.swatch !== true}
              onChange={() => setChannel({...channel, swatch: false})}
              value="No"
            > No</Form.Radio>
          </div>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <Button color="success" type="submit">Actualizar</Button>
      </div>
    </form>
  </Box>
  );
};

UpdateChannelForm.propTypes = {

};

UpdateChannelForm.defaultProps = {
  
};

export default UpdateChannelForm;
