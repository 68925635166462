import React, { Component } from "react";
import ItemsService from "../../../../Services/ItemsService";
import ShootingService from "../../../../Services/ShootingService";

import "./style.scss";
import {
  Box,
  Content,
  Columns,
  Heading,
  Image,
  Media,
} from "react-bulma-components/full";

import { confirmAlert } from "react-confirm-alert";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DetailsTable from "./components/DetailsTable";
import DetailsImages from "./components/DetailsImages";
import ModalCard from "../../../General/Modal/ModalCard";
import Activity from "../Activity/Activity";

import { STATUS as ITEM_STATUS } from "../../../General/Constants/Item";
import { toast } from "react-toastify";

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activityCollapse: true,
      reviewButtons: null,
      approving: false,
      bussy: false,

      detailsTable: (
        <DetailsTable
          bussy={false}
          photoMode={this.props.photoMode}
          getUpdateChannel={(item, index) => this.updateItem(item, index)}
          statusUpdate={(item, status) =>
            this.updateStatusModalDetail(item, status)
          }
          item={this.props.item}
          loggedInUser={this.props.loggedInUser}
          updateAll={this.props.updateAll}
        />
      ),
      item: props.item,
      loggedInUser: this.props.loggedInUser,
      modal: {
        show: false,
        size: "",
      },
    };

    this.itemService = new ItemsService();
    this.shootingService = new ShootingService();
  }

  closeModal = () => {
    let modal = {
      show: false,
    };

    this.setState({ modal: modal });
  };

  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({ modal: modal });
  };

  updateItem = (item, index) => {
    this.setState({
      item: item,
      detailsImage: (
        <DetailsImages
          item={item}
          channels={item.channels}
          index={index}
          loggedInUser={this.state.loggedInUser}
          updateAll={this.props.updateAll}
          toggleGeneralLoading={this.props.toggleGeneralLoading}
          getUpdateChannel={this.updateItem}
        />
      ),
      detailsTable: (
        <DetailsTable
          bussy={false}
          photoMode={this.props.photoMode}
          getUpdateChannel={(item, index) => this.updateItem(item, index)}
          statusUpdate={(item, status) =>
            this.updateStatusModalDetail(item, status)
          }
          item={item}
          loggedInUser={this.props.loggedInUser}
          updateAll={this.props.updateAll}
        />
      ),
    });
  };

  getCategoryIcon = () => {
    const category = this.state.item.category;
    var categoryIcon = "";
    switch (category) {
      case "Tops":
      case "TOPS":
        categoryIcon = "./images/icon_category/tops.png";
        break;
      case "Bottoms":
      case "BOTTOMS":
        categoryIcon = "./images/icon_category/bottoms.png";
        break;
      case "Belts":
        categoryIcon = "./images/icon_category/belts.png";
        break;
      case "Wallets":
        categoryIcon = "./images/icon_category/wallet.png";
        break;
      case "Underwear":
      case "Duo Pack":
        categoryIcon = "./images/icon_category/boxers.png";
        break;
      case "Headwear":
        categoryIcon = "./images/icon_category/cap.png";
        break;
      default:
        categoryIcon = "./images/icon_category/battery.png";
    }

    return categoryIcon;
  };

  getBrandLogo = () => {
    const brand = this.state.item.brand;
    var brandLogo = "";
    switch (brand) {
      case "Levi's":
      case "Levis":
      case "Levi's®":
      case "LEVIS":
      case "LEVI'S":
      case "Levi´s":
      case "Levi`s":
        brandLogo = "./images/brand_logos/levi.png";
        break;
      case "Denizen":
      case "DENIZEN":
        brandLogo = "./images/brand_logos/denizen.png";
        break;
      case "Dockers":
      case "Dockers®":
      case "DOCKERS":
        brandLogo = "./images/brand_logos/dockers.png";
        break;
      case "Levi's Signature":
      case "Signature":
      case "Levis Signature":
      case "Signature®":
        brandLogo = "./images/brand_logos/signature.png";
        break;
      default:
        brandLogo = "./images/brand_logos/unknown_brand.png"

    }

    return brandLogo;
  };

  componentWillMount() {
    let indexFocus = 0;
    let indexMaster = this.props.item.channels
      .filter((ch) => {
        return ch.sku != null;
      })
      .map((ch) => ch.channel)
      .indexOf("master");
    let indexMpguide = this.props.item.channels
      .filter((ch) => {
        return ch.sku != null;
      })
      .map((ch) => ch.channel)
      .indexOf("mpguide");

    if (this.props.type === "pGuide" && indexMpguide !== -1) {
      indexFocus = indexMpguide;
    } else if (this.props.type === "eComm" && indexMaster !== -1) {
      indexFocus = indexMaster;
    }

    this.setState({
      detailsImage: (
        <DetailsImages
          item={this.props.item}
          channels={this.props.item.channels}
          index={indexFocus}
          loggedInUser={this.state.loggedInUser}
          updateAll={this.props.updateAll}
          toggleGeneralLoading={this.props.toggleGeneralLoading}ç
          getUpdateChannel={this.updateItem}
        />
      ),
    });
  }

  componentWillReceiveProps(nextProps) {
    let indexFocus = 0;
    let indexMaster = nextProps.item.channels
      .filter((ch) => ch.sku != null)
      .map((ch) => ch.channel)
      .indexOf("master");
    let indexMpguide = nextProps.item.channels
      .filter((ch) => ch.sku != null)
      .map((ch) => ch.channel)
      .indexOf("mpguide");

    if (nextProps.type === "pGuide" && indexMpguide !== -1) {
      indexFocus = indexMpguide;
    } else if (nextProps.type === "eComm" && indexMaster !== -1) {
      indexFocus = indexMaster;
    }

    this.setState({
      item: nextProps.item,
      detailsImage: (
        <DetailsImages
          item={nextProps.item}
          channels={nextProps.item.channels}
          index={indexFocus}
          loggedInUser={this.state.loggedInUser}
          updateAll={this.props.updateAll}
          toggleGeneralLoading={this.props.toggleGeneralLoading}
          getUpdateChannel={this.updateItem}
        />
      ),
      detailsTable: (
        <DetailsTable
          bussy={false}
          photoMode={nextProps.photoMode}
          getUpdateChannel={(item, index) => this.updateItem(item, index)}
          statusUpdate={(item, status) =>
            this.updateStatusModalDetail(item, status)
          }
          item={nextProps.item}
          loggedInUser={nextProps.loggedInUser}
          updateAll={this.props.updateAll}
        />
      ),
    });
  }



  updateStatusModalDetail = (item, status) => {
    return this.itemService
      .put(item._id, {
        co: [
          {
            "status.current": ITEM_STATUS[status],
          },
        ],
      })
      .then((response) => {
        let updatedItem = response.d[0].ref;

        if (this.props.fetch) {
          this.props.fetch();
        }

        this.setState(
          {
            item: updatedItem,
          },
          () => this.props.getTitleUpdated(updatedItem)
        );
      });
  };

  onToggleCollapseActivity = () => {
    this.setState({
      activityCollapse: !this.state.activityCollapse,
    });
  };

  deleteSwatch = () => {
    confirmAlert({
      title: "Eliminar",
      message: "¿Quieres eliminar la muestra?",
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            let response = await this.itemService.deleteSwatch(this.props.item._id);

            if (response && response.d && response.d[0]) {
              this.props.updateAll(response.d[0]);
              toast.success("Se eliminó el swatch correctamente")
            } else {
              toast.warn("Ocurrió un error al eliminar el swatch")
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    return (
      <div>
        <ModalCard
          title={this.state.modal.title}
          show={this.state.modal.show}
          body={this.state.modal.body}
          closeModal={this.closeModal}
          size={this.state.modal.size}
        />
        <Columns>
          {/* Left side */}
          <Columns.Column>{this.state.detailsImage}</Columns.Column>

          {/* Right side */}
          <Columns.Column>
            <Columns>
              <Columns.Column size={12}>
                <Activity
                  itemId={this.state.item._id}
                  collapsed={this.state.activityCollapse}
                  onToggleCollapse={this.onToggleCollapseActivity}
                />
              </Columns.Column>
              <Columns.Column>
                <Box className="description">
                  <Media>
                    <Media.Item>
                      <Content className="has-text-right">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Image
                            size={64}
                            alt="Brand"
                            src={this.getBrandLogo()}
                          />
                          <Heading size={4}>{this.state.item.name}</Heading>
                        </div>
                        <div style={{ fontSize: "18px" }}>
                          Última vez usado en: &nbsp;
                          <b>{this.state.item.season.pop()}</b>
                        </div>

                        <div style={{ fontSize: "14px" }}>
                          <b>{this.state.item.simple_description}</b>
                        </div>

                        <p
                          className="has-text-right"
                          style={{ fontSize: "12px" }}
                        >
                          {this.state.item.marketing_description}
                        </p>
                        <Columns
                          className="has-text-right"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            fontSize: "12px",
                          }}
                        >
                          <Columns.Column>
                            <ContextMenuTrigger id="swatchDeleteContextMenu">
                              <div className="muestra-context">
                                <b>Swatch</b>
                                <figure className="image is-50x50">
                                  <img
                                    className="is-rounded"
                                    alt="swatch"
                                    src={this.props.item.swatch !== null ? 
                                      this.props.item.swatch.url 
                                    : 
                                      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP89x8AAwEB/9nmgRwAAAAASUVORK5CYII="
                                    }
                                  />
                                </figure>
                              </div>
                            </ContextMenuTrigger>
                            <ContextMenu
                              id="swatchDeleteContextMenu"
                              className="Eliminar_muestra"
                            >
                              <MenuItem onClick={this.deleteSwatch} disabled={this.props.item.swatch === null}>
                                Eliminar muestra
                              </MenuItem>
                            </ContextMenu>
                          </Columns.Column>
                          <Columns.Column>
                            <b>Categoría</b>
                            <div className="image-category__wrapper">
                              <Image
                                src={this.getCategoryIcon()}
                                alt="Cat"
                                size={48}
                              />
                            </div>
                          </Columns.Column>

                          <Columns.Column>
                            <b>Género</b>
                            <p>{this.state.item.gender}</p>
                          </Columns.Column>
                          <Columns.Column>
                            <b>Colección</b>
                            <p>{this.state.item.clothes_collection}</p>
                          </Columns.Column>
                          <Columns.Column>
                            <b>Tipo</b>
                            <p>{this.state.item.line}</p>
                          </Columns.Column>
                          <Columns.Column>
                            <b>Color</b>
                            <p>{this.state.item.simple_color}</p>
                          </Columns.Column>
                        </Columns>
                        {this.state.detailsTable}
                      </Content>
                    </Media.Item>
                  </Media>
                  &nbsp;
                </Box>
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </div>
    );
  }
}

export default Details;
