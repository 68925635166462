import React from "react";
import PropTypes from "prop-types";
import { Columns } from "react-bulma-components";
import MultiProgressBar from "../General/MultiPogressBar";

const PdpGeneral = ({ tags, photoStatusEcomm, photoStatusProgress, copyStatus, photoOrigin }) => {
  return (
    <div className="d-pdp-general">
      <Columns className="header">
        <Columns.Column size={5}>
          <div className="is-size-4 has-text-weight-bold">Entrega de contenido PDP</div>
        </Columns.Column>
        <Columns.Column size={7} className="is-size-4 d-tags">
          {tags.map((r, i) => (
            <div key={i}>{r}</div>  
          ))}
        </Columns.Column>
      </Columns>

      <Columns className="d-pdp-charts">
        <Columns.Column size={7}>
          <MultiProgressBar 
            height="50px"
            title="Estatus general de entrega de fotografías"
            max={photoStatusEcomm.itemsLength ? photoStatusEcomm.itemsLength : 0}
            maxLegend="PC9"
            values={[{
              color: "#0c2f76",
              label: "Entregados",
              value: photoStatusEcomm.count && photoStatusEcomm.count.delivered ? photoStatusEcomm.count.delivered : 0
            }, { 
              color: "#3a7097",
              label: "Pendientes",
              value: photoStatusEcomm.count && photoStatusEcomm.count.inProgress ? photoStatusEcomm.count.inProgress : 0
            }, { 
              color: "#C6C6C6",
              label: "Sin solicitar",
              value: photoStatusEcomm.count && photoStatusEcomm.count.unprocessed ? photoStatusEcomm.count.unprocessed : 0
            }]}
          />
          <MultiProgressBar 
            height="50px"
            max={photoStatusProgress.itemsLength ? photoStatusProgress.itemsLength : 0}
            maxLegend="PC9"
            title="Reporte de avance de PC9 pendientes"
            values={[{
              color: "#467094",
              label: "Asignados a shooting",
              value: photoStatusProgress.count && photoStatusProgress.count.assignedTo ? photoStatusProgress.count.assignedTo : 0
            }, {
              color: "#607b91",
              label: "Por asignar",
              value: photoStatusProgress.count && photoStatusProgress.count.toAssign ? photoStatusProgress.count.toAssign : 0
            },{
              color: "#818b94",
              label: "Buscando",
              value: photoStatusProgress.count && photoStatusProgress.count.toSearch ? photoStatusProgress.count.toSearch : 0
            }]}
            
          />
        </Columns.Column>
      
        <Columns.Column size={5}>
          <MultiProgressBar 
            height="50px"
            max={copyStatus.itemsLength ? copyStatus.itemsLength : 0}
            maxLegend="PC9"
            title="Estatus copy"
            values={[{
              color: "#0c2f76",
              label: "Aprobado",
              value: copyStatus.count && copyStatus.count.delivered ? copyStatus.count.delivered : 0
            }, {
              color: "#C6C6C6",
              label: "En proceso",
              value: copyStatus.count && copyStatus.count.inProgress ? copyStatus.count.inProgress : 0
            }]}
          />

          <MultiProgressBar 
            height="50px"
            max={photoOrigin.itemsLength ? photoOrigin.itemsLength : 0}
            maxLegend="PC9"
            title="Origen de imágenes"
            values={[{
              color: "#0c2f76",
              label: "Local",
              value: photoOrigin.count && photoOrigin.count.local ? photoOrigin.count.local : 0
            }, {
              color: "#3a7097",
              label: "Global",
              value: photoOrigin.count && photoOrigin.count.local ? photoOrigin.count.global : 0
            }]}
          />
        </Columns.Column>
      </Columns>
  
    </div>
  );
};

PdpGeneral.propTypes = {
  copyStatus: PropTypes.object,
  tags: PropTypes.array,
  photoStatusEcomm: PropTypes.object,
  photoStatusProgress: PropTypes.object,
  photoOrigin: PropTypes.object
};

PdpGeneral.defaultProps = {
  copyStatus: {},
  tags: [],
  photoStatusEcomm: {},
  photoStatusProgress: {},
  photoOrigin: {}
};

export default PdpGeneral;
