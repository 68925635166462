import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import "./imprimir.scss";

const Imprimir = () => {
  return (
    <button
      id="buttonImprimir"
      className="imprimir"
      onClick={() => window.print()}
    >
      <FontAwesomeIcon icon={faPrint} />
      Imprimir
    </button>
  );
};

export default Imprimir;
