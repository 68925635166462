import React, { useEffect, useState } from 'react';
import ShootingService from '../../../../Services/ShootingService'
import moment from 'moment';
import DatePicker from 'react-datepicker';

import {
    Box,
    Button,
    Form,
    Tag
} from "react-bulma-components/full";

import { toast } from 'react-toastify';
import ItemsService from '../../../../Services/ItemsService';
import { ModalCard } from '../../../General/Modal';
import DetailsPhotos from './DetailsPhotos';
import PrevAssignedItems from './PrevAssignedItems'
import { sortBy } from '../../../General/Datatable/utils';
const shootingService = new ShootingService()
const itemsService = new ItemsService()

const AddToShootingV2 = (props) => {
  const [loggedInUser, setLoggedInUser]= useState(props.loggedInUser)
  const [shootingList, setShootingList] = useState([])
  const [selectedShooting, setSelectedShooting] = useState('default')
  const [createNewShooting, setCreateNewShooting] = useState(props.createNewShooting ? props.createNewShooting : false)
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState({
      title: '',
      footer: '',
      show: false,
      body: '',
      size: 'is-medium'
  });
  const [itemsWithPhotos, setItemsWithPhotos] = useState([])
  const [selectedType, setSelectedType] = useState('eComm')
  const [selectedOrigin,setSelectedOrigin] = useState('default')
  const [selectedProducer,setSelectedProducer] = useState('default')
  const [newShootingName, setNewShootingName] = useState('')
  const [photoman, setPhotoman] = useState('')
  const [location, setLocation] = useState('')
  const [shootingDescription, setShootingDescription] = useState('')
  const [scheduledShootingDate, setScheduledShootingDate] = useState(props.start? props.start : new Date())
  const [etaDate, setEtaDate] = useState(props.start? props.start : new Date())
  const [itemsToSearch, setItemsToSearch] = useState([])

  const fetch = async () => {
    let resp = await shootingService.get({
      rf: ['name', 'items_count', 'scheduled_date', 'type', 'origin', 'producer', "createdAt"],
      qf: {
        status: {
          $in: ["new", "inProgress"]
        }
      }
    });

    let filteredShootings = resp.d
    //console.log(loggedInUser)
    let producerUser = props.loggedInUser.producer
    if (producerUser !== null){
      if(producerUser === 'boicot'){
        filteredShootings = resp.d.filter((shooting) => shooting.producer === 'boicot');
      } else if(producerUser === 'curiosity'){
        filteredShootings = resp.d.filter((shooting) => shooting.producer === 'curiosity');
      }
    }
    console.log(filteredShootings)
    sortBy(filteredShootings, {
      field: "createdAt",
      order: "desc"
    })
    setShootingList(filteredShootings);
    setLoading(false);
  };

  const changeStatus = async (itemId, job, nextStatus) => {
    let lookingFor = `status.${job}`;
    await itemsService.put(itemId, {
      co: [{
        [lookingFor]: nextStatus
      }]
    });
    return true;
  };

  const lookFor = async (item, job) => {
    await changeStatus(item._id, job, 'pending');

    toast.warning('PC9: ' + item.pc9 + ' enviado a búsqueda');
    return true;
  };

  const markAsUrgent = async (item, job) => {
    await changeStatus(item._id, job, 'urgent');

    toast.warning('El PC9: ' + item.pc9 + ' está siendo localizado');
    return true;
  };

  const assignToShooting = async (item, job) => {
    await changeStatus(item._id, job, 'assigned');
    toast.success('El PC9: ' + item.pc9 + ' ha sido asignado al shooting');
    return true;
  };

  const resetInShootingProcess = async (item, job) => {
    await changeStatus(item._id, job, 'assigned');
    toast.warning('Asignado a shooting');
    return true;
  };

  const setETA = async (item, eta) => {
    await itemsService.put(item._id, {
      co: [{
        'eta_photos': {
          'eta': eta
        } 
      }]
    });
  };

  const addToSearchArray = (item, type, actualStatus) => {
    let photosFrom = 'master';
    let job = 'current';

    switch (type) {
      case 'eComm':
        photosFrom = 'master'
        job = 'current'
        break;
      case 'pGuide':
        photosFrom = 'mpguide'
        job = 'product_guide'
        break;
      default:
        photosFrom = 'master'
        job = 'current'
        break;
    }

    let auxItemsToSearch = itemsToSearch;
    let newObj = {
      item: item,
      _id: item._id,
      pc9: item.pc9,
      job: job,
      status: item.status[job]
    };
    auxItemsToSearch.push(newObj);
    setItemsToSearch(auxItemsToSearch);
  };

  const itemPrevioulyAssigned = async (type, shootingId) => {
    let auxModal = { ...{}, ...modal };
    auxModal.title = "Revisar PC9's asignados previamente";
    auxModal.size = 'is-medium';
    auxModal.body = (
      <PrevAssignedItems
        items={itemsToSearch}
        type={type}
        closeThisModal={closeModal}
        closeShootingModal={props.closeModal}
        showNextModal={reviewItemsWithPhotos}
        itemsWithPhotos={itemsWithPhotos}
        shootingId={shootingId}
      />
    );
    auxModal.show = true;

    setModal({ ...{}, ...auxModal });
  }

  const reviewItemsWithPhotos = async (type, shootingId) => {

    let auxModal = { ...{}, ...modal };
    auxModal.title = "Revisar PC9's con fotos";
    auxModal.size = 'is-medium';

    auxModal.body = (
      <DetailsPhotos
        items={itemsWithPhotos}
        type={type}
        closeThisModal={closeModal}
        closeShootingModal={props.closeModal}
        shootingId={shootingId}
      />
    );
    auxModal.show = true;

    setModal({ ...{}, ...auxModal });
  }

  const addToReviewArray = (item, type) => {
    let photosFrom = 'master'
    let job = 'current'
    switch (type) {
      case 'eComm':
        photosFrom = 'master'
        job = 'current'
        break;
      case 'pGuide':
        photosFrom = 'mpguide'
        job = 'product_guide'
        break;
      default:
        photosFrom = 'master'
        job = 'current'
        break;
    }

    let channel = item.channels.filter(channel => channel.channel === photosFrom);
    let auxItemsWithPhotos = itemsWithPhotos;
    let newObj = {
      item: item,
      _id: item._id,
      pc9: item.pc9,
      images: channel[0].images,
      status: item.status[job]
    };
    auxItemsWithPhotos.push(newObj);
    setItemsWithPhotos(newObj);
  }

  const addItemsToShooting = async () => {

    let resp = await shootingService.get({
      rf: ['name', 'items_stack', 'type', 'scheduled_date', 'origin'],
      qf: {
        _id: selectedShooting
      }
    });

    let { _id, name, items_stack, type, origin } = resp.d[0];
    
    let data = {
      ol: [selectedShooting],
      co: [{
        items_stack: props.checkList
      }]
    };

    for (let i = 0; i< props.checkList.length;i++){
      itemsService.put(props.checkList[i], {
        co: [{
          origin: origin
        }]
      })
    }
    shootingService.put(data)
      .then((resp) => {
        checkItems(props.checkList, items_stack, type, _id)
        toast.info("Los PC9's han sido agregados al shooting " + name);
      })
      .catch(error => {
        toast.error("Sucedió un error, inténtalo de nuevo");
      });
  };

  const checkItems = async (checkList, inShootingItems, type, shootingId) => {
    let job = 'current';
    switch (type) {
      case 'eComm':
        job = 'current'
        break;
      case 'pGuide':
        job = 'product_guide'
        break;
      default:
        job = 'current'
        break;
    }
        
    for (let i = 0; i < checkList.length; i++) {
      if (inShootingItems.indexOf(checkList[i]) === -1) {
        let item = await itemsService.getItemById(checkList[i])
        let jobStatus = item.status[job]
        setETA(item, etaDate)
        switch (jobStatus) {
          case 'required':
            lookFor(item, job)
            //setETA(item, 'pending')
            break;
          case 'pending':
            markAsUrgent(item, job)
            //setETA(item, 'urgent')
            break;
          case 'urgent':
            //setETA(item, 'pending')
            toast.warning('El PC9: ' + item.pc9 + ' está siendo localizado')
            break;
          case 'available':
            assignToShooting(item, job)
            //setETA(item, etaDate)
            break;
          case 'unavailable':
            lookFor(item, job)
            //setETA(item, 'pending')
            break;
          case 'assigned':
            addToSearchArray(item, type)
            //setETA(item, etaDate)
            break;
          case 'toShoot':
            addToSearchArray(item, type)
            //setETA(item, etaDate)
            break;
          case 'waitingPhotos':
            addToSearchArray(item, type)
            //setETA(item, etaDate)
            break;
          case 'toReview':
            addToReviewArray(item, type)
            //setETA(item, 'toReview')
            break;
          case 'approved':
            addToReviewArray(item, type)
            //setETA(item, 'approved')
            break;
          case 'rejected':
            lookFor(item, job)
            //setETA(item, 'pending')
            break;
          default:
            console.log('Status not considered')
        }
      }
    }

    if (itemsToSearch.length > 0) {
      itemPrevioulyAssigned(type, shootingId)
    } else if (itemsToSearch.length === 0 && itemsWithPhotos.length > 0) {
      reviewItemsWithPhotos(type, shootingId)
    } else if (itemsToSearch.length === 0 && itemsWithPhotos.length === 0) {
      props.closeModal();
    }
  };

  const newShooting = async (e) => {
    e.preventDefault()

    for (let i = 0; i< props.checkList.length;i++){
      itemsService.put(props.checkList[i], {
        co: [{
          origin: selectedOrigin
        }]
      })
    }

    shootingService.post({
      co: [{
        name: newShootingName,
        description: shootingDescription,
        photoman: photoman,
        studio: location,
        producer: selectedProducer,
        type: selectedType,
        items_stack: props.checkList,
        items_count: props.checkList.length,
        scheduled_date: scheduledShootingDate,
        origin:selectedOrigin,
        delivery_date:etaDate
      }]
    }).then(response=>{
      let newShooting=response.d[0].ref._id;
      checkItems(props.checkList, [], selectedType, newShooting._id);
      if(props.getShootings){
        props.getShootings()
      }
      toast.success('Shooting creado correctamente')
    })
    .catch(error=>{
      console.log(error)
    })

  };

  const closeModal = () => {
    let auxModal = modal

    auxModal.show = false
    setModal({ ...{}, ...auxModal });
  }

  useEffect(() => {
    if (loading) fetch();
  }, [loading]);

  return (
    <div>
      <ModalCard
        {...modal}
        closeModal={closeModal}
      />
      {!createNewShooting ?
          <Box className="is-marginless">

            <div className="field is-horizontal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <div className="field-label is-normal">
                <label className="label">PC9</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <Tag.Group>
                    <Tag color="info">{props.checkList.length}</Tag>
                    <Tag>Piezas</Tag>
                  </Tag.Group>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Shooting</label>
              </div>
              <div className="field-body">
                <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Form.Select onChange={e => {
                    e.preventDefault()
                    setSelectedShooting(e.target.value)
                  }} name="shooting" value={selectedShooting} style={{ width: '250px' }}>
                    <option value="default" disabled>Selecciona un shooting</option>
                    {shootingList.map((element, index) => {
                      return (
                        <option value={element._id} key={index}>{'[' + element.items_count + '] ' + element.name} ({element.producer + '|'+ element.origin+' ' + moment(element.scheduled_date).format('ddd DD/MM/YY')})</option>
                      );
                    })}
                  </Form.Select>&nbsp;&nbsp;
                  <a href="/" onClick={(e) => {
                    e.preventDefault()
                    setCreateNewShooting(true)
                  }}>O crea uno nuevo</a>
                </div>
              </div>
            </div>
            <div className="field is-grouped is-grouped-right">
              <Button color="success" onClick={addItemsToShooting} disabled={selectedShooting === 'default' || props.checkList.length < 1}>Agregar PC9's</Button>
            </div>
          </Box>
          :
          <Box className="is-marginless">
            <form onSubmit={newShooting}>
              <div className="field is-horizontal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <div className="field-label is-normal">
                  <label className="label">PC9</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <Tag.Group>
                      <Tag color="info">{props.checkList.length}</Tag>
                      <Tag>Piezas</Tag>
                    </Tag.Group>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Nombre</label>
                </div>
                <div className="field-body">
                  <div className="field" >
                    <Form.Input
                      style={{ width: '300px' }}
                      autoComplete="off"
                      value={newShootingName}
                      name="name"
                      onChange={(e) => {
                        e.preventDefault()
                        setNewShootingName(e.target.value)
                      }}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Tipo</label>
                </div>
                <div className="field-body">
                  <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Form.Select onChange={e => {
                      e.preventDefault()
                      setSelectedType(e.target.value)
                    }} value={selectedType} placeholder="Selecciona un tipo de foto" >
                      <option key={1} value="eComm" > Ecommerce </option>
                      <option key={2} value="pGuide" > Guía de producto</option>

                    </Form.Select>
                  </div>
                </div>
              </div> */}



              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Origen</label>
                </div>
                <div className="field-body">
                  <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Form.Select onChange={e => {
                      e.preventDefault()
                      setSelectedOrigin(e.target.value)
                    }} value={selectedOrigin} placeholder="Selecciona un tipo de foto" >
                      <option key={1} value="default" > Indica el origen de las fotos</option>
                      <option key={2} value="local" > Producción local </option>
                      <option key={3} value="global" > Adaptaciones global</option>

                    </Form.Select>
                  </div>
                </div>
              </div>
             
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Productora</label>
                </div>
                <div className="field-body">
                  <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Form.Select onChange={e => {
                      e.preventDefault()
                      setSelectedProducer(e.target.value)
                    }} value={selectedProducer} placeholder="Selecciona un tipo de foto" >
                      <option key={1} value="default" > Selecciona la productora</option>
                      <option key={2} value="boicot" > Boicot </option>
                      <option key={3} value="curiosity" > Curiosity</option>

                    </Form.Select>
                  </div>
                </div>
              </div>


              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Locación</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <Form.Input
                      style={{ width: '300px' }}
                      autoComplete="off"
                      value={location}
                      name="studio"
                      onChange={(e) => {
                        e.preventDefault()
                        setLocation(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Fecha de Shooting</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      isClockOpen={false}
                      minDate={new Date()}
                      onChange={(date, e) => {
                        e.preventDefault()
                        setScheduledShootingDate(date)
                        setEtaDate(date)
                      }}
                      required
                      selected={scheduledShootingDate}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Fecha de entrega (ETA)</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      isClockOpen={false}
                      minDate={scheduledShootingDate}
                      onChange={(date, e) => {
                        e.preventDefault()

                        setEtaDate(date)
                      }}
                      required
                      selected={etaDate}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Fotógrafo</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <Form.Input
                      style={{ width: '300px' }}
                      value={photoman}
                      autoComplete="off"
                      name="photoman"
                      onChange={(e) => {
                        e.preventDefault()
                        setPhotoman(e.target.value)
                      }}
                      
                    />
                  </div>
                </div>
              </div>

              {/* <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Descripción</label>
                </div>
                <div className="field-body">
                  <div className="field" style={{ width: '100px' }}>
                    <Form.Textarea

                      autoComplete="off"
                      value={shootingDescription}
                      name="description"
                      onChange={(e) => {
                        e.preventDefault()
                        setShootingDescription(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="field is-grouped is-grouped-right">
                <Button color="success" type="submit" disabled={selectedOrigin === 'default'}>Crear shooting y agregar PC9's</Button>
              </div>
            </form>
          </Box>
      }
    </div>
  );
};

export default AddToShootingV2;
