import React, { Component } from 'react';

import QuotationService from '../../../Services/QuotationService';
import ItemsService from '../../../Services/ItemsService'
import {
  Button,
  Tag
} from 'react-bulma-components/full';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import ChannelService from '../../../Services/ChannelsService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class ReviewQuotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotation: this.props.quotation,
      loading: false,
    }
    this.quotationService = new QuotationService();
    this.itemsService = new ItemsService();
    this.channelsService = new ChannelService();
  }

  withComments = async(e)=>{
    e.preventDefault()
    let response = await this.quotationService.changeStatus(this.state.quotation._id, {
      co: [
        {
          status: 'sent'
        }
      ]
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true
    })
    await this.addLatamChannel()

    let response = await this.quotationService.changeStatus(this.state.quotation._id, {
      co: [
        {
          status: 'accepted'
        }
      ]
    });

    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.info('Ya puedes generar y descargar tus imágenes');

        this.props.fetch();

      } else {
        toast.error('Error al aprobar la cotización');
      }
    }

    this.props.close();
  }

  addLatamChannel = async () => {

    let latamChannels=['levi.cl', 'falabella', 'ripley', 'paris', 'dockers.cl', 'hites', 'dafiti', 'linio.cl', 'oeschle', 'Levi.comar']
    let latamChannelsIds=[]
    let itemsResponse = await this.itemsService.get({
      rf: ['_id', 'pc9'],
      qf: {
        quotation: this.props.quotation._id
      }
    });
    let items = itemsResponse.d;

    for (let j = 0; j<latamChannels.length;j++){
      let channelsResponse = await this.channelsService.get({
        rf: ['_id', 'name'],
        qf: {
          name: latamChannels[j]
        }
      });
      latamChannelsIds.push(channelsResponse.d[0])
    }
    for (let i = 0; i < items.length; i++) {
      for(let k = 0; k<latamChannelsIds.length;k++){
        await this.addNewChannel(items[i], latamChannelsIds[k])
      }
    }
  }


  addNewChannel = async (item, channel) => {
    let sku = item.pc9.slice(0, 5) + '-' + item.pc9.slice(5, 9)
    await this.itemsService.addNewChannel(item, channel, sku)
  }

  onChange = (event) => {
    let { quotation } = this.state;
    quotation[event.target.name] = event.target.value;
    this.setState({
      quotation
    });
  }

  onChangeFileInput = (event) => {
    let quotation = this.state.quotation
    quotation.estimate_result.file_pdf = event.target.files[0]
    this.setState({
      quotation,
      files: event.target.files[0]
    });
  }

  render() {
    return (
      <div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Nombre</label>
          </div>
          <div className="field-body">
            <div className="field">

              {this.state.quotation.name}

            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">PC9's</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Tag.Group>
                <Tag color="info">{this.state.quotation.items_stack.length}</Tag>
              </Tag.Group>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Solicitud</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p>{this.state.quotation.additional_info}</p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">PDF</label>
          </div>
          <div className="field-body">
            <div className="field">
              {this.state.quotation.estimate_result.file_pdf === null ?
                <p>Todavía no se carga el archivo</p> :
                <a href={this.state.quotation.estimate_result.file_pdf} target="_blank">Ver detalle (click) </a>}
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Notas</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p>{this.state.quotation.estimate_result.notes}</p>
            </div>
          </div>
        </div>




        <div className="field is-grouped is-grouped-right">
          {this.props.isViewOnly ?
            <Button color="warning" onClick={this.props.close}>OK</Button>
            :
            <div>
              {/* <Button color="warning" disable={this.state.loading} onClick={this.withComments}>
                {this.state.loading ? <FontAwesomeIcon spin={true} icon={faSpinner} /> : 'Tengo comentarios'}
              </Button>&nbsp;&nbsp; */}
              <Button color="success" disable={this.state.loading} onClick={this.handleSubmit}>
                {this.state.loading ? <FontAwesomeIcon spin={true} icon={faSpinner} /> : 'Aprobar cotización'}
              </Button>
            </div>

          }
        </div>
      </div>
    );
  }
}

ReviewQuotation.propTypes = {

};

ReviewQuotation.defaultProps = {
  fetch: () => { }
};

export default ReviewQuotation;