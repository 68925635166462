import React, { useEffect } from 'react';
import {
  useHistory,
  useLocation,
  withRouter
} from "react-router-dom";
import { Navbar } from "react-bulma-components/full";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

const Header = ({ loggedInUser, onChangeFluid, ...props }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (["/", "/dashboard"].indexOf(location.pathname) !== -1) {
      onChangeFluid(true);
    } else {
      onChangeFluid(false);
    }
  }, [location.pathname]);

  return (
    <Navbar
      id="navbar"
      fixed="top"
      className="push-top has-shadow no-print"
    >
      <Navbar.Brand>
        <Navbar.Burger
          onClick={props.toggleMenu}
        />

        <Navbar.Item onClick={() => history.push("/")}>
          <img
            alt="Phototool Levi's"
            src="/images/camera_icon.png"
          />&nbsp;
          PHOTOTOOL
        </Navbar.Item>
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container position="end" className="is-mobile">
          <Navbar.Item renderAs="div">

            <FontAwesomeIcon style={{ width: '30px', height: '30px' }} icon={faUser} />&nbsp;
            <div style={{ fontSize: '12px' }}>
              <strong>{loggedInUser.account.first_name + ' ' + loggedInUser.account.last_name}</strong>
              <p>{loggedInUser.account.job_title}</p>
            </div>

          </Navbar.Item>

          <Navbar.Item>&nbsp;</Navbar.Item>

        </Navbar.Container>
      </Navbar.Menu>

    </Navbar>
  );
};

export default withRouter(Header);
