import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  Button,
  Tag
} from "react-bulma-components/full";

import {
  faSearch
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemsService from '../../../Services/ItemsService';

class OnSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkList: []
    };

    this.itemsService = new ItemsService();
  }

  componentWillMount() {
    let checkList = this.props.checkList
    this.setState({
      checkList: checkList
    });
  }

  componentWillReceiveProps(props) {
    if (this.state.checkList !== props.checkList) {
      this.setState({
        checkList: props.checkList
      });
    }
  }



  lookForEcomm = async (e) => {
    e.preventDefault();
    //console.log(this.props.checkList)
    let toSearch=0
    for(let i=0;i<this.props.checkList.length;i++){

      let item = await this.itemsService.getItemById(this.props.checkList[i])

      if( item.status.current === 'required' ||
        item.status.current === 'pending' ||
        item.status.current === 'available' 
        ){
          toSearch++
          await this.itemsService.put(item._id, {
              co: [{
                  'status.current': 'pending'
              }]
          })
      } else {
        toast.error('El PC9: '+ item.pc9 + ' no puede regresar a búsqueda')
      }
    }
    toast.success('[ECOMM] Se ha solicitado la búsqueda de: '+toSearch + ' PC9')
    this.props.updateTable()
    this.props.close()
  }

  lookForPguide = async (e) => {
    e.preventDefault();
    //console.log(this.props.checkList)
    let toSearch=0
    for(let i=0;i<this.props.checkList.length;i++){

      let item = await this.itemsService.getItemById(this.props.checkList[i])

      if( item.status.product_guide=== 'required'){
          toSearch++
          await this.itemsService.put(item._id, {
              co: [{
                  'status.product_guide': 'pending'
              }]
          })
      }
    }
    toast.success('[PGUIDE] Se ha solicitado la búsqueda de: '+toSearch + ' PC9')
    this.props.updateTable()
    this.props.close()
  }



  render() {
    return (
      <div className="has-text-centered">

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          
            <label className="label">Solicitar búsqueda de: </label>&nbsp;&nbsp;
          
          <Tag.Group className="label">
            <Tag color="info">{this.props.checkList.length}</Tag>
            <Tag>PC9</Tag>
          </Tag.Group>

            &nbsp;
            <div>
            {/* <Button color="default" disabled={this.state.loading} onClick={this.lookForPguide}>
              <FontAwesomeIcon icon={faSearch} />&nbsp;
                Buscar para pGuide
              </Button> */}
              &nbsp;&nbsp;
              <Button color="warning" disabled={this.state.loading} onClick={this.lookForEcomm}>
              <FontAwesomeIcon icon={faSearch} />&nbsp;
                Buscar para eComm
              </Button>
          </div>
        </div>

      </div>
    );
  }
}

OnSearch.defaultProps = {
  checkList: []
};

export default OnSearch;
