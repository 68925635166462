
import React, { Component } from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSpinner
} from '@fortawesome/free-solid-svg-icons';;

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <span>
        <FontAwesomeIcon icon={this.props.icon} pulse /> 
        <span>&nbsp;</span>
        Cargando...
      </span>
      
    );
  }
}

Loader.defaultProps = {
  icon: faSpinner
};

export default Loader;