import React, {Component} from 'react';
import moment from 'moment';
import LogsService from '../../../../../../Services/LogsService';

import {
  Button,
  Card,
  Content,
  Heading,
  Media,
  Panel,
} from 'react-bulma-components/full';

import {
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ResponseList from '../CommentResponse/ResponseList';

import './style.css';
import {STATUS} from '../../../../../General/Constants/Log';

class CommentItem extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      responses: []
    };

    this.logsService = new LogsService();

  }

  componentDidMount() {
    if (this.props.log.status === 'active') {  
      this.props.onCommentActive();
    }
  }

  resolve = () => {
    this.logsService.resolve(this.props.log._id)
      .then((response) => {
        for (let err in response.error) {
          if (response.error[err]) {
            this.props.onChange();
            this.props.onCommentActive(false);
          }}
      });
  }

  render () {
    return (
      <Panel.Block key={this.props.log._id} className={(this.props.log.status === 'active' ? 'is-active' : '')}>
        <span className="panel-icon">
          <FontAwesomeIcon icon={faComment} />
        </span>
        <Card radiusless={true} shadowless={true} className="commentFullWidth">
          <Card.Content className="is-paddingless">
            <Media>
              <Media.Item>
                <Heading size={6}>{this.props.log.user ? this.props.log.user.email : 'anónimo'}</Heading>
                <Heading subtitle size={6}>
                  <small>{moment(this.props.log.created_at).format('DD/MM/YYYY h:mm:s A')}</small>
                </Heading>
              </Media.Item>
              <Media.Item>
                {this.props.log.status === STATUS.ACTIVE ?
                  <Button
                    pull="right" 
                    size="small"
                    onClick={this.resolve}
                  >
                    Resolver
                  </Button>
                :
                  <Button
                    pull="right" 
                    size="small"
                    color="primary"
                    disabled
                  >
                    Resuelto
                  </Button>
                }
              </Media.Item>
            </Media>
            <Content>
              {this.props.log.data.comment}
            </Content>
          </Card.Content>
          {this.props.log.status === 'active' ?
            <ResponseList log={this.props.log} />
          :
            null
          }
        </Card>
      </Panel.Block>
    );
  }
}

CommentItem.propTypes = {

};

CommentItem.defaultProps = {

};

export default CommentItem;