import React from 'react';
import PropTypes from 'prop-types';

function Empty(props) {
  return (
    <tr className="fullwidth">
      <td colSpan={props.columnsLength} className="has-text-centered">
        {props.loading ? 
          props.loadingLegend
          :
          props.emptyLegend
        }
      </td>
    </tr>
  );
}

Empty.propTypes = {
  emptyLegend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loadingLegend: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

Empty.defaultProps = {
  emptyLegend: <b>Empty</b>,
  loadingLegend: <b>Loading...</b>
};

export default Empty;
