import React, { Component } from 'react';

import { 
  faPlus,
  faEdit
} from '@fortawesome/free-solid-svg-icons';

import Datatable from '../General/Datatable/Datatable';
import ModalCard from '../General/Modal/ModalCard';
import UpdateChannelForm from './components/UpdateChannelForm';
import CreateChannelForm from './components/CreateChannelForm';
import ChannelService from '../../Services/ChannelsService';

class Channels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      queryString: {
        rf: ['_id', 'name', 'width', 'heigth', 'maxSize', 'density', 'nameExtension', 'charSeparator', 'numerationBegin'],
        qf: {
          season: process.env.APP_DEFAULT_SEASON
        }
      },
      loading: true,
      table: {
        checkCounter:false,
        title: 'Catálogo de canales',
        searchFields: ['name'],
        columns: [ 
          {
            field: 'name',
            label: 'Canal',
            sortable: true
          }, {
            field: 'width',
            label: 'Ancho (px)',
            sortable: true
          }, {
            field: 'height',
            label: 'Largo (px)',
            sortable: true
          }, {
            field: 'density',
            label: 'DPI',
            sortable: true
          }, {
            field: 'maxSize',
            label: 'Tamaño máximo (Kb)',
            sortable: true
          },{
            field: 'charSeparator',
            label: 'Separador',
            sortable: true
          }, {
            field: 'numerationBegin',
            label: 'Inicio num',
            sortable: true
          }, {
            label: 'Acciones',
            type: 'actions',
            actions: [
              {
                title: 'Editar',
                icon: faEdit,
                onClick: (channel) => this.editChannel(channel._id),
                visible: (channel) => {
                  if (channel.name !== 'master') {
                    return true;
                  }
                  return false;
                }
              }
            ]
          }
        ],
        actions: [
          {
            title: 'Agregar canal',
            label: 'Crear canal',
            icon: faPlus,
            className: 'is-warning',
            onClick: () => {
              this.createNewChannel();
            }
          }
        ]
      },
      modal: {
        show: false,
        size: ''
      }
    }
    this.confirm = {
      title: <strong>Confirmar</strong>,
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onKeypressEscape: () => {}
    };

    this.service = new ChannelService()
  }
  
  closeModal = () => {
    let modal = {
      show: false
    };
    this.fetch();
    this.setState({modal: modal});
  }

  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({modal: modal});
  }

  componentDidMount() {
    this.fetch();
    // if (this.props.season._id) {
    //   this.fetch();
    // }
  }

  componentWillReceiveProps(props) {
    this.fetch()
    // if (this.props.season !== props.season) {
    //   let queryString = this.state.queryString;

    //   queryString.qf.season = props.season._id;

    //   this.setState({
    //     queryString: queryString
    //   }, () => {
    //     this.fetch();
    //   });
    // }
  }

  fetch = () => {
    this.service.get({}, true)
      .then((response) => {
        this.setState({
          data: response.d,
          loading: false
        })
      });
  }

  createNewChannel = () => {
    let modal = this.state.modal;
    modal.title = 'Agrega un nuevo canal';
    modal.size = '';
    modal.show = true;
    modal.body = (<CreateChannelForm close={this.closeModal}/>);
    this.openModal();
    this.setState({
      modal: modal
    });
  }

  editChannel = (channelId) => {
    let modal = this.state.modal;
    modal.title = 'Actualiza la información del canal';
    modal.size = '';
    modal.show = true;
    modal.body = (
      <UpdateChannelForm 
        channelId={channelId}
        close={this.closeModal}
      />
    );

    this.setState({
      moda: modal
    });
  }

  deleteChannel = (id) =>{
    //falta agregar confirmación de borrado
    this.service.delete(id)
    .then(response => {
      this.fetch();
    })
    .catch(error =>{
      console.log(error)
    });
  }

  render() {
    return (
      <div>
        <ModalCard 
          title={this.state.modal.title}
          show={this.state.modal.show}
          body={this.state.modal.body}
          closeModal={this.closeModal}
          size={this.state.modal.size}
        />
        <Datatable 
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
          onChange={this.onChangeDatatable}
          fetch={this.fetch}
        />
      </div>
    );
  }
};

export default Channels;
