import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import search from "../CurbsideData/assets/search.png";
import { toast } from "react-toastify";
import ItemService from "../../../../Services/ItemsService";

import "./buscador.scss";

const itemService = new ItemService();

const Buscador = () => {
  const [pc9, setPc9] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const history = useHistory();

  const handleKeyPress = ({ target }) => {
    let { value } = target;
    let newValue;
    let join;

    value = value.replace(/[^a-zA-Z0-9]/, '');

    if (value.length > 9) return;

    value = value.toUpperCase();
    join = value.split('-').join('');
    newValue = value;

    if (join.length > 5) {
      newValue = join.slice(0, 5) + "-" + join.slice(5);
    }

    if (pc9 !== newValue) {
      setPc9(newValue);
    }
  };

  useEffect(() => {
    if (pc9.length === 10) {
      getPC9();
    }
    setBtnDisabled(true);
  }, [pc9]);

  const getPC9 = async () => {
    let queryString = {
      rf: ["_id", "status", "pc9"],
      qf: {
        pc9: pc9.replace("-", ""),
      },
    };
    let response = await itemService.get(queryString);

    if (response.d[0] === undefined) {
      toast.error("El PC9 " + pc9 + " no existe");
    } else {
      let imgStatus = response.d[0].status.current;
      let infoStatus = response.d[0].status.mkt;
      if (imgStatus !== "approved" || infoStatus !== "approved") {
        toast.warn("El PC9 " + pc9 + " no tiene fotos o información aprobadas");
      } else {
        toast.success("El PC9 " + pc9 + " tiene fotos e información aprobadas");
        setBtnDisabled(false);
      }
    }
  };

  const goToDetail = () => {
    history.push(`/curbside/${pc9.replace("-", "")}`);
  };

  return (
    <div className="buscador-container">
      <div className="buscador">
        <div className="buscador__header">
          <img src="/images/levis_phototool.png" alt="phototool-logo" />
          <h2>Curbside Catalogue</h2>
        </div>
        <div className="buscador__input">
          <input
            onChange={(e) => handleKeyPress(e)}
            placeholder="PC9"
            type="text"
            value={pc9}
          />
          <img src={search} alt="search-icon" />
        </div>
        <button disabled={btnDisabled} onClick={() => goToDetail()}>
          Ir a detalle
        </button>
      </div>
    </div>
  );
};

export default Buscador;
