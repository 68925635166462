import React, { useEffect, useState } from "react";
import { Columns, Container } from "react-bulma-components";
import DashboardFilters from "./DashboardFilters";
import PdpGeneral from "./PdpGeneral";
import InfoHeader from "./InfoHeader";
import PdpMonth from "./PdpMonth";
import PhotoSchedule from "./PhotoSchedule";
import useDashboard from "../../_hooks/useDashboard";
import EmptyFilters from "./EmptyFilters";
import LoadingRequest from "./LoadingRequest";
import "./style.scss";

const prefix = "v-dashboard";

const Dashboard = ({ loggedInUser }) => {
  const { 
    applyFilters,
    fetchAllData,
    filterOptions,
    filters,
    fetchFilterOptions,
    getTags,
    tags
  } = useDashboard();  

  const [data, setData] = useState({
    copyStatus: {},
    photoOrigin: {},
    photoStateusEcomm: {},
    photoStatusProgress: {},
    shootings: [],
    timelinePhoto: {}
  });
  const [emptyFilters, setEmptyFilters] = useState(true);
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    if (!filters) return;
    setLoading(true);
    let _filters = {};

    for (let f in filters) {
      if (!filters[f] || !filters[f].length) continue;

      _filters[f] = filters[f].map((r) => r.value);
    }
    if (Object.keys(_filters).length) {
      let _data = await fetchAllData(_filters);
      setData(_data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFilterOptions(["season", "brand", "gender", "category"]);
  }, []);

  useEffect(() => {
    if ((filters.season && filters.season.length) || 
      (filters.brand && filters.brand.length) || 
      (filters.channels && filters.channels.length) || 
      (filters.gender && filters.gender.length) || 
      (filters.category && filters.category.length)
    ) {
      setEmptyFilters(false);
      fetch();
    } else {
      setEmptyFilters(true);
      setData({
        copyStatus: {},
        photoOrigin: {},
        photoStateusEcomm: {},
        photoStatusProgress: {},
        shootings: [],
        timelinePhoto: {}
      });
    }

    getTags(filters);
  }, [filters]);

  return (
    <Columns className={prefix}>
      <Columns.Column size={2} className={`${prefix}__filters no-print`}  id="dashboard-filters">
        <DashboardFilters
          filters={filters}
          filterOptions={filterOptions}
          loading={loading}
          toggleLoader={setLoading}
          onChange={applyFilters}
        />
      </Columns.Column>
      <Columns.Column size={10}>

        <div className={`${prefix}__data`}>
          {emptyFilters ?
            <EmptyFilters />
          :
            <>
              <LoadingRequest className="no-print" isVisible={loading} />
              {!loading && 
                <div id="charts">
                  <InfoHeader 
                    loggedInUser={loggedInUser}
                  />
                  <PdpGeneral 
                    {...data}
                    tags={tags}
                  />
                  <PdpMonth
                    {...data}
                  />
                  <PhotoSchedule
                    shootings={data.shootings}
                  />
                </div>
              }
            </>
          }
        </div>
      </Columns.Column>
    </Columns>
  )
};

export default Dashboard;
