import React from "react";
import { ReactComponent as CardPayment } from "../assets/CardPayment.svg";
import { ReactComponent as Atencion } from "../assets/Atencion.svg";
import { ReactComponent as Box } from "../assets/Box.svg";

const Faqs = () => {
  return (
    <>
      <div className="curbside__faqs-item">
        <CardPayment />{" "}
        <a
          href="https://www.levi.com.mx/sp/preguntas-frecuentes/formas-de-pago-y-precio#s1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Paga a Meses o en Efectivo
        </a>
      </div>
      <div className="curbside__faqs-item">
        <Box />
        <a
          href="https://www.levi.com.mx/sp/preguntas-frecuentes/devoluciones-y-reembolsos#s1-p1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Devoluciones fáciles y gratis
        </a>
      </div>
      <div className="curbside__faqs-item">
        <Atencion />
        <a
          href="https://www.levi.com.mx/sp/preguntas-frecuentes/contactar#s1-p1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Asesoría Telefónica: 01 (55) 8525 1206
        </a>
      </div>
    </>
  );
};

export default Faqs;
