import React from "react";
import "./infoCard.scss";

const InfoCard = ({
  name,
  simple_description,
  marketing_description,
  gender,
  composition,
  category,
  simple_color,
  technology,
}) => {
  return (
    <div className="info-card">
      <h1 className="info-card__title">{name}</h1>
      <p className="info-card__shortDescription">{simple_description}</p>
      {technology && (
        <div className="info-card__ts">
          <span>{technology}</span>
        </div>
      )}
      <div className="info-card__c">
        <div className="info-card__c-item">
          <span>Categoría</span>
          <span>{category}</span>
        </div>
        <div className="info-card__c-item">
          <span>Género</span>
          <span>{gender}</span>
        </div>
        <div className="info-card__c-item">
          <span>Color</span> <span>{simple_color}</span>
        </div>
      </div>
      <p className="info-card__description"> {marketing_description}</p>
      <div className="info-card__destacado">
        <h4>Características destacadas</h4>
        <ul>
          <li>{name}</li>
          <li>{composition}</li>
        </ul>
      </div>
    </div>
  );
};

export default InfoCard;
