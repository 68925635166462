import React from 'react';
import PropTypes from 'prop-types';

import {
  Form
} from "react-bulma-components/full";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch
} from '@fortawesome/free-solid-svg-icons';

function Search(props) {

  const searching = (event) => {
    let textSearch = event.target.value;

    props.onChange(textSearch);
  }

  return (
    <div className="control has-icons-right">
      <Form.Input 
        autoComplete="off"
        value={props.textSearch}
        onChange={searching}
        placeholder={props.placeholder}
      />
      <span className="icon is-small is-right">
        <FontAwesomeIcon icon={faSearch}/>
      </span>
    </div>
  );
}

Search.propTypes = {
  onChange: PropTypes.func,
  textSearch: PropTypes.string
};

Search.defaultProps = {
  onChange: () => {},
  textSearch: ''
};

export default Search;
