import React, { useEffect, useState } from 'react';
import {
    Tag
} from 'react-bulma-components/full';
import { faBan, faSearch, faCamera, faCheckCircle, faCircle, faClock, faCross, faCrosshairs, faExclamationCircle, faExclamationTriangle, faFlag, faInfoCircle, faPenSquare, faPhotoVideo, faTshirt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { toast } from 'react-toastify';



function ETACell(props) {  

  const [sentToSearch, setSentToSearch] = useState(false)
  const [itemStatus, setItemStatus] = useState(props.row.status.current)

  const getColor =(daysElapsed)=>{
    if (daysElapsed <= -2){
        return 'danger'
    } else if(daysElapsed >= -1 && daysElapsed < 3 ){
        return 'warning'
    } else if (daysElapsed >= 0){
        return 'info'
    }
  }
  const toSearch =()=>{
    const item = props.row
    let sentToSearch = false 
    sentToSearch = props.onSearch(item)
    if(sentToSearch){
      toast.success('Se han solicitado las fotos del PC9: '+item.pc9 + ' correctamente')
      setSentToSearch(true)
    }
    console.log(item.pc9)
  }

  const getExtraData = ()=>{
    const item = props.row
    const status = item.status.current;
    const eta = new Date(props.content)
    let nowDate = new Date()
    let daysToETA = Math.round((eta.getTime()-nowDate.getTime())/(1000 * 3600 * 24))

    let eta_info = {
        color:'grey-light',
        value: 'No ETA',
        icon: faClock
    }
    switch (status) {
        case 'required':
          eta_info.color='link'
          eta_info.value= 'Solicitar fotos'
          eta_info.icon= faSearch
          break;
        case 'pending':
          eta_info.color='light'
          //eta_info.value= 'Buscando muestra'
          eta_info.value= (moment(eta).isValid() ? moment(eta).format('DD/MM/YY')+ ' | '+daysToETA +'d': 'Buscando muestra')
          eta_info.icon= faCrosshairs
          break;
        case 'urgent':
          eta_info.color='light'
          //eta_info.value= 'Búsqueda urgente'
          eta_info.value= (moment(eta).isValid() ? moment(eta).format('DD/MM/YY')+ ' | '+daysToETA +'d': 'Búsqueda urgente')
          eta_info.icon= faExclamationCircle
          break;
        case 'available':
          eta_info.color='light'
          //eta_info.value= 'Por asignar fecha'
          eta_info.value= (moment(eta).isValid() ? moment(eta).format('DD/MM/YY')+ ' | '+daysToETA +'d': 'Por asignar fecha')
          eta_info.icon= faFlag 
          break;
        case 'unavailable':
          eta_info.color='light'
          eta_info.value= 'Muestra no disponible'
          eta_info.icon= faBan
          break;
        case 'assigned':
          eta_info.color=(moment(eta).isValid() ? getColor(daysToETA): 'warning')
          eta_info.value= (moment(eta).isValid() ? moment(eta).format('DD/MM/YY')+ ' | '+daysToETA +'d': 'Shooting asignado')
          eta_info.icon= faClock
          break;
        case 'toShoot':
          eta_info.color=(moment(eta).isValid() ? getColor(daysToETA): 'warning')
          eta_info.value= (moment(eta).isValid() ? moment(eta).format('DD/MM/YY')+ ' | '+daysToETA +'d': 'Tomando fotos')
          eta_info.icon= faClock
          break;
        case 'waitingPhotos':
          eta_info.color=(moment(eta).isValid() ? getColor(daysToETA): 'warning')
          eta_info.value= (moment(eta).isValid() ? moment(eta).format('DD/MM/YY')+ ' | '+daysToETA +'d': 'Editando')
          eta_info.icon= faClock
          break;
        case 'toReview':
          eta_info.color=(moment(eta).isValid() ? getColor(daysToETA): 'warning')
          eta_info.value= (moment(eta).isValid() ? 'Requiere aprobación | '+daysToETA +'d': 'Requiere aprobación')
          eta_info.icon= faClock
          break;
        case 'approved':
            eta_info.color='success'
            eta_info.value= 'Entregado'
            eta_info.icon= faCheckCircle
    
          break;
        case 'rejected':
            eta_info.color='danger'
            eta_info.value= 'ERROR'
            eta_info.icon= faCheckCircle
          break;
        default:
          console.log('Status not considered')
      }
    
      return eta_info;
  }


  return (
    <div>
    
{sentToSearch ? 
<Tag color={'light'}>
<FontAwesomeIcon icon={faCrosshairs} /> &nbsp;
Buscando muestra
</Tag>
:

<Tag color={getExtraData().color} onClick={toSearch} >
<FontAwesomeIcon icon={getExtraData().icon} /> &nbsp;
{getExtraData().value}
</Tag>

}
    </div>
  )
}

ETACell.defaultProps = {
  className: '',
  renderAs: 'span'
}


export default ETACell;



