import React from 'react';

import {
  Button
} from "react-bulma-components/full";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import ActionsDropdown from './Actions/ActionsDropdown';

import './actions.scss';

function Actions(props) {
  
  const checkVisible = (element) => {
    let visible = true;
    if (element.visible) {
      if (typeof element.visible === 'function') {
        visible = element.visible();
      } else {
        visible = element.visible;
      }
    }

    return visible;
  }

  const checkDisabled = (element) => {
    let disabled = false;
    if (element.disabled) {
      if (typeof element.disabled === 'function') {
        disabled = element.disabled();
      } else {
        disabled = element.disabled;
      }
    }

    return disabled;
  }

  const checkWaiting = (element) => {
    let waiting = false;
    if (element.waiting) {
      if (typeof element.waiting === 'function') {
        waiting = element.waiting();
      } else {
        waiting = element.waiting;
      }
    }

    return waiting;
  }

  
  return(
    <div className="dt-actions buttons is-pulled-right">
      {props.actions.map((item, index) => {
        if (checkVisible(item)) { 
          if (item.options) {
            return <ActionsDropdown {...item} key={index} disabled={checkDisabled(item) || checkWaiting(item)} />
          } else {
            return (
              <Button 
                key={index} 
                className={item.className}
                color={item.color}
                disabled={checkDisabled(item) || checkWaiting(item)}
                title={item.title}
                onClick={item.onClick}
              >
                {checkWaiting(item) ? 
                  <FontAwesomeIcon icon={faSpinner} spin={true} ></FontAwesomeIcon>
                :
                  <FontAwesomeIcon icon={item.icon} ></FontAwesomeIcon>
                }
                &nbsp;
                {item.label}
              </Button>
            );
          }
        } 
        return null;
      }
    )}
    </div>
  );
}

export default Actions;
