import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  Content,
  Panel
} from 'react-bulma-components/full';

class CommentItemEmpty extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Panel.Block>
        <Card radiusless={true} shadowless={true} className="commentFullWidth">
          <Card.Content className="is-paddingless">
            <Content>
              Aún no hay comentarios
            </Content>
          </Card.Content>
        </Card>
      </Panel.Block>
    );
  }
}

CommentItemEmpty.propsDefault = {
  leyend: "Empty registry."
}

CommentItemEmpty.propTypes = {
  leyend: PropTypes.string
}

export default CommentItemEmpty;