import React, { useState } from 'react';
import './style.scss'
import AuthService from '../../Services/AuthService';
import {
  Link
} from "react-router-dom";

import { toast } from 'react-toastify';

import {
  Button,
  Container,
  Columns,
  Form,
  Heading
} from "react-bulma-components/full";

import logo from '../../levis_phototool.png';

const authService = new AuthService();

const Restore = (props) => {
  const [password, setPassword] = useState('');
  const [sending, setSending] = useState();
  const [sent, setSent] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSending(true);
    authService.restore(props.match.params.token, password)
      .then( response => {
        setSent(true);
        setSending(false);
        for (let e in response.error) {
          switch(response.error[e].code) {
            case 400:
              toast.error("El token usado no existe, verifica visitar el link en el correo que se te envió.");
              break;
            case 410:
              toast.error("El token ya se utilizó previamente para cambiar la contraseña.");
              break;
            case 0:
              toast.success("Cambio de contraseña exitoso.");
              break;
            default:
              break;
          }
        }
      })
      .catch(() => {
        setSending(false);
      });
  }

  return (
    <Container className="has-text-centered">
    <Columns>
      <Columns.Column size={4} offset={4}>
        <div className="box">
          <div className="avatar">
            <figure className="avatar">
              <img style={{width:'150px'}} src={logo} alt="Boicot" className="avatar-circle"/>
            </figure>
          </div>
          <Heading size={4} className="title has-text-grey">Cambiar contraseña</Heading>

          {sent ? 
            <p>Se ha cambiado la contraseña.</p>
          :
            <form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Control>
                  <Form.Input
                    autoComplete="off"
                    value={password}
                    name="password"
                    placeholder="Nueva contraseña (min. 4 caracteres)"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    type="password"
                  >
                  </Form.Input>
                </Form.Control>
              </Form.Field>

              <Button
                color="info"
                className="is-block is-fullwidth"
                disabled={sending || !password.length > 4}
              >
                Cambiar contraseña
              </Button>
            </form>
          }
        </div>

        <p className="has-text-grey">
          <Link to="/login">Iniciar sesión</Link>
        </p>
        <br />
        <p>Phototool v{props.version}</p>
      </Columns.Column>
    </Columns>
  </Container>
  );
};

export default Restore;
