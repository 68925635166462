import React, { Component } from 'react';

import QuotationService from '../../../Services/QuotationService';
import {
  Button,
  Form,
  Tag
} from 'react-bulma-components/full';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


class UploadQuotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotation: this.props.quotation,
      files: null,
      loading: false,
    }
    this.quotationService = new QuotationService();
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    let response = await this.quotationService.uploadPDF(this.state.quotation, this.state.files);

    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.info('Se ha envíado la cotización al solicitante');
      } else {
        toast.error('Error al cargar la cotización');
      }
    }
    this.props.fetch();
    this.props.close();
  }

  handleSubmit = async (event) => {
    this.setState({loading:true})
    event.preventDefault();
    let response = await this.quotationService.uploadPDF(this.state.quotation, this.state.files);

    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.info('Se ha envíado la cotización al solicitante');
      } else {
        toast.error('Error al cargar la cotización');
      }
    }
    this.props.fetch();
    this.props.close();
  }

  onChange = (event) => {
    let quotation = this.state.quotation
    quotation.estimate_result.notes = event.target.value

    this.setState({
      quotation
    });
  }

  onChangeFileInput = (event) => {
    this.setState({
      files: event.target.files
    });
  }


  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Nombre</label>
          </div>
          <div className="field-body">
            <div className="field">

              {this.state.quotation.name}

            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">PC9's</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Tag.Group>
                <Tag color="info">{this.state.quotation.items_stack.length}</Tag>

              </Tag.Group>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Detalle</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p>{this.state.quotation.additional_info}</p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Notas</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Form.Textarea
                autocomplet="off"
                value={this.state.quotation.estimate_result.notes}
                name="notes"
                placeholder='Agrega comentarios respecto a la cotización que estás enviando'
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">PDF</label>
          </div>
          <div className="field-body">
            <div className="field">
              <input
                type='file'
                name="files"
                onChange={this.onChangeFileInput}
                accept={'.pdf,pdf'}
                required
              />
            </div>
          </div>
        </div>
        <div className="field is-grouped is-grouped-right">
        <Button color="success" type="submit" disabled={this.state.loading}>              
          {this.state.loading ? <FontAwesomeIcon spin={true} icon={faSpinner}/>: 'Subir cotización'}
        </Button>
        </div>
      </form>
    );
  }
}

UploadQuotation.propTypes = {

};

UploadQuotation.defaultProps = {
  fetch: () => { }
};

export default UploadQuotation;
