import React, { Component } from 'react';

import QuotationService from '../../../Services/QuotationService';
import {
  Button,
  Form,
  Tag
} from 'react-bulma-components/full';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';


class SendQuotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotation: this.props.quotation
    }
    this.quotationService = new QuotationService();
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    let response = await this.quotationService.changeStatus(this.state.quotation._id, {
      co: [{
        name: this.state.quotation.name,
        id_owner: this.props.id_owner,
        additional_info: this.props.quotation.additional_info,
        status: 'sent'
      }]
    });

    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.info('La cotización será revisada, te haremos saber cuando esté lista');
        if (this.props.fetch) {
          this.props.fetch();
        }
      } else {
        toast.error('Error al crear la cotización');
      }
    }

    this.props.close();
  }

  onChange = (event) => {
    let { quotation } = this.state;
    quotation[event.target.name] = event.target.value;
    this.setState({
      quotation
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Nombre</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Form.Input
                autocomplete="off"
                placeholder='Ponle un nombre'
                value={this.state.quotation.name}
                name="name"
                onChange={this.onChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">PC9's</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Tag.Group>
                <Tag color="info">{this.state.quotation.items_stack.length}</Tag>

              </Tag.Group>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Comentarios</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Form.Textarea
                autocomplet="off"
                value={this.state.quotation.additional_info}
                name="additional_info"
                placeholder='Agrega el tiempo de uso, los medios y los paises en los que se utilizarán las imágenes'
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <Button color="success" type="submit" disabled={this.props.quotation.items_stack.length===0}>Solicitar cotización</Button>
        </div>
      </form>
    );
  }
}

SendQuotation.propTypes = {

};

SendQuotation.defaultProps = {
  fetch: () => { }
};

export default SendQuotation;