// Services/AuthService.js

import axios from 'axios';

class AuthService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/auth`,
      withCredentials: true
    });
    this.service = service;
  }

  signup = (user) => {
    return this.service.post('/signup', user)
      .then(response => response.data)
      .catch((response) => {
        console.log(response);
      });
  }

  loggedin = () => {
    return this.service.get('/loggedin')
      .then(response => response.data)
  }

  login = (user) => {
    return this.service.post('/login', user)
      .then(response => response.data)
      .catch((response) => {
        console.log(response);
      });
  }
  
  logout = () => {
    return this.service.post('/logout', {})
      .then(response => response.data)
  }

  forgot = (email) =>{
    return this.service.post('/forgot', {
        email: email
      })
      .then(response => response.data)
      .catch(error => error.response);
  }

  restore = (token, newPassword) => {
    return this.service.post('/restore/' + token, {
        token: token,
        password: newPassword
      })
      .then(response => response.data)
      .catch(error => {
        if (error.response.data) {
          return error.response.data;
        }
        return error.response;
      });
  }
}

export default AuthService;
