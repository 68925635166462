// Services/ChannelsService.js

import axios from 'axios';
import qs from 'qs';

class ChannelService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/channel`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      }
    });
    this.service = service;
  }

  get = (query = {}, all = false, apiConsumer = false) => {
    if (!all) {
      if (!query.qf) {
        query.qf = {};
      }
      
      query.qf.api_consumer = apiConsumer;
    }

    return this.service.get('/', { params: query })
      .then(response => response.data)
      .catch((error) => {
        //console.log(error);
        return error.response.data;
      });
  }

  getById = (id) => {
    return this.service.get('/' + id)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  post = (data) => {
    return this.service.post('/', data)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  put = (id, data) => {
    return this.service.put('/' + id, data)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }
  
  multiPut = (data) => {
    return this.service.put('/', data)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  delete = (id) =>{
    return this.service.delete('/'+id)
    .then(response => response.data)
    .catch(error =>{
      console.log(error)
      return error.response.data
    })
  }
}

export default ChannelService;
