import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ImageEmptyFilters } from "./utils/business-analytics-icon.svg";

const prefix = "d-loading-request";

const LoadingRequest = ({ className, isVisible }) => {
  
  return (
    <div className={`${prefix} ${className || ""} ${isVisible ? "isVisible" : "notVisible"}`}>
      <div>
        <FontAwesomeIcon icon={faSpinner} spin />
        {/* <ImageEmptyFilters /> */}
        <h2 className="title">LEVI STRAUSS & CO.</h2>
        <h5 className="subtitle"><b>Reportes de entrega de contenido pdp</b></h5>
      </div>
    </div>
  );
};

export default LoadingRequest;
