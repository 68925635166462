import React, {Component} from 'react';
import moment from 'moment';

import {
  Heading,
  Panel
} from 'react-bulma-components/full';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCommentAlt
} from '@fortawesome/free-solid-svg-icons';

class ResponseItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return(
      <Panel.Block>
        <span className="panel-icon">
          <FontAwesomeIcon icon={faCommentAlt} />
        </span>
        <Heading size={6} subtitle={true}>
          <small>
            <p>
              <strong><u>{this.props.log.user ? this.props.log.user.email : 'anónimo'}</u></strong>
              &nbsp;
              {this.props.log.created_at ? moment(this.props.log.created_at).format('DD/MM/YYYY h:mm:s A') : ''}
            </p>
            {this.props.log.data ? this.props.log.data.response : ''}
          </small>
        </Heading>
      </Panel.Block>
    );
  }
}

ResponseItem.propTypes = {
  
};

ResponseItem.defaultProps = {

};

export default ResponseItem;