import React from 'react';
import PropTypes from 'prop-types';

import {
  Form
} from "react-bulma-components/full";

function CheckboxCell(props) {

  const isCheckable = (props) => {
    let checkable = true;
    if (props.checkable) {
      if (typeof props.checkable === 'function') {
        checkable = props.checkable(props.row);
      } else {
        checkable = props.checkable;
      }
    }
  
    return checkable;
  }

  return (
    <Form.Checkbox 
      checked={props.checked}
      disabled={!isCheckable(props)}
      onChange={() => props.checkItemToggle()}
      value={props.content}
    />
  );
}

CheckboxCell.defaultProps = {
  checkable: true,
  checked: false
};

CheckboxCell.propTypes = {
  checkable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  checked: PropTypes.bool
};

export default CheckboxCell;