import React, { useState } from "react";
import ItemsService from "../../../../Services/ItemsService";
import ShootingService from "../../../../Services/ShootingService";

import { Button, Columns, Image } from "react-bulma-components/full";

import { toast } from "react-toastify";
import { ModalCard } from "../../../General/Modal";
const itemsService = new ItemsService();
const shootingService = new ShootingService();

const DetailsPhotos = (props) => {
  const [actualItem, setActualItem] = useState(0);
  const [modal, setModal] = useState({
    title: "",
    footer: "",
    show: false,
    body: "",
    size: "is-medium",
  });

  const getCategoryIcon = () => {
    const category = props.items[actualItem].item.category;
    var categoryIcon = "";
    switch (category) {
      case "Tops":
      case "TOPS":
        categoryIcon = "./images/icon_category/tops.png";
        break;
      case "Bottoms":
      case "BOTTOMS":
        categoryIcon = "./images/icon_category/bottoms.png";
        break;
      case "Belts":
        categoryIcon = "./images/icon_category/belts.png";
        break;
      case "Wallets":
        categoryIcon = "./images/icon_category/wallet.png";
        break;
      case "Underwear":
      case "Duo Pack":
        categoryIcon = "./images/icon_category/boxers.png";
        break;
      case "Headwear":
        categoryIcon = "./images/icon_category/cap.png";
        break;
      default:
        categoryIcon = "./images/icon_category/battery.png";
    }

    return categoryIcon;
  };

  const getBrandLogo = () => {
    const brand = props.items[actualItem].item.brand;
    var brandLogo = "";
    switch (brand) {
      case "Levi's":
      case "Levis":
      case "Levi's®":
      case "LEVIS":
      case "LEVI'S":
      case "Levi´s":
      case "Levi`s":
        brandLogo = "./images/brand_logos/levi.png";
        break;
      case "Denizen":
      case "DENIZEN":
        brandLogo = "./images/brand_logos/denizen.png";
        break;
      case "Dockers":
      case "Dockers®":
      case "DOCKERS":
        brandLogo = "./images/brand_logos/dockers.png";
        break;
      case "Levi's Signature":
      case "Signature":
      case "Levis Signature":
        brandLogo = "./images/brand_logos/signature.png";
        break;
      default:
    }

    return brandLogo;
  };

  const categoryIcon = getCategoryIcon();
  const brandLogo = getBrandLogo();

  const changeStatus = async (itemId, job, nextStatus) => {
    let lookingFor = `status.${job}`;
    itemsService
      .put(itemId, {
        co: [
          {
            [lookingFor]: nextStatus,
          },
        ],
      })
      .then((resp) => {
        console.log(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deletePhotos = async (photos) => {
    let channels = props.items[actualItem].item.channels;
    let channelsToClean = [];

    if (photos === "master") {
      for (let i = 0; i < channels.length; i++) {
        if (
          channels[i].channel !== "mpguide" &&
          channels[i].channel !== "pdp"
        ) {
          channelsToClean.push(channels[i]);
        }
      }
    } else if (photos === "mpguide") {
      for (let i = 0; i < channels.length; i++) {
        if (
          channels[i].channel === "mpguide" ||
          channels[i].channel === "pdp"
        ) {
          channelsToClean.push(channels[i]);
        }
      }
    }

    for (let i = 0; i < channelsToClean.length; i++) {
      await itemsService.deleteImages(props.items[actualItem]._id, [
        {
          channel: channelsToClean[i].channel,
          keys: channelsToClean[i].images.map((element) =>
            element.key ? element.key : null
          ),
        },
      ]);
    }
  };

  const flushPhotosAndSetStatus = async (nextStatus) => {
    let photosFrom = "master";
    let job = "current";
    switch (props.type) {
      case "eComm":
        photosFrom = "master";
        job = "current";
        break;
      case "pGuide":
        photosFrom = "mpguide";
        job = "product_guide";
        break;
      default:
        photosFrom = "master";
        job = "current";
        break;
    }

    if (actualItem === props.items.length - 1) {
      await deletePhotos(photosFrom);

      changeStatus(props.items[actualItem]._id, job, nextStatus);

      closeModal();
      toast.info("Revisión de PC9's con fotos finalizada");
      props.closeThisModal();
      toast.success("Shooting creado con éxito");
      props.closeShootingModal();
    } else {
      await deletePhotos(photosFrom);
      changeStatus(props.items[actualItem]._id, job, nextStatus);
      closeModal();
      toast.info(
        "Revisa el siguiente PC9 [" +
          (actualItem + 2) +
          "/" +
          props.items.length +
          "]"
      );
      setActualItem(actualItem + 1);
    }
  };

  const resetProcess = () => {
    let auxModal = { ...{}, ...modal };
    auxModal.title = "Nuevas fotos";
    auxModal.size = "is-small";
    auxModal.body = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <strong>¿Requieres solicitar búsqueda en almacén?</strong>&nbsp;
        <div>
          <Button
            color="warning"
            onClick={() => flushPhotosAndSetStatus("assigned")}
          >
            No, solo asignar
          </Button>
          &nbsp;&nbsp;
          <Button
            color="success"
            onClick={() => flushPhotosAndSetStatus("pending")}
          >
            Sí, buscar
          </Button>
        </div>
      </div>
    );
    auxModal.show = true;
    setModal({ ...{}, ...auxModal });
  };
  const closeModal = () => {
    let auxModal = modal;

    auxModal.show = false;
    setModal({ ...{}, ...auxModal });
  };

  const deleteFromShooting = () => {
    if (actualItem === props.items.length - 1) {
      console.log("elemento único");
      shootingService.deleteItemFromShooting(props.shootingId, {
        co: [
          {
            $action: "delete",
            items_stack: [props.items[actualItem]._id],
          },
        ],
      });
      toast.info("Revisión de PC9's con fotos: Finalizada");

      props.closeThisModal();
      toast.success("Shooting creado con éxito");
      props.closeShootingModal();
    } else {
      shootingService.deleteItemFromShooting(props.shootingId, {
        co: [
          {
            $action: "delete",
            items_stack: [props.items[actualItem]._id],
          },
        ],
      });
      console.log("varios elementos");
      toast.info(
        "Revisa el siguiente PC9 [" +
          (actualItem + 2) +
          "/" +
          props.items.length +
          "]"
      );
      setActualItem(actualItem + 1);
    }
  };
  const navigate = () => {
    if (actualItem === props.items.length - 1) {
      console.log("elemento único");
      toast.info("Revisión de PC9's con fotos finalizada");
      props.closeThisModal();
      toast.success("Shooting creado con éxito");
      props.closeShootingModal();
    } else {
      toast.info(
        "Revisa el siguiente PC9 [" +
          (actualItem + 2) +
          "/" +
          props.items.length +
          "]"
      );
      console.log("varios elementos");
      setActualItem(actualItem + 1);
    }
  };

  return (
    <div>
      <ModalCard {...modal} closeModal={closeModal} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>
          <strong>PC9:</strong> {props.items[actualItem].pc9}
        </h2>
        <h2>
          <strong>{"Estatus " + props.type + ":"}</strong>{" "}
          {props.items[actualItem].status}
        </h2>
        <strong>
          {actualItem + 1 + " de " + props.items.length + " PC9's"}{" "}
        </strong>
      </div>
      <hr />
      <Columns
        className="has-text-right"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        <Columns.Column>
          <Image size={64} alt="64x64" src={brandLogo} />
        </Columns.Column>
        <Columns.Column>
          <Image src={categoryIcon} alt="Cat" size={48} />
        </Columns.Column>
        <Columns.Column>
          <b>Género</b>
          <p>{props.items[actualItem].item.gender}</p>
        </Columns.Column>
        <Columns.Column>
          <b>Colección</b>
          <p>{props.items[actualItem].item.clothes_collection}</p>
        </Columns.Column>
        <Columns.Column>
          <b>Tipo</b>
          <p>{props.items[actualItem].item.line}</p>
        </Columns.Column>
        <Columns.Column>
          <b>Color</b>
          <p>{props.items[actualItem].item.simple_color}</p>
        </Columns.Column>
      </Columns>

      <br />
      <Columns
        size={2}
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {props.items[actualItem].images.map((img, index) => {
          return (
            <Image
              style={{
                width: "120px",
                border: "1px solid #D2D8D8",
              }}
              key={index}
              src={img.thumbnail}
              onClick={() => console.log(index)}
            />
          );
        })}
      </Columns>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button color="grey-light" onClick={deleteFromShooting}>
          No agregar
        </Button>
        &nbsp;&nbsp;
        <Button color="warning" onClick={resetProcess}>
          Tomar nuevas fotos{" "}
        </Button>
        &nbsp;&nbsp;
        <Button color="success" onClick={navigate}>
          Agregar con fotos
        </Button>
      </div>
    </div>
  );
};

export default DetailsPhotos;
