import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UserService from '../../../Services/UserService';
import PackageService from '../../../Services/PackageService';

import {
  Columns,
  Form,
  Panel,
  Tag,
  Button,
  Box
} from 'react-bulma-components/full';

import {uniq as _uniq} from 'underscore';
import { 
  faMinusCircle,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const userService = new UserService();
const packageService = new PackageService();

const PackageShareWith = (props) => {

  const [filteredList, setFilteredList] = useState([]);
  const [listEmail, setListEmail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [shares, setShares] = useState([]);
  const [localPackage, setLocalPackage] = useState({});
  

  const fetch = async () => {
    let response = await userService.get({
      rf: ['_id', 'email'],
      qf: {
        _id: {$ne: props.ownerId},
        code: {$nin: ['latam', 'picker']}
      }
    });
    if (response.d) {
      setListEmail([ ...response.d]);
    }

    let respPkg = await packageService.get({
      rf: ['name', 'share'],
      qf: {_id: props.packageId}
    });

    setLocalPackage(respPkg.d[0]);
  };

  const onSearch = () => {
    let str = search.trim();
    let filtered = [];

    if (str.length) {
      let regex = new RegExp('(' + str + ')', 'i');
      filtered = listEmail.filter((le) => {
        
        if (regex.test(le.email)) {
          return true;
        }
        return false;
      });
    }

    setFilteredList(filtered);
  };

  const save = async (value) => {
    let response = await packageService.addShare(props.packageId, [value]);
    if (response.d && response.d[0] && response.d[0].pkg.share) {
      setLocalPackage({...localPackage, ...{share: response.d[0].pkg.share}});
    }
  };

  const addShare = (value) => {
    let array = [...shares];
    array.push(value);
    array = _uniq(array);

    if (Array.isArray(localPackage.share)) { 
      if (localPackage.share.indexOf(value._id) !== -1) {
        return;
      }
    }
    
    setShares(array);
    save(value._id)
    setSearch('');
  };


  const removeShare = async (id) => {
    let response = await packageService.removeShare(props.packageId, [id]);
    if (response.d && response.d[0] && response.d[0].pkg.share) {
      setLocalPackage({...localPackage, ...{share: response.d[0].pkg.share}});
    }
  };

  
  useEffect(() => {
    if (loading) {
      fetch();
    }
  }, [loading]);


  useEffect(() => {
    let objectList = [];
    for (let i in localPackage.share) {
      if (['@brand_manager', '@marketing'].indexOf(localPackage.share[i]) !== -1 ) {
        objectList.unshift({
          _id: localPackage.share[i],
          email: localPackage.share[i]
        })
      } else {
        for (let le in listEmail) {
          if (listEmail[le]._id === localPackage.share[i]) {
            objectList.unshift(listEmail[le]);
          }
        }
      }
    }

    setShares(objectList);
    
    if (loading) {
      setTimeout(() => setLoading(false), 1000);
    }
  }, [localPackage])


  useEffect(() => {
    onSearch();
  }, [search]);
  

  return (
    <Columns className="package-share-width">

      {/* <Columns.Column size={12}>
        <Heading size={5}>Paquete: <small>{localPackage.name}</small></Heading>
      </Columns.Column> */}
      
      <Columns.Column size={3}>
      <strong>Compartir con: </strong>
      </Columns.Column>

      <Columns.Column size={9}>
        <Form.Control>
          <Form.Input 
            autoComplete="off"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Busca por e-mail"
          />
        </Form.Control>
      </Columns.Column>
      <Columns.Column size={6} >
        <Box className='shared' style={{marginTop:'0.5rem', }}>
          <strong>Compartido con </strong> <hr/>
        
        {shares.length > 0 ? 
        shares.map((share, key) => {
          return (
            <Tag.Group key={key} style={{display:'flex', justifyContent:'space-between', marginBottom:'0rem'}}>
              <Tag color="primary">{share.email}</Tag>

              <Button size='small' onClick={() => removeShare(share._id)}>
              <FontAwesomeIcon icon={faMinusCircle}/>
              </Button>
            </Tag.Group>
          );
        }) :
        <label>Nadie</label>
        }
        </Box>
      </Columns.Column>
      <Columns.Column size={6} className="filtered">
        <Panel>
          {filteredList.map((item, key) => {
            return (
              <Panel.Block 
                onClick={() => addShare(item)}
                key={key}
                renderAs="a"
                style={{display:'flex', justifyContent:'space-between'}}
              >
                {item.email}
                <FontAwesomeIcon icon={faPlusCircle}/>
              </Panel.Block>
            );
          })}
        </Panel>
      </Columns.Column>

    </Columns>
  );
};


PackageShareWith.propTypes = {
  packageId: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired
};

PackageShareWith.defaultProps = {
  
};

export default PackageShareWith;
