import axios from 'axios';
import qs from 'qs';

class AnalyticService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/analytic`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      },
    });
    
    this.service = service;
  }

  fetchPhotoStatusEcom = (filters) => {
    return this.service.post('/photo/statusEcomm', {
      qf: filters
    }).then((response) => response.data)
  }

  fetchPhotoStatusProgress = (filters) => {
    return this.service.post('/photo/process/shooting', {
      qf: filters
    }).then((response) => response.data)
  }

  fetchCopyStatus = (filters) => {
    return this.service.post('/copy/statusEcomm  ', {
      qf: filters
    }).then((response) => response.data)
  }
  
  fetchPhotoOrigin = (filters) => {
    return this.service.post('/photo/origin', {
      qf: filters
    }).then((response) => response.data)
  }

  fetchTimelinePhoto = (filters) => {
    return this.service.post('/timeline/photo', {
      qf: filters
    }).then((response) => response.data)
  }

  fetchShootings = () => {
    return this.service.post('/shooting/process', {
      qf: { status: ["new", "inProgress", "terminated"] }
    }).then((response) => response.data || {})
  }

  generateXLS = (filters) => {
    return this.service.post('/export/data', {
      qf: filters
    }).then((response) => response.data || {})
    .catch((error) => error.response.data);
  }

  generatePDF = (html) => {
    let data = new FormData();
    let file = new Blob([html], { type: 'text/plain' })

    data.append('files', file);
    
    return this.service.post('/report/pdf', data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then((response) => response.data || {})
      .catch((error) => error.response.data)
  }

}

export default AnalyticService;
