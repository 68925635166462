import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import CompraAsistida from "./CompraAsistida/CompraAsistida";
import InfoCard from "./InfoCard/InfoCard";
import Carrusel from "./Carrusel/Carrusel";
import NotFound from "./NotFound/NotFound";
import Faqs from "./Faqs/Faqs";
import CopyToClipboard from "./CopyToClipboard/CopyToClipboard";
import ItemService from "../../../../Services/ItemsService";
import Spiner from "./Spiner/Spiner";
import Imprimir from "./Imprimir/Imprimir";
import { ReactComponent as Dockers } from "./assets/dockers.svg";

import "./curbsideData.scss";

const itemService = new ItemService();

const CurbsideData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPublic, setIsPublic] = useState(false);
  const [thePc9, setThePc9] = useState("");
  const [itemData, setItemData] = useState(null);
  const [imagesArray, setImagesArray] = useState([]);

  const { pc9 } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.split("/").includes("curbside")) {
      document.title = "Photo Tool";
      favicon(false);
      return setIsPublic(false);
    }
    setIsPublic(true);
    document.title = "Levi's";
    favicon(true);
  }, [pathname]);

  useEffect(() => {
    const withDash = pc9.replace(/(\d{5})(\d{4})/, "$1-$2");
    setThePc9(withDash);
    getPC9();
  }, [pc9]);

  const favicon = (isPublic) => {
    let link = document.querySelector('link[rel="shortcut icon"]');
    if (!link) {
      link = document.createElement("link");
      link.id = "favicon";
      link.rel = "shortcut icon";
      document.head.appendChild(link);
    }
    link.href = isPublic ? `/images/levi-favicon.png` : "/favicon.ico";
  };

  const getPC9 = async () => {
    let queryString = {
      rf: [
        "_id",
        "status",
        "pc9",
        "brand",
        "gender",
        "category",
        "name",
        "simple_description",
        "marketing_description",
        "composition",
        "simple_color",
        "technology",
        "bullets",
        "channels",
      ],
      qf: {
        pc9: pc9.replace("-", ""),
      },
    };

    let {
      d: [response],
    } = await itemService.get(queryString);

    if (response !== undefined) {
      let { current, mkt } = response.status;
      if (current === "approved" && mkt === "approved") {
        setItemData(response);
        //usaremos master por ahora, luego le cambiamos a la adaptación correcta
        getAdaptations(response.channels, "master");
      }
    }
    setIsLoading(false);
  };

  const getAdaptations = (channels, useThis) => {
    let { images } = channels.filter((e) => e.channel === useThis)[0];
    setImagesArray(images);
  };

  if (itemData === null)
    return (
      <div className="curbside info error">
        {isLoading ? <Spiner /> : <NotFound />}
      </div>
    );
  return (
    <div className={`curbside ${isPublic ? "info" : ""}`}>
      <div className="curbside__header">
        {itemData.brand.split("'")[0].toLowerCase() === "levi" ? (
          <img
            className="curbside__header-logo-levis"
            src="/images/brand_logos/levi.svg"
            alt="Levis"
          />
        ) : (
          <Dockers className="curbside__header-logo-dockers" />
        )}

        <h1 className="curbside__header-pc9">{thePc9}</h1>
        <CopyToClipboard />
      </div>
      <div className="curbside__flexContainer">
        <div className="curbside__imagenes">
          <Carrusel images={imagesArray} />
        </div>

        <div className="curbside__body">
          <InfoCard {...itemData} />
        </div>
        {isPublic ? (
          <>
            <div className="curbside__compra">
              <CompraAsistida />
            </div>
            <div className="curbside__faqs">
              <Faqs />
            </div>
          </>
        ) : null}
      </div>
      {isPublic ? (
        <div className="curbside__footer">
          <a
            href="https://www.levi.com.mx/sp/terminos-y-condiciones"
            target="_blank"
            rel="noopener noreferrer"
          >
            Términos y condiciones
          </a>
          <span>LS&Co. 2021 ® </span>
        </div>
      ) : (
        <>
          <CopyToClipboard isButton />
          <Imprimir />
        </>
      )}
    </div>
  );
};

export default CurbsideData;
