const sortBy = (data, config, conditional = null) => {
  let nested = config.field.split('.');
  let lastKey = nested.pop();
  
  data.sort((a, b) => {
    
    for (let i in nested) {
      a = a[nested[i]];
      b = b[nested[i]];
    }
    
    if (typeof conditional === 'function') {
      return conditional(config.order, (a && a[lastKey] ? a[lastKey] : null), (b && b[lastKey] ? b[lastKey] : null));
    } else {
      if (config.order === 'asc') {
        return a[lastKey] > b[lastKey] ? 1 : -1;
      } else {
        return a[lastKey] > b[lastKey] ? -1 : 1;
      }
    }
  });

  return data;
};

export default sortBy;
