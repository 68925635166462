import React, { Component } from 'react';

import {
  Box,
  Columns
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation, faCamera } from '@fortawesome/free-solid-svg-icons';
import { faImages, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

class ShootingStatusInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      located: 0,
      shooted: 0,
      withPhotos: 0,
      approved: 0,
      total: 0,
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.data !== undefined) {
      this.setState({
        data: newProps.data
      }, () => {
        this.getResumeInfo();
      });
    }
  }

  getResumeInfo = () => {
    let located = 0;
    let shooted = 0;
    let withPhotos = 0;
    let approved = 0;
    let pending = 0;
    let assigned = 0;

    for (let i = 0; i < this.state.data.length; i++) {
      let status = (this.props.type === 'eComm' ? this.state.data[i].status.current : this.state.data[i].status.product_guide)

      switch (status) {
        case "pending":
        case "urgent":
          pending++
          break;
          case "available":
            case "assigned":
              assigned++
              break;       
        case "approved":
          located++
          shooted++
          withPhotos++
          approved++
          break;
        case "toReview":
          located++
          shooted++
          withPhotos++
          break;
        case "waitingPhotos":
          located++
          shooted++
          break;
        case "toShoot":
          located++
          break;
        default:
          break;
      }
    }
  
    this.setState({
      pending,
      assigned,
      located,
      shooted,
      withPhotos,
      approved,
      total: this.state.data.length
    });
  }

  render() {
    return (
      <Box className="is-top-marginless">
        <Columns style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around'
        }}>

          <Columns.Column className="has-text-centered" size={2}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 30 }}>
              {(this.props.shooting.items_count-this.state.pending) + '/' + this.props.shooting.items_count}
            </div>
            <div><b>Confirmados</b></div>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={2}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25 }}>
              <FontAwesomeIcon icon={faSearchLocation} />&nbsp;
              {this.state.located}
            </div>
            <div>En piso</div>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={2}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25 }}>
              <FontAwesomeIcon icon={faCamera} />&nbsp;
              {this.state.shooted}
            </div>
            <div>Shooteados</div>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={2}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25 }}>
              <FontAwesomeIcon icon={faImages} />&nbsp;
              {this.state.withPhotos}
            </div>
            <div>Con fotos</div>
          </Columns.Column>

          <Columns.Column className="has-text-centered" size={2}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25 }}>
              <FontAwesomeIcon icon={faCheckCircle} />&nbsp;
              {this.state.approved}
            </div>
            <div>Aprobados</div>
          </Columns.Column>

        </Columns>
      </Box>
    );
  }
}

export default ShootingStatusInfo;