import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Heading,
  Table
} from "react-bulma-components/full";
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Dropzone = (props) => {
  const fileInputRef = useRef(null);
  const [highlight, setHighlight] = useState(false);
  const [files, setFiles] = useState();
  
  
  const fileListToArray = async(list) => {    
    
    let filesAux = [];
    
    list = Array.from(list);

    for (let i = 0; i < list.length; i++) {
      let file = list[i];
      
      if (filesAux.length > props.maxFiles || typeof file !== 'object') {
        break;
      }
      
      if (props.formats.length) {
        let textSearch = file.name.search('._');
        
        if (textSearch !== 0 && props.formats.indexOf(file.type) !== -1) {
          filesAux.push(file);
        } else {

          if ((!file.type || file.type === '') && typeof file.name !== 'undefined') {
            let extension = '.' + file.name.split('.').pop();
            
            if (textSearch !== 0 && props.formats.indexOf(extension) !== -1) {
              filesAux.push(file);
            }
          }
        }
      } else {
        filesAux.push(file);
      }
    }
    
    await setFiles(filesAux);
  };


  const onDragOver = (evt) => {
    evt.preventDefault();
  
    if (props.disabled) return;
  
    setHighlight(true);
  }


  const onDrop = (event) => {
    event.preventDefault();
  
    if (props.disabled) return;
  
    const files = event.dataTransfer.files;
    
    if (props.onFilesAdded) {
      fileListToArray(files);
      props.onFilesAdded(files);
    }
    
    setHighlight(false);
  }


  const onFilesAdded = (event) => {
    if (props.disabled) return;
    
    const files = event.target.files;

    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(array);
    }
  }


  const onRemoveFile = async(event, index) => {
    event.stopPropagation();
    let newfiles = [...files];
    
    newfiles.splice(index, 1);

    setFiles(newfiles);
  }

  
  const openFileDialog = () => {
    if (props.disabled) return;    
    fileInputRef.current.click();
  }


  useEffect(() => {
    if (typeof files !== 'undefined') {
      props.onChange({files: files});
    }
  }, [files]);



  return (
    <div
      className={`dropzone ${highlight ? 'highlight' : ''}`}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{ cursor: props.disabled ? 'default' : 'pointer' }}
    >
      
      {props.directory ?
        <div>
          <Heading size={4} className={files && files.length ? 'is-hidden' : ''}>Da click para seleccionar la carpeta</Heading>
          <input
            ref={fileInputRef}
            className="fileInput"
            type="file"
            multiple={props.maxFiles > 1}
            onChange={onFilesAdded}
            accept={props.formats.join(',')}
            webkitdirectory="true"
            directory="true"
          />
        </div>
      :
        <div>
          <Heading size={4} className={files && files.length ? 'is-hidden' : ''}>Arrastra los archivos o da click</Heading>
          <input
            ref={fileInputRef}
            className="fileInput"
            type="file"
            multiple={props.maxFiles > 1}
            onChange={onFilesAdded}
            accept={props.formats.join(',')}
          />
        </div>
      }

      {files && files.length ?
        <Table striped={true}>
          <thead>
            <tr>
              <th>Archivo</th>
              <th>Tamaño</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {files.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{(item.size / 1024).toFixed(2)}Kb</td>
                  <td>
                    <Button size="small" onClick={(e) => {onRemoveFile(e, index)}}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      :
        null
      }
    </div>
  );
}

Dropzone.propTypes = {
  directory: PropTypes.bool,
  disabled: PropTypes.bool,
  maxFiles: PropTypes.number,
  onChange: PropTypes.func,
  onFilesAdded: PropTypes.func,
  shape: PropTypes.string
};

Dropzone.defaultProps = {
  directory: false,
  disabled: false,
  maxFiles: 1,
  onChange: () => {},
  onFilesAdded: () => {},
  formats: [],
  shape: 'square'
};

export default Dropzone;
