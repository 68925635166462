import React, { useEffect, useState } from "react";

import whatsapp from "../assets/WhatsApp_logo.png";
import "./compraAsistida.scss";

const CompraAsistida = () => {
  const [location, setLocation] = useState("");

  useEffect(() => {
    setLocation(encodeURIComponent(window.location.href));
  }, []);

  return (
    <div className="compra-asistida">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://api.whatsapp.com/send?phone=5215551955422&text=${location}`}
      >
        <img
          src={whatsapp}
          alt="WhatsApp logo"
          className="compra-asistida__icon"
        />
        <h3>Compra asistida</h3>
        <span>*Consulta precio y disponibilidad de tallas en el chat</span>
      </a>
    </div>
  );
};

export default CompraAsistida;
