import React from 'react';
import PropTypes from 'prop-types';
import {
  Button
} from  "react-bulma-components/full";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ButtonCell(props) {

  const onClick = (event) => {
    event.preventDefault();
    props.onClick(props.row);
  }

  return (
    <Button 
      className={
        props.visible ? '' : 'is-hidden'
      }
      title={props.title}
      color={props.color}
      disabled={props.disabled}
      onClick={onClick}
      size={props.size}
    >
      {props.icon ? 
        <FontAwesomeIcon icon={props.icon} />
      :
      ''
      }
      &nbsp;
      {props.label || ''}
    </Button>
  );
}

ButtonCell.propTypes = {
  color: PropTypes.oneOf(["", "white", "success", "warning", "danger", "info", "default"]),
  content: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  title: PropTypes.string,
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};

ButtonCell.defaultProps = {
  color: null,
  content: '',
  disabled: false,
  icon: false,
  onClick: () => {},
  size: 'small',
  title: '',
  visible: true
};

export default ButtonCell;