import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ItemService from '../../../Services/ItemsService';


import {
  faCheckCircle
} from '@fortawesome/free-regular-svg-icons';

import {
  faCircle,
  faBinoculars,
  faCalendar,
  faCalendarCheck,
  faClock,
  faSearch
} from '@fortawesome/free-solid-svg-icons';

import 'react-confirm-alert/src/react-confirm-alert.css';

import Datatable from '../../General/Datatable/Datatable';
import Loader from '../../General/Loader/Loader';
import ModalCard from '../../General/Modal/ModalCard'

import {ITEMS} from '../../General/Language/es';
import DetailsPC9 from '../../Items/components/Details/DetailsPC9'

class QuotationItems extends Component {
  constructor(props) {
    super(props);
    this.tabIndex = React.createRef();

    this.state = {
      loggedInUser: this.props.loggedInUser,
      adaptations: false,
      checkList: [],
      data: [],
      renderRows:[],
      hasChange: false,
      modal: {
        show: false,
      },
      showDetails:false,
      waitMichelada: false
    };

    this.state.table = {
      filtersConfig: {
        brand: {
          label: 'Marca',
        }, 
        gender: {
          label: 'Género',
        }, 
        category: {
          label: 'Categoría',
        }, 
        'status.current': {
          label: 'Estatus',
          dictionary: ITEMS.STATUS
        },
        pc9: {
          type: 'textarea',
          label: 'Varios PC9 (Independiente)'
        }
      },
      actions: [],
      checkCounter: true,
      columns: [
        {
          field: '_id',
          type: 'checklist'
        },{
          field: 'pc9',
          label: 'PC9',
          onClick: (e, item) => {
            this.showDetailsPC9(item._id);
          },
          sortable: true,
          type: 'link'
        }, {
          label: 'Marca',
          field: 'brand',
          sortable: true
        }, {
          label: 'Género',
          field: 'gender',
          sortable: true
        }, {
          label: 'Categoría',
          field: 'category',
          sortable: true
        }, {
          dictionary: ITEMS.STATUS,
          sortable: true,
          field: 'status.current',
          label: 'Fotos',
          color: (item) => {
            let colors = {
              required: 'danger',
              pending: 'grey-light',
              available: 'primary',
              assigned: 'grey-light',
              toShoot: 'grey-light',
              waitingPhotos: 'grey-light',
              toReview:'primary',
              approved: 'success',
            };
            
            return colors[item.status.current] ? colors[item.status.current] : '';
          },
          icon: (item) => {
            let icons = {
              required: faCircle,
              pending: faBinoculars,
              available: faCalendar,
              assigned: faCalendarCheck,
              toShoot: faClock,
              waitingPhotos: faClock,
              toReview:faSearch,
              approved: faCheckCircle,
            };
            
            return icons[item.status.current] ? icons[item.status.current] : null;
          },
          title: (item) => item.status.current,
          type: 'icon'
        },
      ],
      emptyLegend: "Aún no existen items asignados a la cotización.",
      filters: [
        {
          field: 'brand',
          label: 'Marca',
        }, {
          field: 'gender',
          label: 'Género'
        }, {
          field: 'category',
          label: 'Categoría'
        }, {
          dictionary: ITEMS.STATUS,
          field: 'status.current',
          label: 'Estatus'
        }
      ],
      loadingLegend: <Loader />,
      searchFields: ['pc9']
    };

    this.confirm = {
      title: <strong>Confirmar</strong>,
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onKeypressEscape: () => { }
    };

    this.itemService = new ItemService();
  }


  componentWillMount() {
    this.fetch(this.props.quotation);
  }

  onChangeDatatable = (state) => {  
    this.setState({
      checkList: state.checkList,
      renderRows:state.renderRows
    });
  }

  fetch = (quotation) =>{
    this.itemService.get({
      rf: ['_id', 'pc9', 'brand', 'gender', 'category', 'name', 'status', 'departament', 'month', 'last_update'],
      qf: {
        quotation:this.props.quotation._id
      }
    })
    .then((response) => {
      this.setState({
        data: response.d,
        loading: false
      });
    });
  }

  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({
      modal: modal
    });
  };

  showDetailsPC9 = (itemId) =>{
    this.itemService.getItemById(itemId)
    .then(item=>{
      this.setState({
        showDetails:true,
        clickedItem:item,
      })
    });
  };

  close = () => {
    this.setState({
      showDetails:false
    }, () => this.fetch(this.props.quotation));
  }

  updateAll = (item) => {
    this.setState({
      clickedItem:item
    });
  }

  render() {
    return (
      <div>
        <ModalCard
          {...this.state.modal}
          closeModal={this.closeModal}
        />
        {this.state.showDetails ? 
        <DetailsPC9
          clickedItem={this.state.clickedItem}
          loggedInUser={this.props.loggedInUser}
          close={this.close}
          show={this.state.showDetails}
          renderRows={this.state.renderRows}
          updateAll={this.updateAll}
          type={this.props.type}
        />
        : null }

        <Datatable
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
          onChange={this.onChangeDatatable}
        />
      </div>

    );
  }
}

QuotationItems.defaultProps = {
  season: {}
}

QuotationItems.propTypes = {
  season: PropTypes.object
}

export default QuotationItems;