export default [
  "white",
  "black",
  "light",
  "dark",
  "primary",
  "info",
  "link",
  "success",
  "warning",
  "danger",
  "black-bis",
  "black-ter",
  "grey-darker",
  "grey-dark",
  "grey",
  "grey-light",
  "grey-lighter",
  "white-ter",
  "white-bis"
];