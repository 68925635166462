import React, { Component } from 'react';

import QuotationService from '../../../Services/QuotationService';
import {
  Button,
  Form
} from 'react-bulma-components/full';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';


class QuotationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotation: {
        name: '',
        additional_info: ''
      }
    };
    
    this.quotationService = new QuotationService();
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let co = [
      {
        name: this.state.quotation.name,
        id_owner: this.props.loggedInUser._id,
        additional_info: this.state.quotation.additional_info
      }
    ];

    let response = await this.quotationService.post({ co });

    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.success('Se ha creado una nueva cotización vacía');
        if (this.props.fetch) {
          this.props.fetch();
        }
      } else {
        toast.error('Error al crear la cotización');
      }
    }

    this.props.close();
  }

  onChange = (event) => {
    let { quotation } = this.state;
    quotation[event.target.name] = event.target.value;
    this.setState({
      quotation
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Nombre</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Form.Input
                autocomplete="off"
                placeholder='Ponle un nombre'
                value={this.state.quotation.name}
                name="name"
                onChange={this.onChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Comentarios</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Form.Textarea
                autocomplet="off"
                value={this.state.quotation.additional_info}
                name="additional_info"
                placeholder='Agrega el tiempo de uso, los medios y los paises en los que se utilizarán las imágenes'
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <Button color="success" type="submit">Crear</Button>
        </div>
      </form>
    );
  }
}

QuotationForm.propTypes = {

};

QuotationForm.defaultProps = {
  fetch: () => { }
};

export default QuotationForm;