import axios from 'axios';
import qs from 'qs';


class LinkService {

  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/link`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      }
    });
    this.service = service;
  }

  get = (query = {}) => {
    return this.service.get('/', { params: query })
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  post = (data) => {
    return this.service.post('/', data)
      .then(response => response.data)
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  put = (data) =>{
    return this.service.put('/', data)
    .then(response => response.data)
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
  }
}

export default LinkService;