import React from 'react';
import PropTypes from 'prop-types';

import {
  Columns
} from "react-bulma-components/full";

import Actions from './components/Actions';
import Counter from './components/Counter';
import Search from './components/Search';
import FilterList from './components/FilterList/FilterList';

function Header(props) {
  
  const onExecute = (index) => {
    props.actions[index].action();
  };
  
  return(
    <Columns>
      {props.checkCounter ?
        <Columns.Column size={2} className="is-vcentered">
          <Counter size={props.checkList.length} />
        </Columns.Column>
        :
        null
      }
      
      {props.searcheable ?
        <Columns.Column >
          <Search 
            textSearch={props.textSearch}
            onChange={props.onChangeSearch}
            placeholder={props.phSearchBar}
          />
        </Columns.Column>
        :
        null
      }
      
      {Object.keys(props.filtersConfig).length ?
        <Columns.Column size={2}>
          <FilterList 
            filters={props.filters}
            filterFills={props.filterFills}
            filtersConfig={props.filtersConfig}
            onChange={props.onChangeFilter}
            renderRowsPC9={props.renderRowsPC9}
          />
        </Columns.Column>
        :
        null
      }

      {props.actions.length ?
        <Columns.Column size={(Object.keys(props.filtersConfig).length || props.searchable) ? 5 : 11}>
          <Actions 
            {...props}
            onExecute={(e, index) => onExecute(index)}
          />
        </Columns.Column>
        :
        null
      }
    </Columns>
  );
}

Header.propTypes = {
  actions: PropTypes.array,
  checkCounter: PropTypes.bool,
  filters: PropTypes.object,
  filterFills: PropTypes.object,
  onChangeFilter: PropTypes.func,
  onChangeSearch: PropTypes.func,
  textSearch: PropTypes.string
};

Header.defaultProps = {
  actions: [],
  checkCounter: false,
  filters: {},
  filterFills: {},
  onChangeFilter: () => {},
  onChangeSearch: () => {},
  textSearch: ''
};

export default Header;
