// App.js
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import AuthService from './Services/AuthService';
import Layout from './components/General/Layout/Layout';
import { ToastContainer } from 'react-toastify';
import Logoff from './components/General/Layout/Logoff';
import packageJson from '../package.json';

import 'react-confirm-alert/src/react-confirm-alert.css'; 
import './App.scss';

const authService = new AuthService();

const App = () => {
  const location = useLocation();

  const [redirect, setRedirect] = useState();
  const [request, setRequest] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  const fetchUser = () => {
    if(loggedInUser === null){
      authService.loggedin()
        .then(response => {
          setLoggedInUser(response.d[0]);
          setRequest(true);
        })
        .catch( err =>{
          setLoggedInUser(false);
          setRequest(true);
        });
    }
  };

  const setUser = (userObj) => {
    let redirect = '';

    switch (userObj.code) {
      case "brand_manager":
        redirect = '/master';
        break;
      case "photo_team":
        redirect = '/shootings';
        break;
      case "warehouse_picker":
        redirect = '/looking-for';
        break;
      case "marketing":
      case "commercial":
        redirect = '/master';
        break;
        case "latam":
          redirect = '/master';
          break;
      case "curbside":
          redirect = '/curbside'
          break;
      case undefined:
        redirect = '/';
        break;
      default:
        redirect = '/'
        break;
    }

    setLoggedInUser(userObj);
    setRedirect(redirect);
  }

  useEffect(() => {
    fetchUser();
  }, []);

  if (!request) {
    return (
      <div />
    );
  }

  return (
    <div>
      <ToastContainer />
      {loggedInUser ?
        <Layout 
          loggedInUser={loggedInUser} 
          setUser={setUser}
          version={packageJson.version}
          redirect={redirect}
        />
      :
        <Logoff 
          setUser={setUser} 
          version={packageJson.version}
        />
      }
    </div>  
  );
}

export default App;
