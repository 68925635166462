import React, { Component } from 'react';

import { Columns, Button } from "react-bulma-components";

import Datatable from '../../General/Datatable/Datatable';
import ModalCard from '../../General/Modal/ModalCard'
import { 
  faTrash,
  faDownload,
  faCheckCircle,
  faSpinner,
  faFileExcel,
  faCopy
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import linkService from '../../../Services/LinkService';
import { sortBy } from '../../General/Datatable/utils';

class Links extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        show: false,
        size: ''
      },

      loading: true,
      checkList: [],
      data: [],
      table: {
        checkCounter: false,
        searcheable: false,
        emptyLegend:'Aún no creas ningún link',
        searchFields: ['name'],
        filtersConfig: {
          ownerId: {
            label: 'Creado por',
          }
        },
        actions: [{
          visible: () => this.state.checkList.length,
          title: 'Eliminar',
          label: 'Eliminar links',
          icon: faTrash,
          className: 'is-danger',
          onClick: () => {
            if (!this.state.checkList.length) return;
            this.confirmDeleteLink(this.state.checkList);
          }
        }],
        columns: [
          {
            field: '_id',
            type: 'checklist',
          },
          {
            label: 'Nombre: paqt canal (inc/tot)',
            field: 'name',
            sortable: true,
          },
          {
            label: 'Fecha creación',
            field: 'created_at',
            format: ['MOMENT::DD/MM/YYYY HH:mm'],
            sortable: true,
          }, {
            sortable: true,
            field: 'status',
            label: 'Estatus',
            color: (link) => {
              let colors = {
                active: 'success',
                processing: 'grey-light',
              };

              return colors[link.status] ? colors[link.status] : '';
            },
            icon: (link) => {
              let icons = {
                active: faCheckCircle,
                processing:faSpinner
              };

              return icons[link.status] ? icons[link.status] : null;
            },
            title: (link) => link.status,
            type: 'icon'
          },
          {
            label: 'Acciones',
            type: 'actions',
            actions: [
              {
                title: "Descargar fotos",
                icon: faDownload,
                color: 'success',
                onClick: (link) => {
                  this.downloadZip(link)
                },
                visible: (link)=>{
                  if(link.status === 'active'){
                    return true
                  } else {
                    return false
                  }
                }
              }, 
              // {
              //   title: "Descargar csv",
              //   icon: faFileExcel,
              //   color: 'warning',
              //   onClick: (link) => {
              //     this.downloadCsv(link)
              //   },
              //   visible: (link)=>{
              //     if(link.status === 'active'){
              //       return true
              //     } else {
              //       return false
              //     }
              //   }
              // },
              {
                title: "Copiar link",
                icon: faCopy,
                color: 'default',
                onClick: (link) => {
                  this.copyZipUrl(link)
                },
                visible: (link)=>{
                  if(link.status === 'active'){
                    return true
                  } else {
                    return false
                  }
                }
              },
              // {
              //   title: "Eliminar",
              //   icon: faTrash,
              //   color: 'danger',
              //   onClick: (link) => {
              //     this.confirmDeleteLink(link)
              //   }
              // }
            ]
          }
        ]
      },
      QSLinks: {
        rf: ['name','created_at','status', 'id_season', 'url', 'snapshot._items_count'],
        qf:{
          status:['active', 'processing'],
          owner:this.props.loggedInUser._id,
          id_season:this.props.seasonId
        }
      }
    }

    this.linkService = new linkService();
  }

  downloadZip = (link) => {
    window.location.href = link.url.zip
  }

  downloadCsv = (link) => {
    window.location.href = link.url.csv
  }

  copyZipUrl = (link) => {
    const el = document.createElement('textarea');
    el.value = link.url.zip;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.info('Se ha copiado el link para:    '+link.name)
  }

  deleteLink = (link) => {
    this.linkService.put({
        ol: Array.isArray(link) ? link : [link],
        co:[
          {
            status:'eliminated'
          }
        ]
      })
      .then(resp => {
        this.closeModal()
        if (Array.isArray(link)) {

        } else {
          toast.info('Se ha eliminado el link '+ link.name)
        };
        this.setState({
          checkList: []
        })
        this.fetch();
      });
  }

  componentWillMount = () => {
    this.fetch()
  }

  fetch = () => {
    this.linkService.get(this.state.QSLinks)
      .then((response) => {
        let sorted = sortBy(response.d, {
          field: 'created_at',
          order: 'desc'
        });
        
        this.setState({
          data: sorted,
          loading: false
        })
      });
  }


  closeModal = () => {
    let modal = {
      show: false
    };
    this.setState({
      modal: modal,
    });
  }

  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({ modal: modal });
  }

  onChangeTable = (state) => {
    let { checkList } = state;

    this.setState({
      checkList
    });
  }

  confirmDeleteLink = (link) => {
    let modal = this.state.modal;
    modal.title = "Eliminar link";
    modal.size = 'is-small';
    modal.body = (
      <div>
        <p className="has-text-centered">
          <b>
            {Array.isArray(link) ? 
              "Los links seleccionados serán eliminados y no estarán disponibles"
            :
              "El link será eliminado y no estará disponible"
            } para su consulta</b></p>
        <br />
        <Columns centered={true}>
          <Columns.Column>
            <Button
              color="info"
              fullwidth={true}
              onClick={this.closeModal}
            >
              No, cancelar
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="warning"
              fullwidth={true}
              onClick={() => 
                this.deleteLink(Array.isArray(link) ? link : link._id)
              }
            >
              Sí, eliminar
            </Button>
          </Columns.Column>
        </Columns>

      </div>
    )

    this.setState({
      modal: modal
    }, this.openModal());
  }


  render() {
    return (
      <div>
        <ModalCard
          title={this.state.modal.title}
          footer={this.state.modal.footer}
          show={this.state.modal.show}
          body={this.state.modal.body}
          closeModal={this.closeModal}
          size={this.state.modal.size}
        />
        <Datatable
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
          onChange={this.onChangeTable}
        />

      </div>
    )
  }
}

export default Links;
