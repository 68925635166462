import React, { Component } from 'react';
import ItemsService from '../../../../../Services/ItemsService';
import AuthService from '../../../../../Services/AuthService'

import {
  Button,
  Columns
} from "react-bulma-components/full";

import { toast } from 'react-toastify';
import { MASTER } from '../../../../General/Language/es';

class EditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: this.props.item,
      updated: true,
      loggedInUser: this.props.loggedInUser,
      isReadOnly: undefined,
      modelSize:"No data"
    };

    this.itemsService = new ItemsService();
    this.authService = new AuthService();
  }

  componentWillReceiveProps(props) {
    this.setState({
      item: props.item,
      loggedInUser: props.loggedInUser
    });
  }

  componentWillMount() {
    this.isReadOnly();
    this.itemsService.getItemById(this.props.item._id)
      .then(item => {
        this.setState({
          item: item
        })
      });
  }

  onChange = (event) => {
    let { item } = this.state;
    item[event.target.name] = event.target.value;
    this.setState({
      item: item,
      updated: false
    });
  }

  updateItem = async (event) => {
    this.authService.loggedin()
      .then(resp => {
        let user = resp.d[0].email
        let { item } = this.state;
        item.last_update = {
          date: new Date(),
          by: user
        }
        this.setState({
          item: item
        }, () => {
          this.itemsService.put(this.state.item._id, {
            co: [item]
          })
            .then(response => {
              for (let iErr in response.error) {
                if (response.error[iErr].code === 0) {
                  this.setState({ updated: true })
                  toast.success(MASTER.UPDATE_SUCCESS);
                } else {
                  toast.success(response.error[iErr].message);
                }
              }
            })
        })
      });
  }

  isReadOnly = () => {
    let isReadOnly = true

    if (this.state.loggedInUser.code === "commercial") {
      isReadOnly = true
    } else if (this.state.loggedInUser.code === "brand_manager" || this.state.loggedInUser.code === "marketing") {
      isReadOnly = false
    }

    this.setState({
      isReadOnly: isReadOnly
    });
  }

  approveInfoMKT = (e) => {
    let itemId = this.state.item._id

    this.itemsService.put(itemId, {
      co: [{
        'status.mkt': 'approved'
      }]
    })
      .then((response) => {
        let item = response.d[0].ref;
        this.props.updateAll(item)
        toast.success('Se ha aprobado la comunicación');

      }).catch(() => {
        toast.error('Ocurrió un error al cambiar el estatus, vuelve a intentarlo.');
      });
  }

  draftCopy = (e) => {
    let itemId = this.state.item._id

    this.itemsService.put(itemId, {
      co: [{
        'status.mkt': 'toReview'
      }]
    })
      .then((response) => {
        let item = response.d[0].ref;
        this.props.updateAll(item)
        toast.warning('Copy a borrador');

      }).catch(() => {
        toast.error('Ocurrió un error al cambiar el estatus, vuelve a intentarlo.');
      });
  }

  setModelSize =(item)=>{

    let modelSize="No data"
    if(item.model_height === 'no data' && item.sample_size === 'no data'){
      modelSize = "no data"
    } else if (item.model_height !== 'no data' || item.sample_size !== 'no data') {

      modelSize = 'Altura del modelo es '+ item.model_height+' cm y usa talla ' + item.sample_size
    }
    

    if(item.model_height === 'no data' && item.sample_size === 'no data'){
      modelSize = "no data"
    } else if(item.model_height !== 'no data'){
      if(item.sample_size === 'no data' || item.sample_size === ' ' || item.sample_size === '-'){
        modelSize = 'Altura del modelo es '+ item.model_height+' cm '
      } else {
        modelSize = 'Altura del modelo es '+ item.model_height+' cm y usa talla ' + item.sample_size
      }
    }
    return modelSize
  }

  render() {
    return (
      <div>
        <Columns>
          <Columns.Column>
            <div style={{ display: 'flex', flexFlow: 'columns', justifyContent: 'space-between', }}>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Nombre</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Nombre" 
                    name='name' 
                    onChange={this.onChange} 
                    value={this.state.item.name !== null ? this.state.item.name : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexFlow: 'columns', justifyContent: 'space-between', }}>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Marca</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Marca (Levi's, Dockers, Denizen)" 
                    name='brand' 
                    onChange={this.onChange} 
                    value={this.state.item.brand !== null ? this.state.item.brand : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Género</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Género (Men's, Women's)" 
                    name='gender' 
                    onChange={this.onChange} 
                    value={this.state.item.gender !== null ? this.state.item.gender : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
              <div className="field" fullwidth={'true'}>
                <label className="label">Categoría</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Categoría (Tops, Bottoms, etc.)" 
                    name='category' 
                    onChange={this.onChange} 
                    value={this.state.item.category !== null ? this.state.item.category : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexFlow: 'columns', justifyContent: 'space-between', }}>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Colección</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Colección" 
                    name='clothes_collection' 
                    onChange={this.onChange} 
                    value={this.state.item.clothes_collection !== null ? this.state.item.clothes_collection : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Moda/Básico</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Moda/Básico" 
                    name='line' 
                    onChange={this.onChange} 
                    value={this.state.item.line !== null ? this.state.item.line : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
              <div className="field">
              <label className="label">Composición</label>
              <div className="control">
                <input 
                  autoComplete="off"
                  className="input" 
                  type="text" 
                  placeholder="Composición" 
                  name='composition' 
                  onChange={this.onChange} 
                  value={this.state.item.composition !== null ? this.state.item.composition : "-"} 
                  readOnly={this.state.isReadOnly} />
              </div>
            </div>
            </div>

            <div style={{ display: 'flex', flexFlow: 'columns', justifyContent: 'flex-start', }}>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Color</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Color (inglés)" 
                    name='official_color' 
                    onChange={this.onChange} 
                    value={this.state.item.official_color !== null ? this.state.item.official_color : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Color español</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Color (español)" 
                    name='simple_color' 
                    onChange={this.onChange} 
                    value={this.state.item.simple_color !== null ? this.state.item.simple_color : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>

              <div className="field">
                <label className="label">Mes</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Mes" 
                    name='month' 
                    onChange={this.onChange} 
                    value={this.state.item.month !== null ? this.state.item.month : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
            </div>





            <div style={{ display: 'flex', flexFlow: 'columns', justifyContent: 'space-between', }}>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Altura modelo</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text"
                    placeholder="XXX cm" 
                    name='model_height'    
                    onChange={this.onChange} 
                    value={this.state.item.model_height !== "no data" ? this.state.item.model_height : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              </div>
              <div className="field" style={{ marginRight: '10px' }}>
                <label className="label">Talla prenda</label>
                <div className="control">
                  <input 
                    autoComplete="off"
                    className="input" 
                    type="text" 
                    placeholder="Talla en modelo" 
                    name='sample_size' 
                    onChange={this.onChange} 
                    value={this.state.item.sample_size !== "no data" ? this.state.item.sample_size : "-"} 
                    readOnly={this.state.isReadOnly} />
                </div>
              
              </div>
            </div>

            <div className="field">
              <label className="label">Altura+Talla Export (read only)</label>
              <div className="control" >
                <label>{this.setModelSize(this.state.item)}</label>
              </div>
            </div>

          <div className="field">
              <label className="label">Fitting modelo+prenda</label>
              <div className="control" >
                <input className="input" placeholder="Describe cómo le queda la prenda al modelo" rows='2' name='fitting_model_sample' readOnly={this.state.isReadOnly} onChange={this.onChange} value={this.state.item.fitting_model_sample}></input>
              </div>
            </div>


          </Columns.Column>

          <Columns.Column>

          <div style={{ display: 'flex', flexFlow: 'columns', justifyContent: 'space-between', }}>
            <div className="field">
              <label className="label">Descripción corta</label>
              <div className="control" >
                <textarea className="input" placeholder="Descripción corta" rows='2' name='simple_description' readOnly={this.state.isReadOnly} onChange={this.onChange} value={this.state.item.simple_description !== null ? this.state.item.simple_description : "-"}></textarea>
              </div>
            </div>

            <div className="field">
              <label className="label">Tecnología</label>
              <div className="control">
                <textarea className="input" placeholder="Tecnología" rows='2' name='technology' readOnly={this.state.isReadOnly} onChange={this.onChange} value={this.state.item.technology !== null ? this.state.item.technology : "-"}></textarea>
              </div>
            </div>
</div>
            <div className="field">
              <label className="label">Descripción larga</label>
              <div className="control">
                <textarea className="textarea" placeholder="Textarea" name='marketing_description' readOnly={this.state.isReadOnly} onChange={this.onChange} value={this.state.item.marketing_description !== null ? this.state.item.marketing_description : "-"}></textarea>
              </div>
            </div>



            <div className="field">
              <label className="label">Bullets</label>
              <div className="control">
                <textarea className="textarea" placeholder="Bullets" rows='2' name='bullets' readOnly={this.state.isReadOnly} onChange={this.onChange} value={this.state.item.bullets !== null ? this.state.item.bullets : "-"}></textarea>
              </div>
            </div>

            <div className="field">
              <label className="label">Instrucciones de lavado</label>
              <div className="control">
                <textarea className="textarea" placeholder="Instrucciones de lavado" rows='2' name='wash_instructions' readOnly={this.state.isReadOnly} onChange={this.onChange} value={this.state.item.wash_instructions !== null ? this.state.item.wash_instructions : "-"}></textarea>
              </div>
            </div>

            <div className="field">
              <label className="label">Fit & Sizing</label>
              <div className="control">
                <textarea className="input" placeholder="Textarea" rows='2' name='fit_and_sizing' readOnly={this.state.isReadOnly} onChange={this.onChange} value={this.state.item.fit_and_sizing !== null ? this.state.item.fit_and_sizing : "-"}></textarea>
              </div>
            </div>
          </Columns.Column>
        </Columns>
        {this.props.loggedInUser.code === 'brand_manager' ?
          <div style={{ display: 'flex', flexFlow: 'columns', justifyContent: 'space-between', }}>
            {this.props.item.status.mkt !== 'approved' ? 
             <Button
             onClick={e => this.approveInfoMKT(e)}
             color='success'
             style={{ marginRight: '10px' }}
             disabled={!this.state.updated}
           >
             Aprobar Copy
           </Button>
           :              <Button
           onClick={e => this.draftCopy(e)}
           color='default'
           style={{ marginRight: '10px' }}
           disabled={!this.state.updated}
         >
           Convertir en borrador
         </Button>          
          }



            <div style={{ display: 'flex', flexFlow: 'columns', justifyContent: 'flex-end', }}>

              {this.state.isReadOnly ?
                  <div></div>
              :
                <Button

                  color='primary'
                  onClick={this.updateItem}
                  disabled={this.state.updated}
                >
                  Guardar
                </Button>
              }
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}

export default EditForm;
