import React, { Component } from 'react';

import {
  Button,
  Columns
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


class ShootingEmptyRows extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return(
      <Columns className="is-vcentered">
        <Columns.Column>
          <span>Aún no existen shootings para ésta temporada, utiliza el botón </span>
          <Button size="small"> 
            <FontAwesomeIcon icon={faPlus} />
            <span>&nbsp;</span>
            Crear nuevo
          </Button>
        </Columns.Column>
      </Columns>
    );
  }
}

export default ShootingEmptyRows;
