import React, { Component } from 'react';
import QuotationService from '../../../Services/QuotationService'

import {
  Box,
  Button,
  Form,
  Tag
} from 'react-bulma-components/full';

import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

class AddToQuotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      add: {
        quotation: '',
      },
      checkList: [],
      loading: false,
      quotations: [],
      newQta: false,
      quotation: '',
      additional_info: ''

    }
    this.quotationService = new QuotationService();
  }

  componentWillMount() {
    this.quotationService.get({
      qf: {
        id_owner: this.props.loggedInUser._id,
        status: {
          $in: ['pending']
        },
      }
    }).then((response) => {
      this.setState({
        quotations: response.d
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      ol: [this.state.quotation],
      co: [
        {
          items_stack: this.props.checkList
        }
      ]
    };

    this.quotationService.put(data)
      .then(resp => {

        toast.info("Los PC9's han sido agregados a la cotización");

      })
      .catch(error => {
        toast.error("Sucedió un error, inténtalo de nuevo");
      })
    this.props.close();
  }

  handleNewQta = async (event) => {
    event.preventDefault();
    let co = [
      {
        name: this.state.quotation,
        id_owner: this.props.loggedInUser._id,
        additional_info: this.state.additional_info,
        items_stack: this.props.checkList

      }
    ];

    let response = await this.quotationService.post({ co });

    for (let iErr in response.error) {
      if (response.error[iErr].code === 0) {
        toast.success('Se ha creado una nueva cotización con los PC9 seleccionados');
        if (this.props.fetch) {
          this.props.fetch();
        }
      } else {
        toast.error('Error al crear la cotización');
      }
    }

    this.props.close();
  }

  onChange = (e) => {
    console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  createNewQta = () => {
    console.log('Funciona')
    this.setState({
      newQta: true,
      quotation: ''
    })


  }

  render() {
    return (
      <Box className="is-marginless">
        <form onSubmit={this.handleSubmit}>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">PC9's</label>
            </div>
            <div className="field-body">
              <div className="field">
                <Tag.Group>
                  <Tag color="info">{this.props.checkList.length}</Tag>
                  <Tag>Seleccionados</Tag>
                </Tag.Group>
              </div>
            </div>
          </div>
          {!this.state.newQta ?
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Cotización</label>
              </div>
              <div className="field-body">
                <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Form.Select onChange={this.onChange} name="quotation" value={this.state.quotation} style={{width:'200px'}}>
                    <option value="" disabled>Selecciona una cotización</option>
                    {this.state.quotations.map((element, index) => {
                      return (
                        <option value={element._id} key={index}>{element.name} ({element.items_stack.length + " PC9's"})</option>
                      );
                    })}
                  </Form.Select>&nbsp;&nbsp;
                <a onClick={(e) => this.createNewQta(e)}>O crea una nueva</a>

                </div>
              </div>
            </div>
            :
            <form onSubmit={this.handleNewQta}>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Nombre</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <Form.Input
                      autocomplete="off"
                      placeholder='Ponle un nombre'
                      value={this.state.quotation}
                      name="quotation"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Comentarios</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <Form.Textarea
                      autocomplet="off"
                      value={this.state.additional_info}
                      name="additional_info"
                      placeholder='Agrega el tiempo de uso, los medios y los paises en los que se utilizarán las imágenes'
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          }<br/>
          <div className="field is-grouped is-grouped-right">

          {!this.state.newQta ?
              <Button color="primary" onClick={e=>this.handleSubmit(e)} disabled={this.props.checkList.length < 1}>Agregar</Button>
              :

            <Button color="primary" onClick={e=>this.handleNewQta(e)} disabled={this.props.checkList.length < 1 || this.state.quotation === ''}>Crear y agregar PC9's</Button>
            }
          </div>
        </form>
      </Box>
    );
  }
}

AddToQuotation.propTypes = {
};

AddToQuotation.defaultProps = {
};

export default AddToQuotation;