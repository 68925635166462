import React, { useEffect, useState } from 'react';
import ItemService from '../../../Services/ItemsService';
import { Button, Columns, Form, Tag } from 'react-bulma-components/full';
import { toast } from 'react-toastify';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import ChannelService from '../../../Services/ChannelsService';


const itemService = new ItemService();
const channelService = new ChannelService();

const MoveToSeason = (props) => {
    const [filtersUnique, setFiltersUnique] = useState(props.filtersUnique);
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [selectedGender, setSelectedGender] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedChannel, setSelectedChannel] = useState('empty')
    const [newPC9, setNewPC9] = useState('')
    const [skuChannel, setSkuChannel] = useState('')
    const [existInPT, setExistInPT] = useState(true)
    const [fieldsComplete, setFieldsComplete] = useState(false)
    const [channelsInDB, setChannelsInDB] = useState([])
    const channelsToAvoid =['master', 'mpguide', 'master_cut', 'pdp']

    useEffect(() => {
        if (selectedBrand !== null && selectedGender !== null && selectedCategory !== null) {
            setFieldsComplete(true)
        } else {
            setFieldsComplete(false)
        }
    }, [selectedBrand, selectedGender, selectedCategory])

    useEffect(() => {
        if (newPC9.length === 9) {
            setNewPC9(newPC9.substr(0, 5).toUpperCase() + "-" + newPC9.substr(5, 4).toUpperCase())
            itemService.get({
                rf: ['_id', 'pc9', 'status'],
                qf: {
                    pc9: newPC9.replace(/[^0-9a-z]/gi, '').toUpperCase()
                }
            }).then(resp => {
                if (resp.d.length > 0) {
                    setExistInPT(true)
                } else {
                    setExistInPT(false)
                }
            })
        } else if (newPC9.length < 9 && newPC9 !== '') {
            setExistInPT(false)
            setSelectedBrand(null)
            setSelectedGender(null)
            setSelectedCategory(null)
            setSelectedChannel(null)
            setSkuChannel(null)

        }
    }, [newPC9]);

    useEffect(() => {
        if (!existInPT && newPC9.length >= 9) {
            channelService.get()
                .then(resp => {
                    filterChannels(resp.d)
                    //setChannelsInDB(resp.d)
                })
                .catch(err => {
                    console.log(err)
                    setChannelsInDB([])
                })
        }

    }, [existInPT, newPC9])

    const createNewPC9 = () => {

        itemService.createNewPC9({
            newPC9: newPC9.replace(/[^0-9a-z]/gi, '').toUpperCase(),
            brand: selectedBrand,
            gender: selectedGender,
            category: selectedCategory,
            channelId: selectedChannel,
            skuChannel: skuChannel
        }).then(resp => {
            toast.success('El PC9  ' + resp.pc9 + ' ha sido creado y enviado a búsqueda correctamente')
            props.close()
            props.updateTable()

        })
    }

    const filterChannels = async (channelsComplete) => {

        if (channelsComplete) {
            let auxChannels =[]
            for (let i = 0;i<channelsComplete.length;i++){
                if(channelsToAvoid.indexOf(channelsComplete[i].name)===-1){
                    auxChannels.push(channelsComplete[i])
                }
            }
          setChannelsInDB(auxChannels);
        } 
      };

    return (
        <Columns>
            <Columns.Column size={12}>
                {newPC9.length < 9 ? 'Ingresa el código PC9' : existInPT ? 'El PC9 ' + newPC9 + ' ya se encuentra registrado' : <strong>Nuevo PC9 (*)</strong>}
            </Columns.Column>
            <Columns.Column size={12}>
                <div className="control">
                    <input

                        autoComplete="off"
                        className="input"
                        type="text"
                        placeholder={'00000-0000'}
                        onChange={(e) => {
                            setNewPC9(e.target.value.toString().replace(/[^0-9a-z]/gi, '').substr(0, 9))
                            setSkuChannel(e.target.value.toString().replace(/[^0-9a-z]/gi, '').substr(0, 9).toUpperCase())
                        }}
                        value={newPC9}
                        readOnly={false} />
                </div>
            </Columns.Column>

            {!existInPT && newPC9.length > 9 ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}          >
                    <Columns.Column size={12}>
                        <strong> Selecciona marca, género y categoría (*)</strong>
                    </Columns.Column>

                    <Columns.Column>
                        <Form.Select
                            className="move-season"
                            onChange={(e) => setSelectedBrand(e.target.value)}
                            value={selectedBrand === null ? "" : selectedBrand}
                        >
                            <option value='' disabled >
                                Marca
                            </option>
                            <option value="Levi's®" >
                                Levi's®
                            </option>
                            <option value="Dockers®" >
                                Dockers®
                            </option>
                            <option value="Denizen®" >
                                Denizen®
                            </option>

                        </Form.Select>&nbsp;

                        <Form.Select
                            className="move-season"
                            onChange={(e) => setSelectedGender(e.target.value)}
                            value={selectedGender === null ? "" : selectedGender}

                        >
                            <option value='' disabled >
                                Género
                            </option>
                            <option value="Men" >
                                Men
                            </option>
                            <option value="Women" >
                                Women
                            </option>
                            <option value="Plus" >
                                Plus
                            </option>
                            <option value='B&T' >
                                Big & Tall
                            </option>
                            <option value="Kids" >
                                Kids
                            </option>
                            <option value="No binario" >
                                No binario
                            </option>

                        </Form.Select>&nbsp;

                        <Form.Select
                            className="move-season"
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            value={selectedCategory === null ? "" : selectedCategory}

                        >
                            <option value='' disabled >
                                Categoría
                            </option>
                            <option value='Tops'  >
                                Tops
                            </option>
                            <option value='Bottoms'  >
                                Bottoms
                            </option>
                            <option value='Dresses'  >
                                Dresses
                            </option>
                            <option value='Accesories'  >
                                Accesories
                            </option>

                        </Form.Select>

                    </Columns.Column>

                    <Columns.Column size={12}>
                        Selecciona un canal de venta (opcional) &nbsp;
                        {selectedChannel ?
                           <a onClick={(e)=>{
                            e.preventDefault()
                            setSelectedChannel(null)}
                        }>Omitir</a> : null    
                    }
                 
                    </Columns.Column>

                    <Columns.Column>
                        <Form.Select
                            className="move-season"
                            onChange={(e) => setSelectedChannel(e.target.value)}
                            value={selectedChannel === null ? "" : selectedChannel}
                        >
                            <option value='' disabled >
                                Canal de venta
                            </option>
                            {channelsInDB.map((channel, index) => {
                                return (
                                    <option key={index} value={channel._id}>{channel.name}</option>
                                )
                            })}

                        </Form.Select>&nbsp;

                        {selectedChannel !== null ?
                          

        
                                <input
                                    style={{ maxWidth: '160px' }}
                                    autoComplete="off"
                                    className="input"
                                    type="text"
                                    placeholder={'SKU Canal'}
                                    value={skuChannel}
                                    onChange={(e) => {
                                        setSkuChannel(e.target.value.toString().replace(/[^0-9a-z]/gi, '').substr(0, 14).toUpperCase())
                                    }}
                                />

                      



                            : null}

                    </Columns.Column>
                    <Columns.Column>
                        <Button
                            color="info"
                            onClick={() => createNewPC9()}
                            disabled={!fieldsComplete}
                        >
                            Crear PC9 y enviar a búsqueda
                        </Button>
                    </Columns.Column>

                </div>

                : null}


        </Columns>
    );
};

export default MoveToSeason;
