import React from 'react';
import SubCell from './SubCell';

function LinkCell(props) {

  
  const renderSubCell = () => {
    if (!props.column.subcell) {
      return;
    }

    return  (
      <SubCell {...props.column.subcell}/>
    );
  }

  return (
    <span>
      <a 
      {...props}
      onClick={(e) => props.onClick(e, props.row)}
      >{props.content}</a>
      &nbsp;
      {renderSubCell()}
    </span>
  );
}

export default LinkCell;