import React, {Component} from 'react';

import LogsService from '../../../../../../Services/LogsService';
import {
  Button,
  Columns,
  Form
} from 'react-bulma-components';
import { toast } from 'react-toastify';

import { LOGS } from '../../../../../General/Language/es';

class CreateResponse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response: '',
      loading: false
    };

    this.logsService = new LogsService();
  }

  changeHandler = (e) => {
    this.setState({
      response: e.target.value
    });
  }

  setLoandig = (loading) => {
    this.setState({
      loading: loading
    });
  }

  submitHandler = (e) => {
    e.preventDefault();

    this.setLoandig(true);
    
    let data = {
      id_log_link: this.props.commentId,
      data: {
        response: this.state.response
      }
    };

    this.logsService.post({
      co: [
        data
      ]}, this.props.commentId)
      .then((response) => {
        for (let err in response.error) {
          if (!response.error[err].code) {
            this.props.onChange();
            this.resetForm();
            toast.success(LOGS.RESPONSE.CREATE_SUCCESS);
          } else {
            toast.error(LOGS.RESPONSE.CREATE_ERROR);
          }
        }
        this.setLoandig(false);
      });
  }

  resetForm = () => {
    this.setState({
      response: ''
    });
  }

  render() {
    return (
      <form onSubmit={this.submitHandler}>
        <Columns>
          <Columns.Column size={12}>
            <Form.Textarea 
              onChange={this.changeHandler}
              rows={1}
              value={this.state.response}
              disabled={this.state.loading}
            />
          </Columns.Column>
          <Columns.Column size={12} >
            <Button 
              className="is-pulled-right"
              color="info"
              size="small"
              type="submit"
              disabled={this.state.response.length < 2 || this.state.loading}
            >
              Responder
            </Button>
          </Columns.Column>
        </Columns>
      </form>
    );
  }
}

CreateResponse.propTypes = {

};

CreateResponse.defaultProps = {

};

export default CreateResponse;