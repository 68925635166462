import React, { Component } from 'react';

import {
  Columns
} from "react-bulma-components/full";

class UploadEmptyRows extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return(
      <Columns className="is-vcentered">
        <Columns.Column>
          <span>No se han cargado PC9's a esta temporada</span>
        </Columns.Column>
      </Columns>
    );
  }
}

export default UploadEmptyRows;
