export const STATUS = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  RESOLVED: 'resolved'
};

export const TYPE = {
  COMMENT: 'comment',
  RESPONSE: 'response',
  SYSTEM: 'system'
};

export const SECTIONS = {
  BATCH: 'batch',
  CHANNEL: 'channel',
  ITEM: 'item',
  PROFILE: 'profile',
  SHOOTING: 'shooting'
};