import React, { Component } from 'react';

import Datatable from '../../General/Datatable/Datatable';
import ModalCard from '../../General/Modal/ModalCard';
import QuotationForm from './QuotationForm'

import { faFolderPlus, faTags, faDownload, faClock, faEye, faCross, faCheckCircle, faCalendarTimes, faMinusCircle, faUpload, faBoxOpen, faLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../General/Loader/Loader'
import { faPaperPlane, faFilePdf, faFileAlt } from '@fortawesome/free-regular-svg-icons';

import QuotationService from '../../../Services/QuotationService';
import QuotationItems from '../components/QuotationItems';
import SendQuotation from './SendQuotation';
import UploadQuotation from './UploadQuotation';
import ReviewQuotation from './ReviewQuotation';
import GenerateChannelLink from '../../Items/components/GenerateChannelLink';
import { QUOTATIONS } from '../../General/Language/es';
import { toast } from 'react-toastify';

class QuotationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      table: {
        checkCounter: false,
        searcheable: true,
        emptyLegend: 'Sin cotizaciones',
        loadingLegend: <Loader />,
        searchFields: ['name'],
        actions: [{
          title: "Nueva cotización",
          icon: faFolderPlus,
          label: 'Nueva cotización',
          className: 'is-info',
          onClick: () => this.newQuotation()
        }],
        columns: [
          {
            label: 'Nombre',
            field: 'name',
            sortable: true,
            type: 'link',
            onClick: (e, quotation) => this.quotationDetails(quotation),
          }, {
            label: "PC9's",
            field: 'items_stack.length',
            type: 'link',
            onClick: (e, quotation) => this.quotationItems(quotation),
            sortable: true
          }, {
            label: 'Solicitante',
            field: 'id_owner.email',
            sortable: true
          }, {
            label: 'Solicitado',
            field: 'request_date',
            format: ['MOMENT::DD/MM/YYYY hh:mm'],
            sortable: true
          }, {
            dictionary: QUOTATIONS.STATUS_BM,
            sortable: true,
            field: 'status',
            label: 'Estatus',
            color: (quotation) => {
              let colors = {
                pending: '-',
                sent: 'primary',
                answered: 'primary',
                accepted: 'success',
                rejected: 'primary',
                expired: 'primary',
                eliminated: 'primary'
              };

              return colors[quotation.status] ? colors[quotation.status] : '';
            },
            icon: (quotation) => {
              let icons = {
                pending: faClock,
                sent: faFileAlt,
                answered: faEye,
                accepted: faCheckCircle,
                rejected: faCross,
                expired: faCalendarTimes,
                eliminated: faMinusCircle
              };

              return icons[quotation.status] ? icons[quotation.status] : null;
            },
            title: (quotation) => quotation.status,
            type: 'icon'
          }, {
            label: 'Acciones',
            type: 'actions',
            actions: [
              {
                title: "Solicitar cotización",
                icon: faPaperPlane,
                label: 'Enviar',
                color: 'primary',
                visible: (quotation) => (quotation.status === 'pending'),
                onClick: (quotation) => this.sendQuotation(quotation)
              }, {
                title: "Cargar cotización",
                icon: faUpload,
                color: 'default',
                visible: (quotation) => (quotation.status === 'sent'),
                onClick: (quotation) => this.uploadQuotation(quotation)
              }, {
                title: "Ver cotización",
                icon: faFilePdf,
                color: 'info',
                visible: (quotation) => (quotation.status === 'answered'),
                onClick: (quotation) => this.reviewQuotation(quotation)
              }, {
                title: "Descargar",
                icon: faDownload,
                color: 'success',
                visible: (quotation) => (quotation.status === 'accepted'),
                onClick: (quotation) => this.generateLink(quotation)
              }
            ]
          }
        ],
        filtersConfig: {
          status: {
            label: 'Estatus',
          }
        }
      },
      modal: {
        show: false,
        title: ''
      }
    }
    this.quotationService = new QuotationService();
  }


  constructTableWithUserInfo = (user) => {
    let table = {};
    switch (user.code) {
      case "brand_manager":
        table = {

          checkCounter: false,
          searcheable: true,
          emptyLegend: 'Sin cotizaciones',
          loadingLegend: <Loader />,
          searchFields: ['name'],
          actions: [],
          columns: [
            {
              label: 'Nombre',
              field: 'name',
              sortable: true,
              type: 'link',
              onClick: (e, quotation) => {
                this.quotationDetails(quotation)
              },
            },
            {
              label: "PC9's",
              field: 'items_stack.length',
              type: 'link',
              onClick: (e, quotation) => {
                this.quotationItems(quotation)
              },
              sortable: true
            }, {
              label: 'Solicitante',
              field: 'id_owner.email',
              sortable: true
            },
            {
              label: 'Solicitado',
              field: 'request_date',
              format: ['MOMENT::DD/MM/YYYY hh:mm'],
              sortable: true
            }, {
              dictionary: QUOTATIONS.STATUS_BM,
              sortable: true,
              field: 'status',
              label: 'Estatus',
              color: (quotation) => {
                let colors = {
                  pending: '-',
                  sent: 'grey-light',
                  answered: 'grey-light',
                  accepted: 'success',
                  rejected: 'primary',
                  expired: 'primary',
                  eliminated: 'primary'
                };

                return colors[quotation.status] ? colors[quotation.status] : '';
              },
              icon: (quotation) => {
                let icons = {
                  pending: faClock,
                  sent: faClock,
                  answered: faEye,
                  accepted: faCheckCircle,
                  rejected: faCross,
                  expired: faCalendarTimes,
                  eliminated: faMinusCircle
                };

                return icons[quotation.status] ? icons[quotation.status] : null;
              },
              title: (quotation) => quotation.status,
              type: 'icon'
            }, {
              label: 'Acciones',
              type: 'actions',
              actions: [
                {
                  title: "Cargar cotización",
                  icon: faUpload,
                  color: 'primary',
                  visible: (quotation) => { if (quotation.status === 'sent') { return true } return false },
                  onClick: (quotation) => {
                    this.uploadQuotation(quotation)
                  }
                }, {
                  title: "Generar link",
                  icon: faLink,
                  color: 'success',
                  visible: (quotation) => { if (quotation.status === 'accepted') { return true } return false },
                  onClick: (quotation) => {
                    this.generateLink(quotation)
                  }
                }
              ]
            }
          ],
          filtersConfig: {
            status: {
              label: 'Estatus',
            }
          }
        }
        break;
      case "latam":
        table = {
          checkCounter: false,
          searcheable: true,
          emptyLegend: 'Sin cotizaciones',
          loadingLegend: <Loader />,
          searchFields: ['name'],
          actions: [{
            title: "Nueva cotización",
            icon: faFolderPlus,
            label: 'Nueva cotización',
            className: 'is-info',
            onClick: () => {
              this.newQuotation()
            },
          }],
          columns: [
            {
              label: 'Cotización (click para detalle)',
              field: 'name',
              sortable: true,
              type: 'link',
              onClick: (e, quotation) => {
                this.quotationDetails(quotation)
              },
            },
            {
              label: "PC9's",
              field: 'items_stack.length',
              type: 'link',
              onClick: (e, quotation) => {
                this.quotationItems(quotation)
              },
              sortable: true
            },
            {
              label: 'Solicitado',
              field: 'request_date',
              format: ['MOMENT::DD/MM/YYYY hh:mm'],
              sortable: true
            }, {
              dictionary: QUOTATIONS.STATUS_LATAM,
              sortable: true,
              field: 'status',
              label: 'Estatus',
              color: (quotation) => {
                let colors = {
                  pending: 'grey-light',
                  sent: 'grey-light',
                  answered: 'grey-light',
                  accepted: 'success',
                  rejected: 'primary',
                  expired: 'primary',
                  eliminated: 'primary'
                };

                return colors[quotation.status] ? colors[quotation.status] : '';
              },
              icon: (quotation) => {
                let icons = {
                  pending: faBoxOpen,
                  sent: faClock,
                  answered: faEye,
                  accepted: faCheckCircle,
                  rejected: faCross,
                  expired: faCalendarTimes,
                  eliminated: faMinusCircle
                };

                return icons[quotation.status] ? icons[quotation.status] : null;
              },
              title: (quotation) => quotation.status,
              type: 'icon'
            },
            {
              label: 'Acciones',
              type: 'actions',
              actions: [
                {
                  title: "Solicitar cotización",
                  icon: faPaperPlane,
                  label: 'Enviar',
                  color: 'default',
                  visible: (quotation) => { if (quotation.status === 'pending') { return true } return false },
                  onClick: (quotation) => {
                    this.sendQuotation(quotation)
                  }
                }, {
                  title: "Eliminar cotización",
                  icon: faTrash,
                  label: 'Eliminar',
                  color: 'danger',
                  visible: (quotation) => { if (quotation.status === 'pending') { return true } return false },
                  onClick: (quotation) => {
                    this.deleteQuotation(quotation)
                  }
                },{
                  title: "Ver cotización",
                  icon: faFilePdf,
                  color: 'primary',
                  visible: (quotation) => { if (quotation.status === 'answered') { return true } return false },
                  onClick: (quotation) => {
                    this.reviewQuotation(quotation)
                  }
                }, {
                  title: "Generar link de descarga",
                  icon: faLink,
                  color: 'success',
                  visible: (quotation) => { if (quotation.status === 'accepted') { return true } return false },
                  onClick: (quotation) => {
                    this.generateLink(quotation)
                  }
                }
              ]
            }
          ]
        }
        break;
      default:
        break;
    }

    this.setState({
      table
    }, () => this.fetch());
  }

  componentWillMount = () => {
    this.constructTableWithUserInfo(this.props.loggedInUser);
  }

  fetch = () => {
    let user = this.props.loggedInUser
    let queryString = {}
    if (user.code === 'brand_manager') {
      queryString = {
        rf: ['name', 'status', 'items_stack', 'additional_info', 'estimate_result', 'request_date', 'id_owner'],
        qf: {
          status: ['sent', 'answered', 'accepted', 'rejected']
        }
      }
    } else if (user.code === 'latam') {
      queryString = {
        rf: ['name', 'status', 'items_stack', 'additional_info', 'estimate_result', 'request_date', 'id_owner'],
        qf: {
          status: ['pending', 'sent', 'answered', 'accepted', 'rejected'],
          id_owner: user._id
        }
      }
    }

    this.quotationService.get(queryString)
      .then((response) => {
        this.setState({
          data: response.d,
          loading: false
        })
      });
  }

  closeModal = () => {
    let modal = {
      show: false
    };

    this.setState({
      modal: modal
    });
  }

  deleteQuotation = (quot) => {
    this.quotationService.put({
        ol: [ quot._id ],
        co: [ { status : "eliminated" } ]
      })
      .then(resp => {
        console.log(resp)
        toast.success('La cotización '+ quot.name + 'ha sido eliminada')
        this.fetch();
      });
  }


  newQuotation = () => {
    let modal = this.state.modal;
    modal.title = 'Nueva cotización';
    modal.body = (
      <QuotationForm
        close={this.closeModal}
        loggedInUser={this.props.loggedInUser}
        fetch={this.fetch}
      />
    );
    modal.show = true;
    this.setState({
      modal: modal
    });
  }

  sendQuotation = (quotation) => {
    let modal = this.state.modal;
    modal.title = 'Revisa y envía';
    modal.body = (
      <SendQuotation
        quotation={quotation}
        close={this.closeModal}
        loggedInUser={this.props.loggedInUser}
        fetch={this.fetch}
      />
    );
    modal.show = true;
    this.setState({
      modal: modal
    });
  }

  uploadQuotation = (quotation) => {
    let modal = this.state.modal;
    modal.title = 'Revisa y envía';
    modal.body = (
      <UploadQuotation
        quotation={quotation}
        close={this.closeModal}
        loggedInUser={this.props.loggedInUser}
        fetch={this.fetch}
      />
    );
    modal.show = true;
    this.setState({
      modal: modal
    });
  }

  quotationDetails = (quotation) => {
    let modal = this.state.modal
    modal.title = 'Detalles'
    modal.body = (
      <ReviewQuotation
        isViewOnly={true}
        quotation={quotation}
        close={this.closeModal}
        loggedInUser={this.props.loggedInUser}
        fetch={this.fetch}
      />
    );
    modal.show = true;

    this.setState({
      modal: modal
    });
  }

  reviewQuotation = (quotation) => {
    let modal = this.state.modal;
    modal.title = 'Aprobar cotización';
    modal.body = (
      <ReviewQuotation
        quotation={quotation}
        close={this.closeModal}
        loggedInUser={this.props.loggedInUser}
        fetch={this.fetch}
      />
    );
    modal.show = true;

    this.setState({
      modal: modal
    });
  }

  generateLink = (quotation) => {

    let modal = this.state.modal;
    modal.title = 'Generar link de descarga';

    modal.body = (
      <GenerateChannelLink
        quotation={quotation}
        loggedInUser={this.props.loggedInUser}
        close={this.closeModal}
        seasonId={this.props.seasonId}
        type={'quotation'}
      />
    );
    modal.show = true;
    this.setState({
      modal: modal
    })
  }

  quotationItems = (quotation) => {
    this.props.addTab({
      label: quotation.name,
      icon: faTags,
      content: QuotationItems,
      props: {
        closeTab: true,
        quotation: quotation,
        loggedInUser: this.props.loggedInUser
      }
    });
  }




  render() {
    return (
      <div>
        <ModalCard
          {...this.state.modal}
          closeModal={this.closeModal}
        />
        <Datatable
          {...this.state.table}
          data={this.state.data}
          loading={this.state.loading}
        />
      </div>
    )
  }
}

export default QuotationTable;
