import React, { Component } from 'react';

import {
  Icon,
  Tabs
} from "react-bulma-components/full";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInbox
} from '@fortawesome/free-solid-svg-icons';

import QuotationsTable from './components/QuotationTable'

import BatchService from '../../Services/BatchService';

class Quotations extends Component {
  constructor(props) {
    super(props);
    this.tabIndex = React.createRef();

    this.state = {
      loggedInUser:this.props.loggedInUser,
      activeTab: 0,
      batchs: [],
      tabs: [
        {
          label: 'Cotizaciones',
          icon: faInbox,
          content: QuotationsTable,
          props:{
            loggedInUser:this.props.loggedInUser,
          }
        }
      ]
    };

    this.batchService = new BatchService();
  }


  addTab = (tab) => {
    let tabs = this.state.tabs;
    tabs[1] = tab;
    this.setState({
      tabs: tabs,
      activeTab: 1
    });
  }

  componentDidMount() {
    this.batchService.get({
      rf: ['_id', 'name']
    }).then((response) => {
      let batchIds = response.d.map((batch) => {
        return batch._id;
      });

      this.setState({
        batchs: batchIds
      });
    });
  }

  onChangeDatatable = (state) => {
    let {renderRows, checkList} = state;

    this.setState({
      checkList: checkList,
      renderRows: renderRows
    });
  }

  setActiveTab = (index) => {
    this.setState({
      activeTab: index
    });
  }

  closeTab = (e = null) => {
    if (e) {
      e.stopPropagation();
    }
    let tabs = this.state.tabs;
    tabs.splice(1, 1);

    this.setState({
      activeTab: 0,
        tabs: tabs
    });
  }

  render() {
    let MyTab = this.state.tabs[this.state.activeTab].content;

    return (
      <div>
        <Tabs>
          {this.state.tabs.map((tab, index) => {
            return (
              <Tabs.Tab
                active={this.state.activeTab === index}
                onClick={(e) => this.setActiveTab(index)}
                key={index}
              >
                <Icon size="small" >
                  <FontAwesomeIcon icon={tab.icon}/>
                </Icon>
                {tab.label} &nbsp;
                {tab.props && tab.props.closeTab ?
                  <span className="delete is-small" onClick={this.closeTab}></span> :
                  ''
                }
              </Tabs.Tab>
            );
          })}
        </Tabs>

        <div className="tab-content">
          <MyTab
            {...this.state.tabs[this.state.activeTab].props}
            addTab={this.addTab}
            closeTab={true}
          />
        </div>
      </div>
    );
  }
};

export default Quotations;
