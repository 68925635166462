import React from 'react';
import PropTypes from 'prop-types';
import Row from './components/Row';
import Empty from './components/Empty';

const TBody = (props) => {

  return (
    <tbody>
      {!props.data.length || props.loading ?
        <Empty 
          columnsLength={props.columns.length}
          emptyLegend={props.filtered ? props.emptyFilterLegend : props.emptyLegend}
          loading={props.loading}
          loadingLegend={props.loadingLegend}
        />
      :
        props.data.map((element, index) => {
          return (
            <Row 
              {...props}
              row={element}
              key={index}
            />);
        })
      }
    </tbody>
  );
}

TBody.propTypes = {
  data: PropTypes.array,
  emptyFilterLegend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  emptyLegend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loadingLegend: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

TBody.defaultProps = {
  data: [],
  emptyFilterLegend: <b>Not match filters</b>,
  emptyLegend: <b>Empty</b>,
  loading: false,
  loadingLegend: <b>Loading...</b>
};

export default TBody;
