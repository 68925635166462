import React, {Component} from 'react';
import moment from 'moment';
import ShootingService from '../../../../Services/ShootingService';

import {
  Box,
  Button,
  Form,
  Tag
} from 'react-bulma-components/full';

import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

//import { STATUS } from '../../../General/Constants/Item';
import { SHOOTING } from '../../../General/Language/es';
import ItemsService from '../../../../Services/ItemsService';

class AddToShooting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      add: {
        shooting: '',
        _items: Array.from(this.props.checkList)
      },
      checkList: [],
      loading: false,
      shootings: []
    }
    this.shootingService = new ShootingService();
    this.itemsService = new ItemsService()
  }

  componentWillMount() {
    this.shootingService.get({
      qf: {
        status: {
          $in : ['new','inProgress']
        },
        type: this.props.type
      }
    }).then((response) => {
      this.setState({
        shootings: response.d
      });
    });
  }

 changeStatus = async (itemId, job, nextStatus) => {

  switch (this.props.type) {
    case 'eComm':
        job = 'current'
        break;
    case 'pGuide':
        job = 'product_guide'
        break;
    default:
        job = 'current'
        break;
}

    let lookingFor = `status.${job}`
    await this.itemsService.put(itemId, {
        co: [{
            [lookingFor]: nextStatus
        }]
    })
    return true
}

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.add)
    let data = {
      ol: [this.state.add.shooting],
      co: [
        {
          items_stack: this.props.checkList
        }
      ]
    };
    console.log(data)
    this.shootingService.put(data)
    .then((response) => {
      for (let iErr in response.error) {
        if (response.error[iErr].code === 0) {
          toast.success(SHOOTING.ADDTO.SUCCESS);
          //console.log(this.props.checkList)
          for (let j=0;j<this.props.checkList.length;j++){
            this.changeStatus(this.props.checkList[j],this.props.type,'assigned')
          }
          this.props.updateTable();
        } else {
          toast.error(SHOOTING.ADDTO.ERROR);
        }
      }

      this.props.close();
    });
  }

  onChange = (e) => {
    let add = this.state.add;
    add[e.target.name] = e.target.value;
    this.setState({
      add: add
    });
  }

  createNewShooting =()=>{
    console.log('Nuevo shooting')
  }

  render() {
    return (
      <Box className="is-marginless">
        <form onSubmit={this.handleSubmit}>
          <div className="field is-horizontal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <div className="field-label is-normal">
              <label className="label">PC9</label>
            </div>
            <div className="field-body">
              <div className="field">
                <Tag.Group>
                  <Tag color="info">{this.props.checkList.length}</Tag>
                  <Tag>Piezas</Tag>
                </Tag.Group>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Shooting</label>
            </div>
            <div className="field-body">
              <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>  
                <Form.Select onChange={this.onChange} name="shooting" value={this.state.add.shooting} style={{width:'250px'}}>
                  <option value="" disabled>Selecciona un shooting</option>
                  {this.state.shootings.map((element, index) => {
                    return (
                      <option value={element._id} key={index}>{element.name} ({moment(element.scheduled_date).format('YYYY/MM/DD')+ ' '+element.type})</option>
                    );
                  })}
                </Form.Select>&nbsp;&nbsp;
                  {/* <a onClick={(e) => this.createNewShooting()}>O crea uno nuevo</a> */}
              </div>
            </div>
          </div>
          <div className="field is-grouped is-grouped-right">
            <Button color="success" type="submit" disabled={this.state.loading || this.props.checkList.size < 1}>Agregar</Button>
          </div>
        </form>
      </Box>
    );
  }
}

AddToShooting.propTypes = {
};

AddToShooting.defaultProps = {
};
  
export default AddToShooting;