import React, { useState } from 'react';
import ItemsService from '../../../../Services/ItemsService';
import ShootingService from '../../../../Services/ShootingService';

import {
  Button,
  Columns,
  Image
} from "react-bulma-components/full";
import { toast } from 'react-toastify';
const itemsService = new ItemsService()
const shootingService = new ShootingService();

const PrevAssignedItems =(props)=>{

  const [actualItem, setActualItem ] = useState(0)
  
  const getCategoryIcon = () => {
      console.log(props.items[actualItem])
      const category = props.items[actualItem].item.category;
      var categoryIcon = "";
      switch (category) {
        case "Tops":
        case "TOPS":
          categoryIcon = "./images/icon_category/tops.png";
          break;
        case "Bottoms":
        case "BOTTOMS":
          categoryIcon = "./images/icon_category/bottoms.png";
          break;
        case "Belts":
          categoryIcon = "./images/icon_category/belts.png";
          break;
        case "Wallets":
          categoryIcon = "./images/icon_category/wallet.png";
          break;
        case "Underwear":
        case "Duo Pack":
          categoryIcon = "./images/icon_category/boxers.png";
          break;
        case "Headwear":
          categoryIcon = "./images/icon_category/cap.png";
          break;
        default:
          categoryIcon = "./images/icon_category/battery.png"
      }
  
      return categoryIcon;
    }
    const getBrandLogo = () => {
      const brand = props.items[actualItem].item.brand
      var brandLogo = ""
      switch (brand) {
        case "Levi's":
        case "Levis":
        case "Levi's®":
        case "LEVIS":
        case "LEVI'S":
        case "Levi´s":
        case "Levi`s":  
          brandLogo = "./images/brand_logos/levi.png";
          break;
        case "Denizen":
        case "DENIZEN":
          brandLogo = "./images/brand_logos/denizen.png"
          break;
        case "Dockers":
        case "Dockers®":
        case "DOCKERS":
          brandLogo = "./images/brand_logos/dockers.png";
          break;
        case "Levi's Signature":
        case "Signature":
        case "Levis Signature":  
          brandLogo = "./images/brand_logos/signature.png";
          break;
        default:
      }
      
      return brandLogo;
    }

    const categoryIcon = getCategoryIcon();
    const brandLogo = getBrandLogo();
    
    
    const changeStatus = async (itemId, job, nextStatus) => {
      let lookingFor = `status.${job}`
      itemsService.put(itemId, {
          co: [{
              [lookingFor]: nextStatus
          }]
      })
      .then(resp=>{
          console.log(resp)
      })
      .catch(error=>{
          console.log(error)
      })
  }

  const deleteFromShooting=()=>{
    if (actualItem === props.items.length - 1) {  
      // Delete from 
      shootingService.deleteItemFromShooting(props.shootingId, {
        co:[
          {
            "$action":"delete",
            items_stack:[props.items[actualItem]._id],
          }
        ]
      });

      setActualItem(actualItem + 1);

      if (props.itemsWithPhotos.length > 0) {
        toast.info("Revisión de PC9's previamente asignados: Finalizada ["+ (actualItem+1) +"/"+props.items.length+"]")
        toast.info("Iniciando revisión de PC9's con fotos")
          props.showNextModal(props.type)
      } else {
        toast.info("Revisión de PC9's previamente asignados: Finalizada ["+ (actualItem+1) +"/"+props.items.length+"]")
        toast.success("Shooting creado con éxito")
        props.closeThisModal()
        props.closeShootingModal()
      }
          
    } else {
      shootingService.deleteItemFromShooting(props.shootingId, {
        co:[
          {
            "$action":"delete",
            items_stack:[props.items[actualItem]._id],
          }
        ]
      });
        
      toast.info("Revisa el siguiente PC9")
      setActualItem(actualItem + 1)
    }
  };

  const resetProcess = () => {
    let type = props.type;
    let job = 'current';

    switch (type) {
      case 'eComm':
        job = 'current'
        break;
      case 'pGuide':
        job = 'product_guide'
        break;
      default:
        job = 'current'
        break;
    }

    if (actualItem === props.items.length - 1) {
      changeStatus(props.items[actualItem]._id, job, 'pending');
      toast.info("PC9: "+props.items[actualItem].pc9+ ' enviado a búsqueda');
      setActualItem(actualItem + 1);
      if (props.itemsWithPhotos.length > 0) {
        toast.info("Revisión de PC9's previamente asignados: Finalizada ["+ (actualItem+2) +"/"+props.items.length+"]");
        toast.info("Iniciando revisión de PC9's con fotos");
        props.showNextModal(props.type);
      } else {
        toast.info("Revisión de PC9's previamente asignados: Finalizada ["+ (actualItem+2) +"/"+props.items.length+"]");
        toast.success("Shooting creado con éxito");
        props.closeThisModal();
        props.closeShootingModal();
      }
        
    } else {
      changeStatus(props.items[actualItem]._id, job, 'pending');
      toast.warning("PC9: "+props.items[actualItem].pc9+ ' enviado a búsqueda');
      toast.info("Revisa el siguiente PC9 ["+ (actualItem+1) + "/" + props.items.length + "]");
      setActualItem(actualItem + 1)
    }
  };

  const navigate = () => {
    if (actualItem === props.items.length - 1) {
      toast.info("Revisión de PC9's previamente asignados: Finalizada")
      setActualItem(actualItem + 1)
      if(props.itemsWithPhotos.length > 0){
        toast.info("Iniciando revisión de PC9's con fotos");
        props.showNextModal(props.type);
      } else {
        props.closeThisModal();
        toast.success("Shooting creado con éxito");
        props.closeShootingModal();
      }
          
    } else {
      toast.info("Revisa el siguiente PC9 ["+ (actualItem+1) +"/"+props.items.length+"]");
      setActualItem(actualItem + 1);
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2><strong>PC9:</strong> {props.items[actualItem].pc9}</h2>
        <h2><strong>{'Estatus ' + props.type + ':'}</strong> {props.items[actualItem].status}</h2>
        <strong>{actualItem + 1 + " de " + props.items.length + " PC9's"} </strong>
      </div>
      <hr />
      <Columns className="has-text-right" style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: "12px"
      }} >
        <Columns.Column>
          <Image size={64} alt="64x64" src={brandLogo} />

        </Columns.Column>
        <Columns.Column>

          <Image src={categoryIcon} alt="Cat" size={48} />
        </Columns.Column>
        <Columns.Column>
          <b>Género</b>
          <p>{props.items[actualItem].item.gender}</p>
        </Columns.Column>
        <Columns.Column>
          <b>Colección</b>
          <p>{props.items[actualItem].item.clothes_collection}</p>
        </Columns.Column>
        <Columns.Column>
          <b>Tipo</b>
          <p>{props.items[actualItem].item.line}</p>
        </Columns.Column>
        <Columns.Column>
          <b>Color</b>
          <p>{props.items[actualItem].item.simple_color}</p>
        </Columns.Column>
      </Columns>

      <br />
      <Columns size={2} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>

      </Columns>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button color='grey-light' onClick={deleteFromShooting}>No asignar</Button>&nbsp;&nbsp;
        <Button color='warning' onClick={resetProcess}>Asignar y buscar</Button>&nbsp;&nbsp;
        <Button color='success' onClick={navigate}>Sólo asignar</Button>
      </div>

    </div>
  )
}

export default PrevAssignedItems;
