import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const VerticalProgressBar = ({ height, label, max, shadow, value, width }) => {
  return (
    <div className="vertical-progress-bar">
      {label && 
        <div className="vpb-top">
          <p>{label}</p>
        </div>
      }
      <div className="vpb-wrapper" 
        style={{
          content: `${value ? Math.round((value / max) * 100) : 0}%`,
          height, width
        }}
        title={`${value ? Math.round((value / max) * 100) : 0}%`}
      >
        <div
          className="bar"
          style={{ height: `${value ? Math.round((value / max) * 100) : 0}px`}}
        >
          {value && Math.round((value / max) * 100) > 15 &&
            `${value ? Math.round((value / max) * 100) : 0}%`
          }
        </div>
        
        {!!shadow  && (
          <div className="shadow"
            style={{
              height: `calc(${Math.round((shadow / max) * 100) }px + 12px)`
            }}
          ></div>
        )}
        
        {/* <div className="percentage">{`${value ? Math.round((value / max) * 100) : 0}%`}</div> */}
      </div>
      <div className="vpb-bottom">{`(${value})`}</div>
    </div>
  );
};

VerticalProgressBar.propTypes = {
  max: PropTypes.number
};

VerticalProgressBar.defaultProps = {
  max: 100
};

export default VerticalProgressBar;
